import React, { Fragment, useEffect, useState, useContext, useRef } from 'react';
import { Link, useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { SocialContext } from '../../contexts/socialContext';
import { Persona } from '../Persona';
import { Preloader, Preloader0 } from '../UiKit/kit';
import { StateContext } from '../../contexts/stateContext';
import { City } from '../UiKit/City';
import { LangContext } from '../../contexts/langContext';
import { parseDesignInPersonas } from '../utilsDesign';

export function ListAvatars(props) {
  const context = { ...useContext(LangContext), ...useContext(StateContext), ...useContext(SocialContext) };
  const $ui = context.$ui;
  const {
    customHandler,
    fullList = false,
    listId = '',
    quantity = 0,
    array,
    exclude = [],
    actions,
    refetch,
    createNew,
    customGrigClass = ''
  } = { ...props };
  const [searchParams] = useSearchParams();
  const [arr, setArr] = useState(array);

  useEffect(() => {
    // console.log('array = ', array);
    // setArr(parseDesignInPersonas(array.filter(one => !exclude.includes(one.user.token))))
    setArr(parseDesignInPersonas(array))
  }, [array])


  const panelExpanded = context.state.peoplePanel;



  return (
    <>

      {
        !arr.length &&
        Boolean(quantity) && <Preloader0
        height='50vh'
            width='100%'
      >
        <Preloader width={80} height={80} />
        {$ui('loading')}
      </Preloader0>}



      {array && Boolean(arr.length) && <div
        className={`${Boolean(customGrigClass) ? customGrigClass : `x_choose ${panelExpanded ? `x_choose--v` : ''} ${listId ? `x_choose--${listId}` : ''}`} `}
      >


        <>{array
          .filter(one => !(one.user && exclude.includes(one.user.token)))
          .map((one = { avatar: {}, user: {} }, ind) => {

        return (
          <Persona
            customHandler={customHandler}
            // key={`friend_${ind}`
            // }
            listId={listId}
            actions={actions}
            key={`list_${listId}_persona_${one.avatar && one.avatar.token}`}
            panelExpanded={panelExpanded}
            avatar={one.avatar}
            user={one.user}
            followings={one.followings}
            followers={one.followers}
            isMe={one.avatar.isMe}
            fullList={fullList}
            refetch={refetch}
            addClassName={panelExpanded ? 'mypeople' : 'people'}
          />)
        })}</>

        {createNew}

      </div>}





    </>
  )
}


