import React, { Fragment, useEffect, useState, useContext, useRef } from 'react';
import { Link, useNavigate, useSearchParams, useParams, useLocation } from 'react-router-dom';


import { QR } from '../UiKit/QR';
import { Avatar } from '../UiKit/Avatar';
import { getPersonUrl, getPrivateUrl, getUrl } from '../utilsSocial';
import { StateContext } from '../../contexts/stateContext';
import { SocialContext } from '../../contexts/socialContext';
import { LangContext } from '../../contexts/langContext';
import { convertGMTtoDateTime, localizeDate } from '../utilsDesign';
import { Preloader0, Preloader } from '../UiKit/kit';
import { AvatarInfoVisibility } from './MeInfo';
import { Persona } from '../Persona';
import { Button } from '../UiKit/Button';
import { PreloaderHD } from '../UiKit/PreloaderHD';
import { Switch } from '../UiKit/Switch';
import { Hr } from '../UiKit/Hr';

export const PersonaInfo = React.memo(({ user = {}, avatar = {}, handleClose }) => {

  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ui = context.$ui;

  const [title, setTitle] = useState(avatar.name ? avatar.name : context.getDefaultTitle(avatar.design));
  const [image, setImage] = useState(avatar.image ? avatar.image : '');

  const [copydone, setCopydone] = useState(false);

  const [showName, setShowName] = useState(true);
  const [followMe, setFollowMe] = useState(true);

  const [privateNote, setPrivateNote] = useState(avatar.privateNote ? avatar.privateNote : '');

  const inputRef = useRef(null);


  const getUrl = (avatar = {}) => {
    if (!avatar.aggr.city) return false

    return getPrivateUrl(avatar, followMe ? context.me : null, context.language, followMe ? 'copytrue' : false)

    // let tz = avatar.aggr.city.timezone;
    // tz = tz.replace('/', '%2F');
    // let url = `https://hd.cafe/public/?timezone=${tz}&dob=${avatar.dob}&city_id=${avatar.city_id}&city=${avatar.aggr.city.city}&title=${avatar.name}${user ? `&invited_by=${user.token}` : ''}&copy=true#ln=${context.language}`;
    // url = url.replace(' ', '%20');
    // // const url = `https://hd.cafe/u/${avatar.token}`
    // return url;
  }
  // const getPublicUrl = () => {
  //   if (!avatar.aggr.city) return false
  //   return getUrl(avatar, context.me, context.language, 'copytrue')
  // }

  useEffect(() => {
    console.log('title = ', title);
  }, [title])

  let city = avatar.city;
  let dob = avatar.dob;
  let timezone = avatar.timezone;


  const handleCopy = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(getUrl({
        ...avatar,
        'name': showName ? avatar.name : ''
      }))
        .then(() => {
          console.log("Text copied to clipboard successfully!");
        })
        .catch(err => {
          console.error("Failed to copy text: ", err);
        });
    } else {
      console.error("Clipboard API not available.");
    }
  };

  const handleSave = () => {
    context.x.updateAvatar({
      'token': avatar.token,
      'name': title,
      'image': image,
      'privateNote': privateNote,
    })
    handleClose()
  };

  const navigate = useNavigate();
  return (
    <>
      <div className="m_b_c_scroll">
        <div className="m_b_cols">
          <div className="m_b_form0">
            <div className="m_b_form">
              <div className="x_calc_form_line">
                <div className="x_calc_form_title">{$ui('pop_edit_avatar_header')}</div>
              </div>
              <div className="x_calc_form_line">

                <div className="x_calc_form0 x_calc_form0--main">
                  <div className="x_calc_result_ava">
                    <Avatar
                      avatar={avatar}
                    />
                  </div>


                  {user && <div className="x_calc_form_line">
                    <div className="x_calc_label">
                      <div>{$ui('user_publicname_label')}</div>
                    </div>
                    <div className="x_calc_form0">
                      <input
                        placeholder={context.getDefaultTitle(avatar.design)}
                        type="text"
                        className="x_input x_input--name x_input--disabled"
                        value={title}
                        onChange={(e) => {
                          setTitle(e.target.value)
                        }}
                      />

                    </div>
                  </div>}

                  {!user && avatar.my && <div className="x_calc_form_line">
                    <div className="x_calc_label">
                      <div>{$ui('user_set_avatar_title')}</div>
                    </div>
                    <div className="x_calc_form0">
                      <input
                        placeholder={context.getDefaultTitle(avatar.design)}
                        type="text"
                        className="x_input x_input--name"
                        value={title}
                        onChange={(e) => {
                          setTitle(e.target.value)
                        }}
                      />

                    </div>
                  </div>}






                </div>
              </div>

              {/* <div className="x_calc_form_line">
                <div className="x_calc_label">
                  <div>{$ui('user_set_image_title')}</div>
                </div>
                <div className="x_calc_form0">
                  <input
                    placeholder='https://...'
                    type='text' className="x_input x_input--name"
                    // onFocus={(e) => e.target.select()}
                    defaultValue={image}
                  // value={image}
                  >

                  </input>



                </div>
              </div> */}


              {/* <div className="x_calc_form_line">
                <div className="x_calc_label">
                  {$ui('user_set_private_note')}
                </div>
                <div className="x_calc_form0">
                  <input
                    placeholder='Met this person on yoga...'
                    type='text' className="x_input x_input--name"
                    // onFocus={(e) => e.target.select()}
                    defaultValue={privateNote}
                    onChange={(e) => {
                      setPrivateNote(e.target.value)
                    }}
                  >

                  </input>

                </div>
              </div> */}


              <div className="x_calc_form_line">
                <div className="x_calc_form_info0">
                  <div className="x_calc_label">
                    <div>{$ui('pop_created')}:</div>
                  </div>
                  <div className="x_calc_form_info_value">
                    {avatar && <div>{localizeDate(convertGMTtoDateTime(avatar.created, null, 'date'), context.lang)} {convertGMTtoDateTime(avatar.created, null, 'time')}</div>}
                  </div>
                </div>
                <div className="x_calc_form_info0">
                  <div className="x_calc_label">
                    <div>{$ui('pop_updated')}:</div>
                  </div>
                  <div className="x_calc_form_info_value">
                    {avatar && <div>{localizeDate(convertGMTtoDateTime(avatar.updated, null, 'date'), context.lang)} {convertGMTtoDateTime(avatar.updated, null, 'time')}</div>}
                  </div>
                </div>
              </div>


              {avatar && avatar.my &&
                <div className="_f">
                  <div className={"x_calc_form0 x_calc_form0--submit"}>
                <div className={!context.updatingAva ? "x_button" : "x_button x_button--inactive"}
                  onClick={() =>
                    !context.updatingAva && handleSave()
                  }
                >
                  {!context.updatingAva && <div>{$ui('process_save')}</div>}
                  {context.updatingAva && <div>{$ui('process_saving')}...</div>}
                </div>

                <div className={"x_button x_button--flat"}
                  onClick={() => handleClose()}
                >
                  {$ui('pop_cancel')}
                </div>
                  </div>

                  <div className="_f100 _fr">
                    <Button ghost red onClick={() => {
                      context.x.deleteAvatar(avatar, () => navigate(`/u/${context.me.user.token}`))
                    }}>{$ui('but_body_delete_short')}</Button>
                  </div>


                </div>


              }







            </div>
          </div>
        </div>



        <div className="m_b_cols">
          <div className="m_b_form0">
            <div className="m_b_form">
              <div className="x_calc_form_line">
                <div className="x_calc_form_title">{$ui('pop_share')}</div>
              </div>



              <div className="x_info_form_preview_sections">



                <AvatarInfoVisibility
                  addPoints={showName ? {
                    'on': false, 'label': "pop_share_what_they_nick"
                  } : {
                    'on': true, 'label': "pop_share_what_they_nick"
                  }}


                  addComponents={<><Hr nogap transparent />
                    <div className="_fv">

                      <Switch
                        label={$ui('pop_share_show_name')}
                        handle={() => setShowName(prev => !prev)}
                        active={showName}
                      />
                      <Switch
                        tooltip={$ui('pop_share_follow_me_info')}
                        label={$ui('pop_share_follow_me')}
                        handle={() => setFollowMe(prev => !prev)}
                        active={followMe}
                      />
                    </div>

                    <Hr gap={.4} /></>}
                  view={'private'}
                  noSocial={followMe}
                >

                  <Persona
                    avatar={{
                      ...avatar,
                      'name': showName ? avatar.name : ''
                    }}
                    noClick={true}
                    active={false}
                    addLine={'none'}
                  />
                </AvatarInfoVisibility>



                <div className="m_b_form">

              <div className="x_calc_form_line">
                <div className="x_calc_form0">
                      {!Boolean(avatar.design) && <PreloaderHD nologo />}
                  <input
                    ref={inputRef}
                    disabled={Boolean(avatar.design.id === 'dummy')}
                    type="text"
                    className="x_input x_input--url x_input--disabled"
                    onFocus={(e) => e.target.select()}
                        defaultValue={Boolean(avatar.design.id !== 'dummy') ? getUrl({
                          ...avatar,
                          'name': showName ? avatar.name : ''
                        }) : $ui('pop_no_design')}
                  />
                  {avatar.design.id !== 'dummy' && <div className="x_input_right">
                    <div className="x_button x_button--sm" onClick={() => {
                      handleCopy()
                      setCopydone(true)
                      setTimeout(() => {
                        setCopydone(false)
                      }, 1000);
                    }}>


                      <CopyDone copyDone={copydone} />

                      <div className="t_icon">
                        <div>content_copy</div>
                      </div>

                      {$ui('pop_button_copy')}
                    </div>
                  </div>
                  }

                </div>
              </div>


              {avatar.design.id !== 'dummy' && <div className="x_calc_form_line">
                {/* <div className="x_calc_label">
                <div>Scan QR code</div>
              </div> */}
                <div className="x_calc_qr">
                  <QR
                        url={getUrl({
                          ...avatar,
                          'name': showName ? avatar.name : ''
                        })}
                  />

                </div>
              </div>}


                </div>

              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  )
})



export const CopyDone = ({ copyDone }) => {
  const [copydone, setCopydone] = useState(false);

  useEffect(() => {
    if (copyDone !== copydone) {
      setCopydone(copyDone)
      setTimeout(() => {
        setCopydone(false)
      }, 1000);
    }
  }, [copyDone])


  return (
    <div
      className={copydone ? "x_button_ok inactive active" : "x_button_ok inactive"}
    // style={{ opacity: copydone ? 1 : 0 }}
    >
      <div className="t_icon t_icon--60">check</div>
    </div>
  )
}


