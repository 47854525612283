import React, { useEffect, useState } from 'react'
import { Avatar } from '../UiKit/Avatar'
import { drawType } from '../utilsDesign';
import { Txt } from '../UiKit/Txt';
import { ChatMessageRead, ChatMessageTime } from './ChatMessageRead';
import { Svg } from '../UiKit/Svg';



export const ChatMessage = React.memo((
  props
) => {

  const {
    userData,
    my = false,
    _id,
    channel,
    from,
    text,
    read,
    timestamp,
    noAvatar,
  } = { ...props }

  const parseTxt = (text = '') => {
    return text.replace(/\n/g, '  \n');
  // return text
  }



  const chatCorner = () => <svg width="26" height="16" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M26 0V16H1.41353C-1.59795 16 0.911422 14.5 2.41639 14C5.78204 12.8818 15.9646 8 15.9646 0H26Z" fill="currentColor" />
  </svg>
  const chatCornerMy = () => <svg width="26" height="16" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.13303e-06 0V16H24.5865C27.5979 16 25.0886 14.5 23.5836 14C20.218 12.8818 10.0354 8 10.0354 0H1.13303e-06Z" fill="currentColor" />
  </svg>


  return (
    <div className={`x_chat0 ${my ? 'x_chat0--me' : 'x_chat0--them'}`}>

      {!my && <div className={`x_chat0_unread ${userData ? drawType(userData.avatar.design.type, 'class') : ''} ${Boolean(read) ? 'read' : ''}`} />}


      {!my && <>
        {userData && <div
          className='x_chat_ava0'
          style={{ opacity: noAvatar ? 0 : 1 }}>
          <Avatar
          avatar={userData.avatar}
            style={{ fontSize: '1em' }}
          /></div>}
        {!userData && <div className="x_chat_ava0" style={{ fontSize: '1em' }}>
          {/* <div className="x_choose_user_ava"> */}
          {/* <div className="t_icon t_icon--ava t_icon--ava--m">
              <div>no_accounts</div>
            </div> */}
          {/* </div> */}
        </div>}
      </>}
      <div className={`x_chat ${my ? 'x_chat--me' : 'x_chat--them'} ${userData ? drawType(userData.avatar.design.type, 'class') : ''}  ${read ? '' : 'unread'}`}>
        <div className="x_chat_txt">
          <Txt>
            {parseTxt(text)}
          </Txt>
        </div>

        <div className="x_chat_status0">
          <ChatMessageRead {...props} >
            <ChatMessageTime {...props} />
          </ChatMessageRead>
        </div>


        <div className={`x_chat_corner ${my ? 'x_chat_corner--my' : ''} ${!my && Boolean(userData) ? drawType(userData.avatar.design.type, 'class') : ''} ${!my && !read ? 'unread' : ''}`}>
          {/* {!my && <Svg width={26} height={16}>{chatCorner}</Svg>}
          {my && <Svg width={26} height={16}>{chatCornerMy}</Svg>} */}
          {!my && chatCorner()}
          {my && chatCornerMy()}
        </div>

        {/* <div className="x_chat_reaction0">
          <div className="x_chat_reaction">
            <div className="x_chat_reaction_emoji">❤️</div>
          </div>
        </div> */}
      </div>

      {/* {!my && <div className="x_chat_actions">
        <div className="x_chat_reply">
          <div className="t_icon t_icon--40">reply</div>
        </div>
      </div>} */}

    </div>
  )
})





