import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useSearchParams, useParams, useLocation } from 'react-router-dom';
import { setLocal, getLocal } from '../components/utilsSocial';
import { validateHistory } from '../components/utilsDesign';

const StateContext = React.createContext();


function StateProvider({ children }) {

  //PROD
  const [data, setData] = useState([])
  const [svgs, setSvgs] = useState([])
  const [preloadPage, setPreloadPage] = useState('');
  const [highlighted, setHighlighted] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addPop, setAddPop] = useState(false);
  const [peoplePanel, showPeoplePanel] = useState(false);
  const [citySearchActive, setCitySearchActive] = useState(false);
  const [currentUser, setCurrentUser] = useState();
  const [firstPage, setFirstPage] = useState();
  const [activePage, setActivePage] = useState();
  const [importedId, setImportedId] = useState();
  const [composite, setComposite] = useState([]);
  const [search, setSearch] = useState([]);
  const [calcShown, setCalcShownInner] = useState(false);
  const [currentPeopleSet, setCurrentPeopleSet] = useState(['people']);
  const [cityPeople, setCityPeople] = useState([]);

  const [faqShown, setFaqShown] = useState(false);


  const [tryDesignDate, setTryDesignDate] = useState();

  const setCalcShown = (val) => {
    if (!val) {
      setCalcShownInner(false)
      setCitySearchActive(false)
    }
    if (val) setCalcShownInner(true)
  }

  const [showHistory, setShowHistoryInner] = useState(getLocal('showHistory') ? validateHistory(getLocal('showHistory')) : [])
  const [selectByType, setSelectByType] = useState(getLocal('selectByType') ? getLocal('selectByType') : true);
  const [selectByProfile, setSelectByProfile] = useState(getLocal('selectByProfile') ? getLocal('selectByProfile') : true);
  const [selectByChannel, setSelectByChannel] = useState(getLocal('selectByChannel') ? getLocal('selectByChannel') : false);
  const [selectByAuthority, setSelectByAuthority] = useState(getLocal('selectByAuthority') ? getLocal('selectByAuthority') : false);
  const [selectByVariable, setSelectByVariable] = useState(getLocal('selectByVariable') ? getLocal('selectByVariable') : false);
  const [selectByCross, setSelectByCross] = useState(getLocal('selectByCross') ? getLocal('selectByCross') : false);
  const [selectByEnvironment, setSelectByEnvironment] = useState(getLocal('selectByEnvironment') ? getLocal('selectByEnvironment') : false);
  const [sort, setSort] = useState(getLocal('sort') ? getLocal('sort') : 'profiles'); //profiles,channels,gates,emg
  const [namesHidden, setNamesHidden] = useState(getLocal('namesHidden') ? getLocal('namesHidden') : false);
  const [bodygraphChooseSimple, setBodygraphChooseSimple] = useState(getLocal('bodygraphChooseSimple') ? getLocal('bodygraphChooseSimple') : false);
  const [emgStat, setEmgStat] = useState(getLocal('emgStat') ? getLocal('emgStat') : false);
  const [png, setPng] = useState(getLocal('png') ? getLocal('png') : false);
  const [narrativeBeta, setNarrativeBeta] = useState(getLocal('narrativeBeta') ? getLocal('narrativeBeta') : false);
  const [version, setVersion] = useState(getLocal('version') ? getLocal('version') : '.');


  const [hideNames, setHideNames] = useState(getLocal('hideNames') ? getLocal('hideNames') : false);


  const setShowHistory = (newU, del, all) => {
    const limit = 20;

    if (all) {
      setShowHistoryInner(prev => prev.filter(one => one.avatar.isMe));
      return;
    }

    let newHistory;
    if (showHistory.filter(one => one.avatar.token === newU.avatar.token).length) {
      newHistory = JSON.parse(JSON.stringify(showHistory));
      newHistory = newHistory.filter(one => one.avatar.token !== newU.avatar.token);
    } else {
      newHistory = showHistory;
    }

    if (!del) {
      newHistory = [newU, ...newHistory];
    }

    // Apply the limit
    if (newHistory.length > limit) {
      newHistory = newHistory.slice(0, limit);
    }

    setShowHistoryInner(newHistory);
  };







  useEffect(() => {
    if (citySearchActive) {
      document.body.classList.add('noscroll');
    } else {
      document.body.classList.remove('noscroll');
    }
  }, [citySearchActive])

  useEffect(() => {
    // console.log('highlighted = ', highlighted);
  }, [highlighted])

  useEffect(() => {
    // console.log('search = ', search);
  }, [search])


  useEffect(() => {
    setLocal('showHistory', showHistory)
    setLocal('png', png)
    setLocal('bodygraphChooseSimple', bodygraphChooseSimple)
    setLocal('narrativeBeta', narrativeBeta)
    setLocal('selectByType', selectByType)
    setLocal('selectByProfile', selectByProfile)
    setLocal('selectByChannel', selectByChannel)
    setLocal('selectByAuthority', selectByAuthority)
    setLocal('selectByVariable', selectByVariable)
    setLocal('selectByCross', selectByCross)
    setLocal('selectByEnvironment', selectByEnvironment)
    setLocal('sort', sort)
    setLocal('emgStat', emgStat)
    setLocal('namesHidden', namesHidden)
    setLocal('version', version)

  }, [
    showHistory,
    png,
    bodygraphChooseSimple,
    narrativeBeta,
    selectByType,
    selectByProfile,
    selectByChannel,
    selectByAuthority,
    selectByVariable,
    selectByCross,
    selectByEnvironment,
    sort,
    emgStat,
    namesHidden,
    version
  ])




  return (<>
    <StateContext.Provider
      value={{
        state: {
          citySearchActive,
          addPop,
          namesHidden,
          highlighted,
          peoplePanel,
          activePage,
          currentUser,
          firstPage,
          importedId,
          showHistory,
          narrativeBeta,
          loading,
          composite,
          preloadPage,
          svgs,

          png,
          bodygraphChooseSimple,
          selectByType,
          selectByProfile,
          selectByChannel,
          selectByAuthority,
          selectByVariable,
          selectByCross,
          selectByEnvironment,
          sort,
          emgStat,
          search,
          calcShown,
          version,
          currentPeopleSet,
          cityPeople,
          tryDesignDate,
          hideNames,
          faqShown
        },
        setState: {
          setCitySearchActive,
          setPng,
          setShowHistory,
          setHighlighted,
          setAddPop,
          setNamesHidden,
          setBodygraphChooseSimple,
          showPeoplePanel,
          setActivePage,
          setCurrentUser,
          setFirstPage,
          setImportedId,
          setAllData: setData,
          setNarrativeBeta,
          setLoading,
          setComposite,
          setSelectByType,
          setSelectByProfile,
          setSelectByChannel,
          setSelectByAuthority,
          setSelectByVariable,
          setSelectByCross,
          setSelectByEnvironment,
          setSort,
          setEmgStat,
          setPreloadPage,
          setSearch,
          setCalcShown,
          setVersion,
          setCurrentPeopleSet,
          setCityPeople,
          setTryDesignDate,
          setHideNames,
          setFaqShown
        },
      }}
    >
      {children}
    </StateContext.Provider>
  </>
  )

}

const ContextConsumer = StateContext.Consumer;

export { StateContext, StateProvider, ContextConsumer };
