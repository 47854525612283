import React, { Fragment, useEffect, useState, useContext, useRef } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { _notificationsMarkAllRead, _notificationsMarkRead } from '../utilsDesign';
import { Avatar } from './Avatar';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { LangContext } from '../../contexts/langContext';
import { MessageContext } from '../../contexts/messageContext';
import { WithVersion } from './VersionToggler';

export const ChatsNotifications = React.memo(({ listId, actions = [], avatar = {}, user = {}, penta = {} }) => {

  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) }
  const $ui = context.$ui;
  const messageContext = { ...useContext(MessageContext) }

  const navigate = useNavigate();

  const [notiCount, setNotiCount] = useState(0);

  useEffect(() => {
    if (messageContext.unread && messageContext.unread.length) {
      setNotiCount(messageContext.unread.reduce((accumulator, item) => accumulator + item.count, 0))
    }
  }, [messageContext.unread])

  const handleClick = () => {
    if (messageContext.unread && messageContext.unread.length) {
      messageContext.setCurrentChat(messageContext.unread[0]._id)
    } else {
      messageContext.setCurrentChat(Object.keys(messageContext.cachedChats).length ? Object.keys(messageContext.cachedChats)[0] : 'choose')
    }
  }




  return (<>


    {/* <WithVersion> */}
    <div className="x_user0"
      onClick={() => handleClick()}
    >

      {Boolean(notiCount) && <div className="x_noti0 x_noti0--number"><div className="x_noti0_number">{notiCount}</div></div>}

      <div className="t_icon t_icon--60" style={{ opacity: Boolean(notiCount) ? 1 : 0.4 }}>
        chat_bubble
      </div>
    </div>
    {/* </WithVersion> */}

    {/* <DropDown
      id='noti'
      options={[
        // { component: <LangSwitcher /> },
        {
          id: 'allread',
          // active: context.language === 'en',
          icon: 'check',
          // title: 'English',
          handle: () => markAllRead(),
          className: `x_menu_li x_menu_markallread ${Boolean(notifications.filter(one => !one.read).length) ? '' : 'x_menu_li--noti inactive'}`,
          component: <> {Boolean(notiCount.new) && <div>Mark all as read</div>} {!Boolean(notiCount.new) && <div>No notifications yet</div>}</>
        },

        ...notis

      ]}

    >
      <div className="x_user0">

        {Boolean(notiCount.new) && <div className="x_noti0 x_noti0--number"><div className="x_noti0_number">{notiCount.new}</div></div>}

        <div className="t_icon t_icon--60" style={{ opacity: Boolean(notiCount.new) ? 1 : 0.4 }}>
          notifications
        </div>
      </div>
    </DropDown> */}
  </>
  )
})