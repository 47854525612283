import React, { Fragment, useEffect, useState, useContext, useRef } from 'react';



import { QR } from '../UiKit/QR';
import { Avatar } from '../UiKit/Avatar';
import { getPersonUrl, getPrivateUrl, getUrl } from '../utilsSocial';
import { StateContext } from '../../contexts/stateContext';
import { SocialContext } from '../../contexts/socialContext';
import { LangContext } from '../../contexts/langContext';
import { convertGMTtoDateTime, localizeDate } from '../utilsDesign';
import { Preloader0, Preloader } from '../UiKit/kit';
import { AvatarInfoVisibility } from './MeInfo';
import { Persona } from '../Persona';
import { AvatarName } from '../UiKit/AvatarName';
import { PreloaderHD } from '../UiKit/PreloaderHD';

export const UserInfo = React.memo(({ user = {}, avatar = {}, handleClose }) => {

  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ui = context.$ui;

  const [title, setTitle] = useState(avatar.name ? avatar.name : context.getDefaultTitle(avatar.design));
  const [image, setImage] = useState(avatar.image ? avatar.image : '');

  const [copydone, setCopydone] = useState(false);

  const [privateNote, setPrivateNote] = useState(avatar.privateNote ? avatar.privateNote : '');

  const inputRef = useRef(null);





  useEffect(() => {
    console.log('title = ', title);
  }, [title])

  let city = avatar.city;
  let dob = avatar.dob;
  let timezone = avatar.timezone;


  const handleCopy = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(getUrl(user, false, context.language))
        .then(() => {
          console.log("Text copied to clipboard successfully!");
        })
        .catch(err => {
          console.error("Failed to copy text: ", err);
        });
    } else {
      console.error("Clipboard API not available.");
    }
  };

  const handleSave = () => {
    context.x.updateAvatar({
      'token': avatar.token,
      'name': title,
      'image': image,
      'privateNote': privateNote,
    })
    handleClose()
  };

  return (
    <>
      <div className="m_b_c_scroll">

        <div className="m_b_cols">
          <div className="m_b_form0">
            <div className="m_b_form">


              {/* <div className="x_calc_form_line">
                <div className="x_calc_form_title">{$ui('pop_edit_avatar_header')}</div>
              </div> */}

              <div className="x_calc_form_line">

                <div className="x_calc_form0 x_calc_form0--main">
                  <div className="x_calc_result_ava">
                    <Avatar
                      avatar={avatar}
                    />
                  </div>

                  <h2><AvatarName avatar={avatar} /></h2>


                </div>
              </div>



              <div className="x_calc_form_line _fcc">
                <div className="x_calc_form_info0 ">
                  <div className="x_calc_label">
                    <div>{$ui('pop_created')}:</div>
                  </div>
                  <div className="x_calc_form_info_value">
                    {avatar && <div>{localizeDate(convertGMTtoDateTime(avatar.created, null, 'date'), context.lang)} {convertGMTtoDateTime(avatar.created, null, 'time')}</div>}
                  </div>
                </div>
                <div className="x_calc_form_info0">
                  <div className="x_calc_label">
                    <div>{$ui('pop_updated')}:</div>
                  </div>
                  <div className="x_calc_form_info_value">
                    {avatar && <div>{localizeDate(convertGMTtoDateTime(avatar.updated, null, 'date'), context.lang)} {convertGMTtoDateTime(avatar.updated, null, 'time')}</div>}
                  </div>
                </div>
              </div>


              <hr style={{ color: '#fff' }} />

              <div className="x_calc_form_line">
                <div className="x_calc_form_title">{$ui('pop_share')}</div>
              </div>



              <div className="x_info_form_preview_sections">


                {/* <AvatarInfoVisibility
                  view={'private'}
                  noSocial={true}
                >

                  <Persona
                    avatar={{
                      ...avatar,
                      'name': ''
                    }}
                    noClick={true}
                    active={false}
                    addLine={'none'}
                  />
                </AvatarInfoVisibility> */}



                <div className="m_b_form">

                  <div className="x_calc_form_line">
                <div className="x_calc_form0">
                      {!Boolean(avatar.design) && <PreloaderHD nologo />}
                  <input
                        ref={inputRef}
                        disabled={Boolean(avatar.design.id === 'dummy')}
                    type="text"
                        className="x_input x_input--url x_input--disabled"
                    onFocus={(e) => e.target.select()}
                        defaultValue={Boolean(avatar.design.id !== 'dummy') ? getUrl(user, false, context.language) : $ui('pop_no_design')}
                  />
                      {avatar.design.id !== 'dummy' && <div className="x_input_right">
                        <div className="x_button x_button--sm" onClick={() => {
                          handleCopy()
                          setCopydone(true)
                          setTimeout(() => {
                            setCopydone(false)
                          }, 1000);
                        }}>


                          <CopyDone copyDone={copydone} />

                          <div className="t_icon">
                            <div>content_copy</div>
                          </div>

                          {$ui('pop_button_copy')}
                        </div>
                      </div>
                      }

                </div>
                  </div>


                  {avatar.design.id !== 'dummy' && <div className="x_calc_form_line">
                {/* <div className="x_calc_label">
                <div>Scan QR code</div>
              </div> */}
                <div className="x_calc_qr">
                  <QR
                        url={getUrl(user, false, context.language)}
                  />

                </div>
                  </div>}


                </div>

              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  )
})



export const CopyDone = ({ copyDone }) => {
  const [copydone, setCopydone] = useState(false);

  useEffect(() => {
    if (copyDone !== copydone) {
      setCopydone(copyDone)
      setTimeout(() => {
        setCopydone(false)
      }, 1000);
    }
  }, [copyDone])


  return (
    <div
      className={copydone ? "x_button_ok inactive active" : "x_button_ok inactive"}
    // style={{ opacity: copydone ? 1 : 0 }}
    >
      <div className="t_icon t_icon--60">check</div>
    </div>
  )
}


