import React, { useContext, useState } from 'react'




import { findGateInUser, gatherUserChannels, prepareChannels } from '../utilsDesign';
import { planets_dictionary } from '../../static/planets_dictionary';
import { gates_dictionary } from '../../static/gates_dictionary';
import { RadioItem } from '../UiKit/RadioItem';
import { LangContext } from '../../contexts/langContext';
import { WithVersion } from '../UiKit/VersionToggler';
import { Channel } from '../UiKit/Channel';
import { Switch } from '../UiKit/Switch';






export const Channels = React.memo((props) => {

  const context = { ...useContext(LangContext) };
  const $ui = context.$ui;

  const {
    design = {},
    center,
    style = {}
  } = { ...props }

  const designGates = design['gates']


  const [showActivations, setShowActivations] = useState(false);


  const [filter, setFilterInner] = useState()

  const setFilter = (value) => {
    if (filterByType(channels, "circuit", value).length) {
      setFilterInner(value)
    }
  }

  const filterByType = (channels = [], what, value) => {
    // return channels;
    if (!value) return channels;
    return channels.filter(oneChannel => {
      return oneChannel[what].includes(value)
    })
  }

  const channels = prepareChannels(design.gates);




  return (
    <>



      {designGates && Boolean(channels.length) && <div className="x_edu_sections"
        style={{ ...style }}
      >




        <ChannelGateFilterType
          filter={filter}
          setFilter={setFilter}
          filterByType={filterByType}
          array={channels}
        />




        <div className="x_edu_channels">

          {filterByType(channels, 'circuit', filter)
            .map((oneChannel, ind) => {
              return (<Channel
                key={`channel-by-type_${oneChannel['gates'][0]}_${oneChannel['gates'][1]}}`}
                uniq={`${ind}_${center}_${filter}`}
                design={design}
                {...oneChannel}
              />)
            })}

        </div>




      </div >}



    </>
  )
})





export const ChannelGateFilterType = ({
  filter,
  setFilter,
  filterByType,
  array,
}) => {

  const context = { ...useContext(LangContext) };
  const $ui = context.$ui;

  return (<div className="x_edu_select0">
    <div className="x_choose_list">
      <div className="x_choose_h">
        <div className="x_choose_h_title">
          <div>Types:</div>
        </div>
      </div>

      <div className={!filter ? "x_choose_menu_but active" : 'x_choose_menu_but'}
        onClick={() => setFilter()}
      >
        <div className="t_icon t_icon--type" />
        <div>All</div>
        <div className="x_choose_menu_counter">
          {filterByType(array).length}
        </div>
        <div className="x_choose_section_bg" />
      </div>

      <RadioItem
        active={filter === 'individual'}
        inactive={!filterByType(array, "circuit", 'individual').length}
        onClick={() => setFilter('individual')}
      >
        <div className="t_icon t_icon--type" />
        <div>individual</div>
        <div className="x_choose_menu_counter">
          {filterByType(array, "circuit", 'individual').length}
        </div>
        <div className="x_choose_section_bg" />
      </RadioItem>



      <RadioItem
        active={filter === 'tribal'}
        inactive={!filterByType(array, "circuit", 'tribal').length}
        onClick={() => setFilter('tribal')}
      >
        <div className="t_icon t_icon--type" />
        <div>{$ui('tribal')}</div>
        <div className="x_choose_menu_counter">
          {filterByType(array, "circuit", 'tribal').length}
        </div>
        <div className="x_choose_section_bg m" />
      </RadioItem>

      <RadioItem
        active={filter === 'collective'}
        inactive={!filterByType(array, "circuit", 'collective').length}
        onClick={() => setFilter('collective')}
      >
        <div className="t_icon t_icon--type" />
        <div>{$ui('collective')}</div>
        <div className="x_choose_menu_counter">
          {filterByType(array, "circuit", 'collective').length}
        </div>
        <div className="x_choose_section_bg p" />
      </RadioItem>

    </div>
  </div>
  )
}








export const ChannelsTable = React.memo((props) => {
  const context = { ...useContext(LangContext) }
  const $ui = context.$ui;
  const $channel = context.$channel;

  const [showActivations, setShowActivations] = useState(false);

  const {
    design = {},
    center
  } = { ...props }


  const userChannels = gatherUserChannels(design['gates'], 'return channels')

  const filterCenters = (center) => {
    if (center) {
      return userChannels.filter(oneChannel => oneChannel['centers'].includes(center))
    } else {
      return userChannels
    }
  }


  const [filter, setFilterInner] = useState()

  const setFilter = (value) => {
    if (filterByType(channels, "circuit", value).length) {
      setFilterInner(value)
    }
  }

  const filterByType = (channels = [], what, value) => {
    // return channels;
    if (!value) return channels;
    return channels.filter(oneChannel => {
      return oneChannel[what].includes(value)
    })
  }




  const channels = prepareChannels(design.gates);
  // console.log('channels = ', channels);


  const [page, setPage] = useState('circuit')


  const sorts = {
    'circuit': ['individual', 'tribal', 'collective'],
    'type': ['projected', 'generated', 'manifested'],
  }


  const calculateTotalActivations = (obj = {}) => {
    return Object.values(obj.activatedWith).reduce((total, activations) => total + activations.length, 0);
  };


  return (
    <>


      <div className="x_edu_content">

        <div className="_fv _fgap2 _f100">

          <div className="x_edu_select0">
          <div className="x_choose_list">
            {/* <div className="x_group_label">
              {$ui('group_by')}:
            </div> */}



            <RadioItem
              active={page === 'circuit'}
              onClick={() => setPage('circuit')}
            >
              <div className='mobile_v_yes'>{$ui('group_by_circuits')}</div>
              <div className='mobile_v_no'>{sorts.circuit.map((one, ind, arr) => <span key={`c_${one}`}>{$ui(one)}{ind < arr.length - 1 && <span className='x_choose_menu_delim'> /</span>} </span>)}</div>
            </RadioItem>



            <RadioItem
              active={page === 'type'}
              onClick={() => setPage('type')}
            >
              <div className='mobile_v_yes'>{$ui('group_by_types')}</div>
              <div className='mobile_v_no'>{sorts.type.map((one, ind, arr) => <span key={`t_${one}`}>{$ui(one)}{ind < arr.length - 1 && <span className='x_choose_menu_delim'> /</span>} </span>)}</div>

              </RadioItem>
            </div>
            {/* <div className="x_edu_select_right  mobile_v_no">
              <Switch
                size='sm'
                label={$ui('edu_show_activations')}
                active={showActivations}
                handle={() => setShowActivations(prev => !prev)}
              />
          </div> */}
        </div>






        {Boolean(channels.length) && <div className="x_edu_activations_table">





          {sorts[page].map(oneCircuit => {
            return (

              <div className="x_edu_activations_column"
                key={oneCircuit}
              >

                <div className='x_edu_h2 _t100 ellipsis'>{$ui(oneCircuit)}</div>

                <div className="x_edu_channels">
                  {filterByType(channels, page, oneCircuit)
                    .sort((a, b) => {
                      const totalActivationsA = calculateTotalActivations(a);
                      const totalActivationsB = calculateTotalActivations(b);
                      return totalActivationsB - totalActivationsA; // Sort in descending order
                    })
                    .map((oneChannel, ind) => {
                      return (<Channel
                        showActivations={showActivations}
                        label={false}
                        uniq={`${page}_channel-by-${oneCircuit}_${oneChannel['gates'][0]}_${oneChannel['gates'][1]}}`}
                        key={`${page}_channel-by-${oneCircuit}_${oneChannel['gates'][0]}_${oneChannel['gates'][1]}}`}
                        design={design}
                        {...oneChannel}
                        title={$channel(`${oneChannel.gates[0]}-${oneChannel.gates[1]}`, 'title')}
                        description={$channel(`${oneChannel.gates[0]}-${oneChannel.gates[1]}`, 'description')}
                      />)
                    })}

                  {!Boolean(filterByType(channels, page, oneCircuit).length) && <>{$ui('no_channels')}</>}

                </div>

              </div>

            )
          })}

        </div>}

        </div>

      </div>

    </>
  )
})







export const GateOne = React.memo(({ gateNumber, design, uniq }) => {
  const context = { ...useContext(LangContext) }
  const $ui = context.$ui;

  const findGateInUser = (gates, gateNum) => {
    if (!gates && !gates.length) return []
    let result = gates.filter(one => one['gate'] === gateNum);
    return result.length ? result : null;
  }


  return (<>
    <div

    >
      <div className="x_edu_gate_label">
        <div>
          {gates_dictionary[gateNumber.toString()]['gate']} {gates_dictionary[gateNumber.toString()]['title']}
        </div>
      </div>

      {findGateInUser(design['gates'], gateNumber).map((oneGate, ind) => {
        return (

          <GatePlanet
            key={`gate_${ind}_${oneGate['gate']}_${design['id']}_${uniq}`}
            gate={oneGate}
          />
        )
      })}
    </div>
  </>
  )
})


export const GatePlanet = React.memo(({ gate }) => {
  return (<>
    <div className={`x_edu_gate_planet ${gate['label'] === 'prs' ? 'prs' : 'des'}`} >
      <div className="x_bodygraph_pl" >
        {planets_dictionary[gate['planet']]['glyph']}
      </div>
      <div className="x_bodygraph_pl_gate">
        {`${gate['label'] === 'prs' ? 'Conscious' : 'Unconscious'} ${planets_dictionary[gate['planet']]['title']} in line ${gate['line']}`}
      </div>
    </div>
  </>
  )
})
