import React, { Fragment, useEffect, useState, useContext, useRef, memo } from 'react';
import { Link, useNavigate, useSearchParams, useParams, useLocation } from 'react-router-dom';
import { SocialContext } from '../contexts/socialContext';
import { Persona } from './Persona';
import { Lists } from './Lists';
import { ListAvatarsLayout } from './Lists/ListAvatarsLayout';
import { ListAvatars } from './Lists/ListAvatars';
import { Preloader, Preloader0 } from './UiKit/kit';
import { ListTypes } from './ListTypes';
import { getListIcon } from './utilsSocial';
import { StateContext } from '../contexts/stateContext';
import { CreateNew } from './UiKit/CreateNew';
import { WithVersion } from './UiKit/VersionToggler';
import { Checkbox } from './UiKit/Checkbox';
import { Pentas } from './Penta/Pentas';
import { PentaContainer } from './Penta/PentaContainer';
import { ListCity } from './Lists/ListCity';
import { LangContext } from '../contexts/langContext';
import { Celebs } from './Lists/Celebs';
import { SocialChoose } from './UiKit/SocialChoose';
import { MobMenu } from './UiKit/MobMenu';
import { sortBy } from './utilsDesign';
import { Button } from './UiKit/Button';
import { SocialVIewSettings } from './SocialVIewSettings';
import { MeChecklist } from './UiKit/MeChecklist';
import { SocialTabs } from './UiKit/SocialTabs';
import { GroupSelector } from './GroupSelector';
import { Group } from './Group';
import { WithGroup } from './WithGroup';
import { updateGParameter } from './utilsGroup';

export const Social = memo((props) => {
  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ui = context.$ui;
  const panelExpanded = context.state.peoplePanel;


  const navigate = useNavigate();

  const {
    children,
    peopleShown = false,
    hidePreviews,
  } = { ...props };
  const [searchParams] = useSearchParams();






  const [penta, setPenta] = useState();
  const [pentaId2, setPentaId2] = useState();
  const [pentaAvatars, setPentaAvatars] = useState();

  const [groupBrowser, setGroupBrowser] = useState();

  const [filter, setFilter] = useState('types');

  const [tab, setTab] = useState();

  const filterPeople = (array, filter) => {
    return sortBy(array, filter)
  }




  const [socialView, setSocialViewInner] = useState(panelExpanded ? panelExpanded : ['people', 'followers'])
  // const [socialView, setSocialViewInner] = useState(['world', 'people', 'followers', 'bodygraphs'])

  const setSocialView = (what, hover) => {
    // console.log('what = ', what);
    // if (socialView.includes(what)) {
    //   setSocialViewInner(prev => prev.filter(one => one !== what))
    // } else {
    //   setSocialViewInner(prev => [...prev, what])
    // }
    // console.log('what = ', what);
    if (hover) {
      setSocialViewInner(what)
      // context.x.setCurrentPeopleSet(['what'])
    } else {
      setPanel(what)
    }
  }


  const filterMeOut = (array) => {
    // const me = array.filter(one => {
    //   return one.avatar.token === context.me.avatar.token
    // })
    const me = context.me;
    const woMe = array.filter(one => {
      return one.avatar.token !== context.me.avatar.token
    })
    return [me, ...woMe]
  }


  const peoplePanelRef = useRef(null);

  useEffect(() => {
    if (panelExpanded && panelExpanded[0] !== socialView[0]) {
      // peoplePanelRef.current.scrollTop = 0;
      setSocialViewInner(panelExpanded)
    } else {
      // setSocialView(context.state.currentPeopleSet)
      // if (socialView[0] === 'world') setSocialViewInner(['people', 'followers'])
    }
  }, [panelExpanded]);


  useEffect(() => {
    // console.log('socialView = ', socialView);
  }, [socialView])

  const filterOutFriends = () => {
    const arrayFollowers = context.followers;
    const arrayFollowings = context.followings;
    let friendsTokens = arrayFollowings.length ? arrayFollowings.map(one => one.user.token) : []
    let onlyFollowings = friendsTokens.length ? arrayFollowers.filter(one => {
      return !friendsTokens.includes(one.user.token)
    }) : arrayFollowers
    return onlyFollowings;
  }


  // HASH
  const location = useLocation();
  useEffect(() => {
    const hash = location.hash;
    const params = new URLSearchParams(hash.slice(1)); // Remove the leading '#'
    const panel = params.get('p');
    if (panel && !socialView.includes(panel)) {
      context.setState.showPeoplePanel(panel)
      setSocialViewInner(panel)
    }
    const group = params.get('g');
    if (group) {
      setTab(group)
    } else {
      setTab('all')
    }

  }, [location]);
  const setPanel = (newPanel) => {
    const hash = location.hash;
    const params = new URLSearchParams(hash.slice(1)); // Remove the leading '#'
    if (newPanel) {
      params.set('p', newPanel); // Set the new language parameter
      const newHash = params.toString();
      window.history.replaceState(null, '', `${location.pathname}${location.search}${newHash ? `#${newHash}` : ''}`);
      setSocialViewInner(newPanel)
    } else {
      params.delete('p');
      const newHash = params.toString();
      window.history.replaceState(null, '', `${location.pathname}${location.search}${newHash ? `#${newHash}` : ''}`);
      context.setState.showPeoplePanel()
    }
  };


  const setPeoplePanel = (newPanel, group) => {
    const hash = location.hash.substring(1); // Remove the leading '#'
    const params = new URLSearchParams(hash);
    if (newPanel) {
      params.set('p', newPanel); // Set the new panel parameter
      context.setState.showPeoplePanel(newPanel);
    } else {
      params.delete('p');
      context.setState.showPeoplePanel();
    }
    if (group) { params.set('g', group); }
    const newHash = params.toString();
    navigate(`${location.pathname}${location.search}${newHash ? `#${newHash}` : ''}`);
  };



  const clearHistory = () => {
    context.setState.setShowHistory(null, null, true)
    // navigate(`/${context.me.user.token}`)
  }

  const menu = [{
    id: 'world',
    icon: 'travel_explore',
    handleValue: ['world'],
    title: 'people_city',
  },
  {
    id: 'people',
    icon: 'group',
    handleValue: ['people', 'followers'],
    title: 'people_people',
  },
  // {
  //   withVersion: true,
  //   id: 'celebs',
  //   icon: 'star',
  //   handleValue: ['celebs'],
  //   title: 'people_celebs',
  // },
  {
    id: 'bodygraphs',
    icon: 'person_search',
    handleValue: ['bodygraphs'],
    title: 'people_bodygraphs',
  },
  {
    // withVersion: true,
    id: 'pentas',
    icon: 'diversity_3',
    handleValue: ['pentas'],
    title: 'people_synergy',
  },
  {
    id: 'history',
    icon: 'history',
    handleValue: ['history'],
    title: 'people_history'
  },
  ]


  const updateGParam = (newGValue) => {
    const hash = location.hash.substring(1);
    const params = new URLSearchParams(hash);
    params.set('g', newGValue);
    const newHash = params.toString();
    navigate(`${location.pathname}${location.search}#${newHash}`);
  };



  return (
    <>

      <div className={panelExpanded ? "x_people0 active" : "x_people0"}>


        {!panelExpanded && <SocialChoose
          view='left'
          handle={(what) => {
            setSocialView(what)
            if (what && what.length && !panelExpanded) context.setState.showPeoplePanel(what)
            // context.setState.showPeoplePanel([])
          }}
          handleHover={(a) => {
            setSocialView(a, 'hover')
          }}
          active={socialView}
          options={menu}
        />}






        <div className={!panelExpanded ? `${hidePreviews ? 'x_choose_panel x_choose_panel--penta' : 'x_choose_panel'}` : "x_choose_panel active"}>
          <div className={panelExpanded ? "x_choose0 active" : `${hidePreviews ? 'x_choose0 x_choose0--penta' : 'x_choose0'}`}>


            <div ref={peoplePanelRef} className={panelExpanded ? "x_people_panel active" : `${hidePreviews ? 'x_people_panel x_people_panel--penta' : 'x_people_panel'}`}>


              <div
                className={!panelExpanded ? "x_choose_left" : "x_choose_left x_choose_left--open"}
              // className={"x_choose_left"}
              >


                {panelExpanded &&
                  !socialView.includes('pentas') &&
                  !socialView.includes('history') &&
                  <SocialVIewSettings filter={filter} setFilter={setFilter} />}








                {/* {panelExpanded && <div className="x_choose_nav0">




                  <Lists />




                  <ListTypes />


                </div>} */}


                {/* {!panelExpanded &&  */}
                <div className={panelExpanded ? "x_choose_container x_choose_container--v " : "x_choose_container"}>

                  <div className={panelExpanded ? "x_panel_h x_panel_h--people active" : "x_panel_h x_panel_h--people"}>


                    {/* {!panelExpanded && <div className="x_action" onClick={() => context.setState.showPeoplePanel(true)}>
                      <div className="t_icon t_icon--20">
                        <div>chevron_right</div>
                      </div>
                    </div>} */}






                    {/* {context.me && panelExpanded && <div className="x_panel_friends_title">
                      <div className="x_panel_icon">
                        <div className="t_icon t_icon--20">
                          <div>people</div>
                        </div>
                      </div>
                      <div className="x_panel_title active">
                        {$ui('people_header')}
                      </div>
                    </div>} */}



                    <SocialChoose
                      handle={setSocialView}
                      active={socialView}
                      options={menu}
                    />

                    {/* <MobMenu
                      active={socialView}
                      avatar={context.state.showHistory[0].avatar}
                    /> */}



                    {panelExpanded && <>
                      {/* <div className="x_panel_actions x_panel_actions--sub">
                        <Checkbox label={$ui('p_toggles_city')} value={socialView.includes('world')} handle={() => setSocialView('world')} />
                        <Checkbox label={$ui('p_toggles_people')} value={socialView.includes('people')} handle={() => setSocialView('people')} />
                        <Checkbox label={$ui('p_toggles_folowers')} value={socialView.includes('followers')} handle={() => setSocialView('followers')} />
                        <Checkbox label={$ui('p_toggles_bodygraphs')} value={socialView.includes('bodygraphs')} handle={() => setSocialView('bodygraphs')} />
                      </div> */}
                      <div className="mobile_v_no">
                        <div className="x_panel_actions">
                          <div className="x_action"
                            // onClick={(prev) => context.setState.showPeoplePanel()}
                            onClick={() => setPanel()}
                          >
                            <div className="t_icon t_icon--60">
                              <div>close</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>}
                  </div>


                  <div className="x_panel_h0" />

                  <div className={hidePreviews && !panelExpanded ? "x_choose_contents x_choose_contents--penta" : "x_choose_contents"}>

                    <div className="x_choose_sect0">


                      {panelExpanded && context.me && (!context.me.user.city_id || !context.me.avatar.image || !context.me.avatar.name) &&
                        <MeChecklist user={context.me.user} avatar={context.me.avatar}
                          addClassName='x_user_checklist0--choose'
                        >
                          <div className="x_click"
                            onClick={() => navigate(`/u/${context.me.user.token}/info`)}
                          />
                        </MeChecklist>}



                      {socialView.includes('world') && <>
                        <ListCity user={context.me.user}
                          filterPeople={filterPeople}
                          filter={filter}
                        />

                        {socialView.includes('world') && <Celebs />}

                      </>
                      }

                      {socialView.includes('history') && <ListAvatarsLayout
                        className={panelExpanded ? 'v' : ''}
                        icon='history'
                        title={$ui('people_history')}
                        listId='history'
                        quantity={context.state.showHistory.length}
                        privateList={true}
                        addLeft={<Button ghost onClick={() => clearHistory()}>{$ui('people_history_clear')}</Button>}
                      >
                        <ListAvatars
                          listId='history'
                          quantity={context.state.showHistory.length}
                          array={context.state.showHistory}
                        />
                      </ListAvatarsLayout>}



                      {socialView.includes('people') && <>

                        <WithVersion>{panelExpanded &&
                          <SocialTabs
                            active={tab}
                          handleChange={newG => setPeoplePanel(['people', 'followers'], newG)}
                          />}
                        </WithVersion>

                        {tab === 'all' && <>
                          <ListAvatarsLayout
                            icon='group'
                            title={$ui('people_people')}
                            listId='people'
                            quantity={context.followings.length + 1}
                            className={panelExpanded ? 'v' : ''}
                            addRight={<Button ghost onClick={() => navigate(`/u/${context.me.user.token}/info`)}>{$ui('but_edit_profile')}</Button>}
                          >

                            {/* <ListAvatars
                          listId='me'
                          quantity={1}
                          array={[context.me]}
                          actions={['edit', 'share']}
                        /> */}
                            <ListAvatars
                              listId='followings'
                              quantity={context.followings.length}
                              array={filterPeople([context.me, ...context.followings], filter)}
                              actions={['share', 'unfollow']}
                              createNew={<CreateNew
                                icon='search'
                                label={$ui('people_find_more')}
                                handle={() => setSocialView('world')}
                              />}
                            />
                          </ListAvatarsLayout>

                          {socialView.includes('followers') &&
                            <ListAvatarsLayout
                              icon='public'
                              title={$ui('people_followers')}
                              listId='followers'
                              quantity={filterOutFriends().length}
                              className={panelExpanded ? 'v' : ''}
                            >
                              <ListAvatars
                                listId='followings'
                                quantity={filterOutFriends().length}
                                array={filterPeople(filterOutFriends(), filter)}
                              // actions={['follow']}
                              />
                            </ListAvatarsLayout>
                          }

                        </>}

                        <WithVersion>{panelExpanded && <>
                        {context.groups.map(group => {
                          if (tab === group.token) {
                            return <WithGroup
                              id={group.token}
                              key={group.token}
                            ><Group
                              // group={group}
                                groupBrowser={groupBrowser}
                                setGroupBrowser={setGroupBrowser}
                            />
                            </WithGroup>
                          }
                        })}

                        </>}
                        </WithVersion>

                      </>}







                      {socialView.includes('bodygraphs') && <ListAvatarsLayout
                        title={$ui('people_bodygraphs')}
                        listId='avatars'
                        icon='person_search'
                        quantity={context.avatars.length - 1}
                        className={panelExpanded ? 'v' : ''}
                        privateList={true}
                      >
                        <ListAvatars
                          quantity={context.avatars.length - 1}
                          listId='avatars'
                          array={filterPeople(filterMeOut(context.avatars), filter)}
                          // array={context.avatars}

                          actions={[
                            'edit',
                            // 'setMe',
                            'delete']}
                          createNew={<CreateNew
                            icon='add'
                            label={$ui('button_create_new_bodygraph')}
                            handle={() => !context.creatingAva && context.x.createAvatar(
                              null,
                              (newAvatar) => {
                                navigate(`/u/${newAvatar.token}`)
                              }
                            )}
                            loading={context.creatingAva}
                          />}
                        />

                      </ListAvatarsLayout>}



                      {socialView.includes('pentas') && <>
                        <Pentas
                          icon='group'
                          title={$ui('people_composites')}
                          max={2}
                          min={0}
                          create={true}
                        />

                        <WithVersion><>
                          <Pentas
                            icon='diversity_3'
                            title={$ui('people_pentas')}
                            min={3}
                            max={5}
                          />

                          <Pentas
                            icon='diversity_3'
                            title={$ui('people_va')}
                            min={6}
                            max={10}
                          />
                        </>
                        </WithVersion>

                      </>
                      }



                      {/* {socialView.includes('pentas') && <ListAvatarsLayout
                        title={$ui('people_composites')}
                        listId='pentas'
                        icon='diversity_3'
                        quantity={context.avatars.length - 1}

                      >
                        <Pentas />
                        <CreateNew
                          icon='add'
                          label={$ui('button_create_new_bodygraph')}
                          handle={() => !context.creatingAva && context.x.createAvatar(
                            (newUrl) => {
                              navigate(`/u/${newUrl}`)
                            }
                          )}
                          loading={context.creatingAva}
                        />
                      </ListAvatarsLayout>} */}


                      {/* {context.pentas
                        && !context.pentas.length
                        && <Preloader0 style={{ paddingTop: '4em' }}>
<Preloader width={80} height={80} />
</Preloader0>} */}






                    </div>
                  </div>



                </div>
                {/* } */}


              </div>


            </div>
            {panelExpanded && <div className="x_choose_bg">
              <div className="x_edu_bg g" />
            </div>}



            {/* <WithVersion>{panelExpanded && <Pentas />}</WithVersion> */}



          </div>
        </div>

        {panelExpanded && <div
          className="x_choose_z"
          onClick={() => setPanel()}
        >
        </div>}


      </div>






      {/* <ListPersonas title='Pentas' quantity={context.pentas.length}>
        {context.pentas.map((one, ind) => <div key={`penta_${ind}`}>
          <Persona
            name={one.name}
          />
        </div>)}
      </ListPersonas> */}



      {/* <ListPersonas title='Groups' quantity={context.groups.length}>
        {context.groups.map((one, ind) => <div key={`penta_${ind}`}>
          <Persona
            name={one.name}
          />
        </div>)}
      </ListPersonas> */}


    </>
  )
})




