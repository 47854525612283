import React, { Fragment, useEffect, useState, useContext, useRef } from 'react'
import { Link, useNavigate, useSearchParams, useParams, useLocation } from 'react-router-dom';


import { Preview } from '../UiKit/Preview';
import { Background } from '../UiKit/Background';
import { drawProfile, drawType, drawAuthority, parseNewDesign, getPrsDes } from '../utilsDesign';
import { Switch } from '../UiKit/Switch';
import { AvatarDob, PublicDob } from '../UiKit/AvatarPanel';
import { StateContext } from '../../contexts/stateContext';
import { SocialContext } from '../../contexts/socialContext';
import { LangContext } from '../../contexts/langContext';
import { DesignToggles } from '../UiKit/DesignToggles';
import { CityDateTime } from '../UiKit/CityDateTime';
import { Search } from '../UiKit/Search';
import { theme } from '../UiKit/Bodygraph';
import { Icon } from '../UiKit/Icon';

export const Full = React.memo((props) => {


  const {
    id,
    user,
    avatar,
    children,
    loading,
    numbers,
    planets,
    hoverable,
    advanced,
    publ,
    penta,
    hints,
    noSettings,
    noSearch,
    addControls,
    noHints
  } = { ...props };

  // console.log('user = ', user);

  const bodyContainer = useRef(null);

  const design = avatar.design;

  const [hintsInner, setHintsInner] = useState(hints);
  const [numbersInner, setNumbers] = useState(numbers);
  const [planetsInner, setPlanets] = useState(planets);
  const [advancedInner, setAdvanced] = useState(advanced);
  const [arrows, setArrows] = useState(true);
  const [prs, setPrs] = useState({});
  const [des, setDes] = useState({});
  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ = context.$;
  const $ui = context.$ui;
  const $type = context.$type;
  const $profile = context.$profile;
  const $line = context.$line;

  // const almostVisiblePrs = '#efefef';
  // const almostVisibleDes = '#ffe4e5';
  const almostVisiblePrs = 'blue';
  const almostVisibleDes = 'orange';

  // const [profileId, setProfileId] = useState(`${design['profile'][0]}${design['profile'][1]}`);
  const profileId = `${design['profile'][0]}${design['profile'][1]}`;
  const linePrs = `${design['profile'][0]}`;
  const lineDes = `${design['profile'][1]}`;

  useEffect(() => {
    setDesignInner(design)
    setPrs({})
    setDes({})
  }, [design, design.id])


  const [designInner, setDesignInner] = useState(design)


  useEffect(() => {


    if (design.id !== designInner.id) {
      setDesignInner(design)
    }
    if (prs.linePersonality) {
      setDesignInner(getPrsDes(design, 'des'));
    }
    if (des.lineDesign) {
      setDesignInner(getPrsDes(design, 'prs'));
    }
    if (!des.lineDesign && !prs.linePersonality) {
      setDesignInner(design)
      // setProfileId(`${design['profile'][0]}${design['profile'][1]}`);
    }

  }, [prs, des, design.id])


  const hintsTheme = {
    designColor: '#ff00001b',
    personalityColor: '#00000011',
    // openColor: '#FFF',
    // definedColor: '#010101',
    // authorityColor: 'black',
    lineOpen: '#ffffff0',
    line0: '#d6d6d6',
    line0comp: 'transparent',
  }


  const [colorTheme, setColorTheme] = useState(hintsTheme)

  useEffect(() => {
    if (hintsInner) {
      setColorTheme({
        ...theme,
        ...hintsTheme
      })
    } else {
      setColorTheme(theme)
    }
  }, [hintsInner])

  const navigate = useNavigate();
  const location = useLocation();
  const handleCloseInfoPop = () => {
    document.body.classList.remove('noscroll');
    context.setState.setHighlighted()
    context.setState.setSearch([])

    const removeStudySegments = (pathname) => {
      const segments = pathname.split('/study')
      return segments.length > 1 ? segments[0] : null
    };
    const currentPath = location.pathname;
    const newPath = removeStudySegments(currentPath);
    if (newPath) navigate(newPath);
  };




  return (
    <>







      <div className="x_edu_content"
        // onClick={() => handleCloseInfoPop()}
      >

        <div className="x0_topline"
          style={{ opacity: context.state.calcShown ? 0 : 1 }}
        >

          {!avatar.unsaved && <AvatarDob
            avatar={avatar}
            user={user}
            penta={penta}
          />}

          {avatar.unsaved && <PublicDob
            avatar={avatar}
          />}


          {(!noSettings || !noSearch || !addControls) && <div className="_f _fm">
              {design.id !== 'dummy' && <>


              {!noSettings && <DesignToggles
                  numbers={numbersInner}
                  planets={planetsInner}
                hints={hintsInner}
                  arrows={arrows}
                  advanced={advancedInner}
                  prs={!prs.linePersonality}
                  des={!des.lineDesign}
                  setNumbers={() => setNumbers((prev) => !prev)}
                  setPlanets={() => {
                    if (planetsInner) {
                      setPlanets(false)
                      setAdvanced(false)
                    }
                    if (!planetsInner) {
                      setPlanets(true)
                    }
                  }}

                  setArrows={() => setArrows((prev) => !prev)}
                setHints={() => setHintsInner((prev) => !prev)}
                  setAdvanced={() => {
                    if (!planetsInner && !advancedInner) setPlanets(true)
                    setAdvanced((prev) => !prev)
                  }}
                  setPrs={() => setPrs((prev) => !prev.linePersonality ? { linePersonality: almostVisiblePrs } : {})}
                  setDes={() => setDes((prev) => !prev.lineDesign ? { lineDesign: almostVisibleDes } : {})}
                  editAvailable={avatar['my'] || publ ? true : false}
              />}

              {!noSearch && <Search design={design} />}


              </>}
          </div>}

          {addControls}



        </div>



        <div className="x_edu_100"
          onClick={() => setHintsInner(false)}
        >


          {(!noHints && design.id !== 'dummy') && <div className={`x_edu_help0 ${hintsInner ? 'active' : ''} mobile_v_no`}
            onClick={(e) => {
              e.stopPropagation()
              setHintsInner(prev => !prev)
            }}
          >
            {/* <div className={`x_body_view ${hintsInner ? 'active' : ''}`} onClick={() => setHintsInner(prev => !prev)}>
              <Icon>contact_support</Icon>
              {$ui('edu_help_bodygraph')}
            </div> */}
            <div className="x_help_ico">🤷‍♂️</div>
            {/* <Switch
              active={hintsInner}
              handle={() => setHintsInner(prev => !prev)}
              label={$ui('edu_help_bodygraph')}
            /> */}
          </div>}


          <div className="x_body_content x_body_content--fullpro"
          // style={{ border: '1px solid red' }}
            // ref={bodyContainer}
          >

            {designInner.type !== 'dummy' && <div
              className="x_bodygraph_base "
              // style={{ minWidth: '30em', minHeight: '35em' }}
              ref={bodyContainer}
            >
              <Preview
                body={hintsInner ? false : true}
                style={{
                  opacity: hints ? 0 : 1
                }}
                hints={hintsInner}
                hoverable={hoverable}
                // interactive={true}
                parentContainer={bodyContainer}
                // width={500}
                // height={800}
                // planetsDes={(planetsInner && !des.lineDesign && !prs.linePersonality) ? true : prs.linePersonality}
                // planetsPrs={(planetsInner && !des.lineDesign && !prs.linePersonality) ? true : des.lineDesign}
                planets={planetsInner}
                arrowsB={(arrows && !des.lineDesign && !prs.linePersonality) ? true : prs.linePersonality}
                arrowsP={(arrows && !des.lineDesign && !prs.linePersonality) ? true : des.lineDesign}
                numbers={numbersInner}
                aura={false}
                // design={{
                //   ...design,
                //   active_chakras: designInner.active_chakras
                // }}
                design={designInner}
                preloader={(!design && avatar.dob) || loading}
                // colorTheme={{ ...prs, ...des }}
                colorTheme={colorTheme}
                highlightedGate={context.state.search}
                highlighted={context.state.highlighted}
                advanced={advancedInner}
                addClassName='main'
              />
            </div>}



          </div>









          {design.id !== 'dummy' && <div className="x_edu_1st ">
            <div className="x_edu_content_h x_edu_content_h--2">
              {
                <>
                  {/* {drawType(design['type'], 'auth', design['auth'])}  */}
                  {/* {drawType(design['type'], 'full', design['auth'])} */}
                  {$type(designInner['type'], 'title')}
                </>}
            </div>

            {(!prs['linePersonality'] && !des['lineDesign']) && <div className="x_edu_content_h x_edu_content_h--3">
              {$profile(profileId, 'profile')}  "{$profile(profileId, 'title')}"
            </div>}


            {(prs['linePersonality'] && !des['lineDesign']) && <div className="x_edu_content_h x_edu_content_h--3">
              {$line(lineDes, 'id')}  "{$line(lineDes, 'title')}"
            </div>}

            {(!prs['linePersonality'] && des['lineDesign']) && <div className="x_edu_content_h x_edu_content_h--3">
              {$line(linePrs, 'id')}  "{$line(linePrs, 'title')}"
            </div>}




            {/* <div className="x_edu_content_title_auth">{drawType(design['type'], 'auth', design['auth'])} Inner Authority</div> */}
          </div>}


          {design.id !== 'dummy' && <div className="x_body_view0">


            <div className={(prs['linePersonality'] && !des['lineDesign']) ? "x_body_view active" : 'x_body_view '}
              onClick={() => {
                setDes({})
                setPrs({ 'linePersonality': almostVisiblePrs })
              }}
            >
              <div className="x_type-xs"><div className={`x_typexs ${design.des.toLowerCase()}`} /></div>
              <div>{$ui('body_des')}</div>
            </div>

            <div className={(!prs['linePersonality'] && !des['lineDesign']) ? "x_body_view active" : 'x_body_view '}
              onClick={() => {
                setPrs({})
                setDes({})
              }}
            >
              <div className="x_type-xs"><div className={`x_typexs ${design.type.toLowerCase()}`} /></div>
              <div>{$ui('body_fullbody')}</div>
            </div>

            <div className={(!prs['linePersonality'] && des['lineDesign']) ? "x_body_view active" : 'x_body_view '}
              onClick={() => {
                setPrs({})
                setDes({ 'lineDesign': almostVisibleDes })
              }}
            >

              <div className="x_type-xs"><div className={`x_typexs ${design.prs.toLowerCase()}`} /></div>

              <div>{$ui('body_prs')}</div>
            </div>



          </div>}


        </div>

        <div className="x_edu_bg0 inactive">
          <Background design={designInner} className='inact' style={{ height: '100%', zIndex: -1 }} />
        </div>

      </div>

      {/* <div className="x_edu_bg0">
        <Background design={designInner} style={{ height: '100%', zIndex: -1 }} />
      </div> */}


    </>
  )
})




