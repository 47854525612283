import React, { Fragment, useEffect, useState, useContext, useRef } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { ListAvatarsLayout } from './Lists/ListAvatarsLayout';
import { ListAvatars } from './Lists/ListAvatars';
import { SocialContext } from '../contexts/socialContext';

export function FollowList(props) {
  const context = { ...useContext(SocialContext) };

  const { token = '', count = 0, list = 'followings' } = { ...props };

  const [listInner, setListInner] = useState([]);

  useEffect(() => {
    context.x.fetchUser(token, (userData) => {
      setListInner(userData[list] ? userData[list] : [])
    }, true)
  }, [token])

  return (
    <>


      <ListAvatarsLayout icon=''
        className='followers'

      >

          <ListAvatars
            listId='followers'
            quantity={count}
            array={listInner}
          // fullList={true}
          />
      </ListAvatarsLayout>



    </>
  )
}