import React, { Fragment, useEffect, useState, useContext, useRef } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { LangContext } from '../../contexts/langContext';

export function AvatarName({
  avatar,
  hideName,
  className,
  style
}) {
  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };


  const drawCross = (cross) => {
    // return cross.length ? `${findHexagram(cross[0])}${findHexagram(cross[1])}${findHexagram(cross[2])}${findHexagram(cross[3])}` : '䷀䷀䷀䷀';
  }


  const obscureName = (cross = []) => {

    let a = drawCross(cross);
    // a = a.substring(0, title.length);
    return a

  }



  const $ui = context.$ui;
  return (
    <>

      {avatar && (context.state.hideNames || hideName)
        ? context.getDefaultTitle(avatar.design, 'type')
        : <>{avatar.name ? avatar.name : context.getDefaultTitle(avatar.design)}</>
      }

    </>
  )
}