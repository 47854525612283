import { message } from 'antd';

const API_URL = process.env.REACT_APP_API_URL;


export const getGeoLocation = () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    } else {
      reject(new Error("Geolocation is not supported by this browser."));
    }
  });
};

export const showGeoPosition = (position) => {
  const latitude = position.coords.latitude;
  const longitude = position.coords.longitude;
  console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);

  // You can now use the latitude and longitude for your app's functionality
};

const showGeoError = (error) => {
  switch (error.code) {
    case error.PERMISSION_DENIED:
      alert("User denied the request for Geolocation.");
      break;
    case error.POSITION_UNAVAILABLE:
      alert("Location information is unavailable.");
      break;
    case error.TIMEOUT:
      alert("The request to get user location timed out.");
      break;
    case error.UNKNOWN_ERROR:
      alert("An unknown error occurred.");
      break;
  }
};



export const fetchNearestCities = async (latitude, longitude) => {
  try {
    const response = await fetch(`${API_URL}/api/geo/close?lat=${latitude}&lon=${longitude}`);
    if (!response.ok) {
      throw new Error('Failed to fetch city data');
    }
    const data = await response.json();
    return data; // Return the city data directly
  } catch (error) {
    message.error("Failed to fetch city data");
    console.error("Error fetching city data:", error);
    return []; // Return an empty array in case of error
  }
};