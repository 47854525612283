import React, { Fragment, useEffect, useState, useContext, useRef } from 'react'
import {
  drawProfile,
  drawType,
  drawAuthority,
  getDesignCenters,
  prepareChannels,
  findGateInUser
} from '../utilsDesign';
import { StateContext } from '../../contexts/stateContext';
import { SocialContext } from '../../contexts/socialContext';
import { LangContext } from '../../contexts/langContext';
import { default_design } from '../../static/default_design';
import { Channel } from '../UiKit/Channel';
import { Avatar } from '../UiKit/Avatar';
import { Txt } from '../UiKit/Txt';
import { AvatarName } from '../UiKit/AvatarName';
import { PentaChannelOwner } from '../Penta/PentaChannelOwner';
import { getDomChannels, getPentaDesign, getEmChannels, getSameChannels } from '../utilsPenta';
import { Variables, Var, MarsMoon } from '../Edu/Variables';

export const PentaPHS = React.memo((props) => {

  const {
    videos = true,
    penta
  } = { ...props };

  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ = context.$;
  const $ui = context.$ui;
  const $type = context.$type;
  const $profile = context.$profile;

  const design1 = penta.members[0] ? penta.members[0].avatar.design : default_design;
  const design2 = penta.members[1] ? penta.members[1].avatar.design : default_design;

  const profileId1 = design1.profile.length ? `${design1['profile'][0]}${design1['profile'][1]}` : '';
  const profileId2 = design2.profile.length ? `${design2['profile'][0]}${design2['profile'][1]}` : '';

  const pentaDesign = {
    ...getPentaDesign(penta),
    // 'gates': prepareChannels(getPentaDesign(penta).gates, true)
  };




  return (
    <>


      <div className="_fv  _f100">



        <div className="_f  _f100">
              {penta.members.map((oneMember, ind) => {
                const memberId = oneMember.avatar.token;
                const design = oneMember.avatar.design;
                return (
                  <div
                    key={`phs_${oneMember.avatar.token}`}
                    className='_fv _fgap1 _f100'
                    style={{ width: `${(100 / penta.members.length - 2)}%` }}
                  >
                    <div className="x_edu_channels">
                      <div className='x_edu_h2 x_edu_h2--sticky'>
                        {/* {$ui('penta_channels_of')} */}
                        <span className='x_ava_inline0'>
                          <span className='x_ava_inline' style={{ display: 'inline-flex' }}>
                            <Avatar style={{ fontSize: '1em' }} avatar={oneMember.avatar} /></span>
                          <span className={`x_ava_inline_name x_ava_inline_name--${oneMember.avatar.design.type.toLowerCase()}`}>
                            <AvatarName avatar={oneMember.avatar} />
                          </span></span>
                      </div>



                      {/* <Var
                        design={design}
                        variable='diet' addClassName='100'
                        advanced={true}
                      />
                      <Var design={design} variable='env' addClassName='100' advanced={true} />
                      <Var design={design} variable='motivation' addClassName='100' advanced={true} />
                      <Var design={design} variable='perspective' addClassName='100' advanced={true} />

                      <MarsMoon design={design} variable='trauma' addClassName='100' advanced={true} />
                      <MarsMoon design={design} variable='love' addClassName='100' advanced={true} /> */}


                      {/* {!pentaDesign
                        .channels.filter(oneChannel => Object.keys(oneChannel.activatedBy).includes(memberId)).length && <div className="x_edu_channel_no"><div className="x_edu_channel_h1">{$ui("no_channels_0")}</div>
                        </div>} */}

                    </div>
                  </div>
                )
              })}

</div>



        <div className="_fv _fgap2 _f100">
          {['diet', 'env', 'motivation', 'perspective', 'trauma', 'love']
            .map(oneVar => {
              return (
                <div
                  key={`phs_${penta.token}_${oneVar}`}
                  className="_f  _f100"
                >
                  {penta.members.map((oneMember, ind) => {
                    const memberId = oneMember.avatar.token;
                    const design = oneMember.avatar.design;
                    return (<div
                      className='_fv _fgap1 _f100'
                      key={oneMember.avatar.token}
                      style={{ width: `${(100 / penta.members.length - 2)}%` }}
                    >

                      <Var
                        design={design}
                        variable={oneVar}
                        addClassName='100'
                        advanced={true}
                        videos={videos}
                      />
                    </div>)
                  })}
                </div>
              )
            })}
        </div>


      </div>



    </>
  )
})




