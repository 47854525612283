import React, { Fragment, useMemo, useEffect, useState, useContext, useRef, memo } from 'react';
import { Table, Tag, Select } from "antd";
import { handleCitySearchHD, _getNewDesign, _getNewDesignHD } from './utilsDesign';
import { Preview } from './UiKit/Preview';
import { Button } from './UiKit/Button';
import { SocialContext } from '../contexts/socialContext';
import { StateContext } from '../contexts/stateContext';
import { LangContext } from '../contexts/langContext';

export function Import(props) {
  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const [fileContent, setFileContent] = useState('');
  const [data, setData] = useState([]); // Full data
  const [paginatedData, setPaginatedData] = useState([]); // Data for current page
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [designs, setDesigns] = useState({});


  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === 'application/json') {
      const reader = new FileReader();
      reader.onload = (event) => {
        try {
          const json = JSON.parse(event.target.result);
          setData(parseData(json));  // Set full data
          setFileContent(JSON.stringify(json, null, 2)); // Pretty print the JSON
          setError(null); // Clear any previous errors
        } catch (err) {
          setError('Invalid JSON file');
          setFileContent('');
        }
      };
      reader.readAsText(file);
    } else {
      setError('Please select a valid JSON file');
      setFileContent('');
    }
  };

  const parseData = (data) => {
    return Object.keys(data).map(oneId => {
      const one = data[oneId];
      return ({
        ...one,
        'hdCity': '',   // Initialize with empty string
        'cities': [],   // Initialize an empty array for cities
      });
    });
  };

  useEffect(() => {
    const fetchCitiesForPage = async () => {
      setLoading(true);
      const start = (currentPage - 1) * pageSize;
      const end = currentPage * pageSize;
      const dataForPage = data.slice(start, end);

      const updatedData = await Promise.all(dataForPage.map(async (one) => {
        const search = one.city.split(',')[0];
        const foundCities = await handleCitySearchHD(search);
        const filteredCities = foundCities ? foundCities.filter(cityObj => {
          return cityObj.timezone.split('/')[0] === one.p.split('/')[0];
        }) : [];

        const city = filteredCities[0] || {}; // Use the first city or an empty object
        const dob = `${one.datar}T${one.timer}:00`;

        // Fetch design data
        const fetchDesign = async () => {
          return new Promise((resolve) => {
            _getNewDesign(dob, city.timezone, (design) => {
              resolve(design);
            });
          });
        };

        const design = await fetchDesign();

        return {
          ...one,
          hdCity: filteredCities.length > 0 ? filteredCities[0].city : 'No city found',
          hdCityId: filteredCities.length > 0 ? filteredCities[0].id : 0,
          cities: filteredCities.map(city => <><b>{city.city}</b>, {city.country}, {city.timezone}</>),
          design, // Add the design data
        };
      }));

      setPaginatedData(updatedData); // Only update paginated data
      setLoading(false);
    };

    if (data.length > 0) {
      fetchCitiesForPage();
    }
  }, [currentPage, pageSize, data]);


  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const handleCityChange = (value, key) => {
    const updatedData = data.map(item => {
      if (item.key === key) {
        return { ...item, hdCity: value };
      }
      return item;
    });
    setData(updatedData);

    const updatedPaginatedData = paginatedData.map(item => {
      if (item.key === key) {
        return { ...item, hdCity: value };
      }
      return item;
    });
    setPaginatedData(updatedPaginatedData);
  };

  const columns = useMemo(() => [
    {
      key: 'title',
      title: 'Name',
      dataIndex: 'title',
    },
    {
      key: 'dob',
      title: 'DOB',
      dataIndex: 'datar',
    },
    {
      key: 'time',
      title: 'Time',
      dataIndex: 'timer',
    },
    {
      key: 'city',
      title: 'City',
      dataIndex: 'city',
    },
    {
      key: 'timezone',
      title: 'Timezone',
      dataIndex: 'p',
    },

    {
      key: 'type',
      title: 'Type',
      dataIndex: 'types',
      render: (_, { types }) => (
        <Tag key={types}>
          {types}
        </Tag>
      ),
    },
    {
      key: 'profile',
      title: 'Profile',
      dataIndex: 'profile',
    },

    {
      key: 'hd_city',
      title: 'Found City',
      dataIndex: 'hdCity',
      render: (_, record) => (
        <>
          {record.cities.length > 1 ? (
            <Select
              value={record.hdCity}
              onChange={(value) => handleCityChange(value, record.key)}
              style={{ width: 400, backgroundColor: '#f0f0f0' }}
              options={record.cities.map((city, index) => ({
                value: city,
                label: city,
              }))}
            />
          ) : record.cities[0] ? (
            <>{record.cities[0]}</>
          ) : (
            <b style={{ color: 'red' }}>Not found</b>
          )}
        </>
      ),
    },
    {
      key: 'design',
      title: 'Design',
      dataIndex: 'design',
      render: (design) => (
        <Preview
          arrowsB={true}
          arrowsP={true}
          width={80}
          height={120}
          aura={true}
          design={design}
        />
      ),
    },
    {
      key: 'import',
      title: 'Import',
      dataIndex: '',
      render: (_, { types }) => (
        <Button
          disabled={!_.design.type}
          onClick={() => context.x.createAvatar({
            name: !_.design.title,
            dob: `${_.datar}T${_.timer}:00`,
            city_id: _.hdCityId,
          })
          }
        >
          Import
        </Button >
      ),
    },
  ], [paginatedData]);

  return (
    <div className="b" style={{ padding: '2em' }}>
      <div className="_fv">
        <div className="import-container _f _fm">
          <input
            className='x_button'
            type="file"
            accept=".json"
            onChange={handleFileChange}
          />
          {error && <div className="error-message">{error}</div>}
          <textarea
            value={fileContent}
            readOnly
            rows={3}
            cols={50}
            placeholder="JSON content will appear here"
          />
        </div>
        <Table
          dataSource={paginatedData}
          columns={columns}
          loading={loading}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: data.length,
          }}
          onChange={handleTableChange}
        />
      </div>
    </div>
  );
}
