import React, { Fragment, useEffect, useRef, useState, useContext } from 'react'
import { Link, withRouter, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { getVariable, getPRR } from '../../static/variables_dictionary';
import { BodyArrow, BodyArrowNew } from '../UiKit/BodyArrows';
import { LangContext } from '../../contexts/langContext';
import { StateContext } from '../../contexts/stateContext';
import { SocialContext } from '../../contexts/socialContext';
import { Svg } from '../UiKit/Svg';
import {
  getDesignSunColor,
  getDesignSunTone,
  getDesignSunBase,
  getDesignNodeColor,
  getDesignNodeTone,
  getDesignNodeBase,
  getPersSunColor,
  getPersSunTone,
  getPersSunBase,
  getPersNodeColor,
  getPersNodeTone,
  getPersNodeBase,
  getArrow,
  getColor,
  getTone,
  getBase
} from '../utilsDesign';
import { Txt } from '../UiKit/Txt';
import { PHS2 } from './PHS2';
import { Variants } from '../UiKit/Variants';
import { Videos } from '../UiKit/Videos';
import { IconColor, IconTone, IconBase } from '../UiKit/ColorToneBase';
import { VariablesAdv } from './VariablesAdv';
import { TooltipH } from '../UiKit/TooltipH';
import { Hr } from '../UiKit/Hr';






export const Variables = React.memo(({
  id,
  design,
  withDescriptions = true,
  style = {},
  videos = true
}) => {

  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $vars = context.$vars;




  return (
    <>

      <div className="x_edu_content_inner  _fv  _f100"
        style={{ ...style }}
      >
        {withDescriptions && <div className='x_edu_content x_edu_content--full'
          style={{ paddingBottom: '1em' }}
        >
          <div className="_fv _fgap06">
            <div className="x_edu_content_h x_edu_content_h--2">
              {$vars('phs_des', 'title')}
            </div>
            <div className="x_edu_content_desc">
              {$vars('phs_des', 'description')}
            </div>
          </div>
        </div>}

        <div className="x_edu_content">
          <div className="x_phs_simple0">

            <Var
              design={design}
              variable='diet'
              // transference={true}
              videos={videos}
            />
            <Var
              design={design}
              variable='env'
              transference={true}
              videos={videos}
            />


          </div>
        </div>

        {withDescriptions && <div className='x_edu_content x_edu_content--full'
          style={{ paddingBottom: '0em' }}
        >
          <div className="_fv _fgap06">
            <div className="x_edu_content_h x_edu_content_h--2">
              {$vars('phs_prs', 'title')}
            </div>

            <div className="x_edu_content_desc">
              {$vars('phs_prs', 'description')}
            </div>
          </div>
        </div>}



        <div className="x_edu_content">
          <div className="x_phs_simple0">

            <Var
              design={design}
              variable='motivation'
              transference={true}
              videos={videos}
            />
            <Var
              design={design}
              variable='perspective'
              transference={true}
              videos={videos}
            />

          </div>

        </div>

        <div className="x_edu_content">
          <div className="x_phs_simple0">

            <Var
              design={design}
              variable='trauma'
              transference={true}
              videos={videos}
            />
            <Var
              design={design}
              variable='love'
              transference={true}
              videos={videos}
            />
          </div>
        </div>








        {/* <VariablesAdv design={design} /> */}
      </div>

    </>
  )
})









export const Var = React.memo(({
  variable = 'diet',
  design,
  addClassName = 'simple',
  withDescriptions = true,
  transference,
  advanced,
  videos
}) => {

  const prs = variable === 'motivation' || variable === 'perspective'

  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $vars = context.$vars;
  const $ui = context.$ui;


  return (
    <>




      <div className={`x_phs_item1 x_phs_item1--${addClassName}`}>

        {['diet', 'env', 'motivation', 'perspective'].includes(variable) && <>
          <div className="x_phs_item1_main">

            {Boolean(getColor[variable](design)) && <div className='_fv _fgap14'>

              <div className="_fv _fgap06">

              <div className="_fv _fgap04">
                <div className="x_phs_h">
                    <span className='x_edu_channel_of _tnowrap'>{$vars(`label_${variable}`)}:</span> <div>"{$vars(`${variable}_${getColor[variable](design)}`)}"</div>
                </div>

                  {$vars(`${variable}_${getColor[variable](design)}`, 'description') && <div className="x_phs_tone_t x_phs_tone_t--simple">
                    <div className="x_edu_phs_desc">
                      <Txt>
                        {$vars(`${variable}_${getColor[variable](design)}`, 'description')}
                      </Txt>
                    </div>
                  </div>}
                </div>

                {$vars(`${variable}_${getColor[variable](design)}_${getTone[variable](design, true)}`, 'title') && <div className="_fv _fgap04">
                  <div className="x_phs_tone_h" style={{ margin: 0 }}>
                    <span className='x_edu_channel_of _tnowrap'>{$ui('variables_subtype')}:</span> {$vars(`${variable}_${getColor[variable](design)}_${getTone[variable](design, true)}`, 'title')}
                  </div>

                  <div className="x_phs_tone_t x_phs_tone_t--simple" style={{ paddingTop: 0 }}>
                    <div className="x_edu_phs_desc">
                      <Txt>
                        {$vars(`${variable}_${getColor[variable](design)}_${getTone[variable](design, true)}`, 'description')}
                      </Txt>
                    </div>
                  </div>

                </div>}

              </div>


              {videos && <div className="x_phs_tone_t x_phs_tone_t--simple"><Videos
                // title={`${$vars(`label_${variable}`, 'title')}: ${getColor[variable](design)} ${$vars(`${variable}_${getColor[variable](design)}`, 'title')}`}
                title={`${$vars(`label_${variable}`, 'title')}: ${$vars(`${variable}_${getColor[variable](design)}_${getTone[variable](design, true)}`, 'title')}`}
                size='sm'
                relType='variables'
                relValue={`${variable}_${getColor[variable](design)}`}
              /></div>}



            </div>}

          </div>


          {Boolean(getColor[variable](design)) && advanced && <div className="x_phs_item1_nodes">
            <div className={`x_phs_coltonbas ${prs ? 'x_phs_coltonbas--prs' : 'x_phs_coltonbas--des'}`}>
              <div className="x_phs_col">
                <div className="x_phs_col_ico w-embed">

                  <BodyArrowNew
                    prs={prs} arrow={getTone[variable](design, true)}
                    // tooltip={}
                    tooltip={`${$vars(`${getArrow[variable]}_${getTone[variable](design, true)}`, 'title')} ${$vars(getArrow[variable])}`}
                  />

                </div>
              </div>
              <div className="x_phs_col">
                <IconColor title={`${getColor[variable](design)} ${$vars('label_color')}`} color={getColor[variable](design)} />
              </div>
              <div className="x_phs_col">
                <IconTone title={`${getTone[variable](design)} ${$vars('label_tone')}`} tone={getTone[variable](design)} />
              </div>
              <div className="x_phs_col">
                <IconBase title={`${getBase[variable](design)} ${$vars('label_base')}`} base={getBase[variable](design)} />
              </div>
            </div>
          </div>}

          {Boolean(getColor[variable](design)) && transference && <>
            <div className="x_phs_item1_transference">
              <div className="x_phs_h x_phs_h--transference">
                <div>{$vars(`label_${variable}_transference`)}: {$vars(`${variable}_${getColor[variable](design, false, true)}`)}</div>
              </div>
              <div
                className='x_phs_tone_t x_phs_tone_t--simple'
              >
                <Txt>
                  {$vars(`${variable}_${getColor[variable](design, false, true)}`, 'description')}
                </Txt>
              </div>
            </div>

          </>}

        </>}


        {['trauma', 'love'].includes(variable) && <>
          <MarsMoon
            videos={videos}
            design={design}
            variable={variable}
            addClassName={addClassName}
            withDescriptions={withDescriptions}
          />
        </>}


      </div>


    </>
  )
})

export const MarsMoon = React.memo(({
  videos,
  variable = 'trauma',
  design,
  addClassName = 'simple',
  withDescriptions = true,
  transference,
  advanced
}) => {

  const prs = false;

  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $vars = context.$vars;
  const $ui = context.$ui;


  const getMarsLine = () => {
    const findM = (planet) => design['gates'].length ? design['gates'].filter(one => one['planet'] === planet && one['label'] === 'des')[0].line : null
    return findM('Mars')
  }
  const getMoonLine = () => {
    const findM = (planet) => design['gates'].length ? design['gates'].filter(one => one['planet'] === planet && one['label'] === 'des')[0].line : null
    return findM('Moon')
  }

  const getVar = variable === 'trauma' ? getMarsLine : getMoonLine;



  return (
    <>





      {Boolean(getVar()) && <div className="x_phs_item1_main">


        <div className='_fv _fgap14'>

          <div className="_fv _fgap04">
            <div className="x_phs_h">
              <span className='x_edu_channel_of _tnowrap'>{$vars(`label_${variable}`)}:</span> <div>{$vars(`${variable}_${getVar()}`)}</div>
            </div>

            <div className="x_phs_tone_t x_phs_tone_t--simple">
              <div className="x_edu_phs_desc">
                <Txt>
                  {$vars(`${variable}_${getVar()}`, 'description')}
                </Txt>
              </div>
            </div>
          </div>




          {videos && <div className="x_phs_tone_t x_phs_tone_t--simple"><Videos
            // title={`${$vars(`label_${variable}`, 'title')}: ${getColor[variable](design)} ${$vars(`${variable}_${getColor[variable](design)}`, 'title')}`}
            title={`${$vars(`label_${variable}`, 'title')}: ${$vars(`${variable}_${getVar()}`, 'title')}`}
            size='sm'
            relType='variables'
            relValue={`${variable}_${getVar()}`}
          /></div>}



        </div>

      </div>}


      {/* {advanced && <div className="x_phs_item1_nodes">
          <div className={`x_phs_coltonbas ${prs ? 'x_phs_coltonbas--prs' : 'x_phs_coltonbas--des'}`}>
            <div className="x_phs_col">
              <div className="x_phs_col_ico w-embed">


              </div>
            </div>
            <div className="x_phs_col">

            </div>
            <div className="x_phs_col">

            </div>
            <div className="x_phs_col">

            </div>
          </div>
        </div>} */}









    </>
  )
})



