import React, { Fragment, useEffect, useState, useContext, useRef } from 'react'
import { Link, useNavigate, useSearchParams, useParams, useLocation } from 'react-router-dom';
import { Svg } from './Svg';

import { centersI, centerGates } from '../../static/centers_dictionary';

import { numbers_dictionary } from '../../static/numbers_dictionary';
import { numbers_selects } from '../../static/numbers_selects';

import { channels_dictionary } from '../../static/channels_dictionary';
import { gates_paths, gates_paths_thin, ggg } from '../../static/gates_svg';

import { BodyArrows } from './BodyArrows';
import { Preloader0, Preloader } from './kit';
import {
  getDesignCenters,
  defineAuthority,
  handleCenterClick,
  handleChannelClick
} from '../utilsDesign';
import { default_design } from '../../static/default_design';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { LangContext } from '../../contexts/langContext';
import { Planets } from './Planets';
import bodygraphsvg from './bodygraph.svg';
import { CenterSvg, CenterSvg2, HDsvg } from './bodygraph_parts';
import { Svg2 } from './Svg2';
import { tooltipTheme } from './tooltip_theme';
import { TooltipH } from './TooltipH';
import { CenterInfo } from './CenterInfo';
import { BodyHint } from './BodyHint';
import { gates_dictionary } from '../../static/gates_dictionary';

export const bwColor = '#c0c0c0';
export const theme = {
  designColor: '#f45656',
  designColor2: '#ffa8a8',
  personalityColor: '#000000',
  personalityColor2: '#878787',
  highlightColor: '#002bff',
  highlightColorInactive: '#fcfcfc',
  highlightDimmed: '#c0c0c0',
  openColor: '#FFF',
  openColor2: '#ffffff43',
  authorityColor: 'black',
  definedColor: bwColor,
  personalityColorC1: '#8300E9',
  lineOpen: '#FFF',
  designColorC1: '#C173FE',
  designColorC2: '#00C394',
  personalityColorC2: '#69FF6F',
  personalityColoroth: 'blue',
  line0: '#FFF',
  line0comp: 'transparent',
  highlightedGateInnerColor: '#002bff',
  // ...colorThemeInner
}





export const Bodygraph = React.memo(({
  hints,
  interactive,
  hoverable,
  body = true,
  children,
  designComposite = {},
  centers = ['HD', 'AA', 'TT', 'HT', 'GC', 'SN', 'SL', 'SP', 'RT'],
  activePage,
  design = default_design,
  width,
  height,
  lines = true,
  authority,
  numbers,
  dim = true,
  planets,
  planetsPrs,
  planetsDes,
  // size,
  bodyContainer,
  blur,
  arrowsP,
  arrowsB,
  preloader,
  composite,
  whiteLines = true,
  style,
  advanced,
  parentContainer,

  focusedCenter,
  highlighted = [],
  highlightedGate = [],

  colorTheme = {},
  addClassName = '',
  aura = false
}) => {

  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $center = context.$center;
  const $channel = context.$channel;
  const $ui = context.$ui;

  const [colorThemeInner, setColorThemeInner] = useState({
    ...theme,
    ...colorTheme
  })

  useEffect(() => {
    setColorThemeInner({
      ...theme,
      ...colorTheme
    })
  }, [colorTheme])



  // design = (design && design[0] && design[0].type) ? design[0] : default_design
  design = design ? design : default_design;

  const sizes = {
    sm: {
      width: 36,
      height: 64,
    },
    md: {
      width: 90,
      height: 120,
    },
    preview: {
      width: 300,
      height: 380,
    },
    full: {
      width: 600,
      height: 780,
    },
  };


  const [svgWidth, setSvgWidth] = useState(width);
  const [svgHeight, setSvgHeight] = useState(height);


  const [highlightedInner, setHighlightedInner] = useState(highlighted);
  const [highlightedGateInner, setHighlightedGateInner] = useState(highlightedGate);

  function arraysEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) return false;
    const sortedArr1 = arr1.slice().sort();
    const sortedArr2 = arr2.slice().sort();
    return sortedArr1.every((value, index) => value === sortedArr2[index]);
  }

  useEffect(() => {
    if (!arraysEqual(highlighted, highlightedInner)) {
      setHighlightedInner(highlighted)
    }
  }, [highlighted])
  useEffect(() => {
    if (!arraysEqual(highlightedGate, highlightedGateInner)) {
      setHighlightedGateInner(highlightedGate)
    }
  }, [highlightedGate])


  const setWH = () => {


    if (parentContainer && parentContainer.current) {
      let clientWidth, clientHeight;
      clientWidth = parentContainer.current.clientWidth;
      clientHeight = parentContainer.current.clientHeight;
      setSvgWidth(clientWidth)
      setSvgHeight(clientHeight)
    }

    // if (width && height) {
    //   clientWidth = width;
    //   clientHeight = height;
    // } else if (parentContainer && parentContainer.current) {
    //   clientWidth = parentContainer.current.clientWidth;
    //   clientHeight = parentContainer.current.clientHeight;
    // } else {
    //   clientWidth = sizes['md'].width;
    //   clientHeight = sizes['md'].height;
    // }

    // if (clientWidth && clientWidth !== svgWidth) {
    //   console.log('clientWidth = ', clientWidth);
    //   console.log('clientHeight = ', clientHeight);
    //   setSvgWidth(clientWidth ? clientWidth : svgWidth)
    //   setSvgHeight(clientHeight ? clientHeight : svgHeight)
    //   // setSvgWidth(svgWidth)
    //   // setSvgHeight(svgHeight)
    // }
  }


  const location = useLocation();
  const navigate = useNavigate();

  const handleCenterClickInner = (centerId) => {
    context.setState.setHighlighted([centerId]);
    context.setState.setSearch([]);
    handleCenterClick(centerId, location, navigate);
  };
  const handleChannelClickInner = (channelId) => {
    const centers = channels_dictionary.filter(one => one.id === channelId)[0].centers;
    const gates = channels_dictionary.filter(one => one.id === channelId)[0].gates;
    context.setState.setHighlighted([...centers]);
    context.setState.setSearch([...gates]);
    handleChannelClick(channelId, location, navigate);
  };
  const handleChannelHover = (channelId) => {


    if (channelId) {
      const centers = channels_dictionary.filter(one => one.id === channelId)[0].centers;
      const gates = channels_dictionary.filter(one => one.id === channelId)[0].gates;
      // context.setState.setHighlighted([...centers]);
      // context.setState.setSearch([...gates]);
      setHighlightedInner([...centers]);
      setHighlightedGateInner([...gates]);
      // handleChannelClick(channelId, location, navigate);
    } else {
      // context.setState.setHighlighted([...highlighted]);
      // context.setState.setSearch([...highlightedGate]);
      setHighlightedInner([...highlighted]);
      setHighlightedGateInner([...highlightedGate]);
    }
  };



  const handleCenterHover = (center) => {
    // context.setState.setHighlighted(center)
  }



  // const [svgWidth, setSvgWidth] = useState(width ? sizes['md'].width : width);
  // const [svgHeight, setSvgHeight] = useState(height ? sizes['md'].height : height);





  const colors = {
    base: {
      prs: colorThemeInner.personalityColor,
      des: colorThemeInner.designColor,
      open: colorThemeInner.lineOpen,
      chakraOpen: colorThemeInner.openColor,
      chakraDefined: colorThemeInner.definedColor,
    },
    composite1: {
      prs: colorThemeInner.personalityColorC1,
      des: colorThemeInner.designColorC1,
      open: colorThemeInner.openColor,
      chakraOpen: colorThemeInner.openColor,
      chakraDefined: colorThemeInner.definedColor,
    },
    composite2: {
      prs: colorThemeInner.personalityColorC2,
      des: colorThemeInner.designColorC2,
      open: colorThemeInner.openColor,
      chakraOpen: colorThemeInner.openColor,
      chakraDefined: colorThemeInner.definedColor,
    },
  }

  const chakra = (ch, definedCenters) => {
    // return design['active_chakras'] ? design['active_chakras'].indexOf(ch) > -1 : false
    return definedCenters ? definedCenters.indexOf(ch) > -1 : false
  }


  const bodyType = !composite ? 'base' : composite === 1 ? 'composite1' : 'composite2';

  const openChakra = colors[bodyType]['chakraOpen'];
  const definedChakra = colors[bodyType]['chakraDefined'];

  const centerColor = (centerName, definedCenters = getDesignCenters(design['gates'], designComposite['gates'])) => {
    // let clr = chakra(centerName, definedCenters) ? dim || highlighted ? colors.highlightDimmed : definedChakra : openChakra;
    // let clr = chakra(centerName) ? dim || highlighted ? colorThemeInner.highlightDimmed : centersI[centerName]['color'][theme] : colorThemeInner.openColor;

    // let clr = definedCenters.includes(centerName) ?
    //   (centerName === context.state.highlighted && hoverable) ?
    //     colorThemeInner.highlightColor : definedChakra
    //   : (centerName === context.state.highlighted && hoverable) ?
    //     colorThemeInner.highlightColorInactive : openChakra;


    let clr = definedCenters.includes(centerName) ? definedChakra : openChakra;
    // let clr = chakra(centerName, definedCenters) ? definedChakra : openChakra;
    // clr = highlighted === centerName && width < 100 ? colors.highlightColor : clr;
    if (authority && defineAuthority(definedCenters) === centerName) {
      clr = colors.authorityColor
    };
    return clr;
  }





  const personGates = (returnArray, focusedCenter) => {
    let personGatesObject = {}
    // console.log('person = ', design);
    if (!design) return;
    if (design['gates']) design['gates'].forEach((one) => {
      // console.log('one = ', one);
      if (!one) return;
      if (!personGatesObject[one['gate'].toString()]) {
        personGatesObject[one['gate'].toString()] = [{
          ...one
        }]
        // personGatesObject = personGatesObject.flat()
      } else {
        personGatesObject[one['gate'].toString()].push({
          ...one
        })
        // personGatesObject = personGatesObject.flat()
      }

    })

    // const findGatesofCenter = (center) => {
    //   let ret = [];
    //   let foundChannels = channel_dictionary.filter(oneClannel => oneClannel['centers'].includes(center))
    //   if (foundChannels.length) {
    //     foundChannels.forEach(oneChannel => ret.push(...oneChannel['gates']))
    //   }
    //   console.log('ret = ', ret);
    //   return ret;
    // }
    const ifGateIsActivated = (gate) => {
      gate = gate.toString()
      let activated = Object.keys(personGatesObject).filter(oneGate => oneGate === gate.toString()).length
      // console.log('gate ', gate, activated ? 'is activated' : 'is NOT activated');

      return activated
    }

    if (focusedCenter && returnArray) {
      let ret = []
      Object.keys(personGatesObject).forEach(gateNumber => {
        // console.log('gateNumber = ', gateNumber);
        gateNumber = parseInt(gateNumber)
        let r = false;
        let oneGate = personGatesObject[gateNumber][0];
        // console.log('channels_dictionary = ', channels_dictionary);
        channels_dictionary.forEach(oneChannel => {
          let index = oneChannel['gates'].findIndex((gate) => gate === gateNumber)
          // let connected = oneChannel['centers'][index] === focusedCenter;

          let connected = false;
          connected = oneChannel['centers'][index] === focusedCenter ? true : connected;
          if (index > -1) {
            let otherGate = index === 0 ? oneChannel['gates'][1] : oneChannel['gates'][0];
            let orherGateActivated = ifGateIsActivated(otherGate)
            connected = orherGateActivated && oneChannel['centers'].includes(focusedCenter) ? true : connected;
          }


          // console.log('oneChannel[centers] = ', oneChannel['centers']);
          // console.log('highlighted = ', focusedCenter);
          r = connected ? true : r;
        })
        if (r) { returnArray ? ret.push(gateNumber) : ret.push(oneGate) }
      });
      // console.log('ret = ', ret);
      return ret
    }

    return returnArray ? Object.keys(personGatesObject) : personGatesObject;
  }


  const gateColor = (gate, secondary) => {
    // return 'black'
    let returnColor = colors[bodyType]['open'];
    if (personGates()[gate]) {
      let clrs = []
      personGates()[gate].map((oneActivation) => {
        clrs.push(oneActivation['label'])
      })
      clrs = [...new Set(clrs)];
      if ([...new Set(clrs)].length > 1) {
        return !secondary ? colors[bodyType][clrs[0]] : colors[bodyType][clrs[1]]
      } else {
        return colors[bodyType][clrs[0]];
      }

    } else {
      return returnColor;
    }
  }


  const drawGates = (highlighted, highlightedGateInner = []) => {

    const gates64 = [];
    for (let i = 1; i <= 64; i++) { gates64.push(i.toString()); }

    let emptyGates = focusedCenter ? [] : gates64.filter(value => !personGates(true).includes(value));


    let gates_svg = '';



    const selectWidth = 6;


    // gates_svg = gates_svg.replaceAll('gateWidth', 8);

    let gates_select = '';
    emptyGates
      .filter(oneGateNum => highlightedGateInner.includes(parseInt(oneGateNum)))
      .forEach(oneGateNum => {
        oneGateNum = typeof oneGateNum === 'string' ? parseInt(oneGateNum) : oneGateNum;
        let gatePathSelect = gates_paths[oneGateNum];
        gatePathSelect = gatePathSelect.replace('strokeWidth', selectWidth);
        const highlightColor = colorThemeInner.highlightedGateInnerColor;
        gatePathSelect = gatePathSelect.replace('strokeColor', highlightColor);
        gatePathSelect = gatePathSelect.replace('gateColor', highlightColor)
        gates_select = gates_select + gatePathSelect + '\n';
      })


    emptyGates
      .forEach(oneGateNum => {
        oneGateNum = typeof oneGateNum === 'string' ? parseInt(oneGateNum) : oneGateNum;
        let gatePath = gates_paths[oneGateNum];
        gates_svg += gatePath + '\n'
        // gates_svg += gates_paths_thin[oneGateNum] + '\n'
      })
    gates_svg = gates_svg + whiteLines ? gates_svg.replaceAll('gateColor', highlightedGate.length ? colorThemeInner.openColor2 : colors[bodyType]['open']) : gates_svg.replaceAll('gateColor', 'transparent');

    gates_svg = gates_svg + gates_select;

    personGates(true, focusedCenter, colorThemeInner)
      .filter(oneGateNum => !highlightedGateInner.includes(parseInt(oneGateNum)))
      .forEach(oneGateNum => {
        let gatePath = gates_paths[oneGateNum];
        let gateCol = gateColor(oneGateNum);
        let gateCbleak = gateCol === colorThemeInner.designColor ? colorThemeInner.designColor2 : colorThemeInner.personalityColor2;

        // console.log('highlightedGateInner = ', highlightedGateInner);



        if (highlightedGateInner.length && !highlightedGateInner.includes(oneGateNum)) gateCol = gateCbleak;
        if (dim && highlightedInner.length) {
          const gateInHighlightedCenter = []
          gateInHighlightedCenter.push(
            ...highlightedInner.map(oneCenter => centerGates[oneCenter])
          )
          gateCol = (gateInHighlightedCenter.flat().includes(oneGateNum)) ? gateCol : gateCbleak;
        }

        gatePath = gatePath.replace('gateColor', gateCol)
        // gatePath = gatePath.replace('gateColor', gateC);
        gatePath = gatePath.replaceAll('gateWidth', 8);

        gates_svg += gatePath + '\n';

        if (personGates()[oneGateNum].length > 1) {
          let gatePathThin = gates_paths_thin[oneGateNum]
          let gateColor2 = gateColor(oneGateNum, true);
          // console.log('gatePathThin = ', gatePathThin);
          // gatePathThin = gatePathThin.replace('gateColor', gateCthin);
          gatePathThin = gatePathThin.replace('gateColor', gateColor2);
          gates_svg += gatePathThin + '\n';
        }
      })


    // SELECTED


    // emptyGates
    //   .filter(oneGateNum => highlightedGateInner.includes(parseInt(oneGateNum)))
    //   .forEach(oneGateNum => {
    //     oneGateNum = typeof oneGateNum === 'string' ? parseInt(oneGateNum) : oneGateNum;
    //     let gatePath = gates_paths[oneGateNum];
    //     let gateOpenColor = colors[bodyType]['open'];
    //     gatePath = gatePath.replace('gateColor', gateOpenColor)
    //     gates_select = gates_select + gatePath + '\n';
    //   })


    personGates(true, focusedCenter, colorThemeInner)
      .filter(oneGateNum => highlightedGateInner.includes(parseInt(oneGateNum)))
      .forEach(oneGateNum => {
        let gatePathSelect = gates_paths[oneGateNum];
        // const highlightColor = colorThemeInner.highlightedGateInnerColor;
        const highlightColor = gateColor(oneGateNum);
        gatePathSelect = gatePathSelect.replace('strokeWidth', selectWidth);
        gatePathSelect = gatePathSelect.replace('strokeColor', highlightColor);
        gatePathSelect = gatePathSelect.replace('gateColor', highlightColor)
        gates_select = gates_select + gatePathSelect + '\n';
      })



    // SELECT TOP
    emptyGates
      .filter(oneGateNum => highlightedGateInner.includes(parseInt(oneGateNum)))
      .forEach(oneGateNum => {
        oneGateNum = typeof oneGateNum === 'string' ? parseInt(oneGateNum) : oneGateNum;
        let gatePathSelect = gates_paths[oneGateNum];
        const highlightColor = colorThemeInner.highlightedGateInnerColor;
        gatePathSelect = gatePathSelect.replace('strokeWidth', selectWidth);
        gatePathSelect = gatePathSelect.replace('strokeColor', highlightColor);
        gatePathSelect = gatePathSelect.replace('gateColor', highlightColor)
        gates_select = gates_select + gatePathSelect + '\n';
      })
    emptyGates
      .filter(oneGateNum => highlightedGateInner.includes(parseInt(oneGateNum)))
      .forEach(oneGateNum => {
        oneGateNum = typeof oneGateNum === 'string' ? parseInt(oneGateNum) : oneGateNum;
        let gatePath = gates_paths[oneGateNum];
        let gateOpenColor = colors[bodyType]['open'];
        gatePath = gatePath.replace('gateColor', gateOpenColor)
        gates_select = gates_select + gatePath + '\n'
        // gates_svg += gates_paths_thin[oneGateNum] + '\n'
      })

    personGates(true, focusedCenter, colorThemeInner)
      .filter(oneGateNum => highlightedGateInner.includes(parseInt(oneGateNum)))
      .forEach(oneGateNum => {
        let gatePath = gates_paths[oneGateNum];
        let gateC = gateColor(oneGateNum);
        gatePath = gatePath.replace('gateColor', gateC)
        gates_select = gates_select + gatePath + '\n';
        if (personGates()[oneGateNum].length > 1) {
          let gatePathThin = gates_paths_thin[oneGateNum]
          let gateColor2 = gateColor(oneGateNum, true);
          // console.log('gatePathThin = ', gatePathThin);
          // gatePathThin = gatePathThin.replace('gateColor', gateCthin);
          gatePathThin = gatePathThin.replace('gateColor', gateColor2);
          gates_select += gatePathThin + '\n';
        }
      })


    gates_svg = gates_svg + gates_select;
    return gates_svg;
  }
  const drawGatesHighlighted = (highlighted, highlightedGateInner = []) => {

    const gates64 = [];
    for (let i = 1; i <= 64; i++) { gates64.push(i.toString()); }

    let emptyGates = focusedCenter ? [] : gates64.filter(value => !personGates(true).includes(value));


    let gates_svg = '';



    const selectWidth = 6;


    // gates_svg = gates_svg.replaceAll('gateWidth', 8);

    let gates_select = '';
    // emptyGates
    //   .filter(oneGateNum => highlightedGateInner.includes(parseInt(oneGateNum)))
    //   .forEach(oneGateNum => {
    //     oneGateNum = typeof oneGateNum === 'string' ? parseInt(oneGateNum) : oneGateNum;
    //     let gatePathSelect = gates_paths[oneGateNum];
    //     gatePathSelect = gatePathSelect.replace('strokeWidth', selectWidth);
    //     const highlightColor = colorThemeInner.highlightedGateInnerColor;
    //     gatePathSelect = gatePathSelect.replace('strokeColor', highlightColor);
    //     gatePathSelect = gatePathSelect.replace('gateColor', highlightColor)
    //     gates_select = gates_select + gatePathSelect + '\n';
    //   })



    // SELECTED


    // emptyGates
    //   .filter(oneGateNum => highlightedGateInner.includes(parseInt(oneGateNum)))
    //   .forEach(oneGateNum => {
    //     oneGateNum = typeof oneGateNum === 'string' ? parseInt(oneGateNum) : oneGateNum;
    //     let gatePath = gates_paths[oneGateNum];
    //     let gateOpenColor = colors[bodyType]['open'];
    //     gatePath = gatePath.replace('gateColor', gateOpenColor)
    //     gates_select = gates_select + gatePath + '\n';
    //   })


    personGates(true, focusedCenter, colorThemeInner)
      .filter(oneGateNum => highlightedGateInner.includes(parseInt(oneGateNum)))
      .forEach(oneGateNum => {
        let gatePathSelect = gates_paths[oneGateNum];
        // const highlightColor = colorThemeInner.highlightedGateInnerColor;
        const highlightColor = gateColor(oneGateNum);
        gatePathSelect = gatePathSelect.replace('strokeWidth', selectWidth);
        gatePathSelect = gatePathSelect.replace('strokeColor', highlightColor);
        gatePathSelect = gatePathSelect.replace('gateColor', highlightColor)
        gates_select = gates_select + gatePathSelect + '\n';
      })



    // SELECT TOP
    emptyGates
      .filter(oneGateNum => highlightedGateInner.includes(parseInt(oneGateNum)))
      .forEach(oneGateNum => {
        oneGateNum = typeof oneGateNum === 'string' ? parseInt(oneGateNum) : oneGateNum;
        let gatePathSelect = gates_paths[oneGateNum];
        const highlightColor = colorThemeInner.highlightedGateInnerColor;
        gatePathSelect = gatePathSelect.replace('strokeWidth', selectWidth);
        gatePathSelect = gatePathSelect.replace('strokeColor', highlightColor);
        gatePathSelect = gatePathSelect.replace('gateColor', highlightColor)
        gates_select = gates_select + gatePathSelect + '\n';
      })
    emptyGates
      .filter(oneGateNum => highlightedGateInner.includes(parseInt(oneGateNum)))
      .forEach(oneGateNum => {
        oneGateNum = typeof oneGateNum === 'string' ? parseInt(oneGateNum) : oneGateNum;
        let gatePath = gates_paths[oneGateNum];
        let gateOpenColor = colors[bodyType]['open'];
        gatePath = gatePath.replace('gateColor', gateOpenColor)
        gates_select = gates_select + gatePath + '\n'
        // gates_svg += gates_paths_thin[oneGateNum] + '\n'
      })

    personGates(true, focusedCenter, colorThemeInner)
      .filter(oneGateNum => highlightedGateInner.includes(parseInt(oneGateNum)))
      .forEach(oneGateNum => {
        let gatePath = gates_paths[oneGateNum];
        let gateC = gateColor(oneGateNum);
        gatePath = gatePath.replace('gateColor', gateC)
        gates_select = gates_select + gatePath + '\n';
        if (personGates()[oneGateNum].length > 1) {
          let gatePathThin = gates_paths_thin[oneGateNum]
          let gateColor2 = gateColor(oneGateNum, true);
          // console.log('gatePathThin = ', gatePathThin);
          // gatePathThin = gatePathThin.replace('gateColor', gateCthin);
          gatePathThin = gatePathThin.replace('gateColor', gateColor2);
          gates_select += gatePathThin + '\n';
        }
      })








    gates_svg = gates_svg + gates_select;
    return gates_svg;
  }


  const strokeColor = (centerName) => {
    let clr = chakra(centerName) ?
      dim ?
        colorThemeInner.highlightDimmed
        : 'transparent'
      : 'transparent';
    // let clr = 'transparent';
    // let clr = 'blue';
    return highlightedInner.includes(centerName) ? colorThemeInner.highlightColor : clr;
  }


  const body_contour = `<path d="M184.403 288.337C160.903 294 152.885 305.5 135.903 332.5C118.92 359.5 69.8444 450.734 46.4025 537C33.9025 583 28.6168 624.5 52.9025 658.5C105.281 731.83 184.173 776.547 276.083 776.547C324.051 776.547 295.886 777.567 330.587 776.547C419.772 773.924 504.347 725.762 550.903 648.5C567.523 620.918 565.153 578 555.902 541C532.277 446.5 470.403 340.5 452.426 318.785C441.856 306.016 431.903 293.5 413.903 288.337C389.595 281.365 355.978 276.603 351.386 238.341C347.369 204.875 361.592 198.038 374.349 168.958C387.107 139.879 388.791 110.799 371.798 83.2501C354.805 55.7011 322.298 47.5384 295.252 47.0283C268.206 46.5181 253.073 52.855 236.567 71.0061C221.258 87.8417 216.665 119.472 218.196 124.064C219.727 128.655 222.789 130.186 224.32 136.308C225.596 141.409 214.624 158.755 212.073 164.877C209.521 170.999 212.583 173.04 218.196 176.101C221.882 178.11 222.789 182.223 222.789 190.895C222.789 199.568 221.769 199.058 222.789 211.302C223.81 223.546 232.717 225.579 243.712 222.526C252.897 219.975 255.959 224.566 255.959 233.239C255.959 241.912 252.897 254.156 232.995 270.992C218.629 283.144 204.888 283.401 184.403 288.337Z"
  stroke="black" stroke-opacity="0.1"
  />`

  // fill='white'
  // style="mix-blend-mode:multiply"



  const lines_svg = `<svg width="600" height="800" viewBox="0 0 600 800" fill="none" xmlns="http://www.w3.org/2000/svg">
    ${drawGates(highlighted, highlightedGateInner)}
  </svg>
  `
  const lines_svg_selected = `<svg width="600" height="800" viewBox="0 0 600 800" fill="none" xmlns="http://www.w3.org/2000/svg">
    ${drawGatesHighlighted([], highlightedGateInner)}
  </svg>
  `


  const renderCenter = (center, centerColor, strokeColor, customId = '') => {
    if (center === 'HD') {
      return `<g>
        <path
        id="${center}"
        d="M287.467 34.435C294.887 27.0151 304.918 27.0151 312.338 34.435L351.936 97.033C357.371 107 351.936 117.903 339.935 117.903C326.371 117.903 272.871 117.903 258.869 117.903C244.868 117.903 242.871 104.5 247.869 97.033L287.467 34.435Z"
        fill="${centerColor}"
        stroke="${strokeColor}"
        stroke-width="5"
        />
      </g>`
    }
    if (center === 'AA') {
      return `<g>
      <path
      id="AA"
      d="M311.338 221.483C303.918 228.903 293.887 228.903 286.467 221.483L246.869 158.885C241.434 148.918 246.869 138.015 258.87 138.015C272.434 138.015 325.934 138.015 339.935 138.015C353.937 138.015 355.934 151.418 350.936 158.885L311.338 221.483Z"
        fill="${centerColor}"
        stroke="${strokeColor}"
      stroke-width="5"
      />
    </g>`
    }
    if (center === 'TT') {
      return `<g>
      <rect

      x="251.902" y="257" width="94" height="94" rx="19"
        fill="${centerColor}"
        stroke="${strokeColor}"
      stroke-width="5"
      />
    </g>`
    }
    if (center === 'GC') {
      return `<g>
      <rect x="232.902" y="432" width="94" height="94" rx="19" transform="rotate(-45 232.902 432)"
        fill="${centerColor}"
        stroke="${strokeColor}"
      stroke-width="5"
      />
    </g>`
    }
    if (center === 'HT') {
      return `<g>
      <path d="M402.68 458.579C409.415 453.651 416.376 454.979 421.42 461.872L446.721 513.981C449.9 522.641 444.344 530.606 434.82 529.017C424.053 527.221 381.588 520.137 370.475 518.283C359.362 516.429 359.302 505.488 364.121 500.202L402.68 458.579Z"
        fill="${centerColor}"
        stroke="${strokeColor}"
      stroke-width="5"
      />
    </g>`
    }
    if (center === 'SP') {
      return `<g>
      <path d="M434.338 603.903C426.918 596.483 426.918 586.453 434.338 579.033L496.936 539.435C506.902 534 517.806 539.435 517.806 551.435C517.806 565 517.806 618.5 517.806 632.501C517.806 646.502 504.402 648.5 496.936 643.501L434.338 603.903Z"
        fill="${centerColor}"
        stroke="${strokeColor}"
      stroke-width="5"
      />
      </g>`
    }
    if (center === 'SN') {
      return `<g>
      <path d="M163.483 603.903C170.903 596.483 170.903 586.453 163.483 579.033L100.885 539.435C90.9182 534 80.0152 539.435 80.0152 551.435C80.0152 565 80.0152 618.5 80.0152 632.501C80.0152 646.502 93.4182 648.5 100.885 643.501L163.483 603.903Z"
        fill="${centerColor}"
        stroke="${strokeColor}"
      stroke-width="5"
      />
</g>`
    }
    if (center === 'SL') {
      return `<g>
      <rect x="251.902" y="553" width="94" height="94" rx="19"
        fill="${centerColor}"
        stroke="${strokeColor}"
      stroke-width="5"
      />
</g>`
    }
    if (center === 'RT') {
      return `<g>
      <rect x="251.902" y="675" width="94" height="94" rx="19"
        fill="${centerColor}"
        stroke="${strokeColor}"
      stroke-width="5"
      />
</g>`
    }
  }

  const Center = ({ center, className, centerColor }) => {
    const c = `<svg
    xmlns="http://www.w3.org/2000/svg"
    width = "600"
    height="800"
    viewBox="0 0 600 800"
      style={{ border: '1px solid red' }}
      data-id="${center}"
      className={className} width="200" height="200" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
      ${renderCenter(center, 'red', 'blue')}
    </svg>`
    return <img src={svgToDataURL(c)}
      alt={center}
      className={className}
    />
  }








  // width = "${width}"
  // height="${height}"
  // viewBox="0 0 ${width} ${height}"


  const renderNumbers = () => {
    let gates = numbers_dictionary;
    Object.keys(gates_dictionary).forEach(oneGate => {
      oneGate = parseInt(oneGate)
      if (highlightedGateInner.includes(oneGate)) {
        const gateRegex = new RegExp(`fill="gate${oneGate}"`, 'g');
        let gateCol = gateColor(oneGate)
        console.log('gateCol = ', gateCol);
        console.log('colorThemeInner.openColor = ', colorThemeInner.openColor);
        // gateCol = 'black';
        gateCol = gateCol === colorThemeInner.openColor ? 'black' : gateCol;
        gateCol = gateCol === colorThemeInner.designColor ? colorThemeInner.personalityColor : gateCol;
        gateCol = gateCol === colorThemeInner.personalityColor ? colorThemeInner.openColor : gateCol;
        gates = gates.replace(gateRegex, `fill="${gateCol}"`);
        // console.log('gates = ', gates);
      }
    });
    const gateSelectRegex = /fill="gate\d+"/g;
    gates = gates.replace(gateSelectRegex, 'fill="#838383"');
    return gates;
  };

  const renderSelects = () => {
    let gates = numbers_selects;
    highlightedGateInner.forEach(oneGate => {
      const gateRegex = new RegExp(`fill="gateSelect${oneGate}"`, 'g');
      let gateCol = gateColor(oneGate)
      gates = gates.replace(gateRegex, `fill="${gateCol}"`);
      // const gateStrokeRegex = new RegExp(`stroke="gateSelect${oneGate}"`, 'g');
    });
    // gates = gates.replace(/fill="(?!`${gateCol}`)[^"]+"/g, 'fill="transparent"');
    return gates;
  };



  const svg = `<svg
    xmlns="http://www.w3.org/2000/svg"
    width = "600"
    height="800"
    viewBox="0 0 600 800"

    fill="none"
    preserveAspectRatio="xMidYMid meet"
  >
    ${body && <path d="M184.403 288.337C160.903 294 152.885 305.5 135.903 332.5C118.92 359.5 69.8444 450.734 46.4025 537C33.9025 583 28.6168 624.5 52.9025 658.5C105.281 731.83 184.173 776.547 276.083 776.547C324.051 776.547 295.886 777.567 330.587 776.547C419.772 773.924 504.347 725.762 550.903 648.5C567.523 620.918 565.153 578 555.902 541C532.277 446.5 470.403 340.5 452.426 318.785C441.856 306.016 431.903 293.5 413.903 288.337C389.595 281.365 355.978 276.603 351.386 238.341C347.369 204.875 361.592 198.038 374.349 168.958C387.107 139.879 388.791 110.799 371.798 83.2501C354.805 55.7011 322.298 47.5384 295.252 47.0283C268.206 46.5181 253.073 52.855 236.567 71.0061C221.258 87.8417 216.665 119.472 218.196 124.064C219.727 128.655 222.789 130.186 224.32 136.308C225.596 141.409 214.624 158.755 212.073 164.877C209.521 170.999 212.583 173.04 218.196 176.101C221.882 178.11 222.789 182.223 222.789 190.895C222.789 199.568 221.769 199.058 222.789 211.302C223.81 223.546 232.717 225.579 243.712 222.526C252.897 219.975 255.959 224.566 255.959 233.239C255.959 241.912 252.897 254.156 232.995 270.992C218.629 283.144 204.888 283.401 184.403 288.337Z" fill="#EBEBEB" />}
    ${body_contour}
    ${lines ? lines_svg : ''}
    ${centers.map(one => renderCenter(one, centerColor(one), strokeColor(one)), design['active_chakras'])}
    ${!composite && numbers ? renderSelects() : ''}
    ${!composite && numbers ? renderNumbers() : ''}
    </svg>`
  // ${lines ? lines_svg_selected : ''}

  const svgCenters = `<svg
    xmlns="http://www.w3.org/2000/svg"
    width = "600"
    height="800"
    viewBox="0 0 600 800"

    fill="none"
    preserveAspectRatio="xMidYMid meet"
  >
  ${centers.map(one => renderCenter(one, centerColor(one), strokeColor(one)), centers)}
  ${numbers && numbers_dictionary}
  </svg>`


  // const renderSvg = () => `${context.state.svgs[design['id']]}`


  useEffect(() => {
    if (parentContainer
      && parentContainer.current
      && parentContainer.current.clientWidth !== svgWidth
    ) {
      window.addEventListener('resize', () => {
        setWH()
      });
      setWH()
    }
  }, [parentContainer]);




  const svgToDataURL = (svg) => {
    const base64Svg = btoa(svg);
    return `data:image/svg+xml;base64,${base64Svg}`;
  };

  // const import bodygraphsvg from './bodygraph.svg';svg = require(`./bodygraph.svg`);


  return (
    <>
      <div className={advanced ? 'x_bodygraph x_bodygraph--magic' : 'x_bodygraph'}
      // onClick={() => handleCloseInfoPop()}
      >

        {planets &&
          <Planets
            label='des'
            design={design}
            advanced={advanced}
            setHighlightedGate={setHighlightedGateInner}
            highlightedGate={highlightedGateInner}
            fontSize={width < 501 ? '0.8em' : '1em'}
          />
        }


        <div
          className={advanced ? `x_bodygraph_body x_bodygraph_body--magic x_bodygraph_body--${addClassName}` : `x_bodygraph_body x_bodygraph_body--${addClassName}`}
          // ref={bodyContainer}
          // style={{
          //   // width: parentContainer ? svgWidth : width ? width : 100,
          //   // height: parentContainer ? svgHeight : height ? height : 100,
          //   width: svgWidth ? svgWidth : sizes['md'].width,
          //   height: svgHeight ? svgHeight : sizes['md'].height,
          //   opacity: preloader ? 0.4 : 1,
          //   zIndex: 3
          // }}
        >
          <img
            src={bodygraphsvg}
            // src='https://uploads-ssl.webflow.com/643f3bd2d71a69bb42b11dfb/643f3dfa49c20525e8af293b_bodygraph.svg'
            className={`x_bodygraph_svg x_bodygraph_svg--${addClassName}`}
            style={{
              visibility: 'hidden',
              width: Boolean(svgHeight) ? svgWidth : 'inherit',
              // height: Boolean(svgHeight) ? svgHeight : 'inherit',
              // opacity: 0.5
            }}
            ref={bodyContainer}
          />

          <img src={svgToDataURL(svg)}
            alt='bodygraph'
            style={{
              position: 'absolute',
              height: '100%',
              // opacity: 0.5
            }}
            className={`x_bodygraph_svg ${hints ? 'x_bodygraph_svg--' : ''}`}
          />

          {/* <Svg2
            className={`x_bodygraph_svg ${addClassName}`}
            style={{ position: 'absolute', zIndex: 111 }}
            // width={parentContainer ? svgWidth : width ? width : 100}
            // height={parentContainer ? svgHeight : height ? height : 100}
            width={svgWidth}
            height={svgHeight}
          >
            {svg}
          </Svg2> */}


          {composite && <div
            className='x_bodygraph'
            style={{
              zIndex: 3,
              position: 'absolute',
              width: svgWidth ? svgWidth : sizes['md'].width,
              height: svgHeight ? svgHeight : sizes['md'].height,
            }}>
            <Svg
              className='x_bodygraph'
              width={parentContainer ? svgWidth : width ? width : sizes['md'].width}
              height={parentContainer ? svgHeight : height ? height : sizes['md'].height}
            >
              {svgCenters}
            </Svg></div>}

          {/* {children} */}

          {preloader && <Preloader0 style={{ paddingTop: '4em' }}><Preloader width={80} height={80} /></Preloader0>}


          {/* <div

          style={{
            width: svgWidth ? svgWidth : sizes['md'].width,
            height: svgHeight ? svgHeight : sizes['md'].height,
            transition: 'opacity 100ms ease-in',
            ...style
          }}
        > */}

          {design['variables'] && design['variables']['left_down'] && <BodyArrows
            hints={hints}
            theme={{
              designColor: colorThemeInner.designColor,
              personalityColor: colorThemeInner.personalityColor,
            }}
            design={design}
            bodygraphWidth={svgWidth}
            variables={design['variables']}
            arrowsB1={arrowsB}
            arrowsB2={arrowsB}
            arrowsP1={arrowsP}
            arrowsP2={arrowsP}
            // size={svgWidth > 300 ? 'lg' : 'sm'}
            size={'lg'}
          />}





          {
            design.id !== 'dummy' &&
            hoverable && <>



            {channels_dictionary.map(one => one.id).map(oneId =>
              <div
                key={`channel--${oneId}`}
                className={`x_body_hover_channel x_body_hover_channel--${oneId}`}
                // style={{ border: '1px solid red' }}
                onMouseOver={(e) => {
                  e.stopPropagation();
                  handleChannelHover(oneId);
                }}
                onMouseOut={(e) => {
                  e.stopPropagation();
                  handleChannelHover();
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleChannelClickInner(oneId)
                }}
              >
                <TooltipH
                  mouseEnterDelay={500}
                  title={<>{oneId} <span className="x_edu_channel_of _tnowrap">{$ui('channel_of')}</span> <b>{$channel(oneId, 'title')}</b><br />{$channel(oneId, 'description')}</>}
                  className='mobile_v_no'
                >
                  {/* <div className="x_body_hov x_body_hov--channel" /> */}
                </TooltipH>
              </div>
            )}


            {Object.keys(centersI).map((center, ind) => {

              return <div
                key={`hover_${center.toLowerCase()}`}
                className={`x_body_hover x_body_hover--${center.toLowerCase()}`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleCenterClickInner(center)
                }}
                onMouseOver={() => handleCenterHover(center)}
                onMouseOut={() => handleCenterHover()}
              >


                {hints && <BodyHint center={center} index={ind} design={design} />}

                {/* <TooltipH
                  {...tooltipTheme}
                  mouseEnterDelay={0}
                  title={<><b>{$center(one, 'title')}</b>: {$center(center, 'description')}</>}>
                  <div className="x_body_hov" />
                </TooltipH> */}
                <div className="x_body_hov" />
              </div>
            })}
          </>}




        </div>

        {planets &&
          <Planets
            label='prs'
            design={design}
            advanced={advanced}
          highlightedGate={highlightedGateInner}
          setHighlightedGate={setHighlightedGateInner}
          fontSize={width < 501 ? '0.8em' : '1em'}
          />
        }





        {/* <div className='x_bodygraph_svg'
          style={{
            height: svgWidth
          }}
        >
        <Svg
          // width={parentContainer ? svgWidth : width ? width : 100}
          // height={parentContainer ? svgHeight : height ? height : 100}
          width={svgWidth}
          height={svgHeight}
        >
          {svg}
        </Svg>
        </div> */}


        {/* <div
            style={{
              // color: color,
              width: `${width}px`,
              height: `${height}px`,
              // color: color,
              transition: "all 200ms ease-in-out",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "50% 50%",
              backgroundSize: "contain",
              backgroundImage: "url(data:image/svg+xml;base64," + context.state.svgs[design['id']] + ")",
            }} /> */}



        {/* {renderSvg()} */}



        {/* <img src="https://assets.website-files.com/643f3bd2d71a69bb42b11dfb/643f3dfa49c20525e8af293b_bodygraph.svg" loading="lazy" alt /> */}



        {/* </div> */}


      </div>
    </>
  )
})





