import React, { useEffect, useState, useContext } from 'react';
import { ListAvatars } from './ListAvatars';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { LangContext } from '../../contexts/langContext';
import { ListAvatarsLayout } from './ListAvatarsLayout';

export function Celebs(props) {
  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ui = context.$ui;


  return (
    <>
      <ListAvatarsLayout
        icon='star'
        title={$ui('people_celebs')}
        listId='celbs'
        quantity={context.celebsData.length}
        className={context.state.peoplePanel ? 'v' : ''}
      >
        <ListAvatars
          listId='celebs'
          array={context.celebsData}
          actions={['share']}
        />
      </ListAvatarsLayout>
    </>
  );
}
