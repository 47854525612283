import React, { Fragment, useEffect, useState, useContext, useRef, memo } from 'react';
import { useNavigate, useSearchParams, useParams, useLocation } from 'react-router-dom';
import {
  _getGroup,
  _getGroupMembers,
  _getGroupAdmins,
  _seeGroupInvitations,
  _getmyRequests,
  _getMyRequests,
  _requestToJoin
} from './utilsGroup';
import { GROUP_TOKEN_LENGTH } from './utilsDesign';
import { SocialContext } from '../contexts/socialContext';
import { StateContext } from '../contexts/stateContext';

export function WithGroup({ id, children }) {
  const context = { ...useContext(SocialContext), ...useContext(StateContext) };
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [group, setGroup] = useState();
  const [invited, setInvited] = useState()
  const [admins, setAdmins] = useState()
  const [members, setMembers] = useState()
  const [myRequests, setMyRequests] = useState()

  const updateGroup = (newData) => {
    context.x.updateGroup({ ...newData }, (data) => {
      setGroup(data)
    })
  }


  const { groupId } = useParams();

  if (!id) {
    id = groupId
  };

  const fetchGroup = async () => {
    try {
      const data = await _getGroup(id);
      setGroup(data);
    } catch (error) {
      console.log('error = ', error);
    }
  };




  useEffect(() => {

    if (id && id.length === GROUP_TOKEN_LENGTH)
      fetchGroup();
  }, [id]);


  const fetchMembers = async () => {
    try {
      const data = await _getGroupMembers(id);
      setMembers(data);
    } catch (error) {
      console.log('error = ', error);
    }
  };
  useEffect(() => {
    fetchMembers();
  }, [id]);

  const fetchMyRequests = async () => {
    try {
      const data = await _getMyRequests(id);
      setMyRequests(data);
    } catch (error) {
      console.log('error = ', error);
    }
  };
  useEffect(() => {
    fetchMyRequests();
  }, [id]);

  const fetchAdmins = async () => {
    try {
      const data = await _getGroupAdmins(id);
      setAdmins(data);
    } catch (error) {
      console.log('error = ', error);
    }
  };
  useEffect(() => {
    fetchAdmins();
  }, [id]);

  useEffect(() => {
    if (admins && admins.filter(u => u.user.token === context.me.user.token).length) {
      const callback = (data) => {
        console.log('data = ', data);
        setInvited(data)
      }
      _seeGroupInvitations(id, callback)
    }
  }, [admins])

  const requestToJoin = () => {
    _requestToJoin(group.token, () => {
      fetchMyRequests()
    })
  }



  const parameters = {
    group,
    members,
    admins,
    invited,
    myRequests,
    updateGroup,
    requestToJoin,
    fetchMembers,
    fetchMyRequests
  };
  // console.log('parameters = ', parameters);

  return (
    <>
      {
        parameters.group
          && parameters.members
          && parameters.admins
          ? React.Children.map(children, child =>
            React.cloneElement(child, parameters)
          )
          : null}
    </>
  );
}
