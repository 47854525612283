import React, { Fragment, useEffect, useState, useContext, useRef } from 'react';
import { Link, useNavigate, useSearchParams, useParams, useLocation } from 'react-router-dom';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { LangContext } from '../../contexts/langContext';
import { MessageContext } from '../../contexts/messageContext';
import { _getUser, USER_TOKEN_LENGTH, CHANNEL_TOKEN_LENGTH } from '../utilsDesign';
import { AvatarName } from '../UiKit/AvatarName';

import { ChatMessage } from './ChatMessage';
import { Preloader, Preloader0 } from '../UiKit/kit';
import { ListAvatarsLayout } from '../Lists/ListAvatarsLayout';
import { ListAvatars } from '../Lists/ListAvatars';
import { ChatSend } from './ChatSend';
import { ChatMessageTime, getMessageDate } from './ChatMessageRead';
import { ChatHead } from './ChatHead';

export const Chat = ({
  handleState = () => void (0),
  showChats,
  setShowChats,
  showInfo,
  setShowInfo
}) => {

  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext), ...useContext(MessageContext) }
  const messageContext = { ...useContext(MessageContext) }
  const $ui = context.$ui;
  const myId = context.me.user.token;



  // const { chatId } = useParams();
  const chatId = messageContext.currentChat;
  console.log('chatId = ', chatId);

  const [userData, setUserData] = useState()

  const navigate = useNavigate();

  const [messages, setMessages] = useState()

  const [members, setMembers] = useState([])
  const [to, setTo] = useState()
  const [chatData, setChatData] = useState()
  const [loaded, setLoaded] = useState(false)



  const getMembers = (chatData = {}) => {
    return chatData.members ? chatData.members.filter(one => one !== myId) : []
  }


  const startMessage = (message) => {
    const newM = {
      "channel": chatId,
      "from": myId,
      "text": message,
      "temp": true,
      "read": true,
      "timestamp": Math.round(new Date().getTime() / 1000),
      "_id": new Date().getTime()
    }
    setMessages([newM])
    const afterChatStartedCallback = (channelId) => {
      handleState(channelId)
      // navigate(`/chats/${channelId}`)
    }
    messageContext.startChat(
      chatId,
      message,
      afterChatStartedCallback
    )
  }

  const sendMessage = (message) => {
    const newM = {
      "channel": chatId,
      "from": myId,
      "text": message,
      "temp": true,
      // "read": false,
      "timestamp": Math.round(new Date().getTime() / 1000),
      "_id": new Date().getTime()
    }
    //TODO: messages delta
    const lastMessageId = (messages && messages.length) ? messages[messages.length - 1]._id : '';
    const newMessages = JSON.parse(JSON.stringify(messages))
    // setMessages([newM, ...newMessages])
    setMessages([...newMessages, newM])
    messageContext.sendMessage(
      to,
      message,
      // lastMessageId
    )
  }

  useEffect(() => {
    //GET TO
    if (chatId
      && chatId.length === CHANNEL_TOKEN_LENGTH
      && messageContext.chatsList) {
      messageContext.getChannel(chatId, (channelData) => {
        setTo(getMembers(channelData)[0])
        console.log('getMembers(channelData)[0] = ', getMembers(channelData)[0]);
      })
    }
    if (chatId && chatId.length === USER_TOKEN_LENGTH
    ) {
      setTo(chatId)
    }
  }, [chatId, messageContext.chatsList])


  const checkIfNew = (messages = [], cached = []) => {
    if (!messages.length) return true;
    const mIds = messages.map(one => one._id)
    const cIds = cached.map(one => one._id)
    const newDelta = cIds.filter(one => !mIds.includes(one)).length;
    const mRead = messages.filter(one => one.read).length
    const cRead = cached.filter(one => one.read).length

    const newInsteadOfTemp = mIds.length === cIds.length && messages.filter(one => one.temp).length

    return newInsteadOfTemp || (newDelta || mRead !== cRead) ? true : false
  }





  useEffect(() => {
    //GET MESSAGES
    if (chatId && chatId.length === CHANNEL_TOKEN_LENGTH) {
      // if (messageContext.currentChat !== chatId) {
      // messageContext.setCurrentChat(chatId)
      // }
      if (
        messageContext.cachedChats[chatId]
        && checkIfNew(messages, messageContext.cachedChats[chatId])
      ) {
        setMessages(messageContext.getChatMessages(chatId))
        if (chatId && chatId.length === CHANNEL_TOKEN_LENGTH) {
          const markRead = () => {
            setTimeout(() => {
              messageContext.markRead(chatId)
              setMessages(prev => prev.map(one => ({
                ...one,
                read: one.from !== myId ? true : one.read
              })))
            }, 200);
          }
          messageContext.getUnreadFor(chatId, (q) => {
            // if (q) {
              markRead()
            // }
          })
        }
      }
      if (!messageContext.cachedChats[chatId]) {
        setMessages([])
      }
    } else if (chatId && chatId.length === USER_TOKEN_LENGTH) {
      setTo(chatId)
      // messageContext.setCurrentChat(chatId)
      if (messages && !messages[messages.length - 1].temp) setMessages()
    }
    if (!chatId) {
      // handleState('choose')
      // navigate('/chats/choose')
    }

  }, [chatId, messageContext.cachedChats, messageContext.unread])




  useEffect(() => {
    console.log('to = ', to);
  }, [to])


  const scrollableDivRef = useRef(null);
  const scrollToBottom = () => {
    const div = scrollableDivRef.current;
    if (div) {
      div.scrollTop = div.scrollHeight;
    }
  };

  useEffect(() => {
    if (messages) {
      scrollToBottom()
    }
  }, [messages])




  return (<>
    <div className="m_b m_b--chats m_b--chats--conv">


      {Boolean(chatId) && chatId === 'choose' && <div className="m_b_c m_b_c--chats m_b--chats--conv">

        <ListAvatarsLayout
          icon='3p'
          className='chats'
          title='Choose'
          listId='chats'
        >

          <ListAvatars
            listId='chats'
            // quantity={count}
            array={context.me.followings}
            // fullList={true}
            customHandler={(userAvatar) => {
              const chat = messageContext.findChannelByMember(userAvatar.user.token);
              if (chat) {
                handleState(chat)
                // navigate(`/chats/${chat}`)
              } else {
                handleState(userAvatar.user.token)
                // navigate(`/chats/${userAvatar.user.token}`)
              }

            }}
          />
        </ListAvatarsLayout>

      </div>}

      {
        Boolean(chatId)
        && chatId.length === USER_TOKEN_LENGTH
        && chatId !== 'choose' &&

        <div className="m_b_c m_b_c--chats">


            {Boolean(to) && <ChatHead
              handleState={handleState}
              userData={messageContext.getMemberData(to)}
              handleShowChats={() => setShowChats(true)}
              showInfo={showInfo}
              setShowInfo={setShowInfo}
            />}



          <div className="x_messenger0">
            <div className="x_mess_conv">
              <div className="x_mess_conv_chat" >

                  {!messages && <ChatEmpty userData={messageContext.getMemberData(to)} />}

                  {messages && Boolean(messages.length) && <div className="x_chat_date0" key={`startdate`}>
                    {getMessageDate(messages[0], 'date')}
                  </div>}

                {messages && messages.map(one => {
                  return (<ChatMessage
                    key={`message_${one.timestamp}_${one._id}`}
                    my={one.from === myId}
                    {...one}
                    // read={true}
                  />)
                })}
                </div>

            </div>
          </div>
            <ChatSend
              handler={!messages ? startMessage : sendMessage}
            />
        </div>

      }


      {Boolean(chatId)
        && chatId.length === CHANNEL_TOKEN_LENGTH
        && chatId !== 'choose'
        && <div className="m_b_c m_b_c--chats">


          {messageContext.getMemberData(to) && <ChatHead
            handleState={handleState}
            handleShowChats={() => setShowChats(true)}
            userData={messageContext.getMemberData(to)}
            right={<>
              {messageContext.updating && (<Preloader height={32} />)}
            </>}
            showInfo={showInfo}
            setShowInfo={setShowInfo}
          />}


          <div className="x_messenger0">
            <div className="x_mess_conv">
              <div className="x_mess_conv_chat" ref={scrollableDivRef}>


                {!messages && <Preloader0 style={{ height: '75%' }}>
                  <Preloader width={64} height={64} />
                </Preloader0>}



                {messages && Boolean(messages.length) && <div className="x_chat_date0" key={`startdate`}>
                  {getMessageDate(messages[0], 'date')}
                </div>}

                {messages && messages.map((one, ind, arr) => {


                  const getClass = (one, ind, arr) => {

                  }

                  if (ind > 0 && getMessageDate(one) !== getMessageDate(arr[ind - 1])) {
                    return (<div
                      key={`message_${one.timestamp}_${one._id}`}
                      className={`x_chat00
                        ${arr[ind - 1] && arr[ind - 1].from !== one.from ? 'x_chat00--first' : ''}
                        ${arr[ind - 1] && arr[ind - 1].from === one.from ? 'x_chat00--same' : ''}
                        ${arr[ind + 1] && arr[ind + 1].from !== one.from ? 'x_chat00--last' : ''}
                        ${one.from !== myId ? 'x_chat00--them' : ''}
                        ${!arr[ind + 1] ? 'x_chat00--last' : ''}
                        `}
                      style={{ marginTop: arr[ind - 1] && arr[ind - 1].from === one.from ? '-0.6em' : '1em' }}
                    >
                      <div className="x_chat_date0">
                        {getMessageDate(one, 'date')}
                      </div>
                      <ChatMessage
                        // noAvatar={arr[ind - 1] && arr[ind - 1].from === one.from}
                        userData={messageContext.getMemberData(to)}
                        my={one.from === myId}
                        {...one}
                        // read={true}
                      />
                    </div>)
                  } else {
                    return (
                      <div
                        key={`message_${one.timestamp}_${one._id}`}
                        className={`x_chat00
                          ${arr[ind - 1] && arr[ind - 1].from !== one.from ? 'x_chat00--first' : ''}
                          ${arr[ind - 1] && arr[ind - 1].from === one.from ? 'x_chat00--same' : ''}
                          ${arr[ind + 1] && arr[ind + 1].from !== one.from ? 'x_chat00--last' : ''}
                          ${!arr[ind + 1] ? 'x_chat00--last' : ''}
                          ${one.from !== myId ? 'x_chat00--them' : ''}`}
                      >
                        <ChatMessage
                          // noAvatar={arr[ind - 1] && arr[ind - 1].from === one.from}
                          userData={messageContext.getMemberData(to)}
                          my={one.from === myId}
                          {...one}
                          // read={true}
                        />
                      </div>)
                  }
                })}

              </div>







            </div>
          </div>

          <ChatSend
            handler={!messages ? startMessage : sendMessage}
          />
        </div>

      }






    </div>



  </>
  )
}



const ChatEmpty = ({ userData }) => {
  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext), ...useContext(MessageContext) }
  const $ui = context.$ui;
  return (
    <div className="x_mess_conv_empty0">
      <div className="x_mess_conv_empty_message">
        <div className="x_mess_conv_empty_emoji">
          <div>👋</div>
        </div>
        {$ui('chat_startyour')}<br />

        {userData && <div style={{ position: 'relative', height: '1.6em' }}><AvatarName avatar={userData.avatar} /></div>}

        {!userData && <Preloader0 style={{ position: 'relative', height: '1.6em' }}>
          <Preloader height={80} width={80} />
        </Preloader0>}

      </div>
    </div>
  )
}

