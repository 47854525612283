import React, { Fragment, useEffect, useState, useContext, useRef } from 'react';
import { LangContext } from '../contexts/langContext';
import { Link, useNavigate, useSearchParams, useParams, useLocation } from 'react-router-dom';
import { replacements } from './Chats/sanitize';
import { MessageContext } from '../contexts/messageContext';
import { SocialContext } from '../contexts/socialContext';
import { StateContext } from '../contexts/stateContext';


export const Faq = () => {
  const context = { ...useContext(StateContext), ...useContext(SocialContext), ...useContext(LangContext) };
  const messageContext = { ...useContext(MessageContext) }
  const $ui = context.$ui;

  const [success, setSuccess] = useState(false)
  const [message, setMessage] = useState('');

  const send = useRef(null);


  const chatId = process.env.REACT_APP_SUPPORT_CHAT;
  const myId = context.me.user.token;

  function sanitize(input) {
    const replaceSpaces = (text) => text.replace(/\s+/g, ' ');
    // input = replaceSpaces(input)
    const tagRegex = /<[^>]*>/g;

    return input.replace(tagRegex, (match) => {
      return replacements[match] || ''; // Replace with the mapped entity or empty string
    });
  }



  const handler = (message) => {
    const newM = {
      "channel": chatId,
      "from": myId,
      "text": message,
      "temp": true,
      // "read": false,
      "timestamp": Math.round(new Date().getTime() / 1000),
      "_id": new Date().getTime()
    }
    //TODO: messages delta
    // const lastMessageId = (messages && messages.length) ? messages[messages.length - 1]._id : '';
    // const newMessages = JSON.parse(JSON.stringify(messages))
    // setMessages([newM, ...newMessages])
    // setMessages([...newMessages, newM])
    messageContext.sendMessage(
      chatId,
      message,
      // lastMessageId
    )
  }


  const sendMessage = () => {
    handler(sanitize(message));
    setMessage('');
    setSuccess(true)
    setTimeout(() => {
      setSuccess(false);
    }, 1000);
    setTimeout(() => {
      context.setState.setFaqShown(false)
    }, 1050);
  };
  const setMess = (newMess) => {
    setMessage(newMess);
  }

  useEffect(() => {
    if (context.state.faqShown && send.current) {
      send.current.focus();
    }
  }, [context.state.faqShown]);



  return (<>
    <div className={context.state.faqShown ? "faq_how_cont active" : "faq_how_cont inactive"}>
      <div className="faq_hint_inner">
        <div className="m_b_h m_b_h--faq">

          <div className="m_b_h_icon">
            <div className="t_icon t_icon--20">contact_support</div>
          </div>

          <div className="m_b_h_title">
            {$ui('faq_title')}
          </div>

          <div className="x_pop_right">
            <div className="x_action"
              onClick={() => context.setState.setFaqShown()}
            >
              <div className="t_icon t_icon--20">
                <div>close</div>
              </div>
            </div>
          </div>

        </div>
        <div className="faq_pop_line">
          <div className="faq_feedback0">
            <div className="w-form"
              style={{ opacity: success ? 0 : 1 }}
            >
              <form id="email-form" name="email-form" data-name="Email Form" method="get" data-wf-page-id="66971e622db818d2702ee14d" data-wf-element-id="240b324c-b009-fb82-4c31-d167e538b7d6" aria-label="Email Form">

                <textarea
                  ref={send}
                  maxLength={5000}
                  id="faq_feedback"
                  name="faq_feedback"
                  data-name="faq_feedback"
                  className="faq_textarea w-input"
                  value={message}
                  onChange={(e) => {
                    setMess(e.target.value)
                  }}
                />


                <div className="faq_feedback_sendline">
                  <div className={message ? "x_button" : "x_button locked x_button--inactive"}
                    onClick={handler && message.trim() ? sendMessage : () => void (0)}
                  >
                    {$ui('faq_send')}
                  </div>
                  <div className="x_button x_button--flat w-button"
                    onClick={() => {
                      messageContext.setCurrentChat(chatId)
                      context.setState.setFaqShown(false)
                    }}
                  >
                    {$ui('faq_goto')}
                  </div>

                </div>
              </form>
            </div>

            <div className={success ? "faq_success inactive shown" : "faq_success inactive"}>
              <div className="x_mess_conv_empty_emoji">
                <div>🙏</div>
              </div>
              {$ui('faq_success')}
            </div>

          </div>
        </div>
      </div >
    </div >

    <div className={context.state.faqShown ? "faq_how_trigger " : "faq_how_trigger shown"}
      onClick={() => context.setState.setFaqShown(true)}
    ><div>!</div>
      {/* <div className="faq_how_trigger_indicator"><div className="faq_indicator faq_indicator--new" />
    </div> */}
    </div>


  </>
  )
}
