import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams, Navigate } from 'react-router-dom';
import { Background } from '../UiKit/Background';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { LangContext } from '../../contexts/langContext';
import { MobMenu } from '../UiKit/MobMenu';
import { fetchUsers } from '../utilsSocial';
import { getPentaDesign } from '../utilsPenta';
import { PENTA_TOKEN_LENGTH, USER_TOKEN_LENGTH, AVATAR_TOKEN_LENGTH } from '../utilsDesign';
import { default_avatar } from '../../static/default_design';




export const WithPenta = ({ children, bodyContainer }) => {
  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const navigate = useNavigate();
  const [penta, setPenta] = useState();

  const { pentaId } = useParams();

  useEffect(() => {

    // if (!context.me) return;

    const callbackFail = () => {
      // navigate('/');
    };

    if (pentaId && pentaId.length !== PENTA_TOKEN_LENGTH && pentaId.split('-').length) {
      const membersIds = pentaId.split('-').filter(one => one.length === USER_TOKEN_LENGTH);
      console.log('membersIds = ', membersIds);
      const membersIdsAvatars = pentaId.split('-').filter(one => one.length === AVATAR_TOKEN_LENGTH);
      console.log('membersIdsAvatars = ', membersIdsAvatars);

      const containsAllMembers = (onePentamembers, membersIds) => {
        // Extract tokens from onePentamembers
        const onePentamemberTokens = onePentamembers.map(member => member.user ? member.user.token : null);

        // Create sets from both arrays to compare
        const membersIdsSet = new Set(membersIds);
        const onePentamemberTokensSet = new Set(onePentamemberTokens);

        // Compare the sets for equality
        if (membersIdsSet.size !== onePentamemberTokensSet.size) {
          return false;
        }

        for (let token of onePentamemberTokensSet) {
          if (!membersIdsSet.has(token)) {
            return false;
          }
        }

        return true;
      };

      const existingPentas = context.pentas.filter(onePenta => {
        return containsAllMembers(onePenta.members, membersIds);
      });




      if (existingPentas.length) {
        navigate(`/p/${existingPentas[0].token}`);
      } else {
        // NO EXISTING PENTAS
        const pentaEmpty = {
          count: 2,
          created: "2024-07-08T01:41:19+00:00",
          description: "description",
          members: [],
          name: null,
          permissions: 1,
          token: ''
        };


        const pentaAvatars = []
        membersIdsAvatars.forEach(oneAvatarId => pentaAvatars.push({
          user: null,
          avatar: {
            ...context.x.getAvatar(oneAvatarId),
            my: true
          }
        }))
        console.log('pentaAvatars = ', pentaAvatars);

        const emptyUser = {
          user: null,
          avatar: default_avatar
        }

        fetchUsers(membersIds, (members => {
          pentaEmpty.members = [
            ...pentaAvatars,
            ...Object.keys(members).map(oneId => members[oneId])
          ];
          if (pentaEmpty.members.length === 1) {
            pentaEmpty.members = [...pentaEmpty.members, emptyUser]
          }
          // if (pentaEmpty.members.length === 0) {
          //   pentaEmpty.members = [emptyUser, emptyUser]
          // }
          pentaEmpty.token = null;
          setPenta(pentaEmpty);
        }));

      }

    }



    if (pentaId && pentaId.length === PENTA_TOKEN_LENGTH) {
      context.x.getPenta(pentaId, setPenta, callbackFail);
    }

  }, [pentaId]);


  const parameters = {
    penta: penta,
    parentContainer: bodyContainer
  };

  return <>
    {context.me && <MobMenu penta={penta} />}

    {/* {!context.me && <Navigate to="/login" />} */}

    {parameters.penta ? React.cloneElement(children, parameters) : null}
    {/* {penta && <Background
      aura={false}
      style={{ zIndex: 0, position: 'fixed' }}
      design={getPentaDesign(penta)} />} */}

  </>;
};
