import React, { Fragment, useEffect, useState, useContext, useRef } from 'react';
import { Link, useNavigate, useSearchParams, useParams, useLocation } from 'react-router-dom';

export function EmailPreview(props) {
  const { children } = { ...props };
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState();
  return (
    <>
      <div style={{ backgroundColor: "#FCF0E6", width: '100%' }}>
        {/*[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:320px;" width="320" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]*/}
        <div style={{ margin: "0px auto", maxWidth: 320 }}>
          <table
            align="center"
            border={0}
            cellPadding={0}
            cellSpacing={0}
            role="presentation"
            style={{ width: "100%" }}
          >
            <tbody>
              <tr>
                <td
                  style={{
                    direction: "ltr",
                    fontSize: 0,
                    padding: "24px 0 12px 0",
                    textAlign: "center"
                  }}
                >
                  {/*[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:320px;" ><![endif]*/}
                  <div
                    className="mj-column-per-100 mj-outlook-group-fix"
                    style={{
                      fontSize: 0,
                      textAlign: "left",
                      direction: "ltr",
                      display: "inline-block",
                      verticalAlign: "top",
                      width: "100%"
                    }}
                  >
                    <table
                      border={0}
                      cellPadding={0}
                      cellSpacing={0}
                      role="presentation"
                      style={{ verticalAlign: "top" }}
                      width="100%"
                    >
                      <tbody>
                        <tr>
                          <td
                            align="center"
                            style={{
                              fontSize: 0,
                              padding: 0,
                              wordBreak: "break-word"
                            }}
                          >
                            <table
                              border={0}
                              cellPadding={0}
                              cellSpacing={0}
                              role="presentation"
                              style={{ borderCollapse: "collapse", borderSpacing: 0 }}
                            >
                              <tbody>
                                <tr>
                                  <td style={{ width: 112 }}>
                                    <a href="https://hd.cafe" target="_blank">
                                      <img
                                        src="https://uploads-ssl.webflow.com/643f3bd2d71a69bb42b11dfb/661963446018c00683226c58_hd_email_logo.png"
                                        style={{
                                          border: 0,
                                          display: "block",
                                          outline: "none",
                                          textDecoration: "none",
                                          height: 30,
                                          width: "100%",
                                          fontSize: 13
                                        }}
                                        width={112}
                                        height={30}
                                      />
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {/*[if mso | IE]></td></tr></table><![endif]*/}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/*[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:320px;" width="320" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]*/}
        <div style={{ margin: "0px auto", maxWidth: 320 }}>
          <table
            align="center"
            border={0}
            cellPadding={0}
            cellSpacing={0}
            role="presentation"
            style={{ width: "100%" }}
          >
            <tbody>
              <tr>
                <td
                  style={{
                    direction: "ltr",
                    fontSize: 0,
                    padding: "0px 0 16px 0px",
                    textAlign: "center"
                  }}
                >
                  {/*[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:320px;" ><![endif]*/}
                  <div
                    className="mj-column-per-100 mj-outlook-group-fix"
                    style={{
                      fontSize: 0,
                      textAlign: "left",
                      direction: "ltr",
                      display: "inline-block",
                      verticalAlign: "top",
                      width: "100%"
                    }}
                  >
                    <table
                      border={0}
                      cellPadding={0}
                      cellSpacing={0}
                      role="presentation"
                      style={{ verticalAlign: "top" }}
                      width="100%"
                    >
                      <tbody>
                        <tr>
                          <td
                            align="left"
                            style={{
                              fontSize: 0,
                              padding: "10px 25px",
                              wordBreak: "break-word"
                            }}
                          >
                            <div
                              style={{
                                fontFamily: "Ubuntu, Helvetica, Arial, sans-serif",
                                fontSize: 13,
                                lineHeight: '1px',
                                textAlign: "left",
                                color: "#000000"
                              }}
                            >
                              👋{" "}
                              <span style={{ color: "rgba(0, 0, 0, 0.38)" }}>
                                You have 2 new messages in your inbox!
                              </span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {/*[if mso | IE]></td></tr></table><![endif]*/}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/*[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:320px;" width="320" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]*/}
        <div style={{ margin: "0px auto", maxWidth: 320 }}>
          <table
            align="center"
            border={0}
            cellPadding={0}
            cellSpacing={0}
            role="presentation"
            style={{ width: "100%" }}
          >
            <tbody>
              <tr>
                <td
                  style={{
                    direction: "ltr",
                    fontSize: 0,
                    padding: "0px 0px 24px 0px",
                    textAlign: "center"
                  }}
                >
                  {/*[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" width="320px" ><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:320px;" width="320" bgcolor="white" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]*/}
                  <div
                    style={{
                      background: "white",
                      backgroundColor: "white",
                      margin: "0px auto",
                      borderRadius: 8,
                      maxWidth: 320
                    }}
                  >
                    <table
                      align="center"
                      border={0}
                      cellPadding={0}
                      cellSpacing={0}
                      role="presentation"
                      style={{
                        background: "white",
                        backgroundColor: "white",
                        width: "100%",
                        borderRadius: 8
                      }}
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{
                              direction: "ltr",
                              fontSize: 0,
                              padding: "16px 0px 24px 0px",
                              textAlign: "center"
                            }}
                          >
                            {/*[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="width:320px;" ><![endif]*/}
                            <div
                              className="mj-column-per-100 mj-outlook-group-fix"
                              style={{
                                fontSize: 0,
                                lineHeight: 0,
                                textAlign: "left",
                                display: "inline-block",
                                width: "100%",
                                direction: "ltr"
                              }}
                            >
                              {/*[if mso | IE]><table border="0" cellpadding="0" cellspacing="0" role="presentation" ><tr><td style="vertical-align:top;width:57px;" ><![endif]*/}
                              <div
                                className="mj-column-per-18 mj-outlook-group-fix"
                                style={{
                                  fontSize: 0,
                                  textAlign: "left",
                                  direction: "ltr",
                                  display: "inline-block",
                                  verticalAlign: "top",
                                  width: "18%"
                                }}
                              >
                                <table
                                  border={0}
                                  cellPadding={0}
                                  cellSpacing={0}
                                  role="presentation"
                                  width="100%"
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        style={{
                                          verticalAlign: "top",
                                          padding: "2px 0 0 20px"
                                        }}
                                      >
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          role="presentation"
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                align="center"
                                                style={{
                                                  fontSize: 0,
                                                  padding: "0px 0 0 0px",
                                                  wordBreak: "break-word"
                                                }}
                                              >
                                                <table
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  role="presentation"
                                                  style={{
                                                    borderCollapse: "collapse",
                                                    borderSpacing: 0
                                                  }}
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td style={{ width: 32 }}>
                                                        <a
                                                          href="{$user_url}"
                                                          target="_blank"
                                                        >
                                                          <img
                                                            src="https://hd.cafe/profile/logo180.png"
                                                            style={{
                                                              border:
                                                                "1px solid #F1D8AF",
                                                              borderRadius: 100,
                                                              display: "block",
                                                              outline: "none",
                                                              textDecoration: "none",
                                                              height: 32,
                                                              width: "100%",
                                                              fontSize: 13
                                                            }}
                                                            width={32}
                                                            height={32}
                                                          />
                                                        </a>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              {/*[if mso | IE]></td><td style="vertical-align:top;width:198px;" ><![endif]*/}
                              <div
                                className="mj-column-per-62 mj-outlook-group-fix"
                                style={{
                                  fontSize: 0,
                                  textAlign: "left",
                                  direction: "ltr",
                                  display: "inline-block",
                                  verticalAlign: "top",
                                  width: "62%"
                                }}
                              >
                                <table
                                  border={0}
                                  cellPadding={0}
                                  cellSpacing={0}
                                  role="presentation"
                                  width="100%"
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        style={{
                                          verticalAlign: "top",
                                          padding: "2px 0px 0px 4px"
                                        }}
                                      >
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          role="presentation"
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                align="left"
                                                style={{
                                                  fontSize: 0,
                                                  padding: "2px 0px 0px 4px",
                                                  wordBreak: "break-word"
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    fontFamily:
                                                      "Ubuntu, Helvetica, Arial, sans-serif",
                                                    fontSize: 13,
                                                    lineHeight: '16px',
                                                    textAlign: "left",
                                                    color: "#000000"
                                                  }}
                                                >
                                                  <strong>Misha from HD.Cafe</strong>
                                                  <br />
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              {/*[if mso | IE]></td><![endif]*/}
                              {/* <mj-column width="13%" mj-class="message_bodygraph0">
      <mj-image href="{$user_url}" mj-class="message_bodygraph" src="https://uploads-ssl.webflow.com/643f3bd2d71a69bb42b11dfb/661ee96ab80f437547157552_body_placeholder.png" />
    </mj-column> */}
                              {/*[if mso | IE]></tr></table><![endif]*/}
                            </div>
                            {/*[if mso | IE]></td><td class="" style="vertical-align:top;width:320px;" ><![endif]*/}
                            <div
                              className="mj-column-per-100 mj-outlook-group-fix"
                              style={{
                                fontSize: 0,
                                textAlign: "left",
                                direction: "ltr",
                                display: "inline-block",
                                verticalAlign: "top",
                                width: "100%"
                              }}
                            >
                              <table
                                border={0}
                                cellPadding={0}
                                cellSpacing={0}
                                role="presentation"
                                width="100%"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        verticalAlign: "top",
                                        padding: "16px 0 8px 0px"
                                      }}
                                    >
                                      <table
                                        border={0}
                                        cellPadding={0}
                                        cellSpacing={0}
                                        role="presentation"
                                        width="100%"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="center"
                                              style={{
                                                fontSize: 0,
                                                padding: "0px 0px 0px 0px",
                                                wordBreak: "break-word"
                                              }}
                                            >
                                              <p
                                                style={{
                                                  borderTop:
                                                    "solid 1px rgb(224, 224, 224)",
                                                  fontSize: 1,
                                                  margin: "0px auto",
                                                  width: "100%"
                                                }}
                                              ></p>
                                              {/*[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" style="border-top:solid 1px rgb(224, 224, 224);font-size:1px;margin:0px auto;width:320px;" role="presentation" width="320px" ><tr><td style="height:0;line-height:0;"> &nbsp;
</td></tr></table><![endif]*/}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            {/*[if mso | IE]></td><td class="" style="vertical-align:top;width:320px;" ><![endif]*/}
                            <div
                              className="mj-column-per-100 mj-outlook-group-fix"
                              style={{
                                fontSize: 0,
                                textAlign: "left",
                                direction: "ltr",
                                display: "inline-block",
                                verticalAlign: "top",
                                width: "100%"
                              }}
                            >
                              <table
                                border={0}
                                cellPadding={0}
                                cellSpacing={0}
                                role="presentation"
                                width="100%"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        verticalAlign: "top",
                                        padding: "16px 0px 0px 0px"
                                      }}
                                    >
                                      <table
                                        border={0}
                                        cellPadding={0}
                                        cellSpacing={0}
                                        role="presentation"
                                        width="100%"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="left"
                                              style={{
                                                fontSize: 0,
                                                padding: "0px 24px 12px 24px",
                                                wordBreak: "break-word"
                                              }}
                                            >
                                              <div
                                                style={{
                                                  fontFamily:
                                                    "system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif",
                                                  fontSize: 12,
                                                  lineHeight: 1,
                                                  textAlign: "left",
                                                  color: "rgb(179, 179, 179)"
                                                }}
                                              >
                                                12 jul 2024
                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              align="left"
                                              style={{
                                                fontSize: 0,
                                                padding: "0px 24px 0px 24px",
                                                wordBreak: "break-word"
                                              }}
                                            >
                                              <div
                                                style={{
                                                  fontFamily:
                                                    "system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif",
                                                  fontSize: 18,
                                                  fontStyle: "italic",
                                                  fontWeight: 500,
                                                  lineHeight: '24px',
                                                  textAlign: "left",
                                                  color: "black"
                                                }}
                                              >

                                                {children}


                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              align="center"
                                              vertical-align="middle"
                                              style={{
                                                fontSize: 0,
                                                padding: "16px 0 4px 0",
                                                wordBreak: "break-word"
                                              }}
                                            >
                                              <table
                                                border={0}
                                                cellPadding={0}
                                                cellSpacing={0}
                                                role="presentation"
                                                style={{
                                                  borderCollapse: "separate",
                                                  lineHeight: "100%"
                                                }}
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      align="center"
                                                      bgcolor="#002bff"
                                                      role="presentation"
                                                      style={{
                                                        border: "1px solid #002bff",
                                                        borderRadius: 100,
                                                        cursor: "auto",
                                                        msoPaddingAlt: "10px 25px",
                                                        background: "#002bff"
                                                      }}
                                                      valign="middle"
                                                    >
                                                      <a
                                                        href="{$user_url}"
                                                        style={{
                                                          display: "inline-block",
                                                          background: "#002bff",
                                                          color: "#ffffff",
                                                          fontFamily:
                                                            "system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif",
                                                          fontSize: 13,
                                                          fontWeight: "bolder",
                                                          lineHeight: '12px',
                                                          margin: 0,
                                                          textDecoration: "none",
                                                          textTransform: "none",
                                                          padding: "10px 25px",
                                                          msoPaddingAlt: 0,
                                                          borderRadius: 100
                                                        }}
                                                        target="_blank"
                                                      >
                                                        Reply
                                                      </a>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            {/*[if mso | IE]></td></tr></table><![endif]*/}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {/*[if mso | IE]></td></tr></table></td></tr></table><![endif]*/}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/*[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:320px;" width="320" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]*/}
        <div style={{ margin: "0px auto", maxWidth: 320 }}>
          <table
            align="center"
            border={0}
            cellPadding={0}
            cellSpacing={0}
            role="presentation"
            style={{ width: "100%" }}
          >
            <tbody>
              <tr>
                <td
                  style={{
                    direction: "ltr",
                    fontSize: 0,
                    padding: "24px 0 0 0",
                    paddingBottom: 40,
                    textAlign: "center"
                  }}
                >
                  {/*[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="width:320px;" ><![endif]*/}
                  <div
                    className="mj-column-per-100 mj-outlook-group-fix"
                    style={{
                      fontSize: 0,
                      lineHeight: 0,
                      textAlign: "left",
                      display: "inline-block",
                      width: "100%",
                      direction: "ltr"
                    }}
                  >
                    {/*[if mso | IE]><table border="0" cellpadding="0" cellspacing="0" role="presentation" ><tr><td style="vertical-align:top;width:320px;" ><![endif]*/}
                    <div
                      className="mj-column-per-100 mj-outlook-group-fix"
                      style={{
                        fontSize: 0,
                        textAlign: "left",
                        direction: "ltr",
                        display: "inline-block",
                        verticalAlign: "top",
                        width: "100%"
                      }}
                    >
                      <table
                        border={0}
                        cellPadding={0}
                        cellSpacing={0}
                        role="presentation"
                        style={{ verticalAlign: "top" }}
                        width="100%"
                      >
                        <tbody>
                          <tr>
                            <td
                              align="center"
                              style={{
                                fontSize: 0,
                                padding: 0,
                                wordBreak: "break-word"
                              }}
                            >
                              <div
                                style={{
                                  fontFamily:
                                    "system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif",
                                  fontSize: 12,
                                  lineHeight: '1px',
                                  textAlign: "center",
                                  color: "black"
                                }}
                              >
                                <a
                                  href="https://hd.cafe"
                                  style={{
                                    color: "black",
                                    textDecoration: "none",
                                    borderBottom: "1px solid #ccc"
                                  }}
                                  target="_blank"
                                >
                                  hd.cafe
                                </a>
                                &nbsp;&nbsp;&nbsp;&nbsp;ig:{" "}
                                <a
                                  href="https://hd.cafe"
                                  style={{
                                    color: "black",
                                    textDecoration: "none",
                                    borderBottom: "1px solid #ccc"
                                  }}
                                  target="_blank"
                                >
                                  @hd__cafe
                                </a>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <a
                                  href="https://t.me/hd_cafe"
                                  style={{
                                    color: "black",
                                    textDecoration: "none",
                                    borderBottom: "1px solid #ccc"
                                  }}
                                  target="_blank"
                                >
                                  t.me/hd_cafe
                                </a>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    {/*[if mso | IE]></td></tr></table><![endif]*/}
                  </div>
                  {/*[if mso | IE]></td></tr></table><![endif]*/}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/*[if mso | IE]></td></tr></table><![endif]*/}
      </div>

    </>
  )
}