import React, { Fragment, useEffect, useState, useContext, useRef } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { _getCity, _updateAvatar, drawProfile, drawType, localizeDate } from '../utilsDesign';
import { Avatar } from './Avatar';

import { CityDateTime } from './CityDateTime';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { convertGMTtoDateTime } from '../utilsDesign';
import { DropDown } from './DropDown';
import { PreloaderButton, Preloader } from './kit';
import { Icon } from './Icon';
import { LangContext } from '../../contexts/langContext';
import { MobMenu } from './MobMenu';
import { AvatarName } from './AvatarName';
import { MessageContext } from '../../contexts/messageContext';
import { WithVersion } from './VersionToggler';
import { Date } from './StringDateTime';
import { TooltipH } from './TooltipH';
import { EditName } from './EditName';
import { Button } from './Button';




export function AvatarPanel(props) {
  const {
    publ,
    user,
    avatar = {},
    followings = [],
    followers = [],
    refetch = () => void (0),
    children
  } = { ...props };
  const navigate = useNavigate();
  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) }
  const $ui = context.$ui;

  const [city, setCity] = useState()

  useEffect(() => {
    setCity()
  }, [avatar.city_id])

  const [avatarInner, setAvatarInner] = useState(avatar)

  useEffect(() => {
    if (avatar.token !== avatarInner.token) {
      setAvatarInner(avatar)
    }
  }, [avatar])

  const isCeleb = user && Object.keys(context.celebs).filter(celebId => celebId === user.token).length;


  const getGMTwithSign = (offset) => offset ? `GMT${offset >= 0 ? `+${offset}` : offset}` : '';


  return (
    <>
      <div className="x_0_user">


        <div className="x_0_user_block">

          <div className="x_calc_result_name0">
            <div className="x_calc_result_name"
              // onClick={() => avatar.my && navigate('info')}
            >

              <Avatar
                avatar={avatar}
                type='calc'
              />

              <div className="x_calc_sticky">



                {!user && avatar.my &&
                  <TooltipH
                    // color={'#f4f4f4'}
                    // tip={'#f4f4f4'}
                    update={avatarInner.name}
                    fresh
                    placement='bottom'
                    title={<EditName
                      name={avatarInner.name}
                      handleUpdate={(newName) => {
                        context.x.updateAvatar({
                          'token': avatar.token,
                          'name': newName
                        }, (newAva) => setAvatarInner(newAva))
                      }}
                    />}>
                    <div className="x_calc_result_title ellipsis">
                      <AvatarName avatar={avatarInner} />
                    </div>
                  </TooltipH>
                }

                {user && avatar.my &&
                  <div className="x_calc_result_title ellipsis"
                    onClick={() => navigate('info')}
                  >
                    <AvatarName avatar={avatarInner} />
                  </div>
                }

                {!avatar.my && <div className="x_calc_result_title x_calc_result_title--inactive ellipsis"
                //TODO: когда публичный
                // onClick={() => navigate('info')}
                >
                  <AvatarName avatar={avatarInner} />
                </div>}



              </div>

            </div>


          </div>



          {context.me && <UserSocialLine
            user={user}
            avatar={avatar}
            refetch={refetch}
            followers={followers}
            followings={followings}
          />}

        </div>

        <div className="x0_search">
          <CityDateTime
            // publ={false}
            avatar={avatar}
            showForm={context.state.calcShown}
          // // setShowForm={(val) => context.setState.setCalcShown(val)}
          // avatarDate={avatar.dob && city ? convertGMTtoDateTime(avatar.dob)['date'] : null}
          // avatarTime={avatar.dob && city ? convertGMTtoDateTime(avatar.dob)['time'] : null}
          // avatarOffset={city && city.offset ? city.offset : null}
          // avatarCityId={avatar.city_id ? avatar.city_id : null}
          // dob={avatar.dob}
          // city={city}
          />
        </div>


        {/* {(user || avatar.my) && <AvatarDob
          publ={publ}
          avatar={avatar}
        />}

        {(!user && !avatar.my && publ) && <PublicDob
          avatar={avatar}
        />} */}








      </div>







    </>
  )
}


export function AvatarDob(props) {
  const {
    children,
    publ,
    user,
    avatar = {},
    penta
  } = { ...props };
  const navigate = useNavigate();
  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ui = context.$ui;

  let urlParams = new URLSearchParams(window.location.search);
  const cityName = urlParams.get('city') ? urlParams.get('city') : '';

  const [city, setCity] = useState()

  useEffect(() => {
    if ((city && city.id !== avatar.city_id) || !avatar.city_id) {
      setCity()
    }
  }, [avatar.city_id])



  useEffect(() => {
    if ((avatar.city_id && !city)) {
      if (avatar.aggr && avatar.aggr.city) {
        setCity(avatar.aggr.city)
      } else {
        //TODO: город возвращается даже если поменялся урл и в итоге проставляет не тот город
        // _getCity(avatar.city_id, setCity)
      }
    }
  })

  useEffect(() => {
    // console.log('city = ', city);
  }, [city])


  // const [followersQ, setFollowersQ] = useState(followers.length)

  // useEffect(() => {
  //   if (followers.length !== followersQ) {
  //     setFollowersQ(followers.length)
  //   }
  // }, [followers])


  const getGMTwithSign = (offset) => offset ? `GMT${offset >= 0 ? `+${offset}` : offset}` : '';

  // const hideYear = true;

  const isCeleb = user && Object.keys(context.celebs).filter(celebId => celebId === user.token).length;

  let hideYear = !avatar.my || !context.me;
  hideYear = isCeleb ? false : hideYear;
  hideYear = !user ? false : hideYear;
  hideYear = !context.me && penta ? true : hideYear;
  // const hideYear = !avatar.my && user.token && context.me;
  // console.log('!avatar.my = ', !avatar.my);
  // console.log('user = ', user);

  return (
    <>





      <div
        className={avatar.my ? `${context.state.calcShown ? "x_toggles_li" : "x_toggles_li "}` : "x_toggles_li x_toggles_li--locked"}
        onClick={() => avatar.my && context.setState.setCalcShown(true)}
      // style={{ opacity: context.state.calcShown ? 0 : 1, zIndex: 11000 }}
      >

        <UTCinfo avatar={avatar} hideYear={hideYear} />


          <div className="x_calc_result_bio_1">

          {/* <div className="x_calc_result_bio_line x_calc_result_bio_line--label">
              {$ui('birth_data')}:
            </div> */}
          {Boolean(avatar.dob) && <div className="x_calc_result_bio_line  ellipsis">
            {`${localizeDate(convertGMTtoDateTime(avatar.dob, null, 'date'), context.lang, hideYear)}`}
          </div>}

          {Boolean(avatar.dob) && city && <div className="x_calc_result_bio_line">
            {` ${convertGMTtoDateTime(avatar.dob, 0)['time']}`}
          </div>}

          {!hideYear && <>
          {(city || Boolean(cityName)) && <div className="x_calc_result_bio_line ellipsis">
              {city && `${city.city}, ${city.country}`}
              {!city && cityName}
          </div>}
          {city && Boolean(avatar.city_id) && <div className="x_calc_result_bio_line x_calc_result_bio_line--label  ellipsis">
            {getGMTwithSign(avatar.design.utc)}
          </div>}
          </>}

          {hideYear && <>
            {(city || Boolean(cityName)) && <div className="x_calc_result_bio_line ellipsis">
              {city && `${$ui('timezone')}: ${city.timezone}`}
            </div>}
          </>}


            {/* <div className="x_calc_result_bio_line">{avatar.city_id && city && getGMTwithSign(city.offset)}</div> */}

        </div>

        </div>




      {/* <div className="x_calc_result0">
          <CityDateTime
            publ={publ}
            avatar={avatar}
            // showForm={context.state.calcShown}
            // setShowForm={(val) => context.setState.setCalcShown(val)}
            avatarDate={avatar.dob && city ? convertGMTtoDateTime(avatar.dob)['date'] : null}
            avatarTime={avatar.dob && city ? convertGMTtoDateTime(avatar.dob)['time'] : null}
            avatarOffset={city && city.offset ? city.offset : null}
            avatarCityId={avatar.city_id ? avatar.city_id : null}
            dob={avatar.dob}
            city={city}
        />
      </div> */}

    </>

  )
}



export const UTCinfo = ({ avatar, children, hideYear = true }) => {
  return <TooltipH
    // color={'white'} key={'white'}
    // overlayInnerStyle={{ color: 'black', }}
    mouseEnterDelay={0}
    title={<>{avatar.dob && <>
      <div className="_fv _fgap0">


        <div className="">
          <span className='x_calc_label'>Birth date:</span> <span><Date date time hideYear={hideYear}>{avatar.dob}</Date></span>
        </div>
        <div className="">
          <span className='x_calc_label'>UTC:</span> <Date date time hideYear={hideYear}>{avatar.design.meta.personality}</Date><br />
        </div>
        <div className="">
          <div className="_fv _fgap0">
            <div>
              <span className='x_calc_label'>Design:</span> <Date date time hideYear={hideYear}>{avatar.design.meta.design}</Date>
            </div>
            <div>
            </div>
          </div>
        </div>
      </div>
    </>}
    </>}
  >
    {!children && <div>
      <Icon>info</Icon>
    </div>}

    {children}
  </TooltipH>
}




export function PublicDob(props) {
  const {
    children,
    user = {},
    avatar = {},
  } = { ...props };
  const navigate = useNavigate();
  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ui = context.$ui;


  let urlParams = new URLSearchParams(window.location.search);
  const cityName = urlParams.get('city') ? urlParams.get('city') : '';
  const timezone = urlParams.get('timezone') ? urlParams.get('timezone') : 'GMT';
  const offset = urlParams.get('offset') ? urlParams.get('offset') : 0;


  const [city, setCity] = useState()

  useEffect(() => {
    if (avatar.city_id) {
      _getCity(avatar.city_id, setCity)
    } else {
      setCity()
    }
  }, [avatar])

  useEffect(() => {
    // console.log('city = ', city);
  }, [city])


  const getGMTwithSign = (offset) => offset ? `GMT${offset >= 0 ? `+${offset}` : offset}` : '';

  return (
    <>



      <div
        className={avatar.my ? `${context.state.calcShown ? "x_toggles_li" : "x_toggles_li "}` : "x_toggles_li x_toggles_li--locked"}
        onClick={() => avatar.my && context.setState.setCalcShown(true)}
      // style={{ opacity: context.state.calcShown ? 0 : 1, zIndex: 11000 }}
      >

        <UTCinfo avatar={avatar} hideYear={false} />


        {Boolean(avatar.dob) && <div className="x_calc_result_bio_1">

          {/* <div className="x_calc_result_bio_line x_calc_result_bio_line--label">
              {$ui('birth_data')}:
            </div> */}
          {avatar.dob && <div className="x_calc_result_bio_line">
            {`${localizeDate(convertGMTtoDateTime(avatar.dob, null, 'date'), context.lang, false)}`}
          </div>}
          {avatar.dob && city && <div className="x_calc_result_bio_line">
            {` ${convertGMTtoDateTime(avatar.dob, 0)['time']}`}
          </div>}
          <div className="x_calc_result_bio_line ellipsis">
            {cityName}&nbsp;&nbsp;{timezone}
          </div>

        </div>}


        </div>



    </>
  )
}



export const UserSocialLine = ({
  user,
  avatar = {},
  refetch,
  followers = [],
  followings = [],
  vertical
}) => {
  const navigate = useNavigate();
  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) }
  const messageContext = { ...useContext(MessageContext) }
  const $ui = context.$ui;
  let urlParams = new URLSearchParams(window.location.search);

  const isMe = context.me && (avatar.token === context.me.avatar.token);
  const isCeleb = Boolean(user && Object.keys(context.celebs).filter(celebId => celebId === user.token).length);

  // const [followingsLength, setFollowingsLength] = useState(user ? user.aggr.followings_count : '')
  // const [followersLength, setFollowersLength] = useState(user ? user.aggr.followers_count : '')

  useEffect(() => {

    if (user) {
      const fetchDataAsync = async (what, callback = () => void (0)) => {
        // setLoading(true);
        try {
          const data = await context.x.getUserSocial(user.token, what, 'length');
          callback(data);
        } catch (error) {
          console.error("Fetching data failed:", error);
          // setError(error);
        } finally {
          // setLoading(false);
        }
      };

      // fetchDataAsync('followings', setFollowingsLength);
      // fetchDataAsync('followers', setFollowersLength);
    }
  }, [user]);


  return <div className={vertical ? "x_0_user_social0 x_0_user_social0--h" : "x_0_user_social0"}>


    {isMe && <div className="x_0_user_followbutton">

      {(!(user && user.city_id)
        || !avatar.name
        || !avatar.image
      )
        && <div className="x_noti0" />}

      <div className="x_button x_button--ghost"
        onClick={() => context.me ? navigate('info') : void (0)}
      >
        {!(user && user.city_id) && <div className="x_noti0" />}
        {$ui('but_edit_profile')}
      </div>
    </div>}


    {/* {!isMe && <div className="x_0_user_followbutton">

      {(!(user && user.city_id)
        || !avatar.name
        || !avatar.image
      )
        && <div className="x_noti0" />}

      <div className="x_button x_button--ghost"
        onClick={() => context.me ? navigate('info') : void (0)}
      >
        {!(user && user.city_id) && <div className="x_noti0" />}
        {$ui('but_edit_profile')}
      </div>
    </div>} */}



    {!user && !avatar.my && !isCeleb && <>
      {!context.me && <div className="x_0_user_followbutton">
        <a href='/auth0/login' className="x_button x_button--ghost"
        // onClick={() => navigate('/auth0/login')}
        >
          {$ui('but_follow_unlog')}
        </a>
      </div>}
    </>}


    {/* {!user && avatar.my && <>
      <div className="x_0_user_followbutton">
        <div className="x_button x_button--ghost"
          onClick={() => navigate('info')}
        >
          <Icon>edit</Icon>  {$ui('but_edit_avatar')}
        </div>
      </div>
    </>} */}


    {!user && avatar.my && <>
        <div className="x_0_user_followbutton">
        <Button
            onClick={() => navigate('info')}
          >
            <Icon>qr_code</Icon>  {$ui('but_invite')}
        </Button>
      </div>
    </>}







    <>
      {
        user
        && context.me
        && !isMe
        && !isCeleb
        && <div className="x_button "
        onClick={() => messageContext.setCurrentChat(user.token)}
      >
        {/* {$ui('but_following')} */}
        <Icon button>mail</Icon>
        {$ui('chat_send_message')}
      </div>}

      {user && !context.me && <div className="x_button x_button--disabled"
      // onClick={() => messageContext.setCurrentChat(user.token)}
      >
        {/* {$ui('but_following')} */}
        <Icon button>mail</Icon>
        {$ui('chat_send_message')}
      </div>}


    </>




    {
      user
      && !context.x.checkIfFollow(user.token)
      && !isMe
      && <div className="x_0_user_followbutton">

        {!context.followingUser.includes(user.token) && <div className="x_button"
          onClick={() => {
            context.x.follow(user.token,
              () => refetch(user.token)
              // setFollowersQ(prev => prev + 1)
            )
          }}
        >
          <Icon button>person_add</Icon>
          {/* {$ui('but_follow')} */}
          {/* <Icon>check</Icon> */}
        </div>}

        {context.followingUser.includes(user.token) && <div className="x_button x_button--ghost">
          <PreloaderButton><Preloader size={16} /></PreloaderButton>
          {/* <div style={{ visibility: 'hidden' }}>{$ui('but_follow')}</div> */}
        </div>}

      </div>}


    {user
      && !user.isMe
      && context.me
      && <Button
      ghost
      onClick={() => navigate(`info`)}
      icon='qr_code'
      tooltip={$ui('but_public_link_short')}
      />}

    {user
      && !user.isMe
      && context.me
      && <Button
        ghost
        icon='group'
        onClick={() => navigate(`/p/${context.me.user.token}-${user.token}`)}
        tooltip={$ui('penta_composite')}
      >
        {/* {$ui('but_share_profile')} */}
      </Button>}

    {!user
      && !avatar.isMe
      && context.me
      && <Button
        ghost
        icon='group'
        onClick={() => navigate(`/p/${context.me.user.token}-${avatar.token}`)}
        tooltip={$ui('penta_composite')}
      >
        {/* {$ui('but_share_profile')} */}
      </Button>}








    <div className="_f _fgap02">

    {!vertical
      && user
      && !isCeleb
      && <>
        <div className="x_0_user_followers">


          <div className="x_0_user_followcount">


            <Button ghost icon='hub'
              tooltip={`${$ui('but_following')} / ${$ui('but_followers')}`}
              onClick={() => navigate('following')}
            >
              <strong>{user.aggr.followings_count}</strong> <span className='_o02'>/</span> <strong className='_o04'>{user.aggr.followers_count} </strong>
            </Button>
          </div>



        </div>
      </>}

    {user
      && !isMe
      && !isCeleb
      && context.x.checkIfFollow(user.token)
      && <div className="x_0_user_followbutton">


        {!context.followingUser.includes(user.token) && <>

          <DropDown
            id={avatar.token}
            options={[
              {
                icon: 'person_remove',
                title: $ui('but_unfollow'),
                handle: () => context.x.unfollow({ user },
                  () => refetch(user.token)
                  // setFollowersQ(prev => prev - 1)
                )
              }
            ]}
          >
              <Button ghost icon='expand_more'>
              {/* {$ui('but_following')} */}
                {/* <Icon button>group</Icon> */}

              </Button>
          </DropDown>
        </>}




        {context.followingUser.includes(user.token) && <>
          <div className="x_button x_button--ghost">
            <PreloaderButton><Preloader size={16} /></PreloaderButton>
            <div style={{ visibility: 'hidden' }}>{$ui('but_following')}</div>
            <div className="t_icon">
              <div>expand_more</div>
            </div>
          </div>
        </>}

      </div>}

    </div>




    {isCeleb &&
      <TooltipH title={$ui('people_celeb_tooltip')} placement='bottom'>
        <div className="x_button x_button--flat">
          <div className>
            <span style={{ transform: 'none' }}>
              <span className="t_icon  ">star</span></span>
          </div>
        </div>
      </TooltipH>}






  </div>
}