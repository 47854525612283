import React, { Fragment, useEffect, useState, useContext, useRef, memo } from 'react';
import { Link, useNavigate, useSearchParams, useParams, useLocation } from 'react-router-dom';
import { Footer } from '../Footer';
import Soon from './Soon';
import { Txt } from '../UiKit/Txt';
import { NarrativesList } from '../Start';
import { Preview } from '../UiKit/Preview';
import { narrativesComponents } from './narratives';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { LangContext } from '../../contexts/langContext';
import { default_design, default_avatar } from '../../static/default_design';
import { Background } from '../UiKit/Background';

export const DesignPages = memo(({
  user,
  avatar,
  penta,
  narrative,
  publ
}) => {

  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ = context.$;


  const navigate = useNavigate();
  const location = useLocation();

  const drawArray = penta ? penta.members : [{
    user: user,
    avatar: avatar
  }]

  const pentaFontSize = '0.8em';


  return (<>


    {narrative && drawArray.length && narrative.pages && narrative.pages
      .filter(onePage => onePage)
      .map((onePage, pageIndex, array) => {
        // const one = narrative[one.id];
        let parameters = {
          // guide: one['guide'],
          // preview: false,
          // handleNew: setShowGuide,
          // scrollToSection: scrollToSection,
          publ: publ,
          nextId: array[pageIndex + 1] ? array[pageIndex + 1].id : null,
          // key: one.id,
          id: onePage.id,
          // design: avatar.design,
          body: onePage.bodygraph,
          // children: <Preview
          //   // {...one['body']}
          //   design={avatar.design ? avatar.design : default_design}
          //   aura={false}
          // />
        };
        // if (penta && narrative[onePage.id].pentaSettings) {
        //   parameters = {
        //     ...parameters,
        //     ...onePage.pentaSettings
        //   }
        // }

        let component = narrativesComponents[onePage.id];

        if (!penta) {
          component = {
            ...component,
            props: {
              user: user,
              avatar: avatar,
              ...component.props
            }
          }
        } else {
          component = {
            ...component,
            props: {
              ...component.props,
              ...component.props.pentaSettings,
              style: { fontSize: pentaFontSize }

            }
          }
        }

      const avatars = !onePage.utility ? drawArray : [drawArray[0]];


        const handleCloseInfoPop = () => {
          document.body.classList.remove('noscroll');
          context.setState.setHighlighted()
          context.setState.setSearch([])

          const removeStudySegments = (pathname) => {
            const segments = pathname.split('/study')
            return segments.length > 1 ? segments[0] : null
          };
          const currentPath = location.pathname;
          const newPath = removeStudySegments(currentPath);
          const queryParams = location.search ? location.search : '';
          if (newPath) navigate(`${newPath}${queryParams}`);
        };


        return (
        <div
            onClick={() => handleCloseInfoPop()}
            key={`page_${onePage.id}`}
          id={onePage['id']}
          style={{
            // width: (penta && penta.members.length && !one['utility']) ? `${100 / penta.members.length}%` : '100%',
            width: '100%',
            // width: `${100 / penta.members.length}%`,
            ...component.props.style
          }}
            className={`x_edu_content0 x_edu_content0--${penta ? 'penta' : ''} x_edu_content0--${onePage['id']}  _fv`}
        >




          {Boolean($('pageTitle', onePage)) && <PageTitle
            onePage={onePage}
            penta={penta}
          />}



            <div className="x_edu_content_inner _fh  _fgap4 _fgrow _f100"
            // style={{ width: `${100 / penta.members.length}%` }}
            >


            {
                avatars
                  .map((oneU, index) => {
                    return (
                      <div
                        key={`page_${oneU.avatar.token}_${onePage.id}`}
                        style={{
                          width: '100%',
                          // width: `${100 / penta.members.length}%`,
                          // overflow: penta ? 'hidden' : 'visible'
                        }}
                      >
                        <Page
                        // key={`page_${oneU.avatar.token}_${onePage.id}`}
                        penta={penta}
                        one={onePage}
                        avatar={oneU.avatar}
                        user={oneU.user}
                        pageIndex={pageIndex}
                        avatarIndex={index}
                        parameters={parameters}
                        component={component}
                        // style={{
                        //   // overflow: 'hidden',
                        //   fontSize: penta ? '0.8em' : '1em'
                        // }}
                      />
                      </div>
                    )
                  }

                )
            }


            </div>



            {pageIndex === 0 && avatar && avatar.design.id !== 'dummy' && <NarrativesList key={`page_narratives`} />}


          </div>
      )
    })}




    <Footer />


  </>

  )
})


export const PageTitle = memo(({
  penta,
  onePage,
  children
}) => {

  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ = context.$;



  return (<>


    <div className="x_edu_content_title">
      <h1 className="x_edu_content_h">{$('pageTitle', onePage)}</h1>
      {Boolean($('pageDescription', onePage)) && <div className="x_edu_content_desc">
        <Txt>
          {$('pageDescription', onePage)}
        </Txt>
      </div>}
    </div>





  </>

  )



})


export const Page = memo(({
  component,
  penta,
  parameters,
  avatarIndex,
  one,
  pageIndex,
  avatar = default_avatar,
  style = {}
}) => {

  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ = context.$;

  parameters = {
    ...parameters,
    design: avatar.design,
    avatar: avatar,
    style: style,
    children: <Preview
      // {...one['body']}
      design={avatar.design ? avatar.design : default_design}
      aura={false}
    />
  }
  // if (!penta) {
  //   parameters = {
  //     ...parameters,
  //     design: avatar.design,
  //     avatar: avatar
  //   }
  // }
  // if (penta) {
  //   parameters = {
  //     ...parameters,
  //     design: avatar.design,
  //     avatar: avatar
  //   }
  // }




  return (<>







    {component &&
      (avatar.design.id !== 'dummy' || !pageIndex) ? <>


      {React.cloneElement(component, {
        ...component.props,
        ...parameters,
        key: `page_${one.id}`
      })}


    </> : <>
      <div
          style={{ ...style }}
        key={`page_${one.id}`} id={one.id}
        // className={`x_edu_content page--${one.id} current--${activeLink}`}
          // className={`x_edu_content`}

        >

        <Soon key={`page_${one.id}`} id={one.id} title={$('pageTitle', one)} />;
        <div className={`x_edu_page_bg soon`} />
      </div>
    </>}



    <div className='observer inact' />


  </>

  )



})