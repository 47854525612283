import React, { useContext, useRef, useState } from 'react';
import {
  getPentaDesign,
  getComposite9,
  getEmChannels,
  getSameChannels,
  getDomChannels
} from '../utilsPenta';

import { LangContext } from '../../contexts/langContext';
import { Svg } from '../UiKit/Svg';
import { TooltipH } from '../UiKit/TooltipH';


export const PentaStats = ({
  penta = {},
  size = 20
}) => {
  const context = { ...useContext(LangContext) };
  const $ui = context.$ui;

  return (<>
    <div className="x_penta_stats">

      <TooltipH title={$ui('penta_composite9')} placement='bottom'>
        <div className="x_penta_stat1">
          <div className="x_penta_stat_ico">
            <Svg width={size}>{comp}</Svg>
          </div>
          <div className="x_penta_stat_num">
            {getComposite9(penta)}
          </div>
        </div>
      </TooltipH>

      <TooltipH title={$ui('penta_em_channels')} placement='bottom'>
        <div className={`x_penta_stat1 ${Boolean(getEmChannels(penta).length) ? '' : 'x_penta_stat1--0'}`}>
          <div className="x_penta_stat_ico">
            <Svg width={size}>{em}</Svg>
          </div>
          <div className="x_penta_stat_num">
            {getEmChannels(penta).length}
          </div>
        </div>
      </TooltipH>

      <TooltipH title={$ui('penta_same_channels')} placement='bottom'>
        <div className={`x_penta_stat1 ${Boolean(getSameChannels(penta).length) ? '' : 'x_penta_stat1--0'}`}>
          <div className="x_penta_stat_ico">
            <Svg width={size}>{same}</Svg>
          </div>
          <div className="x_penta_stat_num">
            {getSameChannels(penta).length}
          </div>
        </div>
      </TooltipH>

      <TooltipH title={$ui('penta_dom_channels')} placement='bottom'>
        <div className={`x_penta_stat1 ${Boolean(getDomChannels(penta).length) ? '' : 'x_penta_stat1--0'}`}>
          <div className="x_penta_stat_ico">
            <Svg width={size}>{dom}</Svg>
          </div>
          <div className="x_penta_stat_num">
            {getDomChannels(penta).length}
          </div>
        </div>
      </TooltipH>


    </div>
  </>
  );
};


const comp = `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.03857 3.61978L4.35977 8.03516H3.03809L9.03857 2.03467L15.0391 8.03516H13.6366L9.03857 3.61978Z" fill="#BB987A"/>
<path d="M2 9L3.74975 7.25025L14.2483 7.25025L15.998 9L8.99902 15.999L2 9Z" fill="black"/>
</svg>`

const same = `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.5 4C7.67157 4 7 4.67157 7 5.5C7 6.32843 7.67157 7 8.5 7C9.32843 7 10 6.32843 10 5.5C10 4.67157 9.32843 4 8.5 4ZM6 5.5C6 4.11929 7.11929 3 8.5 3C9.88071 3 11 4.11929 11 5.5C11 6.88071 9.88071 8 8.5 8C8.26233 8 8.03241 7.96684 7.81461 7.90488L4.76696 10.4446C4.91649 10.7652 5 11.1229 5 11.5C5 12.8807 3.88071 14 2.5 14C1.11929 14 0 12.8807 0 11.5C0 10.1193 1.11929 9 2.5 9C2.75837 9 3.00758 9.03919 3.24202 9.11196L6.25496 6.60118C6.09169 6.26894 6 5.89518 6 5.5ZM2.5 10C1.67157 10 1 10.6716 1 11.5C1 12.3284 1.67157 13 2.5 13C3.32843 13 4 12.3284 4 11.5C4 10.6716 3.32843 10 2.5 10Z" fill="black"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M15 5.5C14.1716 5.5 13.5 6.17157 13.5 7C13.5 7.82843 14.1716 8.5 15 8.5C15.8284 8.5 16.5 7.82843 16.5 7C16.5 6.17157 15.8284 5.5 15 5.5ZM12.5 7C12.5 5.61929 13.6193 4.5 15 4.5C16.3807 4.5 17.5 5.61929 17.5 7C17.5 8.38071 16.3807 9.5 15 9.5C14.7623 9.5 14.5324 9.46684 14.3146 9.40488L11.267 11.9446C11.4165 12.2652 11.5 12.6229 11.5 13C11.5 14.3807 10.3807 15.5 9 15.5C7.61929 15.5 6.5 14.3807 6.5 13C6.5 11.6193 7.61929 10.5 9 10.5C9.25837 10.5 9.50758 10.5392 9.74202 10.612L12.755 8.10118C12.5917 7.76894 12.5 7.39518 12.5 7ZM9 11.5C8.17157 11.5 7.5 12.1716 7.5 13C7.5 13.8284 8.17157 14.5 9 14.5C9.82843 14.5 10.5 13.8284 10.5 13C10.5 12.1716 9.82843 11.5 9 11.5Z" fill="#BB987A"/>
</svg>`

const em = `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.29224 6.38525L1.79224 5.4358L4.29224 4.38525L5.29201 1.38675L6.29194 4.38525L8.79259 5.38567L6.29297 6.38281L5.29201 9.3854L4.29224 6.38525Z" fill="black"/>
<path d="M12.2913 13.3813L9.79126 12.4319L12.2913 11.3813L13.291 8.38285L14.291 11.3813L16.7916 12.3818L14.292 13.3789L13.291 16.3815L12.2913 13.3813Z" fill="black"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.76588 11.757L9.23525 10.5325L7.95489 8.99609L6.24079 10.4245C6.00642 10.3518 5.7573 10.3126 5.49902 10.3126C4.11831 10.3126 2.99902 11.4319 2.99902 12.8126C2.99902 14.1933 4.11831 15.3126 5.49902 15.3126C6.87974 15.3126 7.99902 14.1933 7.99902 12.8126C7.99902 12.4354 7.91548 12.0777 7.76588 11.757ZM3.99902 12.8126C3.99902 11.9842 4.6706 11.3126 5.49902 11.3126C6.32745 11.3126 6.99902 11.9842 6.99902 12.8126C6.99902 13.6411 6.32745 14.3126 5.49902 14.3126C4.6706 14.3126 3.99902 13.6411 3.99902 12.8126Z" fill="black"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.5364 6.54981L8.9993 7.83074L10.2797 9.36719L12.0593 7.88419C12.2947 7.95764 12.5451 7.99721 12.8047 7.99721C14.1854 7.99721 15.3047 6.87793 15.3047 5.49721C15.3047 4.1165 14.1854 2.99721 12.8047 2.99721C11.424 2.99721 10.3047 4.1165 10.3047 5.49721C10.3047 5.87325 10.3877 6.22989 10.5364 6.54981ZM14.3047 5.49721C14.3047 6.32564 13.6331 6.99721 12.8047 6.99721C11.9763 6.99721 11.3047 6.32564 11.3047 5.49721C11.3047 4.66879 11.9763 3.99721 12.8047 3.99721C13.6331 3.99721 14.3047 4.66879 14.3047 5.49721Z" fill="#BB987A"/>
</svg>`

const dom = `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 4C9.67157 4 9 4.67157 9 5.5C9 6.32843 9.67157 7 10.5 7C11.3284 7 12 6.32843 12 5.5C12 4.67157 11.3284 4 10.5 4ZM8 5.5C8 4.11929 9.11929 3 10.5 3C11.8807 3 13 4.11929 13 5.5C13 6.88071 11.8807 8 10.5 8C10.2623 8 10.0324 7.96684 9.81461 7.90488L6.76696 10.4446C6.91649 10.7652 7 11.1229 7 11.5C7 12.8807 5.88071 14 4.5 14C3.11929 14 2 12.8807 2 11.5C2 10.1193 3.11929 9 4.5 9C4.75837 9 5.00758 9.03919 5.24202 9.11196L8.25496 6.60118C8.09169 6.26894 8 5.89518 8 5.5ZM4.5 10C3.67157 10 3 10.6716 3 11.5C3 12.3284 3.67157 13 4.5 13C5.32843 13 6 12.3284 6 11.5C6 10.6716 5.32843 10 4.5 10Z" fill="black"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.7555 11.6976L16 8.71484L12.4998 9.2138L11.2303 10.3857C10.9993 10.3152 10.7541 10.2773 10.5 10.2773C9.11929 10.2773 8 11.3966 8 12.7773C8 14.1581 9.11929 15.2773 10.5 15.2773C11.8807 15.2773 13 14.1581 13 12.7773C13 12.3907 12.9122 12.0245 12.7555 11.6976ZM9 12.7773C9 11.9489 9.67157 11.2773 10.5 11.2773C11.3284 11.2773 12 11.9489 12 12.7773C12 13.6058 11.3284 14.2773 10.5 14.2773C9.67157 14.2773 9 13.6058 9 12.7773Z" fill="#BB987A"/>
</svg>`