import React, { Fragment, useEffect, useState, useContext, useRef } from 'react';
import { LangContext } from '../contexts/langContext';
import { StateContext } from '../contexts/stateContext';
import { SocialContext } from '../contexts/socialContext';
import { _getNewDesign, parseAvatar, parseNewDesign } from './utilsDesign';

export function WithRectification(props) {

  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };

  const [avatar, setAvatar] = useState(props.avatar);
  const [fetching, setFetching] = useState(false);


  const [debounceFlag, setDebounceFlag] = useState(false);

  useEffect(() => {

    const applyNewDesign = (newDesign) => {
      let newAva = { ...avatar, dob: context.state.tryDesignDate };
      newAva.design = newDesign
      // newAva = parseAvatar(newAva)
      console.log('newAva.design = ', newAva.design);
      // if (JSON.stringify(newAva) !== JSON.stringify(avatar)) {
      setAvatar(newAva)
      setDebounceFlag(false);
      // }
    }

    async function updateAvatar() {
      setFetching(true)
      if (context.state.tryDesignDate && context.state.tryDesignDate !== avatar.dob && !debounceFlag) {
        await _getNewDesign(
          context.state.tryDesignDate,
          avatar.aggr.city.timezone,
          (d) => {
            setFetching(false)
            console.log('d = ', d);
            if (!debounceFlag) applyNewDesign(d)

          });
      }
    }

    if (context.state.tryDesignDate && context.state.tryDesignDate !== avatar.dob && !debounceFlag) {
      setDebounceFlag(true);
      updateAvatar();
    }
  }, [context.state.tryDesignDate]);

  useEffect(() => {
    if (avatar.token !== props.avatar) {
      setAvatar(props.avatar)
    }
  }, [props.avatar])





  return (
    <>
      {React.cloneElement(props.children, { ...props, avatar: avatar, design: avatar.design })}
    </>
  )
}