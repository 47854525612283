



import React, { Fragment, useEffect, useState, useContext } from 'react'
import { getEnv, getDesignNodeColor } from '../utilsDesign';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { LangContext } from '../../contexts/langContext';

export const PHS = React.memo(({ id, design }) => {

  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ = context.$;
  const $ui = context.$ui;
  const $profile = context.$profile;
  const $line = context.$line;
  const $vars = context.$vars;
  const $envs = context.$envs;



  return (
    <>

      <div className="x_edu_content">



            {/* <div className="x_edu_content_title_auth">
            {$envs('header', 'title')}
          </div> */}
            <p className="x_edu_content_h x_edu_content_h--2">
          "{$envs(getDesignNodeColor(design), 'title')}"
            </p>
            <p className='x_edu_content_desc'>

          {$envs(getDesignNodeColor(design), 'description')}
          </p>
            <hr />
          <p>
          {$envs('resonating', 'title')}: <b>"{$envs($envs(getDesignNodeColor(design), 'resonating'), 'title')}"</b>
          </p>
        {/* <div className="x_edu_content_title_auth">{drawType(design['type'], 'auth', design['auth'])} Inner Authority</div> */}



      </div>

    </>
  )
})