import React, { Fragment, useEffect, useState, useContext, useRef } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { LangContext } from '../../contexts/langContext';
import { ListAvatarsLayout } from '../Lists/ListAvatarsLayout';
import { Persona } from '../Persona';


export function PublicMe(props) {
  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ui = context.$ui;
  const panelExpanded = context.state.peoplePanel;
  const navigate = useNavigate();

  let urlParams = new URLSearchParams(window.location.search);
  const [searchParams] = useSearchParams();
  const [dob, setDOB] = useState(urlParams.get('dob') ? urlParams.get('dob') : urlParams.get('dob') ? '' : '');

  return (
    <>

      {context.publicUser && <ListAvatarsLayout
        icon='star'
        title={$ui('people_bodygraphs')}
        listId='public_me'
        // quantity={1}
        className={panelExpanded ? 'v' : ''}
      >

        <div
          className={`x_choose ${panelExpanded ? `x_choose--v` : ''}`}
          onClick={() => {
            navigate(`${context.publicUser.link}`)
          }}
        >


          <Persona
            active={dob ? true : false}
            noClick={true}
            listId={'public_me'}
            // actions={actions}
            // key={`list_${listId}_persona_${one.avatar && one.avatar.token}`}
            key={'public_me'}
            panelExpanded={panelExpanded}
            avatar={context.publicUser.avatar}
            user={null}
            // followings={one.followings}
            // followers={one.followers}
            isMe={true}
            fullList={false}
          // refetch={refetch}
          />
        </div>



      </ListAvatarsLayout>}

    </>
  )
}