import React, { Fragment, useEffect, useState, useContext, useRef } from 'react';
import { Link, useNavigate, useSearchParams, useParams, useLocation } from 'react-router-dom';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { LangContext } from '../../contexts/langContext';
import { MessageContext } from '../../contexts/messageContext';
import { Avatar } from '../UiKit/Avatar';
import { _getUser, USER_TOKEN_LENGTH, CHANNEL_TOKEN_LENGTH } from '../utilsDesign';
import { AvatarName } from '../UiKit/AvatarName';
import { Persona } from '../Persona';
import { Preview } from '../UiKit/Preview';
import { Preloader, Preloader0 } from '../UiKit/kit';
import { ChatsListItem } from './ChatListItem';
import { renderLogo } from '../UiKit/Topbar';
import { Svg } from '../UiKit/Svg';


export const ChatsList = React.memo(({
  handleState,
  showChats,
  setShowChats
}) => {

  const navigate = useNavigate();

  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) }
  const messageContext = { ...useContext(MessageContext) }
  const $ui = context.$ui;

  const chatId = messageContext.currentChat;
  // const { chatId } = useParams();



  // const [chatsData, setChatsData] = useState({})
  // useEffect(() => {
  //   if (messageContext.chatsList.length && !Object.keys(chatsData).length) {
  //     const all = {}
  //     messageContext.fetchAllChatsData(false, (allData) => {
  //       allData.forEach(one => {
  //         all[one._id] = one;
  //       })
  //       console.log('all = ', all);
  //       setChatsData(all)
  //     })
  //   }
  // }, [messageContext])


  const [membersDataInner, setMembersDataInner] = useState(messageContext.membersData)
  const [newChat, setNewChat] = useState()
  const [newChat0, setNewChat0] = useState()
  // const [messageContext.chatsList, setChatsList] = useState(messageContext.chatsList ? messageContext.chatsList : [])

  useEffect(() => {
    if (messageContext.membersData
      && (!membersDataInner || Object.keys(membersDataInner).length !== Object.keys(messageContext.membersData).length)
      || membersDataInner && !membersDataInner[chatId]
    ) {
      setMembersDataInner(messageContext.membersData)
    }
  }, [messageContext.membersData])


  const addInnerUser = (userData) => {
    const newU = {};
    newU[userData.user.token] = userData;
    setMembersDataInner({
      ...membersDataInner,
      ...newU
    })
  }

  useEffect(() => {
    if (
      chatId &&
      chatId.length === CHANNEL_TOKEN_LENGTH) {
      setNewChat()
    }

    if (
      chatId &&
      chatId.length === USER_TOKEN_LENGTH
      && !messageContext.findChannelByMember(chatId)
      && (!newChat || newChat.user.token !== chatId)
      // && membersDataInner
      // && membersDataInner[chatId]
    ) {
      setNewChat0()
      setNewChat({
        user: {},
        avatar: {}
      })
      _getUser(chatId, (newUser) => {
        setNewChat(newUser);
        addInnerUser(newUser)
      })
      // const newChat = membersDataInner[chatId];
    }

    if (
      chatId
      && newChat
      && messageContext.findChannelByMember(chatId)
    ) {
      setNewChat()
    }

    if (messageContext.chatsList
      && messageContext.chatsList.length !== messageContext.chatsList.length) {
    // setChatsList(messageContext.chatsList)
      setNewChat0()
      setNewChat()
    }
  }, [chatId, membersDataInner, messageContext.chatsList])



  useEffect(() => {
    if (messageContext.chatsList && !messageContext.chatsList) {
      handleState('choose')
      // navigate('/chats/choose')
    }
  }, [messageContext.chatsList])

  useEffect(() => {
    if (chatId === 'choose') {
      setNewChat()
      setNewChat0(true)
    } else {
      setNewChat0()
    }
  }, [chatId])


  return (
    <div className={`m_b m_b--chats m_b--chats--chatslist ${showChats ? 'shown' : ''}`}>

      <div className="m_b_h m_b_h--chats">
        <div className="x_choose_section_header x_choose_section_header--chats">
          <div className="x_choose_section_title x_choose_section_title--chats">
            <div className="x_panel_icon">
              <div className="t_icon t_icon--20">
                <div>people</div>
              </div>
            </div>
            <div className="x_panel_title active">
              {$ui('chat_header')}
            </div>
          </div>
          <div className="x_choose_section_header_right _f">

            <div className="x_button x_button--ghost"
              onClick={() => {
                handleState(`choose`)
                // navigate(`/chats/choose`)
                setShowChats()
              }}
            >
              <div className="x_button_icon x_button_icon--solo">
                <div className="t_icon t_icon--20">
                  <div>add_circle</div>
                </div>
              </div>
            </div>

            <div className="x_button x_button--ghost mobile_vhi_yes"
              onClick={() => handleState(null)}
            >
              <div className="x_button_icon x_button_icon--solo">
                <div className="t_icon t_icon--20">
                  <div>close</div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>


      <div className="m_b_c m_b_c--chats">



        <div className="x_chats_ul">


          {!membersDataInner &&
            !messageContext.chatsList
            && <Preloader0 style={{ height: '30%' }}>
              <Preloader width={64} height={64} />
            </Preloader0>}


          {/* {!membersDataInner &&
            messageContext.chatsList && !messageContext.chatsList.length
            && <div>
              Start a conversation
            </div>} */}


          {newChat0 &&
            <div
              className={chatId === 'choose' ? "x_chats_li active" : "x_chats_li"}
              onClick={() => {
                handleState(`choose`)
                // navigate(`/chats/choose`)
                setShowChats()
              }}
            >
              <div className="x_chats_name x_chats_name--new">
                <div className="x_chats_start_label0">
                  <div className="t_icon t_icon--20">
                    3p
                  </div>
                  <div className="x_chats_start_label">New conversation</div>
                </div>
              </div>
            </div>
          }

          {newChat && <ChatsListItem
            handleState={handleState}
            setShowChats={setShowChats}
            memberData={newChat}
            key={newChat.user.token}
            chatId={newChat.user.token}
            active={chatId === newChat.user.token}
          />}


          {membersDataInner && messageContext.chatsList.map(one => {
            const membersList = one.members.filter(one => one !== context.me.user.token);
            const memberData = membersDataInner[membersList[0]] ? membersDataInner[membersList[0]] : {};
            return (<ChatsListItem
              handleState={handleState}
              setShowChats={setShowChats}
              memberData={memberData}
              key={one._id}
              chatId={one._id}
              active={chatId === one._id}
            />)

          })}


          {/* <ChatSupport
            handleState={handleState}
            setShowChats={setShowChats}
            active={chatId === 'support'}
            chatId={'support'}
          /> */}


        </div>
      </div>
    </div >

  )
})


const ChatSupport = ({
  handleState,
  chatId = 'support',
  active,
  setShowChats = () => void (0)
}) => {
  return <div
    className={active ? "x_chats_li active" : "x_chats_li"}
  >

    <div className="x_hover"
      onClick={() => {
        handleState(chatId)
        setShowChats()
      }}
    />

    <div className="x_mobile_tap"
      onClick={() => {
        handleState(chatId)
        setShowChats()
      }}
    />


    <div className="x_chats_name">


      <div className="x_user_ava"
        style={{
          fontSize: '1.4em'
        }}
      >
        {renderLogo()}
      </div>


      <div className="x_choose_user_info x_choose_user_info--chats">
        <div className="x_choose_user_1">
          <div className="x_choose_user_nick x_choose_user_nick--chats">Support</div>
        </div>
      </div>
    </div>

  </div >
}
