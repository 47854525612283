import React, { Fragment, useEffect, useState, useContext, useRef } from 'react';
import { Link, useNavigate, useSearchParams, useParams, useLocation } from 'react-router-dom';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { LangContext } from '../../contexts/langContext';

export function Videos({
  title = '',
  relType = '',
  relValue = '',
  active = false,
  size = '',
  style = {}
}) {
  const navigate = useNavigate();
  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };

  title = title.replaceAll(' ', '%20')

  const location = useLocation();
  const [videoId, setVideoId] = useState(null);
  useEffect(() => {
    const hash = location.hash;
    const params = new URLSearchParams(hash.slice(1));
    const video = params.get('video') ? params.get('video') : '';
    setVideoId(video);
  }, [location]);

  useEffect(() => {
    if (!active) setVideoId(active)
  }, [active]);

  const YouTubeEmbed = ({ video, size = '' }) => {

    const thumbnailUrl = `https://img.youtube.com/vi/${video.youtubeId}/hqdefault.jpg`;

    return (<div className={`x_videos_item0 ${size ? `x_videos_item0--${size}` : ''}`}>

      <div className={`x_hover x_hover--sm ${active && videoId === video._id ? 'active' : ''}`}
        onClick={() => {
          const url = `#video=${video._id}&rel=${relType}&value=${relValue}${title ? `&title=${title}` : ''}`;
          const queryParams = location.search ? location.search : '';
          navigate(`${queryParams}${url}`);
        }}
      />
      <div className={`x_mobile_tap ${active && videoId === video._id ? 'active' : ''}`}
        onClick={(e) => {
          e.stopPropagation();
          const url = `${location.pathname}#video=${video._id}&rel=${relType}&value=${relValue}${title ? `&title=${title}` : ''}`;
          const queryParams = location.search ? location.search : '';
          navigate(`${url}${queryParams}`);
        }}
      />

      <div className={`x_videos_item ${size ? `x_videos_item--${size}` : ''}`}>

        <div className="x_video_play"
          style={{ backgroundImage: `url(${thumbnailUrl})` }}
        >
          <div className="x_video_play_icon">
            <div className="t_icon">
              <div>play_circle</div>
            </div>
          </div>
        </div>
      </div>
      <div className={`x_videos_item_title ${size ? `x_videos_item_title--${size}` : ''}`}>{video['comment']}</div>
    </div>)


  };


  const videoIds = context.getVideoList(relType, relValue)


  return (
    <>

      {Boolean(videoIds.length) && <div className="x_videos00" style={{ ...style }}>
        <div className="x_videos0 active">
          <div className={`x_videos_scroll ${size ? `x_videos_scroll--${size}` : ''}`}>


            {videoIds.map((video, index) => (
              <YouTubeEmbed key={index} video={video} size={size} />
            ))}


          </div>
        </div>
      </div>}





    </>
  )
}