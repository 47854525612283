const ln = 'en';

export const environments_dictionary = {
  "1": {
    index: "1",
    title: { en: "Caves", ru: "Пещеры" },
    resonating: "4",
    description: { en: "A place of refuge and shelter", ru: "Место убежища и укрытия" }
  },

  "2": {
    index: "2",
    title: { en: "Markets", ru: "Рынки" },
    resonating: "5",
    description: { en: "A place of exchange and commerce", ru: "Место обмена и торговли" }
  },

  "3": {
    index: "3",
    title: { en: "Kitchens", ru: "Кухни" },
    resonating: "6",
    description: { en: "A place of nourishment and creativity", ru: "Место питания и креативности" }
  },

  "4": {
    index: "4",
    title: { en: "Mountains", ru: "Горы" },
    resonating: "1",
    description: { en: "A place of grandeur and perspective", ru: "Место величия и перспективы" }
  },

  "5": {
    index: "5",
    title: { en: "Valleys", ru: "Долины" },
    resonating: "2",
    description: { en: "A place of growth and abundance", ru: "Место роста и изобилия" }
  },

  "6": {
    index: "6",
    title: { en: "Shores", ru: "Берега" },
    resonating: "3",
    description: { en: "A place of transition and connection", ru: "Место перехода и соединения" }
  }
}

export const diets_dictionary = {
  "1": {
    index: "1",
    title: { en: "Hunter", ru: "Охотник" },
    description: {
      en: "A place of refuge and shelter",
      ru: "Запах. Раздельное питание, примитивная система пищеварения"
    },
    descriptionLong: {
      en: "Description Long",
      ru: `<p>Это диета охотника. Примитивная система питания. Взял продукт, съел, пошел дальше. Людям с этим цветом следует отдавать предпочтение самой простой, природной пище. Чем проще будет питание, тем проще будет его переварить. И тем лучше оно будет давать энергию вашему организму.</p><p>Диета охотника подразумевает один продукт за раз. Без смешения продуктов или ингредиентов. Это раздельное питание, когда желудку требуется некоторое время, чтобы передохнуть перед новой пищей. Смеси, полуфабрикаты, соусы – все это будет вредным для вашего организма. Только натуральные, первозданные продукты, без каких-либо добавок.</p>`
    },
  },

  "2": {
    index: "2",
    title: { en: "Markets", ru: "Рынки" },
    resonating: "5",
    description: { en: "A place of exchange and commerce", ru: "Место обмена и торговли" }
  },

  "3": {
    index: "3",
    title: { en: "Kitchens", ru: "Кухни" },
    resonating: "6",
    description: { en: "A place of nourishment and creativity", ru: "Место питания и креативности" }
  },

  "4": {
    index: "4",
    title: { en: "Mountains", ru: "Горы" },
    resonating: "1",
    description: { en: "A place of grandeur and perspective", ru: "Место величия и перспективы" }
  },

  "5": {
    index: "5",
    title: { en: "Valleys", ru: "Долины" },
    resonating: "2",
    description: { en: "A place of growth and abundance", ru: "Место роста и изобилия" }
  },

  "6": {
    index: "6",
    title: { en: "Shores", ru: "Берега" },
    resonating: "3",
    description: { en: "A place of transition and connection", ru: "Место перехода и соединения" }
  }
}

