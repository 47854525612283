import React, { useEffect, useState, useContext, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Pop } from './UiKit/Pop';
import { SocialContext } from '../contexts/socialContext';
import { StateContext } from '../contexts/stateContext';
import { LangContext } from '../contexts/langContext';
import { Videos } from './UiKit/Videos';

// YouTube component that uses the IFrame Player API
const YouTube = ({ video = {} }) => {
  const playerRef = useRef(null);
  const { youtubeId, startTime, endTime } = video;

  useEffect(() => {
    const loadYouTubeIframeAPI = () => {
      if (!window.YT) {
        const tag = document.createElement('script');
        tag.src = "https://www.youtube.com/iframe_api";
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      }
    };

    const onYouTubeIframeAPIReady = () => {
      if (window.YT && playerRef.current) {
        playerRef.current = new window.YT.Player(`youtube-player-${youtubeId}`, {
          videoId: youtubeId,
          playerVars: {
            autoplay: 1,
            rel: 0,
            start: startTime,
            end: endTime
          },
          events: {
            onReady: (event) => {
              event.target.seekTo(startTime);
              event.target.playVideo();
            }
          }
        });
      }
    };

    loadYouTubeIframeAPI();

    if (window.YT) {
      onYouTubeIframeAPIReady();
    } else {
      window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;
    }

    return () => {
      if (playerRef.current && playerRef.current.destroy) {
        playerRef.current.destroy();
      }
    };
  }, [youtubeId, startTime]);

  return (
    <div className="x_pop_video_c">
      <div
        id={`youtube-player-${youtubeId}`}
        ref={playerRef}
        className='x_pop_video_embed'
      // style={{ width: '400px', height: '300px' }}
      ></div>
    </div>
  );
};

// VideoPop component
export function VideoPop() {
  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const location = useLocation();
  const [videoId, setVideoId] = useState(null);
  const [rel, setRel] = useState(null);
  const [value, setValue] = useState(null);
  const [title, setTitle] = useState(null);

  useEffect(() => {
    const hash = location.hash;
    const params = new URLSearchParams(hash.slice(1));
    const video = params.get('video');
    const related = params.get('rel');
    const relValue = params.get('value');
    const tit = params.get('title');
    setVideoId(video);
    setRel(related);
    setValue(relValue);
    setTitle(tit);
  }, [location]);

  const handleClose = () => {
    setVideoId(null);
    window.history.replaceState(null, '', window.location.pathname + window.location.search);
  };

  return (
    <>
      {Boolean(videoId) && <Pop
        type='video'
        title={title ? title : context.getVideoById(videoId)?.comment}
        icon='videocam'
        handleClose={handleClose}
        addContent={<div className="x_c_width"><Videos relType={rel} relValue={value} active={videoId} /></div>}
      >
        <YouTube video={context.getVideoById(videoId)} />
      </Pop>}
    </>
  );
}
