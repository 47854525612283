import React, { Fragment, useEffect, useState, useContext } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { GroupSelector } from './GroupSelector';
import { ListAvatarsLayout } from './Lists/ListAvatarsLayout';
import { ListAvatars } from './Lists/ListAvatars';
import { SocialContext } from '../contexts/socialContext';
import { StateContext } from '../contexts/stateContext';
import { LangContext } from '../contexts/langContext';
import { _seeGroupInvitations, _getGroupAdmins, _groupLeave, _getGroupMembers } from './utilsGroup';
import { Button } from './UiKit/Button';
import { PopoverH, TooltipH } from './UiKit/TooltipH';
import { EditName } from './UiKit/EditName';
import { Avatar } from './UiKit/Avatar';
import { AvatarName } from './UiKit/AvatarName';
import { MessageContext } from '../contexts/messageContext';
import { renderLogo } from './UiKit/Topbar';

export function Group({
  group,
  admins = [],
  members = [],
  invited = [],
  groupBrowser,
  setGroupBrowser,
  fetchMembers,
  fetchMyRequests
}) {
  const navigate = useNavigate();
  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const messageContext = { ...useContext(MessageContext) }
  const $ui = context.$ui;




  // useEffect(() => {
  //   if (!invited.length) {
  //     const callback = (data) => {
  //       console.log('data = ', data);
  //       setInvited(data)
  //     }
  //     _seeGroupInvitations(group.token, callback)
  //   }
  // }, [context])




  return (
    <>

      <GroupSelector
        group={group}
        expanded={groupBrowser}
        setExpanded={setGroupBrowser}
        exclude={[
          ...invited.map(one => one.user.token),
          ...admins.map(one => one.user.token)
        ]}
      />


      {/* ME ADMIN */}
      {Boolean(admins.filter(u => u.user.token === context.me.user.token).length) && <div className="x_choose_section x_choose_section--v">

        <div className="x_choose_section_title0 x_choose_section_title0--v"
          style={{ paddingBottom: 0, marginBottom: '-0.6em' }}
        >
          <div className="x_choose_section_header" style={{ padding: 0 }}>
            <div className="x_groupadmin0 _f100 _fgap1">


              <div className="_f _f100 _fm">
                {/* <div className="x_choose_section_title x_choose_section_title--v">
{$ui('group_admin_title')}
</div> */}

                <GroupInvited invited={invited}>
                  <Button
                    icon='person_add'
                    onClick={() => setGroupBrowser(group.token)}
                  >
                    {$ui('group_invite_user')}
                  </Button>

                </GroupInvited>





                <div className="_f100 _fr">
                  <div className="_fv _fcc">
                    <Button ghost icon='open_in_new' onClick={() => navigate(`/g/${group.token}`)}>{$ui('group_open')}</Button>
                  </div>

                  <PopoverH
                    title={<EditName
                      name={group.name}
                      desription={group.description ? group.description : ' '}
                      // placeholder='Set group name'
                      placement='bottom'
                      handleUpdate={(newName, newDescription) => {
                        context.x.updateGroup({
                          'token': group.token,
                          'name': newName,
                          'description': newDescription
                        })
                      }}
                    />}>
                    <div >
                      <Button
                        ghost
                        onClick={() => void (0)}
                      >{$ui('group_edit')}</Button>
                    </div>
                  </PopoverH>

                  <PopoverH
                    title={<>
                      <Button red icon='delete'
                        onClick={() => context.x.deleteGroup(group.token)}
                      >{$ui('group_delete')}</Button>
                    </>}>
                    <div >
                      <Button ghost red icon='delete'></Button>
                    </div>
                  </PopoverH>
                </div>


              </div>


            </div>

          </div>

        </div>
      </div>}

      {/* NOT ADMIN */}
      {!Boolean(admins.filter(u => u.user.token === context.me.user.token).length) && <div className="x_choose_section x_choose_section--v">

        <div className="x_choose_section_title0 x_choose_section_title0--v"
          style={{ paddingBottom: 0 }}
        >
          <div className="x_choose_section_header" style={{ padding: 0 }}>
            <div className="x_groupadmin0 _f100 _fm _fgap1">
              <div className="_f _f100">
                {/* <div className="x_choose_section_title x_choose_section_title--v">
{$ui('group_admin_title')}
</div> */}




                {admins
                  .map(one => {
                    return (<div className="_fm" key={one.user.token}>
                      <div className="_f _fm _fgap1 _click _fnogrow"
                        key={`${one.user.token}`}
                        onClick={() => navigate(`/u/${one.user.token}`)}
                      >
                        <Avatar avatar={one.avatar} style={{ fontSize: '1.2em' }} />
                        <AvatarName avatar={one.avatar} />
                      </div>
                      <Button
                        onClick={() => messageContext.setCurrentChat(one.user.token)}
                      >Message admin</Button>

                      <div className="_fv _fcc">
                        <Button ghost icon='open_in_new' onClick={() => navigate(`/g/${group.token}`)}>{$ui('group_open')}</Button>
                      </div>

</div>
)
})}




                <div className="_f100 _fr">
                  <PopoverH
                    title={<>
                      <Button red icon='delete'
                        onClick={() => context.x.leaveGroup(group.token, () => {
                          fetchMembers();
                          fetchMyRequests();
                        })}
                      >{$ui('group_leave')}</Button>
                    </>}>
                    <div >
                      <Button ghost red icon='logout'></Button>
                    </div>
                  </PopoverH>
                </div>


              </div>


            </div>

          </div>

</div>
</div>}






      <GroupMembers group={group} members={members} />


    </>
  )
}



export function GroupMembers({ group = {}, members = [] }) {
  const navigate = useNavigate();
  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const messageContext = { ...useContext(MessageContext) }
  const $ui = context.$ui;





  return (
    <>


      <ListAvatars
        customGrigClass='x_choose x_choose--v x_choose--followings'
        listId={'followings'}
        quantity={members.length}
        array={members}
      />


      {/* <ListAvatarsLayout
        icon='group'
        title={group.name}
        listId={'followings'}
        quantity={members.length}
        className={'v'}
      >
        <ListAvatars
          listId={'followings'}
          quantity={members.length}
          array={members}
        />
      </ListAvatarsLayout> */}

      {/* <ListAvatarsLayout
        icon='mail'
        title={`${$ui('group_invited')}`}
        listId={`${group.token}_invites`}
        quantity={invited.length}
        className={context.state.peoplePanel ? 'v' : ''}
      >
        <ListAvatars
          listId={group.token}
          quantity={invited.length}
          array={invited}
        />

      </ListAvatarsLayout> */}
    </>
  )
}






const GroupInvited = ({ invited = [], children }) => {
  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ui = context.$ui;
  const navigate = useNavigate();
  return (

    <div className="_fv _f100 ">
      <div className="_tnowrap">
        {$ui('group_invited')}:
      </div>
      <div className={`_f _fgap06 _fwrap _fm _f100`} style={{

      }}>
        {invited
          .map(one => {
            return (<TooltipH
              key={one.user.token}
              title={<AvatarName avatar={one.avatar}
            />}>
              <div className="_fv _fm _fgap04 _click _fnogrow"
                style={{ marginBottom: '-0.8em' }}
                key={`${one.user.token}`}
                onClick={() => navigate(`/u/${one.user.token}`)}
              >
                <Avatar avatar={one.avatar} style={{ fontSize: '0.8em' }} />
                <div style={{
                  width: invited.length > 3 ? '4.6em' : '7em',
                  height: '2em',
                  fontSize: '0.8em',
                  fontWeight: '600',
                  marginRight: '-0.4em'
                }} className="ellipsis">
                  <AvatarName avatar={one.avatar} />
                </div>
              </div>
            </TooltipH>)
          })}
        {children}
      </div>

    </div>

  )
}