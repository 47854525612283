import React, { Fragment, useEffect, useState, useContext } from 'react'


import { getCrossType } from '../utilsDesign';
import { getGodFaceByGate } from '../../static/crosses_dictionary';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { LangContext } from '../../contexts/langContext';


export const Cross = React.memo(({ id, design }) => {


  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ui = context.$ui;
  const $cross = context.$cross;






  const getCross = (what) => {
    const leftRightJuxt = getCrossType(design.profile)

    const crossId = `${leftRightJuxt}_${design.inc_cross.toString()}`;
    return $cross(crossId, what)
    // if (profile[0] === 4 && profile[1] === 1) {
    //   if (cr[0]['title'].includes('The Juxtaposition')) return cr[0];
    // } else {
    //   if (cr[0]['title'].includes('The Juxtaposition')) return cr[1];
    // }
  }






  const getGateEmoji = (index) => {
    const emo = [
      '🌞', '🌎', '🌞', '🌎'
    ]
    return emo[index]
  }

  return (
    <>




      <div className="x_edu_content">


        <div className="x_edu_white0">
          <div className="x_edu_1st">
            <div className="x_edu_content_h x_edu_content_h--3">{$ui(`cross_${getCrossType(design['profile'])}`)}</div>
            <h1 className="x_edu_content_h x_edu_content_h--type">{getCross('title')}</h1>
            <br />
            <p className='x_edu_content_desc'>
              {getCross('description')}
            </p>
            <br />
            <div className="x_edu_cross_faces0">


              {design['inc_cross'].map((one, index) => {
                return <div
                  className={index > 1 ? "x_edu_cross_face x_edu_cross_face--des" : "x_edu_cross_face"}
                  key={`${index}_${getGateEmoji(index)}`}
                >
                    <div className="x_edu_cross_face_sun">
                      {getGateEmoji(index)}
                    </div>
                    <div className="x_edu_cross_face_gate">
                      {one}
                    </div>
                    <div className="x_edu_cross_face_name">
                      {getGodFaceByGate(one)['face']}
                    </div>
                    <div>
                      <div style={{
                        fontSize: '3em',
                        overflow: 'hidden',
                        height: '0.24em',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}>
                        {getGodFaceByGate(one)['hexagram']}
                      </div>
                    </div>
                  </div >

              })}





            </div>
          </div>
        </div>





      </div >




    </>
  )
})