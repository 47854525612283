import React, { Fragment, useEffect, useState, useContext } from 'react'
import { Link, useNavigate, useSearchParams, useParams, useLocation } from 'react-router-dom';
import Chats from './Chats';
import { MessageContext } from '../../contexts/messageContext';

export function ChatsAsPage(props) {

  const messageContext = { ...useContext(MessageContext) }
  const navigate = useNavigate();

  const { chatId } = useParams();

  useEffect(() => {
    if (messageContext && !messageContext.currentChat && messageContext.setCurrentChat) {
      messageContext.setCurrentChat(chatId ? chatId : 'choose')
    }
    if (!chatId) {
      navigate('/')
    }
  }, [chatId])

  const handleStateAdd = (url) => {
    if (!url) {
      navigate(`/`)
    } else {
      navigate(`/chats/${url}`)
    }
    // console.log('url = ', url);
    // messageContext.setCurrentChat(url)
  }
  return (
    <>
      <Chats
        handleStateAdd={handleStateAdd}
      />
    </>
  )
}