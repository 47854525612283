import React, { useContext, useEffect, useState, useRef, memo } from 'react';
import { Link, useNavigate, useSearchParams, useParams, useLocation } from 'react-router-dom';
import styled from 'styled-components';
// import { Guide } from './Guide';

import Soon from './Edu/Soon';


import { Background } from './UiKit/Background';
import { Preloader, Preloader0 } from './UiKit/kit';
import { Preview } from './UiKit/Preview';
import Page404 from './UiKit/Page404';
import { SocialContext } from '../contexts/socialContext';
import { StateContext } from '../contexts/stateContext';
import { AvatarPanel } from './UiKit/AvatarPanel';
import { default_design } from '../static/default_design';
import { Guide } from './UiKit/Guide';
import { WithVersion } from './UiKit/VersionToggler';
import { LangContext } from '../contexts/langContext';
import { setLocal, getLocal } from './utilsSocial';
import { Footer } from './Footer';
import { Txt } from './UiKit/Txt';
import { WithRectification } from './WithRectification';
import { DesignPages } from './Edu/DesignPages';
import { WithURLparams } from './WithURLparams';
import { PreloaderHD } from './UiKit/PreloaderHD';







export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value; //assign the value of ref to the argument
  }, [value]); //this code will run when the value of 'value' changes
  return ref.current; //in the end, return the current ref value.
}






export const Start = memo(({
  publ,
  user,
  avatar,
  penta,
  followings = [],
  followers = [],
  loading,
  children,
  isMe,
  refetch
}) => {


  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ = context.$;
  const $ui = context.$ui;


  // console.log('avatar.design = ', avatar.design);


  // const narrative0 = context.narratives['empty'] ? context.narratives['empty'] : defaultNarrative;
  // const beginner = context.narratives['beginner'] ? context.narratives['beginner'] : defaultNarrative;
  // const professional = context.narratives['professional'] ? context.narratives['professional'] : defaultNarrative;


  const [narrative, setNarrative] = useState(context.narratives['default']);

  const narrativeFromLocal = getLocal('narrative');

  useEffect(() => {
    if (avatar && avatar.design['id'] !== 'dummy' && context.narratives['professional']) {
      // context.setNarrativeCurrent('professional')
      context.setNarrativeCurrent(narrativeFromLocal && context.narratives[narrativeFromLocal] ? narrativeFromLocal : 'simple')
      // setNarrative(context.narratives['professional'])
    } else if (avatar) {
      context.setNarrativeCurrent('default')
      // setNarrative(context.narratives['default'])
    }

  }, [context.narratives, avatar])

  useEffect(() => {
    if (!penta) {
      setNarrative(context.narratives[context.narrativeCurrent])
    } else {
      setNarrative(context.narratives['simple'])
    }

  }, [context.narrativeCurrent])




  const [scrollTo, setScrollTo] = useState(-1);

  const scrollToSection = (id) => {
    // setActiveLink(id)
    const section = document.getElementById(id);
    section && section.scrollIntoView({ behavior: "smooth" });
  };





  const navigate = useNavigate();
  const location = useLocation();
  const handleCloseInfoPop = () => {
    document.body.classList.remove('noscroll');
    context.setState.setHighlighted()
    context.setState.setSearch([])

    const removeStudySegments = (pathname) => {
      const segments = pathname.split('/study')
      return segments.length > 1 ? segments[0] : null
    };
    const currentPath = location.pathname;
    const newPath = removeStudySegments(currentPath);
    if (newPath) navigate(newPath);
  };




  return (<>






    {avatar && <AvatarPanel
      publ={publ}
      isMe={isMe}
      user={user}
      avatar={avatar}
      followings={followings}
      followers={followers}
      refetch={refetch}
    />}

    {children}

    {/* {loading && <>
      <Preloader0 style={{ zIndex: 1, position: 'fixed', pointerEvents: 'none' }}>
        <Preloader width={80} height={80} />
      </Preloader0>
    </>} */}
    {loading && <> <PreloaderHD /></>}


    <div
      style={{
        opacity: loading ? 0.2 : 1
        // marginRight: '-2em', paddingLeft: '2em'
      }}
      // className={"x_edu"}
      className={context.state.calcShown ? "x_edu x_edu--calc" : "x_edu"}

    // style={{
    // opacity: datePickerShown ? 0.3 : 1,
    // overflow: datePickerShown ? 'hidden' : 'auto',
    // height: datePickerShown ? '100svh' : 'auto',
    // pointerEvents: datePickerShown ? 'none' : 'inherit',
    // }}
    >

      <div className={`x_edu_content00`}
      // onClick={() => handleCloseInfoPop()}
      >

        {!penta && avatar && <WithURLparams>
          <WithRectification avatar={avatar}>
            <DesignPages
              user={user}
              avatar={avatar}
              narrative={narrative}
            //TODO: public date change
            // publ={publ}
            />
          </WithRectification>
        </WithURLparams>}

        {penta &&
          <DesignPages
            penta={penta}
            narrative={narrative}
          //TODO: public date change
          // publ={publ}
          />
        }

      </div>

    </div>




    {avatar && avatar.design['id'] !== 'dummy' && <Guide
      design={avatar.design}
      narrative={narrative}
      // activeLink={activeLink}
      scrollToSection={scrollToSection}
    />
    }

  </>
  )
})





export function NarrativesList() {
  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ = context.$;
  const $ui = context.$ui;
  const $narr = context.$narr;


  const def = 'simple';
  useEffect(() => {
    if (context.state.version !== 'β' && context.getNarrative(context.narrativeCurrent).beta) {
      context.setNarrativeCurrent(def);
    }
  }, [context.state.version, context.narrativeCurrent])

  return (
    <>

          <div className="x_edu_narrative_choose">

            {context.narratives && Object.keys(context.narratives)
              .filter(ky => !context.getNarrative(ky).beta || context.state.version === 'β')
              .filter(ky => context.narratives[ky].user_choose)
              .map(ky => <div
                onClick={() => {
                  context.setNarrativeCurrent(ky);
                  setLocal('narrative', ky)
                }}
                key={`${ky}`} className={context.narrativeCurrent === ky ? "x_edu_narrative1 active" : "x_edu_narrative1"}>
                {$narr(ky, 'trigger')}
              </div>)}


      </div >
    </>
  )
}