import React, { Fragment, useEffect, useState, useContext } from 'react'
import { Link, withRouter, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import styled, { css } from 'styled-components';
import { Icon } from './Icon';
import { TooltipH } from './TooltipH';


export function Switch({
  active,
  tooltip,
  handle = () => void (0),
  label = '',
  preLabel = '',
  size = 'sm'
}) {


  const sizes = {
    'xs': '0.5em',
    'sm': '0.7em',
    'md': '1em',
  }

  return (
    <>

      <div className="x_guide_switch"
        onClick={(e) => {
          e.stopPropagation();
          handle();
        }}
        style={{
          fontSize: sizes[size],
          cursor: 'pointer',
        }}
      >
        {Boolean(preLabel) && <div style={{ opacity: !active ? 1 : 0.4 }}>{preLabel}</div>}

        <div className={active ? "x_switch0 active" : "x_switch0"}
        >
          <div className={active ? "x_switch_handle active" : "x_switch_handle"} />
        </div>

        {Boolean(label) && <div
          style={{ opacity: active ? 1 : 0.4, fontSize: '1.4em' }}>{label}</div>}
        {Boolean(tooltip) &&
          <TooltipH title={tooltip}>
            <div
              style={{ opacity: active ? 1 : 0.4, fontSize: '1.4em' }}><Icon>info</Icon></div>
          </TooltipH>
        }

      </div>


    </>
  )
}