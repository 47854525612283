import React, { Fragment, useEffect, useState, useContext, useRef } from 'react';
import { Link, useNavigate, useSearchParams, useParams, useLocation } from 'react-router-dom';
import { FollowList } from '../FollowList';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { LangContext } from '../../contexts/langContext';

export function UserNetwork(props) {
  const {
    useravatarId,
    user,
    children
  } = { ...props };
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState('followings');
  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ui = context.$ui;
  return (
    <>

      <div className="_fv _fgap2">

        <div className="x_choose_section x_choose_section--followers">
          <div className="x_info_form_toggles">
            <div className="ui_radiogroup_h">

              <div className={state === 'followers' ? `ui_radio_h active` : `ui_radio_h`}
                onClick={() => setState('followers')}
              >
                <b>
                  {$ui('pop_followers')}
                </b>
                <div className="x_choose_menu_counter">{user.aggr.followers_count}</div>
              </div>

              <div className={state === 'followings' ? `ui_radio_h active` : `ui_radio_h`}
                onClick={() => setState('followings')}
              >
                <b>
                  {$ui('pop_following')}
                </b>
                <div className="x_choose_menu_counter">{user.aggr.followings_count}</div>

              </div>

            </div>
          </div>
        </div>



        {state === 'followers' && <FollowList
          count={user.aggr.followers_count}
          token={useravatarId}
          list={'followers'}
        />}


        {state === 'followings' && <FollowList
          count={user.aggr.followings_count}
          token={useravatarId}
          list={'followings'}
        />}

      </div>

    </>
  )
}