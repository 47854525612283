import React, { useContext, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { Preview } from '../UiKit/Preview';
import { Background } from '../UiKit/Background';
import { UserDropDown } from '../UiKit/UserDropDown';
import { LangContext } from '../../contexts/langContext';
import { _deleteFromPenta, parseDesignInPersonas } from '../utilsDesign';
import { useEffect } from 'react';
import { default_design } from '../../static/default_design';
import { PentaName } from './PentaName';
import { PentaBody } from './PentaBody';
import {
  getPentaDesign,
  getComposite9,
  getEmChannels,
  getSameChannels,
  getDomChannels
} from '../utilsPenta';
import { PentaStats } from './PentaStats';
import { TooltipH } from '../UiKit/TooltipH';
import { WithVersion } from '../UiKit/VersionToggler';
import { Icon } from '../UiKit/Icon';





export const PentaContainer = ({
  active,
  peoplePanel,
  setExpanded = () => void (0),
  penta = {},
  quantity,
  user = {},
  avatar = {},
  handleClick,
  handleChoose = () => void (0)
}) => {
  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ui = context.$ui;
  const bodyContainer = useRef(null);
  const navigate = useNavigate();



  const { pentaId } = useParams();

  const [pentaInner, setPentaInner] = useState(penta)

  // useEffect(() => {
  //   setPentaInner(penta)
  // }, [context.pentas])

  useEffect(() => {
    setPentaInner(penta)
  }, [penta])

  const bodyContainerThumbs = {
    thumb0: useRef(null),
    thumb1: useRef(null)
  };

  handleClick = handleClick ? handleClick : () => {
    if (pentaInner.members.length > 1) {
      navigate(`/p/${pentaInner.token}`);
      context.setState.showPeoplePanel();
    } else {
      handleChoose(pentaInner.token)
    }
  }


  const deleteFromPenta = (token) => {
    const newPentaInner = JSON.parse(JSON.stringify(pentaInner))
    newPentaInner.members = parseDesignInPersonas(newPentaInner.members)
    newPentaInner.members = newPentaInner.members.filter(one => one.avatar.token !== token);
    setPentaInner(newPentaInner);
    context.x.deleteFromPenta(pentaInner.token, token)
  }


  return (<>
    {pentaInner && <>
      <div
        className={`x_choose_item`}
      // className={`x_choose_item ${(!context.state.peoplePanel && pentaInner.members.length < 2) ? 'x_choose_item--empty' : ''}`}
      >
        <div className={context.state.peoplePanel ? "x_penta0 x_penta0--v" : "x_penta0"}>


          <UserDropDown
            penta={pentaInner}
            listId={pentaInner.token}
            // avatar={avatar}
            // user={user}
            actions={[
              'deletePenta']}

            addComponentFirst={<>
              <WithVersion>
              <div
                className="x_choose_one_menu_li w-inline-block"
                onClick={() => {
                  handleChoose(pentaInner.token)
                }}
              >
                <div className="t_icon t_icon--20">person_add</div>
                {$ui('penta_add_people')}
              </div>
              </WithVersion>
            </>}

          />


          {Boolean(pentaInner.members.length > 3) && <>
            <div className="x_tap"
              onClick={() => {
                navigate(`/p/${pentaInner.token}`);
                context.setState.showPeoplePanel();
              }}
            />
          </>}

          <div className={`${context.state.peoplePanel ? "x_penta_table0 x_penta_table0--v" : "x_penta_table0"} ${pentaInner.members.length > 3 && 'x_penta_table0--4'}`}>

            {pentaInner.members.length < 4 && <div className={pentaId && pentaInner.token === pentaId ? "x_penta_table x_penta_table--active" : "x_penta_table"}>
              {pentaInner.members && pentaInner.members[0] && <PentaBody
                penta={pentaInner}
                members={pentaInner.members}
                parentContainer={bodyContainer}
                className='x_penta_mix'
                handleClick={() => handleClick(pentaInner)} />}
              {pentaInner.members && pentaInner.members[0] && <Background
                design={getPentaDesign(pentaInner)} />}


              {!pentaInner.members.length && <>
                <Preview
                aura={true}

                  parentContainer={bodyContainer}
                className='x_penta_mix'
                design={default_design}
                handleClick={() => {
                  handleChoose(pentaInner.token)
                  }} />
              </>}
            </div>}


            {Boolean(pentaInner.members.length < 4) && pentaInner.members.map((one, index) => {

              return (<div
                key={`member_${one.avatar.token}`}
                // style={{ overflow: 'hidden' }}
                className={`x_penta_slot x_penta_slot_q--${pentaInner.members.length} x_penta_slot--${index + 1}`}
              >
                <div
                  className={pentaId && pentaInner.token === pentaId ? "x_click active" : "x_click"}
                  onClick={() => {
                    navigate(`/p/${pentaInner.token}`);
                    context.setState.showPeoplePanel();
                  }} />
                {peoplePanel && <div className="x_penta_slot_x" onClick={() => deleteFromPenta(one.avatar.token)}>
                  <div className="t_icon t_icon--20">close</div>
                </div>}
                <div className="x_penta_body" ref={bodyContainerThumbs[`thumb${index}`]}>
                  <Preview
                    design={one.avatar.design}
                    width={50}
                    height={70}
                  // parentContainer={bodyContainerThumbs[`thumb${index}`]}
                  />
                </div>
              </div>);
            })}

            {Boolean(pentaInner.members.length > 3) && pentaInner.members.map((one, index) => {

              return (<div
                key={`member_${one.avatar.token}`}
                // style={{ overflow: 'hidden' }}
                className={`x_penta_slot x_penta_slot_q--${pentaInner.members.length} ${pentaInner.members.length > 3 && 'x_penta_slot--4'} ${pentaInner.members.length > 4 && 'x_penta_slot--5'}`}
              >
                <div
                  className={pentaId && pentaInner.token === pentaId ? "x_click active" : "x_click"}
                  onClick={() => {
                    navigate(`/p/${pentaInner.token}`);
                    context.setState.showPeoplePanel();
                  }} />
                {/* {peoplePanel && <div className="x_penta_slot_x" onClick={() => deleteFromPenta(one.avatar.token)}>
                  <div className="t_icon t_icon--20">close</div>
                </div>} */}
                <div className="x_penta_body" ref={bodyContainerThumbs[`thumb${index}`]}>
                  <Preview
                    design={one.avatar.design}
                    width={50}
                    height={70}
                  // parentContainer={bodyContainerThumbs[`thumb${index}`]}
                  />
                </div>
              </div>);
            })}




            {pentaInner.members.length === 0 && <>
              <div className="x_penta_slot x_penta_slot--1 x_penta_slot--empty"
                key={'slot1'}
                onClick={() => handleChoose(pentaInner.token)}
              >
                <div className="t_icon t_icon--40">person_add</div>
              </div>
            </>}
            {pentaInner.members.length < 2 && <>
              <div className="x_penta_slot x_penta_slot--2 x_penta_slot--empty"
                key={'slot2'}
                onClick={() => handleChoose(pentaInner.token)}
              >
                <div className="t_icon t_icon--40">person_add</div>
              </div>
            </>}
          </div>


          <div className="x_penta_name">

            <PentaName
              penta={pentaInner}
            ><div><Icon>edit</Icon></div>
            </PentaName>

          </div>

          {context.state.peoplePanel && pentaInner.members.length === 2 && <PentaStats penta={pentaInner} />}



        </div>
      </div>
    </>}
  </>
  );
};
