import React, { Fragment, useEffect, useState, useContext, useRef } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Preloader } from './kit';

export function CreateNew({
  icon = 'search',
  label = 'Find',
  handle = () => void (0),
  loading = false,

}) {

  return (
    <>
      <div className="x_choose_item">
      <div className="x_choose_one0"
      >
        <div className="x_choose_one x_choose_one--v x_choose_one--new" onClick={() => !loading ? handle() : void (0)}
          style={{ cursor: loading ? 'wait' : 'pointer' }}>

          {loading && <>
            <Preloader size={16} />
          </>}


          {!loading && <div className="x_choose_one_action">
            <div className="t_icon t_icon--100">
              {icon}
            </div>
            {label}
          </div>}
        </div>
        <div className="x_choose_user0">
        </div>
        </div>
      </div>
    </>
  )
}