import React, { Fragment, useEffect, useState, useContext, useRef } from 'react';
import { Link, useNavigate, useSearchParams, useParams, useLocation } from 'react-router-dom';


import styled from 'styled-components';
// import { Guide } from './Guide';
// import { usePrevious } from './tools/utils';

// import Soon from './Edu/Soon';
// import Page404 from './Page404';
// import { Preloader, Preloader0 } from './uikit/kit';

import { Preview } from './UiKit/Preview';
import { Background } from './UiKit/Background';
import { StateContext } from '../contexts/stateContext';
import { SocialContext } from '../contexts/socialContext';
import { Full } from './Edu/Full';
import { AvatarPanel } from './UiKit/AvatarPanel';
import { Preloader, Preloader0 } from './UiKit/kit';
import {
  _getDesignArray,
  adjustUTCByOffset,
  convertDateTimeToGMT,
  convertGMTtoDateTime,
  parseNewDesign,
  parseAvatar,
  _getPublicUser,
  USER_TOKEN_LENGTH,
  AVATAR_TOKEN_LENGTH
} from './utilsDesign';
import { Start } from './Start';
import { default_design } from '../static/default_design';
import { Pop } from './UiKit/Pop';
import { FollowList } from './FollowList';
import { LangContext } from '../contexts/langContext';
import { Topbar } from './UiKit/Topbar';
import { Social } from './Social';
import { MeInfo } from './SocialComponents/MeInfo';
import { PersonaInfo } from './SocialComponents/PersonaInfo';
import { MobMenu } from './UiKit/MobMenu';
import { WithRectification } from './WithRectification';
import { VideoPop } from './VideoPop';
import { PublicSocial } from './PublicSocial';
import { MessageContext, MessageProvider } from '../contexts/messageContext';
import Chats from './Chats/Chats';
import { CityDateTime } from './UiKit/CityDateTime';
import { UserInfo } from './SocialComponents/UseerInfo';
import { Study } from './Edu/Study';
import { Settings } from './Settings';
import { UserNetwork } from './SocialComponents/UserNetwork';






export function Design(props) {

  const context = { ...useContext(SocialContext), ...useContext(StateContext) };
  const {
    publ,
    user,
    avatar,
    isMe,
    useravatarId,
    loading
  } = { ...props };


  // const design =
  //   cachedDesign ? cachedDesign
  //   : avatar ? context.x.getDesign(avatar) : null;
  // const design = avatar ? context.x.getDesign(avatar) : null;

  //if array
  // const design = (avatar && avatar.design && avatar.design[0]) ? avatar.design[0] : default_design;

  const design = (avatar && avatar.design && avatar.design['id'] !== 'dummy') ? avatar.design : default_design;


  const [designObject, setDesignObject] = useState(design)

  useEffect(() => {
    // console.log('context.avatar.design = ', context.avatar ? context.avatar.design : null);
  }, [context.avatar])


  // const design = avatar && avatar.design ? avatar.design : null;

  // useEffect(() => {
  //   context.setState.setCalcShown((!avatar || !avatar.dob) ? true : false)
  // }, [avatar])

  useEffect(() => {
    // console.log('design 🧩 = ', design);
  }, [design])





  return (
    <>





      <div className="l_panel">

        {/* {!avatar && <Preloader0 style={{ paddingTop: '4em' }}><Preloader width={80} height={80} /></Preloader0>} */}

        {avatar && <>
          <div className="x_edu0">





            {Boolean(avatar) &&
              <Start
                user={user}
                avatar={avatar}
                publ={publ}
                useravatarId={useravatarId}
                isMe={isMe}
                design={design}
                loading={avatar.token && context.updatingAva === avatar.token}
              >

              </Start>}
          </div>

        </>
        }

      </div>

    </>
  )
}











export const WithDOB = React.memo(({ children }) => {
  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) }
  let { useravatarId } = useParams();
  if (useravatarId === 'me') useravatarId = context.me.user.token;

  const $ui = context.$ui;
  const navigate = useNavigate();
  const location = useLocation();

  const [user, setUser] = useState({});
  const [avatar, setAvatar] = useState({});
  const [followings, setFollowings] = useState([]);
  const [followers, setFollowers] = useState([]);
  const [scrolled, setScrolled] = useState(false);

  const [newUser, setNewUser] = useState();

  // Create a ref to store the current useravatarId
  const currentIdRef = useRef(useravatarId);



  const removeLanguageParam = () => {
    const hash = location.hash;
    const params = new URLSearchParams(hash.slice(1)); // Remove the leading '#'
    params.delete('ln');
    const newHash = params.toString();
    window.history.replaceState(null, '', `${location.pathname}${location.search}${newHash ? `#${newHash}` : ''}`);
  };

  useEffect(() => {
    const hash = location.hash;
    const params = new URLSearchParams(hash.slice(1)); // Remove the leading '#'
    const languageParam = params.get('ln');
    if (languageParam !== context.language && context.languages.includes(languageParam)) {
      context.setLanguage(languageParam)
    }
    // removeLanguageParam()
  }, [location]);



  const scrollable = useRef(null);



  const fetchCallback = (newUser, refetch) => {
    // Check if useravatarId has changed since the request was made
    if (currentIdRef.current !== useravatarId) {
      // If it has changed, abort the callback execution
      // console.log('Aborting fetchCallback due to useravatarId change');
      return;
    }

    if (!newUser) {
      navigate('/404');
      return;
    }


    newUser.avatar = parseAvatar(newUser.avatar)

    setNewUser(newUser)
  };

  useEffect(() => {
    if (newUser && useravatarId === newUser.user.token) {

      updateUser(newUser)

      //TODO: временно новый диз
      // getNewDesign(newUser.avatar.dob, (newDesign) => updateUser({
      //   ...newUser,
      //   avatar: mergeOldNewDesign(newUser.avatar, newDesign)
      // }))
    }
  }, [newUser, useravatarId])




  const mergeOldNewDesign = (oldAva, newDesign) => {

    // const variables = getVariables(newDesign)


    oldAva.design = parseNewDesign(newDesign);

    return oldAva


  }



  const updateUser = (newUser) => {
    setUser(newUser.user);
    let ava = newUser.avatar;
    if (!ava) {
      navigate('../');
      return;
    }
    if (!ava.design || !ava.design['id']) {
      ava.design = default_design;
    }
    // if (!refetch || (refetch === newUser.avatar.token || refetch === newUser.user.token)) {
    setAvatar(ava);
    setFollowings(newUser.followings ? newUser.followings : []);
    // console.log('newUser.followings = ', newUser.followings);
    setFollowers(newUser.followers ? newUser.followers : []);
    // }
  }





  const getUserAvatar = (useravatarId, refetch = false) => {

    // console.log('context.me = ', context.me);
    if (context.me) {
      if (useravatarId.length === USER_TOKEN_LENGTH) {
        //USER
        currentIdRef.current = useravatarId;
        context.x.fetchUser(useravatarId, (newUser) => {
          fetchCallback(newUser);
          context.setState.setShowHistory(newUser)
        }, refetch);

      } else if (useravatarId.length === AVATAR_TOKEN_LENGTH) {
        //AVATAR
        setUser()
        let ava = context.x.getAvatar(useravatarId)
        if (!ava) {
          navigate('../');
          return;
        }
        if (!ava.design['id']) {
          ava.design = default_design;
        } else {
          ava.design = parseNewDesign(ava.design)
        }
        setAvatar(ava)
        context.setState.setShowHistory({
          user: null,
          avatar: ava,
        })
      }
    }



    if (!context.me) {
      if (useravatarId.length === USER_TOKEN_LENGTH) {

        const getUserCallback = (newUser) => {
          setUser(newUser.user)
          newUser.avatar.design = parseNewDesign(newUser.avatar.design)
          setAvatar(newUser.avatar)
        }

        _getPublicUser(useravatarId, getUserCallback)

      } else if (useravatarId.length === AVATAR_TOKEN_LENGTH) {
        console.log('avatar = ', useravatarId);
      }


    }


  };





  useEffect(() => {
    getUserAvatar(useravatarId);
  }, [useravatarId, context.avatars, context.updatingAva, context.followings, context.cachedUsers]);

  useEffect(() => {
    getUserAvatar(useravatarId, true);
  }, [context.me]);


  useEffect(() => {
    if (avatar && avatar.my) {
      context.setState.setCalcShown((!avatar.dob) ? true : false)
    }
  }, [avatar])




  const isStudy = location.pathname.includes('study');
  const isInfo = location.pathname.includes('info');
  const isSettings = location.pathname.includes('settings');
  const isFollowingPage = location.pathname.includes('following');
  const isFollowersPage = location.pathname.includes('followers');



  const world = location.pathname.includes('world');
  const network = location.pathname.includes('network');
  const bodygraphs = location.pathname.includes('bodygraphs');
  const history = location.pathname.includes('history');
  const pentas = location.pathname.includes('pentas');

  //TODO: urls better
  useEffect(() => {
    if (world) context.setState.showPeoplePanel(['world'])
    if (network) context.setState.showPeoplePanel(['people', 'followers'])
    if (bodygraphs) context.setState.showPeoplePanel(['bodygraphs'])
    if (history) context.setState.showPeoplePanel(['history'])
    if (pentas) context.setState.showPeoplePanel(['pentas'])
  }, [world, network, bodygraphs, history, pentas])




  return (<>



      {context.me && <MobMenu avatar={avatar} />}


      {!avatar.design && (
        <>
          <Preloader0 style={{ zIndex: 100, position: 'absolute' }}>
            <Preloader width={80} height={80} /> {$ui('loading')}...
          </Preloader0>
        </>
      )}


      <VideoPop />

      {avatar && avatar.design && (
        <>



            <div className={!context.state.peoplePanel ? "x_layout" : "x_layout x_layout--people"}
              // onClick={() => handleCloseInfoPop()}
            >

            <Topbar
              user={user}
              avatar={avatar}
              mode2={scrolled}
            />

            {isInfo
              && !user
              &&
              <Pop
                // title={$ui('pop_edit_avatar_header')}
                // icon='person_add'
                handleClose={() => navigate('../')}
              >

                <PersonaInfo
                  user={user ? user : null}
                  avatar={avatar}
                  handleClose={() => navigate('../')}
                />

              </Pop>}

          {isSettings
            && user.isMe
            && <Pop
              title={$ui('settings')}
              // icon='person_add'
              handleClose={() => navigate('../')}
            >
              <Settings
                user={user ? user : null}
                avatar={avatar}
                handleClose={() => navigate('../')}
              />
            </Pop>}


          {isInfo
            && user
            && user.isMe
            && <Pop
                // title={$ui('pop_edit_me_header')}
                // icon='person_add'
                handleClose={() => navigate('../')}
              >

                <MeInfo
                  user={context.me.user}
                  title={$ui('pop_edit_me_header')}
                  avatar={avatar}
                  handleClose={() => navigate('../')}
                />

            </Pop>}

          {isInfo
            && user
            && !user.isMe
            && <Pop
                // title={$ui('pop_edit_me_header')}
                // icon='person_add'
                handleClose={() => navigate('../')}
              >

                  <UserInfo
                    title={$ui('pop_edit_avatar_header')}
                  user={user ? user : null}
                  avatar={avatar}
                  handleClose={() => navigate('../')}
                />

              </Pop>}

              {avatar && <Study
                user={user ? user : null}
                avatar={avatar}
                handleClose={() => navigate('../')}
              />}



          {/* {isFollowersPage && <Pop
            title={$ui('people_people')}
            icon='hub'
              handleClose={() => navigate('../')}
              type="followers"
            >
            <UserNetwork
              useravatarId={useravatarId}
              user={user}
              />
            </Pop>} */}

            {isFollowingPage && <Pop
            title={$ui('people_people')}
            icon='hub'
              handleClose={() => navigate('../')}
              type="followers"
            >
            <UserNetwork
              useravatarId={useravatarId}
              user={user}
              />
            </Pop>}




            {/* {typeof context.cachedDesigns !== 'object' && <Preloader0 style={{ paddingTop: '4em' }}><Preloader width={80} height={80} /></Preloader0>} */}

            <>



              {context.me && <Social />}
              {!context.me && <PublicSocial />}


              <div className={!context.state.peoplePanel ? "x_edu_block" : "x_edu_block x_edu_block--open"}>
                <div className="x_edu_c">
                  <div className="l_panel">
                      <div className={`x_edu0 ${isStudy ? 'x_edu0--info' : ''}`}
                        ref={scrollable}
                      // onClick={() => handleCloseInfoPop()}
                      >





                    <Start
                      user={user}
                      avatar={avatar}
                      followings={followings}
                      followers={followers}
                      loading={context.updatingAva === avatar.token}
                      refetch={(id) => getUserAvatar(id, true)}
                    >
                        {/* <Background style={{ zIndex: -1 }} design={avatar.design}></Background> */}
                      </Start>




                      <WithRectification avatar={avatar}>
                        <Background
                          style={{ zIndex: -1 }}
                          aura={false}
                          design={avatar.design}
                        ></Background>
                      </WithRectification>



                    </div>
                  </div>
                </div>
              </div>
            </>





          </div>

          <WithRectification avatar={avatar}>
            <Background style={{ zIndex: -1 }} aura={false} design={avatar.design} />
          </WithRectification>



        </>
      )}

      </>


  );
});



