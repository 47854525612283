import React, { Fragment, useEffect, useState, useContext, useRef } from 'react'
import {
  drawProfile,
  drawType,
  drawAuthority,
  getDesignCenters,
  prepareChannels,
  findGateInUser
} from '../utilsDesign';
import { StateContext } from '../../contexts/stateContext';
import { SocialContext } from '../../contexts/socialContext';
import { LangContext } from '../../contexts/langContext';
import { default_design } from '../../static/default_design';
import { Channel } from '../UiKit/Channel';
import { Avatar } from '../UiKit/Avatar';
import { Txt } from '../UiKit/Txt';
import { AvatarName } from '../UiKit/AvatarName';
import { PentaChannelOwner } from '../Penta/PentaChannelOwner';
import { getDomChannels, getPentaDesign, getEmChannels, getSameChannels } from '../utilsPenta';

export const PentaChannels = React.memo((props) => {

  const {
    penta,
    view = ['em', 'same', 'dom', 'individual']
  } = { ...props };

  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ = context.$;
  const $ui = context.$ui;
  const $type = context.$type;
  const $profile = context.$profile;

  const design1 = penta.members[0] ? penta.members[0].avatar.design : default_design;
  const design2 = penta.members[1] ? penta.members[1].avatar.design : default_design;

  const profileId1 = design1.profile.length ? `${design1['profile'][0]}${design1['profile'][1]}` : '';
  const profileId2 = design2.profile.length ? `${design2['profile'][0]}${design2['profile'][1]}` : '';

  const pentaDesign = {
    ...getPentaDesign(penta),
    // 'gates': prepareChannels(getPentaDesign(penta).gates, true)
  };




  return (
    <>





      {view.includes('em') && <div className='x_edu_content_inner'>
            <div className="x_edu_content_title">
              <div className='x_edu_h2'>{$ui('penta_em_channels')}
                <div className="x_choose_menu_counter">
                  {getEmChannels(penta).length}
                </div>

              </div>
            <div className="x_edu_content_desc">
                <Txt>{$ui('penta_em_channels', 'rich')}</Txt>
            </div>

            </div>
            <div className="x_edu_channesl_inline" key='1'>

              {!getEmChannels(penta).length && <div className="x_edu_channel_no">
                <div className="x_edu_channel_h1">{$ui("no_channels")}</div>
              </div>}

              {Boolean(getEmChannels(penta).length) && getEmChannels(penta)
                .map(oneChannel =>
                  <Channel
                    penta={penta}
                    className='x_edu_chan--33'
                    key={oneChannel.id}
                    uniq={oneChannel.id}
                    // gates={oneChannel.gates}
                    // design={pentaDesign}
                    {...oneChannel}
                  />
              )}

            </div>
          </div>}

      {view.includes('same') && <div className='x_edu_content_inner'>
            <div className="x_edu_content_title">
              <div className='x_edu_h2'>{$ui('penta_same_channels')}
                <div className="x_choose_menu_counter">
                  {getSameChannels(penta).length}
                </div>

              </div>
              <div className="x_edu_content_desc">
                <Txt>{$ui('penta_same_channels', 'rich')}</Txt>
              </div>
            </div>

            <div className="x_edu_channesl_inline">

              {!getSameChannels(penta).length && <div className="x_edu_channel_no">
                <div className="x_edu_channel_h1">{$ui("no_channels")}</div>
              </div>}

              {Boolean(getSameChannels(penta).length) && getSameChannels(penta)
                .map(oneChannel =>
                  <Channel
                    penta={penta}
                    className='x_edu_chan--33'
                    key={oneChannel.id}
                    uniq={oneChannel.id}
                    // gates={oneChannel.gates}
                    // design={pentaDesign}
                    {...oneChannel}
                  />
              )}
            </div>
          </div>}

      {view.includes('dom') && <div className='x_edu_content_inner'>
            <div className="x_edu_content_title">
              <div className='x_edu_h2'>{$ui('penta_dom_channels')}
                <div className="x_choose_menu_counter">
                  {getDomChannels(penta).length}
                </div>

              </div>
              <div className="x_edu_content_desc">
                <Txt>{$ui('penta_dom_channels', 'rich')}</Txt>
              </div>
            </div>
            <div className="x_edu_channesl_inline" key='1'>

              {!getDomChannels(penta).length
                && <div className="x_edu_channel_no">
                  <div className="x_edu_channel_h1">{$ui("no_channels")}</div>
                </div>}

              {Boolean(getDomChannels(penta).length) && getDomChannels(penta)
                .map(oneChannel =>
                  <Channel
                    key={`dom_${oneChannel.id}`}
                    uniq={oneChannel.id}
                    // gates={oneChannel.gates}
                    // design={pentaDesign}
                    penta={penta}
                    {...oneChannel}
                    className='x_edu_chan--33'
                  />


                )}
            </div>




          </div>}

      {view.includes('individual') && <div className='x_edu_content_inner'>
            <div className="x_edu_activations_table x_edu_activations_table--penta">

            {penta.members.map((oneMember, ind) => {
              const memberId = oneMember.avatar.token;
              const design = oneMember.avatar.design;
              return (
                <div
                  className={`x_edu_activations_column x_edu_activations_column--penta x_edu_activations_column--penta${ind + 1}`}
                  key={oneMember.avatar.token}>
                  <div className="x_edu_channels">
                    <div
                      className='x_edu_h2 x_edu_h2--sticky'>
                      {/* <div>{$ui('penta_channels_of')}</div> */}
                      <div className='x_ava_inline0'>
                        <span className='x_ava_inline' style={{ display: 'inline-flex' }}><Avatar style={{ fontSize: '1em' }} avatar={oneMember.avatar} /></span>
                        <span
                          className={`x_ava_inline_name ellipsis x_ava_inline_name--${oneMember.avatar.design.type.toLowerCase()}`}
                          style={{ width: '100%' }}
                        >
                          <AvatarName avatar={oneMember.avatar} />
                        </span></div></div>
                    {pentaDesign.channels
                      .filter(oneChannel => Object.keys(oneChannel.activatedBy).includes(memberId))
                      .map(oneChannel => <Channel
                        key={`chof_${oneMember.avatar.token}_${oneChannel.id}`}
                        uniq={oneChannel.id}
                        gates={oneChannel.gates}
                        design={design}
                        {...oneChannel}
                      />)}


                    {!pentaDesign.channels
                      .filter(oneChannel => Object.keys(oneChannel.activatedBy).includes(memberId)).length && <div className="x_edu_channel_no"><div className="x_edu_channel_h1">{$ui("no_channels_0")}</div></div>}

                  </div>
                </div>
              )
            })}

        </div>

          </div>}









    </>
  )
})




