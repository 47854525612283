import React from 'react'
import { Svg } from './Svg';
import { numbers_dictionary } from '../../static/numbers_dictionary';
import { channels_dictionary } from '../../static/channels_dictionary';
import { gates_paths, gates_paths_thin } from '../../static/gates_svg';
import { getDesignCenters, defineAuthority } from '../utilsDesign';
import { default_design } from '../../static/default_design';
import { bwColor } from './Bodygraph';



const highlightedGateColor = 'lime'



export const Thumb = React.memo(({
  body = true,
  children,
  designComposite = {},
  centers = ['HD', 'AA', 'TT', 'HT', 'GC', 'SN', 'SL', 'SP', 'RT'],
  activePage,
  design = default_design,
  width,
  height,
  lines = true,
  highlighted,
  authority,
  numbers,
  dim,
  planetsPrs,
  planetsDes,
  // size,
  interactive,
  blur,
  arrowsP,
  arrowsB,
  preloader,
  composite,
  whiteLines = true,
  style,
  advanced,
  colorTheme = {},
  type = 'og'
}) => {

  const settings = {
    og: {
      logo: true,
      aura: true,
      width: 1200,
      height: 630,
      size: 0.8,
      sizeBg: 0.9,
      translate: 360,
      translateBgX: -90,
      translateBgY: -40,
      arrowsSize: 1,
    },
    thumbnail: {
      logo: false,
      aura: true,
      width: 280,
      height: 280,
      size: 0.35,
      sizeBg: 0.4,
      translate: 35,
      translateBgX: 0,
      translateBgY: 0,
      profile: true,
      arrowsSize: 2,
    },
  }

  const svg = getSvgCode(design, type)



  return (
    <>




      <Svg
        forceHeight={settings[type].height}
        width={settings[type].width}
        height={settings[type].height}
      >
        {svg}
      </Svg>





    </>
  )
})








export function getSvgCode(
  design,
  type = 'thumbnail',
  designComposite = {},
  centers = ['HD', 'AA', 'TT', 'HT', 'GC', 'SN', 'SL', 'SP', 'RT'],
  width,
  height,
  lines = true,
  highlighted,
  authority,
  numbers,
  dim,
  planetsPrs,
  planetsDes,
  // size,
  interactive,
  blur,
  arrowsP,
  arrowsB,
  preloader,
  composite,
  whiteLines = true,
  style,
  advanced,
  highlightedGate = [],
  colorTheme = {},
) {


  const settings = {
    og: {
      logo: true,
      aura: true,
      width: 1200,
      height: 630,
      size: 0.8,
      sizeBg: 0.9,
      translate: 360,
      translateBgX: -90,
      translateBgY: -40,
      arrowsSize: 1,
    },
    thumbnail: {
      logo: false,
      aura: true,
      width: 280,
      height: 280,
      size: 0.35,
      sizeBg: 0.348,
      translate: 35,
      translateBgX: 0,
      translateBgY: 0,
      profile: true,
      arrowsSize: 2,
    },
  }


  design = design ? design : default_design;


  const theme = {
    highlightColor: 'blue',
    highlightDimmed: '#c0c0c0',
    openColor: '#FFFFFF',
    authorityColor: 'black',
    definedColor: bwColor,
    linePersonality: '#000000',
    lineDesign: '#f45656',
    linePersonalityC1: '#8300E9',
    lineOpen: '#FFF',
    lineDesignC1: '#C173FE',
    lineDesignC2: '#00C394',
    linePersonalityC2: '#69FF6F',
    linePersonalityoth: 'blue',
    line0: '#FFF',
    line0comp: 'transparent',
    ...colorTheme

  }

  const colors = {
    base: {
      prs: theme.linePersonality,
      des: theme.lineDesign,
      open: theme.lineOpen,
      chakraOpen: theme.openColor,
      chakraDefined: theme.definedColor,
    },
    composite1: {
      prs: theme.linePersonalityC1,
      des: theme.lineDesignC1,
      open: theme.openColor,
      chakraOpen: theme.openColor,
      chakraDefined: theme.definedColor,
    },
    composite2: {
      prs: theme.linePersonalityC2,
      des: theme.lineDesignC2,
      open: theme.openColor,
      chakraOpen: theme.openColor,
      chakraDefined: theme.definedColor,
    },
  }

  const chakra = (ch, definedCenters) => {
    // return design['active_chakras'] ? design['active_chakras'].indexOf(ch) > -1 : false
    return definedCenters ? definedCenters.indexOf(ch) > -1 : false
  }


  const bodyType = !composite ? 'base' : composite === 1 ? 'composite1' : 'composite2';

  const openChakra = colors[bodyType]['chakraOpen'];
  const definedChakra = colors[bodyType]['chakraDefined'];

  const centerColor = (centerName, definedCenters = getDesignCenters(design['gates'], designComposite['gates'])) => {
    // let clr = chakra(centerName) ? dim || highlighted ? highlightDimmed : centersI[centerName]['color'][theme] : openColor;
    // let clr = chakra(centerName, definedCenters) ? dim || highlighted ? colors.highlightDimmed : definedChakra : openChakra;
    let clr = chakra(centerName, definedCenters) ? definedChakra : openChakra;
    if (!design.active_chakras.length) clr = openChakra;
    // clr = highlighted === centerName && width < 100 ? colors.highlightColor : clr;
    if (authority && defineAuthority(definedCenters) === centerName) {
      clr = colors.authorityColor
    };
    return clr;
  }





  const personGates = (returnArray, highlighted) => {
    let personGatesObject = {}
    // console.log('person = ', design);
    if (!design) return;
    if (design['gates']) design['gates'].forEach((one) => {
      if (!personGatesObject[one['gate'].toString()]) {
        personGatesObject[one['gate'].toString()] = [{
          ...one
        }]
      } else {
        personGatesObject[one['gate'].toString()].push({
          ...one
        })
      }

    })

    // const findGatesofCenter = (center) => {
    //   let ret = [];
    //   let foundChannels = channel_dictionary.filter(oneClannel => oneClannel['centers'].includes(center))
    //   if (foundChannels.length) {
    //     foundChannels.forEach(oneChannel => ret.push(...oneChannel['gates']))
    //   }
    //   console.log('ret = ', ret);
    //   return ret;
    // }
    const ifGateIsActivated = (gate) => {
      gate = gate.toString()
      let activated = Object.keys(personGatesObject).filter(oneGate => oneGate === gate.toString()).length
      // console.log('gate ', gate, activated ? 'is activated' : 'is NOT activated');

      return activated
    }

    if (highlighted && returnArray) {
      let ret = []
      Object.keys(personGatesObject).forEach(gateNumber => {
        // console.log('gateNumber = ', gateNumber);
        gateNumber = parseInt(gateNumber)
        let r = false;
        let oneGate = personGatesObject[gateNumber][0];
        // console.log('channels_dictionary = ', channels_dictionary);
        channels_dictionary.forEach(oneChannel => {
          let index = oneChannel['gates'].findIndex((gate) => gate === gateNumber)
          // let connected = oneChannel['centers'][index] === highlighted;

          let connected = false;
          connected = oneChannel['centers'][index] === highlighted ? true : connected;
          if (index > -1) {
            let otherGate = index === 0 ? oneChannel['gates'][1] : oneChannel['gates'][0];
            let orherGateActivated = ifGateIsActivated(otherGate)
            connected = orherGateActivated && oneChannel['centers'].includes(highlighted) ? true : connected;
          }


          // console.log('oneChannel[centers] = ', oneChannel['centers']);
          // console.log('highlighted = ', highlighted);
          r = connected ? true : r;
        })
        if (r) { returnArray ? ret.push(gateNumber) : ret.push(oneGate) }
      });
      // console.log('ret = ', ret);
      return ret
    }

    return returnArray ? Object.keys(personGatesObject) : personGatesObject;
  }


  const gateColor = (gate, secondary) => {
    // return 'black'
    let returnColor = colors[bodyType]['open'];
    if (personGates()[gate]) {
      let clrs = []
      personGates()[gate].map((oneActivation) => {
        clrs.push(oneActivation['label'])
      })
      clrs = [...new Set(clrs)];
      if ([...new Set(clrs)].length > 1) {
        return !secondary ? colors[bodyType][clrs[0]] : colors[bodyType][clrs[1]]
      } else {
        return colors[bodyType][clrs[0]];
      }

    } else {
      return returnColor;
    }
  }


  const drawGates = (highlighted, highlightedGate = []) => {

    const gates64 = [];
    for (let i = 1; i <= 64; i++) { gates64.push(i.toString()); }

    let emptyGates = highlighted ? [] : gates64.filter(value => !personGates(true).includes(value));


    let gates_svg = '';
    emptyGates.forEach(oneGateNum => {
      let gatePath = gates_paths[oneGateNum];
      if (highlightedGate.includes(oneGateNum)) {
        gatePath = gatePath.replace('strokeWidth', '3');
        gatePath = gatePath.replace('strokeColor', highlightedGateColor);
      }
      gates_svg += gatePath + '\n'
      // gates_svg += gates_paths_thin[oneGateNum] + '\n'
    })


    gates_svg = whiteLines ? gates_svg.replaceAll('gateColor', colors[bodyType]['open']) : gates_svg.replaceAll('gateColor', 'transparent');
    // gates_svg = gates_svg.replaceAll('gateWidth', 8);



    // console.log('personGates(true, highlighted) = ', personGates(true, highlighted));
    personGates(true, highlighted).forEach(oneGateNum => {
      let gatePath = gates_paths[oneGateNum];
      // let gateC = gateColor(personGates()[oneGateNum][0]['label']);
      let gateC = gateColor(oneGateNum);

      gatePath = gatePath.replace('gateColor', gateC);
      gatePath = gatePath.replaceAll('gateWidth', 8);

      if (highlightedGate.includes(oneGateNum)) {
        gatePath = gatePath.replace('strokeWidth', '3');
        gatePath = gatePath.replace('strokeColor', highlightedGateColor);
      }

      gates_svg += gatePath + '\n';

      if (personGates()[oneGateNum].length > 1) {
        let gateCthin = gateColor(oneGateNum, 2);
        let gatePathThin = gates_paths_thin[oneGateNum];
        // console.log('gatePathThin = ', gatePathThin);
        gatePathThin = gatePathThin.replace('gateColor', gateCthin);
        gates_svg += gatePathThin + '\n';
      }

    })



    // console.log('gates_svg = ', gates_svg);
    // return ggg;
    return gates_svg;
  }


  const strokeColor = (centerName) => {
    // let clr = chakra(centerName) ? dim ? highlightDimmed : centersI[centerName]['color'][theme] : openColor
    let clr = 'transparent';
    // let clr = 'blue';
    return highlighted === centerName ? colors.highlightColor : clr;
  }


  const body_contour = `<path d="M184.403 288.337C160.903 294 152.885 305.5 135.903 332.5C118.92 359.5 69.8444 450.734 46.4025 537C33.9025 583 28.6168 624.5 52.9025 658.5C105.281 731.83 184.173 776.547 276.083 776.547C324.051 776.547 295.886 777.567 330.587 776.547C419.772 773.924 504.347 725.762 550.903 648.5C567.523 620.918 565.153 578 555.902 541C532.277 446.5 470.403 340.5 452.426 318.785C441.856 306.016 431.903 293.5 413.903 288.337C389.595 281.365 355.978 276.603 351.386 238.341C347.369 204.875 361.592 198.038 374.349 168.958C387.107 139.879 388.791 110.799 371.798 83.2501C354.805 55.7011 322.298 47.5384 295.252 47.0283C268.206 46.5181 253.073 52.855 236.567 71.0061C221.258 87.8417 216.665 119.472 218.196 124.064C219.727 128.655 222.789 130.186 224.32 136.308C225.596 141.409 214.624 158.755 212.073 164.877C209.521 170.999 212.583 173.04 218.196 176.101C221.882 178.11 222.789 182.223 222.789 190.895C222.789 199.568 221.769 199.058 222.789 211.302C223.81 223.546 232.717 225.579 243.712 222.526C252.897 219.975 255.959 224.566 255.959 233.239C255.959 241.912 252.897 254.156 232.995 270.992C218.629 283.144 204.888 283.401 184.403 288.337Z"
  stroke="black" stroke-opacity="0.1"
  />`

  // fill='white'
  // style="mix-blend-mode:multiply"



  const lines_svg = `
    ${drawGates(highlighted, highlightedGate)}

  `


  const logoSvg = `<path d="M172.81 38.5502C170.535 38.5502 168.571 38.1017 166.919 37.2047C165.276 36.2982 164.01 35.0094 163.123 33.3381C162.245 31.6574 161.806 29.6604 161.806 27.3471C161.806 25.0999 162.249 23.1359 163.137 21.4552C164.025 19.7651 165.276 18.4526 166.89 17.5179C168.505 16.5737 170.407 16.1016 172.598 16.1016C174.146 16.1016 175.563 16.3423 176.847 16.8239C178.131 17.3054 179.24 18.0183 180.175 18.9625C181.11 19.9067 181.837 21.0728 182.356 22.4608C182.876 23.8394 183.135 25.4209 183.135 27.2055V28.9334H164.228V24.911H176.691C176.682 24.1746 176.507 23.5183 176.167 22.9424C175.827 22.3664 175.36 21.9179 174.765 21.5969C174.18 21.2664 173.504 21.1012 172.74 21.1012C171.965 21.1012 171.271 21.2758 170.658 21.6252C170.044 21.9651 169.558 22.4325 169.199 23.0273C168.84 23.6128 168.651 24.2784 168.632 25.0243V29.1175C168.632 30.0051 168.807 30.784 169.156 31.4544C169.506 32.1154 170.001 32.63 170.643 32.9982C171.286 33.3664 172.05 33.5506 172.938 33.5506C173.552 33.5506 174.109 33.4656 174.609 33.2956C175.11 33.1257 175.539 32.8755 175.898 32.545C176.257 32.2145 176.526 31.8085 176.705 31.3269L183.065 31.5111C182.8 32.9368 182.219 34.1785 181.322 35.236C180.435 36.284 179.269 37.1008 177.824 37.6862C176.38 38.2622 174.708 38.5502 172.81 38.5502Z" fill="black"/>
 <path d="M160.01 16.3885V21.4873H146.017V16.3885H160.01ZM148.935 38.1431V15.2838C148.935 13.6031 149.251 12.2104 149.884 11.1057C150.516 9.99152 151.394 9.16061 152.518 8.61297C153.642 8.05589 154.94 7.77734 156.413 7.77734C157.376 7.77734 158.282 7.85288 159.132 8.00396C159.991 8.14559 160.629 8.27306 161.044 8.38636L159.939 13.4568C159.685 13.3718 159.373 13.301 159.005 13.2443C158.646 13.1877 158.306 13.1593 157.985 13.1593C157.173 13.1593 156.616 13.3387 156.314 13.6975C156.012 14.0563 155.86 14.5473 155.86 15.1705V38.1431H148.935Z" fill="black"/>
 <path d="M130.41 38.5077C129.022 38.5077 127.79 38.2763 126.713 37.8137C125.646 37.3416 124.801 36.6334 124.178 35.6892C123.564 34.7355 123.257 33.5411 123.257 32.1059C123.257 30.8973 123.47 29.8776 123.895 29.0467C124.32 28.2158 124.905 27.5407 125.651 27.0213C126.397 26.502 127.256 26.1102 128.229 25.8458C129.201 25.572 130.24 25.3879 131.344 25.2934C132.581 25.1801 133.578 25.0621 134.333 24.9394C135.088 24.8072 135.636 24.6231 135.976 24.387C136.325 24.1415 136.5 23.7969 136.5 23.3531V23.2823C136.5 22.5552 136.25 21.9934 135.749 21.5969C135.249 21.2003 134.574 21.002 133.724 21.002C132.808 21.002 132.072 21.2003 131.514 21.5969C130.957 21.9934 130.603 22.5411 130.452 23.2398L124.065 23.0132C124.253 21.6913 124.74 20.511 125.523 19.4724C126.317 18.4243 127.402 17.6029 128.781 17.008C130.169 16.4037 131.835 16.1016 133.781 16.1016C135.169 16.1016 136.448 16.2668 137.619 16.5973C138.79 16.9183 139.809 17.3904 140.678 18.0136C141.547 18.6273 142.217 19.3827 142.689 20.2797C143.171 21.1767 143.411 22.2012 143.411 23.3531V38.1394H136.896V35.1085H136.726C136.339 35.845 135.844 36.4682 135.239 36.978C134.644 37.4879 133.941 37.8703 133.129 38.1253C132.326 38.3802 131.42 38.5077 130.41 38.5077ZM132.548 33.9755C133.294 33.9755 133.965 33.8244 134.56 33.5222C135.164 33.2201 135.645 32.8046 136.004 32.2759C136.363 31.7377 136.542 31.1145 136.542 30.4063V28.3385C136.344 28.4424 136.103 28.5368 135.82 28.6218C135.546 28.7068 135.244 28.787 134.914 28.8626C134.583 28.9381 134.243 29.0042 133.894 29.0608C133.544 29.1175 133.209 29.1694 132.888 29.2166C132.237 29.3205 131.68 29.481 131.217 29.6982C130.764 29.9154 130.414 30.1986 130.169 30.548C129.933 30.8879 129.815 31.2939 129.815 31.766C129.815 32.4836 130.07 33.0312 130.58 33.4089C131.099 33.7866 131.755 33.9755 132.548 33.9755Z" fill="black"/>
 <path d="M120.265 19.6439H113.183C113.089 18.9169 112.895 18.2606 112.603 17.6752C112.31 17.0898 111.923 16.5894 111.441 16.1739C110.96 15.7585 110.388 15.4422 109.728 15.225C109.076 14.9984 108.354 14.8851 107.561 14.8851C106.154 14.8851 104.94 15.2297 103.921 15.919C102.91 16.6083 102.131 17.6044 101.584 18.9074C101.046 20.2104 100.776 21.7873 100.776 23.6379C100.776 25.5641 101.05 27.1787 101.598 28.4817C102.155 29.7753 102.934 30.7525 103.935 31.4135C104.945 32.065 106.14 32.3907 107.518 32.3907C108.292 32.3907 108.996 32.2916 109.628 32.0933C110.27 31.895 110.832 31.607 111.314 31.2294C111.805 30.8422 112.206 30.3749 112.518 29.8272C112.839 29.2701 113.061 28.6422 113.183 27.9435L120.265 27.986C120.142 29.2701 119.769 30.5354 119.146 31.7817C118.532 33.0281 117.687 34.1659 116.611 35.195C115.534 36.2148 114.222 37.0268 112.673 37.6311C111.134 38.2354 109.369 38.5375 107.376 38.5375C104.752 38.5375 102.4 37.9616 100.323 36.8096C98.2554 35.6483 96.6219 33.9581 95.4227 31.7392C94.2236 29.5203 93.624 26.8199 93.624 23.6379C93.624 20.4465 94.233 17.7413 95.4511 15.5224C96.6691 13.3035 98.3167 11.6181 100.394 10.4662C102.471 9.31425 104.799 8.73828 107.376 8.73828C109.133 8.73828 110.757 8.98378 112.249 9.47477C113.74 9.95631 115.053 10.6645 116.186 11.5992C117.319 12.5246 118.24 13.6623 118.948 15.0126C119.656 16.3628 120.095 17.9066 120.265 19.6439Z" fill="black"/>
 <path d="M40.6317 38.1428H29.9102V9.13672H40.6175C43.5729 9.13672 46.1175 9.71741 48.2514 10.8788C50.3948 12.0307 52.0472 13.6925 53.2085 15.8642C54.3699 18.0265 54.9506 20.6136 54.9506 23.6256C54.9506 26.6471 54.3699 29.2437 53.2085 31.4154C52.0566 33.587 50.409 35.2536 48.2656 36.4149C46.1222 37.5669 43.5776 38.1428 40.6317 38.1428ZM36.9209 32.166H40.3626C41.9866 32.166 43.3604 31.8922 44.484 31.3445C45.6171 30.7875 46.4716 29.8857 47.0476 28.6394C47.633 27.3836 47.9257 25.7123 47.9257 23.6256C47.9257 21.5389 47.633 19.8771 47.0476 18.6402C46.4622 17.3938 45.5982 16.4968 44.4557 15.9492C43.3227 15.3921 41.9252 15.1136 40.2634 15.1136H36.9209V32.166Z" fill="black"/>
 <path d="M0.0756836 38.1428V9.13672H7.08644V20.7788H18.5161V9.13672H25.5127V38.1428H18.5161V26.4866H7.08644V38.1428H0.0756836Z" fill="black"/>
 <path d="M75.5954 6.57031C52.033 10.2424 55.9011 41.7611 76.4034 40.078C92.6651 38.7431 91.3979 18.0356 80.8404 15.5975C72.4666 13.6637 67.1803 18.5046 67.1803 24.6247C67.1803 28.9088 69.6285 32.4279 74.9836 32.4279C78.8092 32.4279 81.1037 30.2858 81.1037 26.9197C81.1037 21.4116 74.3716 21.7176 74.3716 24.6247" stroke="#005FDD" stroke-width="3.97808" stroke-linecap="round"/>`




  const renderCenter = (center) => {
    if (center === 'HD') {
      return `<g>
        <path d="M287.467 34.435C294.887 27.0151 304.918 27.0151 312.338 34.435L351.936 97.033C357.371 107 351.936 117.903 339.935 117.903C326.371 117.903 272.871 117.903 258.869 117.903C244.868 117.903 242.871 104.5 247.869 97.033L287.467 34.435Z"
        fill="${centerColor('HD')}"
        stroke="${strokeColor('HD')}"
        stroke-width="5"
        />
      </g>`
    }
    if (center === 'AA') {
      return `<g>
      <path d="M311.338 221.483C303.918 228.903 293.887 228.903 286.467 221.483L246.869 158.885C241.434 148.918 246.869 138.015 258.87 138.015C272.434 138.015 325.934 138.015 339.935 138.015C353.937 138.015 355.934 151.418 350.936 158.885L311.338 221.483Z"
      fill="${centerColor('AA')}"
      stroke="${strokeColor('AA')}"
      stroke-width="5"
      />
    </g>`
    }
    if (center === 'TT') {
      return `<g>
      <rect x="251.902" y="257" width="94" height="94" rx="19"
      fill="${centerColor('TT')}"
      stroke="${strokeColor('TT')}"
      stroke-width="5"
      />
    </g>`
    }
    if (center === 'GC') {
      return `<g>
      <rect x="232.902" y="432" width="94" height="94" rx="19" transform="rotate(-45 232.902 432)"
      fill="${centerColor('GC')}"
      stroke="${strokeColor('GC')}"
      stroke-width="5"
      />
    </g>`
    }
    if (center === 'HT') {
      return `<g>
      <path d="M402.68 458.579C409.415 453.651 416.376 454.979 421.42 461.872L446.721 513.981C449.9 522.641 444.344 530.606 434.82 529.017C424.053 527.221 381.588 520.137 370.475 518.283C359.362 516.429 359.302 505.488 364.121 500.202L402.68 458.579Z"
      fill="${centerColor('HT')}"
      stroke="${strokeColor('HT')}"
      stroke-width="5"
      />
    </g>`
    }
    if (center === 'SP') {
      return `<g>
      <path d="M434.338 603.903C426.918 596.483 426.918 586.453 434.338 579.033L496.936 539.435C506.902 534 517.806 539.435 517.806 551.435C517.806 565 517.806 618.5 517.806 632.501C517.806 646.502 504.402 648.5 496.936 643.501L434.338 603.903Z"
      fill="${centerColor('SP')}"
      stroke="${strokeColor('SP')}"
      stroke-width="5"
      />
      </g>`
    }
    if (center === 'SN') {
      return `<g>
      <path d="M163.483 603.903C170.903 596.483 170.903 586.453 163.483 579.033L100.885 539.435C90.9182 534 80.0152 539.435 80.0152 551.435C80.0152 565 80.0152 618.5 80.0152 632.501C80.0152 646.502 93.4182 648.5 100.885 643.501L163.483 603.903Z"
      fill="${centerColor('SN')}"
      stroke="${strokeColor('SN')}"
      stroke-width="5"
      />
</g>`
    }
    if (center === 'SL') {
      return `<g>
      <rect x="251.902" y="553" width="94" height="94" rx="19"
      fill="${centerColor('SL')}"
      stroke="${strokeColor('SL')}"
      stroke-width="5"
      />
</g>`
    }
    if (center === 'RT') {
      return `<g>
      <rect x="251.902" y="675" width="94" height="94" rx="19"
      fill="${centerColor('RT')}"
      stroke="${strokeColor('RT')}"
      stroke-width="5"
      />
</g>`
    }
  }





  const bg_g = `<g clip-path="url(#clip0_2204_141133)">
  <rect width="1524" height="800" fill="#FCF0E6"/>
  <g opacity="0.4">
  <rect x="285" width="954" height="800" fill="url(#paint0_radial_2204_141133)"/>
  </g>
  </g>
  <defs>
  <radialGradient id="paint0_radial_2204_141133" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(762 582.857) rotate(90) scale(472.381 563.314)">
  <stop stop-color="#FF6B00"/>
  <stop offset="0.180286" stop-color="#FFB627"/>
  <stop offset="0.604167" stop-color="#FFE9BE"/>
  <stop offset="0.723958" stop-color="#FEE8D4"/>
  <stop offset="1" stop-color="white" stop-opacity="0"/>
  </radialGradient>
  <clipPath id="clip0_2204_141133">
  <rect width="1524" height="800" fill="white"/>
  </clipPath>
  </defs>`

  const bg_p = `<g clip-path="url(#clip0_2204_141134)">
  <rect width="1524" height="800" fill="#FCFDFF"/>
  <g opacity="0.4">
  <rect x="1361" y="800" width="1150" height="800" transform="rotate(180 1361 800)" fill="url(#paint0_linear_2204_141134)"/>
  <rect x="1361" y="800" width="1150" height="800" transform="rotate(180 1361 800)" fill="url(#paint1_radial_2204_141134)"/>
  </g>
  </g>
  <defs>
  <linearGradient id="paint0_linear_2204_141134" x1="1908.69" y1="1627.5" x2="1921.09" y2="1071.84" gradientUnits="userSpaceOnUse">
  <stop offset="0.0520529" stop-color="#92EBFF"/>
  <stop offset="0.421875" stop-color="#C2FFFB"/>
  <stop offset="0.463542" stop-color="#DFF9FF"/>
  <stop offset="0.764663" stop-color="#E6FBFF"/>
  <stop offset="0.92651" stop-color="#A7EFFF"/>
  </linearGradient>
  <radialGradient id="paint1_radial_2204_141134" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(1962 1187) rotate(88.0273) scale(450.267 512.474)">
  <stop offset="0.503404" stop-color="white" stop-opacity="0"/>
  <stop offset="0.990197" stop-color="white"/>
  </radialGradient>
  <clipPath id="clip0_2204_141134">
  <rect width="1524" height="800" fill="white"/>
  </clipPath>
  </defs>`

  const bg_m = `<rect width="1524" height="800" fill="#FEE2DE"/>
  <g opacity="0.4">
  <rect x="362" width="800" height="800" fill="#FFDADA"/>
  <rect x="362" width="800" height="800" fill="url(#paint0_radial_2204_141135)"/>
  </g>
  <defs>
  <radialGradient id="paint0_radial_2204_141135" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(762 494.286) rotate(90) scale(534.286)">
  <stop stop-color="#FFC0AB"/>
  <stop offset="0.239583" stop-color="#FEDCD4"/>
  <stop offset="0.302083" stop-color="#FFB9A3"/>
  <stop offset="0.508021" stop-color="#FFD9D7"/>
  <stop offset="0.543241" stop-color="#FFC9BD"/>
  <stop offset="0.942708" stop-color="white" stop-opacity="0"/>
  <stop offset="0.979167" stop-color="white" stop-opacity="0"/>
  </radialGradient>
  </defs>`

  const bg_empty = `<svg width="1524" height="800" viewBox="0 0 1524 800" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="1524" height="800" fill="#F4F6FF"/>
  <g opacity="0.4">
  <rect opacity="0.21" x="1162" y="800" width="800" height="800" transform="rotate(180 1162 800)" fill="url(#paint0_radial_2235_144984)"/>
  </g>
  <defs>
  <radialGradient id="paint0_radial_2235_144984" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(1562 1200) rotate(90) scale(556)">
  <stop stop-color="#FF6B00"/>
  <stop offset="0.180286" stop-color="#FFB627"/>
  <stop offset="0.604167" stop-color="#FFE9BE"/>
  <stop offset="0.723958" stop-color="#FEE8D4"/>
  <stop offset="1" stop-color="white" stop-opacity="0"/>
  </radialGradient>
  </defs>
  </svg>`

  const bg_r = `<rect width="1524" height="800" fill="#EDFEFE"/>
  <g opacity="0.4">
  <rect x="1162" y="800" width="800" height="800" transform="rotate(180 1162 800)" fill="url(#paint0_radial_2204_141154)"/>
  <rect x="1162" y="800" width="800" height="800" transform="rotate(180 1162 800)" fill="url(#paint1_radial_2204_141154)"/>
  <rect x="1162" y="800" width="800" height="800" transform="rotate(180 1162 800)" fill="url(#paint2_radial_2204_141154)"/>
  </g>
  <defs>
  <radialGradient id="paint0_radial_2204_141154" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(1562 1200) rotate(90) scale(400)">
  <stop stop-color="#89E3FF"/>
  <stop offset="0.239583" stop-color="#D4FEF4"/>
  <stop offset="0.296875" stop-color="#A5FFF4"/>
  <stop offset="0.432292" stop-color="#D2FFF4"/>
  <stop offset="0.463542" stop-color="#C0F4FF"/>
  <stop offset="0.8125" stop-color="#DDFFFF"/>
  <stop offset="0.979167" stop-color="#E4FDFC"/>
  </radialGradient>
  <radialGradient id="paint1_radial_2204_141154" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(1398.61 1240.95) rotate(62.0117) scale(297.926)">
  <stop stop-color="#89E3FF"/>
  <stop offset="0.137796" stop-color="#D4FEF4"/>
  <stop offset="0.371223" stop-color="#A5FFF4" stop-opacity="0.09"/>
  <stop offset="0.461349" stop-color="#D2FFF4"/>
  <stop offset="0.612932" stop-color="#C0F4FF" stop-opacity="0.33"/>
  <stop offset="0.8125" stop-color="white" stop-opacity="0"/>
  <stop offset="0.979167" stop-color="white" stop-opacity="0"/>
  </radialGradient>
  <radialGradient id="paint2_radial_2204_141154" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(1717.95 1326.16) rotate(129.401) scale(389.708)">
  <stop stop-color="#89E3FF"/>
  <stop offset="0.192708" stop-color="#C0FFFB" stop-opacity="0.33"/>
  <stop offset="0.239583" stop-color="#D4FEF4"/>
  <stop offset="0.296875" stop-color="#A5FFF4" stop-opacity="0.09"/>
  <stop offset="0.432292" stop-color="#D2FFF4"/>
  <stop offset="0.8125" stop-color="white" stop-opacity="0"/>
  <stop offset="0.979167" stop-color="white" stop-opacity="0"/>
  </radialGradient>
  </defs>`

  const bg_m_sq = `<g clip-path="url(#clip0_2206_144352)">
<rect width="800" height="800" transform="translate(0 0.601562)" fill="#FEE2DE"/>
<g opacity="0.4">
<rect x="-1897.14" y="-1896.54" width="4594.29" height="4594.29" fill="#FFDADA"/>
<rect x="-1897.14" y="-1896.54" width="4594.29" height="4594.29" fill="url(#paint0_radial_2206_144352)"/>
</g>
</g>
<defs>
<radialGradient id="paint0_radial_2206_144352" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(399.998 942.073) rotate(90) scale(3068.33)">
<stop stop-color="#FFC0AB"/>
<stop offset="0.239583" stop-color="#FEDCD4"/>
<stop offset="0.302083" stop-color="#FFB9A3"/>
<stop offset="0.508021" stop-color="#FFD9D7"/>
<stop offset="0.543241" stop-color="#FFC9BD"/>
<stop offset="0.942708" stop-color="white" stop-opacity="0"/>
<stop offset="0.979167" stop-color="white" stop-opacity="0"/>
</radialGradient>
<clipPath id="clip0_2206_144352">
<rect width="800" height="800" fill="white" transform="translate(0 0.601562)"/>
</clipPath>
</defs>`

  const bg_g_sq = `<g clip-path="url(#clip0_2206_144355)">
  <rect width="800" height="800" fill="#FCF0E6"/>
  <g opacity="0.4">
  <rect width="2473.43" height="800" transform="matrix(-1 0 0 1 1636.71 0)" fill="url(#paint0_radial_2206_144355)"/>
  </g>
  </g>
  <defs>
  <radialGradient id="paint0_radial_2206_144355" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(1236.71 582.857) rotate(90) scale(472.381 1460.5)">
  <stop stop-color="#FF6B00"/>
  <stop offset="0.180286" stop-color="#FFB627"/>
  <stop offset="0.604167" stop-color="#FFE9BE"/>
  <stop offset="0.723958" stop-color="#FEE8D4"/>
  <stop offset="1" stop-color="white" stop-opacity="0"/>
  </radialGradient>
  <clipPath id="clip0_2206_144355">
  <rect width="800" height="800" fill="white"/>
  </clipPath>
  </defs>`

  const bg_empty_sq = `<svg width="800" height="800" viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_2227_195751)">
  <rect width="800" height="800" fill="#EDFEFE"/>
  <g opacity="0.4">
  <rect x="800" y="800" width="800" height="800" transform="rotate(180 800 800)" fill="#F4F6FF"/>
  </g>
  <g clip-path="url(#clip1_2227_195751)">
  <rect width="800" height="800" fill="#F4F6FF"/>
  <rect opacity="0.21" width="800" height="800" fill="url(#paint0_radial_2227_195751)"/>
  </g>
  </g>
  <defs>
  <radialGradient id="paint0_radial_2227_195751" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(400 400) rotate(90) scale(556)">
  <stop stop-color="#FF6B00"/>
  <stop offset="0.180286" stop-color="#FFB627"/>
  <stop offset="0.604167" stop-color="#FFE9BE"/>
  <stop offset="0.723958" stop-color="#FEE8D4"/>
  <stop offset="1" stop-color="white" stop-opacity="0"/>
  </radialGradient>
  <clipPath id="clip0_2227_195751">
  <rect width="800" height="800" fill="white"/>
  </clipPath>
  <clipPath id="clip1_2227_195751">
  <rect width="800" height="800" fill="white"/>
  </clipPath>
  </defs>
  </svg>`

  const bg_p_sq = `<g clip-path="url(#clip0_2206_144353)">
  <rect width="800" height="800" transform="translate(0 0.800781)" fill="#FCFDFF"/>
  <g opacity="0.4">
  <rect x="3839.97" y="800.809" width="6604.29" height="800" transform="rotate(180 3839.97 800.809)" fill="url(#paint0_linear_2206_144353)"/>
  <rect x="3839.97" y="800.809" width="6604.29" height="800" transform="rotate(180 3839.97 800.809)" fill="url(#paint1_radial_2206_144353)"/>
  </g>
  </g>
  <defs>
  <linearGradient id="paint0_linear_2206_144353" x1="6985.26" y1="1628.31" x2="6987.42" y2="1072.38" gradientUnits="userSpaceOnUse">
  <stop offset="0.0520529" stop-color="#92EBFF"/>
  <stop offset="0.421875" stop-color="#C2FFFB"/>
  <stop offset="0.463542" stop-color="#DFF9FF"/>
  <stop offset="0.764663" stop-color="#E6FBFF"/>
  <stop offset="0.92651" stop-color="#A7EFFF"/>
  </linearGradient>
  <radialGradient id="paint1_radial_2206_144353" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(7291.43 1187.81) rotate(78.8108) scale(458.719 2888.84)">
  <stop offset="0.503404" stop-color="white" stop-opacity="0"/>
  <stop offset="0.990197" stop-color="white"/>
  </radialGradient>
  <clipPath id="clip0_2206_144353">
  <rect width="800" height="800" fill="white" transform="translate(0 0.800781)"/>
  </clipPath>
  </defs>`

  const bg_r_sq = `<g clip-path="url(#clip0_2206_144354)">
  <rect width="800" height="800" transform="translate(0 0.203125)" fill="#EDFEFE"/>
  <g opacity="0.4">
  <rect x="2697.14" y="800.211" width="4594.29" height="800" transform="rotate(180 2697.14 800.211)" fill="url(#paint0_radial_2206_144354)"/>
  <rect x="2697.14" y="800.211" width="4594.29" height="800" transform="rotate(180 2697.14 800.211)" fill="url(#paint1_radial_2206_144354)"/>
  <rect x="2697.14" y="800.211" width="4594.29" height="800" transform="rotate(180 2697.14 800.211)" fill="url(#paint2_radial_2206_144354)"/>
  </g>
  </g>
  <defs>
  <radialGradient id="paint0_radial_2206_144354" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(4994.28 1200.21) rotate(90) scale(400 2297.14)">
  <stop stop-color="#89E3FF"/>
  <stop offset="0.239583" stop-color="#D4FEF4"/>
  <stop offset="0.296875" stop-color="#A5FFF4"/>
  <stop offset="0.432292" stop-color="#D2FFF4"/>
  <stop offset="0.463542" stop-color="#C0F4FF"/>
  <stop offset="0.8125" stop-color="#DDFFFF"/>
  <stop offset="0.979167" stop-color="#E4FDFC"/>
  </radialGradient>
  <radialGradient id="paint1_radial_2206_144354" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(4055.95 1241.16) rotate(18.1415) scale(844.932 603.286)">
  <stop stop-color="#89E3FF"/>
  <stop offset="0.137796" stop-color="#D4FEF4"/>
  <stop offset="0.371223" stop-color="#A5FFF4" stop-opacity="0.09"/>
  <stop offset="0.461349" stop-color="#D2FFF4"/>
  <stop offset="0.612932" stop-color="#C0F4FF" stop-opacity="0.33"/>
  <stop offset="0.8125" stop-color="white" stop-opacity="0"/>
  <stop offset="0.979167" stop-color="white" stop-opacity="0"/>
  </radialGradient>
  <radialGradient id="paint2_radial_2206_144354" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(5889.86 1326.37) rotate(168.031) scale(1452.14 600.618)">
  <stop stop-color="#89E3FF"/>
  <stop offset="0.192708" stop-color="#C0FFFB" stop-opacity="0.33"/>
  <stop offset="0.239583" stop-color="#D4FEF4"/>
  <stop offset="0.296875" stop-color="#A5FFF4" stop-opacity="0.09"/>
  <stop offset="0.432292" stop-color="#D2FFF4"/>
  <stop offset="0.8125" stop-color="white" stop-opacity="0"/>
  <stop offset="0.979167" stop-color="white" stop-opacity="0"/>
  </radialGradient>
  <clipPath id="clip0_2206_144354">
  <rect width="800" height="800" fill="white" transform="translate(0 0.203125)"/>
  </clipPath>
  </defs>`

  const r_top_l = `<path d="M463.631 93.0228C464.409 93.8015 465.671 93.8042 466.453 93.0289L466.578 92.9048C467.365 92.1247 467.368 90.8537 466.584 90.0702L460.219 83.7051H477.462C478.014 83.7051 478.462 83.2574 478.462 82.7051V80.3841C478.462 79.8318 478.014 79.3841 477.462 79.3841H460.219L466.585 73.0286C467.368 72.2465 467.367 70.977 466.583 70.196L466.453 70.0663C465.671 69.2881 464.407 69.2895 463.627 70.0694L453.566 80.1304C452.785 80.9114 452.785 82.1777 453.566 82.9588L463.631 93.0228Z" fill="black"/>`
  const r_top_r = `<path d="M466.984 93.0228C466.205 93.8015 464.943 93.8042 464.161 93.0289L464.036 92.9048C463.249 92.1247 463.247 90.8537 464.03 90.0702L470.395 83.7051H453.152C452.6 83.7051 452.152 83.2574 452.152 82.7051V80.3841C452.152 79.8318 452.6 79.3841 453.152 79.3841H470.395L464.029 73.0286C463.246 72.2465 463.247 70.977 464.031 70.196L464.161 70.0663C464.943 69.2881 466.207 69.2895 466.987 70.0694L477.048 80.1304C477.829 80.9114 477.829 82.1777 477.048 82.9588L466.984 93.0228Z" fill="black"/>`
  const l_top_l = `<path d="M120.478 93.0228C121.257 93.8015 122.518 93.8042 123.3 93.0289L123.426 92.9048C124.213 92.1247 124.215 90.8537 123.432 90.0702L117.067 83.7051H134.31C134.862 83.7051 135.31 83.2574 135.31 82.7051V80.3841C135.31 79.8318 134.862 79.3841 134.31 79.3841H117.067L123.433 73.0286C124.216 72.2465 124.215 70.977 123.431 70.196L123.3 70.0663C122.519 69.2881 121.255 69.2895 120.475 70.0694L110.414 80.1304C109.633 80.9114 109.633 82.1777 110.414 82.9588L120.478 93.0228Z" fill="#F45656"/>`
  const l_top_r = `<path d="M123.831 93.0228C123.053 93.8015 121.791 93.8042 121.009 93.0289L120.884 92.9048C120.097 92.1247 120.094 90.8537 120.878 90.0702L127.243 83.7051H110C109.448 83.7051 109 83.2574 109 82.7051V80.3841C109 79.8318 109.448 79.3841 110 79.3841H127.243L120.877 73.0286C120.094 72.2465 120.094 70.977 120.879 70.196L121.009 70.0663C121.791 69.2881 123.055 69.2895 123.834 70.0694L133.895 80.1304C134.676 80.9114 134.676 82.1777 133.895 82.9588L123.831 93.0228Z" fill="#F45656"/>`
  const l_bottom_r = `<path d="M124.831 143.023C124.053 143.801 122.791 143.804 122.009 143.029L121.884 142.905C121.097 142.125 121.094 140.854 121.878 140.07L128.243 133.705H111C110.448 133.705 110 133.257 110 132.705V130.384C110 129.832 110.448 129.384 111 129.384H128.243L121.877 123.029C121.094 122.247 121.094 120.977 121.879 120.196L122.009 120.066C122.791 119.288 124.055 119.29 124.834 120.069L134.895 130.13C135.676 130.911 135.676 132.178 134.895 132.959L124.831 143.023Z" fill="#F45656"/>`
  const l_bottom_l = `<path d="M121.478 143.023C122.257 143.801 123.518 143.804 124.3 143.029L124.426 142.905C125.213 142.125 125.215 140.854 124.432 140.07L118.067 133.705H135.31C135.862 133.705 136.31 133.257 136.31 132.705V130.384C136.31 129.832 135.862 129.384 135.31 129.384H118.067L124.433 123.029C125.216 122.247 125.215 120.977 124.431 120.196L124.3 120.066C123.519 119.288 122.255 119.29 121.475 120.069L111.414 130.13C110.633 130.911 110.633 132.178 111.414 132.959L121.478 143.023Z" fill="#F45656"/>`
  const r_bottom_r = `<path d="M466.984 143.023C466.205 143.801 464.943 143.804 464.161 143.029L464.036 142.905C463.249 142.125 463.247 140.854 464.03 140.07L470.395 133.705H453.152C452.6 133.705 452.152 133.257 452.152 132.705V130.384C452.152 129.832 452.6 129.384 453.152 129.384H470.395L464.029 123.029C463.246 122.247 463.247 120.977 464.031 120.196L464.161 120.066C464.943 119.288 466.207 119.29 466.987 120.069L477.048 130.13C477.829 130.911 477.829 132.178 477.048 132.959L466.984 143.023Z" fill="black"/>`
  const r_bottom_l = `<path d="M463.631 143.023C464.409 143.801 465.671 143.804 466.453 143.029L466.578 142.905C467.365 142.125 467.368 140.854 466.584 140.07L460.219 133.705H477.462C478.014 133.705 478.462 133.257 478.462 132.705V130.384C478.462 129.832 478.014 129.384 477.462 129.384H460.219L466.585 123.029C467.368 122.247 467.367 120.977 466.583 120.196L466.453 120.066C465.671 119.288 464.407 119.29 463.627 120.069L453.566 130.13C452.785 130.911 452.785 132.178 453.566 132.959L463.631 143.023Z" fill="black"/>`

  const typeColors = {
    G: '#ce9d6d',
    MG: '#ce9d6d',
    M: '#b97474',
    P: '#8dc8df',
    R: '#96c1bc',
  }



  const arrowsthumbnail = `<g transform="scale(${settings[type].arrowsSize},${settings[type].arrowsSize}), translate(${settings[type].arrowsSize * -30}, ${settings[type].arrowsSize * -10})">${design.variables.left_up === 'left' && l_top_l}</g>
<g transform="scale(${settings[type].arrowsSize},${settings[type].arrowsSize}), translate(${settings[type].arrowsSize * -30}, ${settings[type].arrowsSize * -10})">${design.variables.left_up === 'right' && l_top_r}</g>
<g transform="scale(${settings[type].arrowsSize},${settings[type].arrowsSize}), translate(${settings[type].arrowsSize * -30}, ${settings[type].arrowsSize * -10})">${design.variables.left_down === 'left' && l_bottom_l}</g>
<g transform="scale(${settings[type].arrowsSize},${settings[type].arrowsSize}), translate(${settings[type].arrowsSize * -30}, ${settings[type].arrowsSize * -10})">${design.variables.left_down === 'right' && l_bottom_r}</g>
<g transform="scale(${settings[type].arrowsSize},${settings[type].arrowsSize}), translate(${settings[type].arrowsSize * -115}, ${settings[type].arrowsSize * -10})">${design.variables.right_up === 'left' && r_top_l}</g>
<g transform="scale(${settings[type].arrowsSize},${settings[type].arrowsSize}), translate(${settings[type].arrowsSize * -115}, ${settings[type].arrowsSize * -10})">${design.variables.right_up === 'right' && r_top_r}</g>
<g transform="scale(${settings[type].arrowsSize},${settings[type].arrowsSize}), translate(${settings[type].arrowsSize * -115}, ${settings[type].arrowsSize * -10})">${design.variables.right_down === 'right' && r_bottom_r}</g>
<g transform="scale(${settings[type].arrowsSize},${settings[type].arrowsSize}), translate(${settings[type].arrowsSize * -115}, ${settings[type].arrowsSize * -10})">${design.variables.right_down === 'left' && r_bottom_l}</g>`;

  const arrowsOg = `
  ${design.variables.left_up === 'left' && l_top_l}
  ${design.variables.left_up === 'right' && l_top_r}
  ${design.variables.left_down === 'left' && l_bottom_l}
  ${design.variables.left_down === 'right' && l_bottom_r}
  ${design.variables.right_up === 'left' && r_top_l}
  ${design.variables.right_up === 'right' && r_top_r}
  ${design.variables.right_down === 'right' && r_bottom_r}
  ${design.variables.right_down === 'left' && r_bottom_l}
`


  const profileTxt = `<text x="10" y="30" font-family="Arial" font-size="22" font-weight="bolder" fill="${typeColors[design.type]}">
  <tspan x="16" dy="6">${design.type}</tspan>
  <tspan x="236" dy="0">${design.profile[0]}/${design.profile[1]}</tspan>
</text>`

  // width = "${width}"
  // height="${height}"
  // viewBox="0 0 ${width} ${height}"

  const svg = `<svg
    xmlns="http://www.w3.org/2000/svg"
    width="${settings[type].width}"
    height="${settings[type].height}"
    viewBox="0 0 ${settings[type].width} ${settings[type].height}"

    fill="none"
    preserveAspectRatio="xMidYMid meet"
  >

  <g transform="translate(${settings[type].translateBgX}, ${settings[type].translateBgY}), scale(${settings[type].sizeBg},${settings[type].sizeBg})">
    ${settings[type].aura && design['type'] === 'G' && type === 'og' && bg_g}
    ${settings[type].aura && design['type'] === 'MG' && type === 'og' && bg_g}
    ${settings[type].aura && design['type'] === 'P' && type === 'og' && bg_p}
    ${settings[type].aura && design['type'] === 'M' && type === 'og' && bg_m}
    ${settings[type].aura && design['type'] === 'R' && type === 'og' && bg_r}
    ${settings[type].aura && design['type'] === '' && type === 'og' && bg_empty}

    ${settings[type].aura && design['type'] === 'G' && type === 'thumbnail' && bg_g_sq}
    ${settings[type].aura && design['type'] === 'MG' && type === 'thumbnail' && bg_g_sq}
    ${settings[type].aura && design['type'] === 'P' && type === 'thumbnail' && bg_p_sq}
    ${settings[type].aura && design['type'] === 'M' && type === 'thumbnail' && bg_m_sq}
    ${settings[type].aura && design['type'] === 'R' && type === 'thumbnail' && bg_r_sq}
    ${settings[type].aura && design['type'] === '' && type === 'thumbnail' && bg_empty_sq}
    </g>


    ${settings[type].logo && `<g transform="translate(30, 20)">${logoSvg}</g>`}
    ${settings[type].profile && profileTxt}

  <g transform="translate(${settings[type].translate}, 0), scale(${settings[type].size},${settings[type].size})">


    ${type === 'thumbnail' && arrowsthumbnail}
    ${type === 'og' && arrowsOg}

    ${body_contour}
    ${lines ? lines_svg : ''}
    ${centers.map(one => renderCenter(one), design['active_chakras'])}
    ${!composite && numbers ? numbers_dictionary : ''}
    </g>
  </svg>`



  // console.log('svg = ', svg);

  return svg
}




export function renderBase64(
  design,
  type = 'thumbnail'
) {
  const svg = getSvgCode(design, type)
  const encoded = window.btoa(svg);
  return `data:image/svg+xml;base64,${encoded}`;
}