import React, { Fragment, useEffect, useState, useContext, useRef } from 'react'
import { Link, matchPath, useParams } from 'react-router-dom'
import { Bodygraph } from './Bodygraph';
import { drawType } from '../utilsDesign';
import { default_design } from '../../static/default_design';







export const Preview = React.memo(({
  hints,
  hoverable,
  interactive,
  children,
  body = true,
  simplified,
  activePage,
  design = default_design,
  lines = true,
  blur,
  numbers,
  width,
  height,
  aura = true,
  bg = true,
  planets,
  planetsDes,
  planetsPrs,
  authority,
  arrowsB,
  arrowsP,
  opacity = 1,
  bodyAdd,
  style,
  className,
  preloader,
  advanced,
  setloadingBody = () => void (0),
  parentContainer,
  colorTheme = {},
  focusedCenter,
  highlighted,
  highlightedGate = [],
  addClassName = ''
}) => {


  useEffect(() => {
    setloadingBody(false)
  })

  const withoutHighlighted = (center) => {
    let centers = ['HD', 'AA', 'TT', 'HT', 'GC', 'SN', 'SL', 'SP', 'RT'];

    if (center) {
      let indToDelete = centers.findIndex((one) => one === center);
      centers.splice(indToDelete, 1)
    }
    return centers;
  }

  const bodyContainer = useRef(null);

  parentContainer = parentContainer || bodyContainer;

  // useEffect(() => {
  //   console.log('design = ', design);
  // }, [design])


  return (
    <>
      <>


        {children}




        {!simplified && <div
          className={`x_bodygraph0`}
          // style={{ position: 'absolute' }}
        >

          <Bodygraph
            hints={hints}
            hoverable={hoverable}
            interactive={interactive}
            width={width}
            height={height}
            numbers={numbers}
            arrowsB={arrowsB}
            arrowsP={arrowsP}
            planets={planets}
            planetsPrs={planetsPrs}
            planetsDes={planetsDes}
            aura={false}
            advanced={advanced}
            activePage={activePage}
            design={design}
            {...bodyAdd}
            preloader={preloader}
            parentContainer={parentContainer}
            colorTheme={colorTheme}
            focusedCenter={focusedCenter}
            highlighted={highlighted}
            highlightedGate={highlightedGate}
            addClassName={addClassName}
          />

        </div>}







      </>


      {aura && bg && <div className={`x_edu_bg ${design && drawType(design['type'], 'class')}`}
        style={{ width: '100%' }}
      >
          {aura &&
          <div className={`x_edu_aurabg ${aura ? 'active' : ''} ${design && drawType(design['type'], 'class')} page--${activePage}`}>
            </div>}
      </div>}
    </>

  )
})





