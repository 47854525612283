import { findGateInUser, gatherUserChannels, getDesignCenters, getType } from './utilsDesign';
const API_URL = process.env.REACT_APP_API_URL;


export const getPentaDesign = (penta = {}) => {
  if (!Boolean(penta.members)) return {};
  const pentaGates = JSON.parse(JSON.stringify(penta.members
    .map(one => one.avatar.design.gates)
    .flat()))



  const channels = processPentaGates(pentaGates, penta.members)

  const pentaDesign = {
    gates: pentaGates,
    channels: channels,
    type: getType(pentaGates),
    profile: [],
    active_chakras: getDesignCenters(pentaGates),
  }
  // console.log('pentaDesign = ', pentaDesign);
  return pentaDesign
}


export const getComposite9 = (penta = {}) => {
  if (penta.members.length !== 2) return '';
  return `${getDesignCenters(penta.members[0].avatar.design['gates'], penta.members[1].avatar.design['gates']).length}–${9 - getDesignCenters(penta.members[0].avatar.design['gates'], penta.members[1].avatar.design['gates']).length}`
}

export const getEmChannels = (penta = {}) => {
  if (penta.members.length < 2) return [];
  const pentaDesign = getPentaDesign(penta)
  return pentaDesign.channels.filter(oneChannel => !Object.keys(oneChannel.activatedBy).length)
}

export const getSameChannels = (penta = {}) => {
  if (penta.members.length < 2) return [];
  const pentaDesign = getPentaDesign(penta)
  return pentaDesign.channels.filter(oneChannel => Object.keys(oneChannel.activatedBy).length === penta.members.length)
}

export const getDomChannels = (penta = {}) => {
  if (penta.members.length < 2) return [];
  const pentaDesign = getPentaDesign(penta)
  const possiblyDom = pentaDesign.channels.filter(oneChannel => Object.keys(oneChannel.activatedBy).length === 1)
  const findGatesActivatedByAnother = (gateId, submissiveToken) => {
    const gates = findGateInUser(pentaDesign.gates, gateId)
    const filtered = gates.filter(oneGate => {
      const tok = Object.keys(oneGate.activatedBy).includes(submissiveToken)
      return tok
    })
    return filtered.length ? true : false
  }
  return possiblyDom.filter(oneChannel => {
    const dominatorToken = Object.keys(oneChannel.activatedBy)[0]
    const submissiveToken = penta.members.map(one => one.avatar.token).filter(one => one !== dominatorToken)[0];
    const g1 = findGatesActivatedByAnother(oneChannel.gates[0], submissiveToken);
    const g2 = findGatesActivatedByAnother(oneChannel.gates[1], submissiveToken);
    return (g1 || g2) ? true : false
  })
}




export const processPentaGates = (pentaGates = [], members = []) => {
  pentaGates.forEach(onePentaGate => {
    if (!onePentaGate) return {}
    let gateId = onePentaGate.gate;
    onePentaGate.activatedBy = {}
    members.filter(oneMember => {
      const hasGate = oneMember.avatar.design.gates ? oneMember.avatar.design.gates.filter(oneGate => oneGate.gate === gateId) : []
      if (hasGate.length) onePentaGate.activatedBy[oneMember.avatar.token] = hasGate;
    })
  })

  const channels = JSON.parse(JSON.stringify(gatherUserChannels(pentaGates)));

  channels.forEach(oneChannel => {
    oneChannel.activatedBy = {};
    members.filter(oneMember => {
      const memberChannels = gatherUserChannels(oneMember.avatar.design.gates);
      const hasChannel = memberChannels.filter(memberChannel => memberChannel.id === oneChannel.id)
      if (hasChannel.length) oneChannel.activatedBy[oneMember.avatar.token] = [
        findGateInUser(oneMember.avatar.design.gates, oneChannel.gates[0]),
        findGateInUser(oneMember.avatar.design.gates, oneChannel.gates[1])
      ];
    })
  })

  return channels
}






export const _updatePenta = async (newData = {}, callback = () => void (0)) => {
  const url = `${API_URL}/api/penta/update`;

  try {
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newData),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }
    const data = await response.json();
    console.log('Success:', data);
    callback && callback(data)
  } catch (error) {
    console.error('Error:', error.message);
  }

};
