import React, { Fragment, useEffect, useState, useContext } from 'react';
import { gates_dictionary } from '../../static/gates_dictionary';
import { Switch } from '../UiKit/Switch';
import { Iching } from '../UiKit/Iching';
import { drawProfile, getCenterByGate, getMars } from '../utilsDesign';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { LangContext } from '../../contexts/langContext';
import { Txt } from '../UiKit/Txt';
import { TagList } from './Tags';
import { Variants } from '../UiKit/Variants';
import { PlanetWithPop } from '../UiKit/Planet';
import { TooltipH } from '../UiKit/TooltipH';

export const LinesTable = React.memo(({ design, scrollToSection, title = true }) => {

  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ = context.$;
  const $ui = context.$ui;
  const $profile = context.$profile;
  const $line = context.$line;
  const $planet = context.$planet;
  const $vars = context.$vars;

  const findPlanetInUser = (gates, line, label, planet) => {
    console.log('planet = ', planet);
    let result = [];
    if (line) {
      result = label ? gates.filter(one => one['line'] === line && one['label'] === label)
        : gates.filter(one => one['line'] === line);
    }
    if (!line && label && planet) {
      result = gates.filter(one => one['planet'] === planet && one['label'] === label)[0]
    }

    if (planet && label && line) {
      result = gates.filter(one => one['label'] === label && one['planet'] === planet && one['line'] === line);
    }
    if (planet && !label && line) {
      result = gates.filter(one => one['planet'] === planet && one['line'] === line);
    }
    console.log('result = ', result);
    return result;
  }

  const [planetName, setPlanetName] = useState(false)
  const [colorShown, setColorShown] = useState(false)

  const profileId = `${design['profile'][0]}${design['profile'][1]}`;

  const checkSun = (line = 1, label = 'prs', planet = 'Sun') => {
    const g = findPlanetInUser(design['gates'], line, label, planet)
    return g.length ? true : false;
  }

  const getMarsLine = () => {
    const findM = (planet) => design['gates'].filter(one => one['planet'] === planet && one['label'] === 'des')[0].line
    return findM('Mars')
  }
  const getMoonLine = () => {
    const findM = (planet) => design['gates'].filter(one => one['planet'] === planet && one['label'] === 'des')[0].line
    return findM('Moon')
  }


  return (
    <>

      <div className="x_edu_content_inner">

        {Boolean(title) && <div className="x_edu_sections x_edu_sections--2">
          <div className="x_edu_content_title">
            <h1 className="x_edu_content_h x_edu_content_h--2">
              <span className="x_edu_content_h_italic">
                {$profile(`${design['profile'][0]}${design['profile'][1]}`, 'profile')}
              </span>{' '}
              {/* {drawProfile(design['profile'], { numbers: false, title: true })} */}

              "{$profile(`${design['profile'][0]}${design['profile'][1]}`, 'title')}"

            </h1>
            <div className="x_edu_content_desc">

              {/* <p>{drawProfile(design['profile'], { numbers: false, title: false, description: true })}</p> */}
              <Txt>{$profile(`${design['profile'][0]}${design['profile'][1]}`, 'description')}</Txt>

              <p >
                {/* <p>{drawProfile(design['profile'], { numbers: false, title: false, description: true })}</p> */}
                {$ui('lines_table_keywords')}: <strong><TagList tagline={$profile(profileId, 'tags')} /></strong>
              </p>
            </div>


          </div>
        </div>}

        <div className="x_edu_content">


        <div className="x_edu_sections x_edu_sections--2">
          <div className="x_profiles00">
            <div className="x_profiles0">
              <div className="x_profiles_hr">

              <div className="x_profile_cell x_profile_cell--th x_profile_cell--1">
                <div className="x_profile_head">{$ui('lines_table_archetype')}</div>
              </div>


              {[1, 2, 3, 4, 5, 6].reverse().map((oneLine) => <div className="x_profile_cell" key={`line_${oneLine}`}>
                <div className="x_profile_th">

                  {checkSun(oneLine, 'prs', 'Sun') && <div className="x_profile_sunmark"><div className="x_planet"><div>b</div></div><div className="x_profile_sunmark_svg w-embed"><svg width={53} height={44} viewBox="0 0 53 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 4C0 1.79086 1.79086 0 4 0H33.9447C35.8643 0 37.668 0.918504 38.7971 2.47097L52.1445 20.8237C52.6545 21.525 52.6545 22.475 52.1445 23.1763L38.7971 41.529C37.668 43.0815 35.8643 44 33.9447 44H4C1.79086 44 0 42.2091 0 40V4Z" fill="currentColor" />
                  </svg></div></div>}
                  {checkSun(oneLine, 'des', 'Sun') && <div className="x_profile_sunmark"><div className="x_planet"><div>b</div></div><div className="x_profile_sunmark_svg x_profile_sunmark_svg--des w-embed"><svg width={53} height={44} viewBox="0 0 53 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 4C0 1.79086 1.79086 0 4 0H33.9447C35.8643 0 37.668 0.918504 38.7971 2.47097L52.1445 20.8237C52.6545 21.525 52.6545 22.475 52.1445 23.1763L38.7971 41.529C37.668 43.0815 35.8643 44 33.9447 44H4C1.79086 44 0 42.2091 0 40V4Z" fill="currentColor" />
                  </svg></div></div>}

                  {checkSun(oneLine, 'des', 'Mars') &&
                    <TooltipH title={<>{$vars('label_trauma')}: <b>{$vars(`trauma_${getMarsLine()}`)}</b></>}>
                      <div className={`x_profile_sunmark x_profile_sunmark--mars ${checkSun(oneLine, false, 'Sun') ? 'x_profile_sunmark--mars--sm' : ''}`}><div className="x_planet"><div>E</div></div><div className="x_profile_sunmark_svg x_profile_sunmark_svg--des w-embed"><svg width={53} height={44} viewBox="0 0 53 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                    < path d="M0 4C0 1.79086 1.79086 0 4 0H33.9447C35.8643 0 37.668 0.918504 38.7971 2.47097L52.1445 20.8237C52.6545 21.525 52.6545 22.475 52.1445 23.1763L38.7971 41.529C37.668 43.0815 35.8643 44 33.9447 44H4C1.79086 44 0 42.2091 0 40V4Z" fill="currentColor" />
                      </svg></div></div>
                    </TooltipH>
                  }

                  {checkSun(oneLine, 'des', 'Moon') &&
                    <TooltipH title={<>{$vars('label_love')}: <b>{$vars(`love_${getMoonLine()}`)}</b></>}>
                      <div className={`x_profile_sunmark x_profile_sunmark--moon ${checkSun(oneLine, false, 'Sun') ? 'x_profile_sunmark--moon--sm' : ''}`}><div className="x_planet"><div>A</div></div><div className="x_profile_sunmark_svg x_profile_sunmark_svg--des w-embed"><svg width={53} height={44} viewBox="0 0 53 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                    < path d="M0 4C0 1.79086 1.79086 0 4 0H33.9447C35.8643 0 37.668 0.918504 38.7971 2.47097L52.1445 20.8237C52.6545 21.525 52.6545 22.475 52.1445 23.1763L38.7971 41.529C37.668 43.0815 35.8643 44 33.9447 44H4C1.79086 44 0 42.2091 0 40V4Z" fill="currentColor" />
                      </svg></div></div>
                    </TooltipH>
                  }


                  <div className="x_profile_iching">
                    <Iching profile={[oneLine, null]} size="lg" />
                  </div>
                    <div className="x_profile_title">
                    {oneLine}. {$line(oneLine, 'title')}
                  </div>
                  </div>
              </div>)}



              </div>


              <div className="x_profiles_content">

              {/* <div className="x_profiles_hr x_profiles_hr--stat">
                  {[1, 2, 3, 4, 5, 6].map((oneLine) => <div
                    key={`line_${oneLine}`}
                    className="x_profile_cell x_profile_cell--stat"
                  >
                    <div className="x_profile_num_tot">{findPlanetInUser(design['gates'], oneLine).length}</div>
                    <div className="x_profile_num_prs">
                      <div>{findPlanetInUser(design['gates'], oneLine, 'prs').length} {$ui('line_in_personality')}</div>
                    </div>
                    <div className="x_profile_num_body">
                      <div>{findPlanetInUser(design['gates'], oneLine, 'des').length} {$ui('line_in_body')}</div>
                    </div>
                  </div>)}
                </div> */}





              <div className="x_profiles_line x_profiles_line--des">

                <div className="x_profile_cell x_profile_cell--th x_profile_cell--des">
                  <div className="x_profile_head">{$ui('lines_table_design')}</div>
                </div>
                {[1, 2, 3, 4, 5, 6].reverse().map((oneLine) => <div
                    key={`line_${oneLine}`}
                    className="x_profile_cell x_profile_cell--des"
                  >
                  {findPlanetInUser(design['gates'], oneLine, 'des')
                    .map(oneGate =>
                      <PlanetWithPop
                        key={`planet_${oneGate['planet']}`}
                        label='des'
                        design={design}
                        gate={oneGate}
                        planet={oneGate['planet']}
                        titleShown={planetName}
                        colorShown={colorShown}
                      />)}
                  </div>)}
                </div>

              <div className="x_profiles_line">
                <div className="x_profile_cell x_profile_cell--th x_profile_cell--prs">
                  <div className="x_profile_head">{$ui('lines_table_personality')}</div>
                </div>
                {[1, 2, 3, 4, 5, 6].reverse().map((oneLine) => <div
                    key={`line_${oneLine}`}
                    className="x_profile_cell x_profile_cell--prs"
                  >
                  {findPlanetInUser(design['gates'], oneLine, 'prs')
                    .map(oneGate =>
                      <PlanetWithPop
                        key={`planet_${oneGate['planet']}`}
                        label='prs'
                        design={design}
                        gate={oneGate}
                        planet={oneGate['planet']}
                        titleShown={planetName}
                        colorShown={colorShown}
                      />)}
                  </div>)}
                </div>

              </div>

            </div>

          <div className="x_profiles_toggles">
            <Switch
              size='sm'
              label={$ui('line_show_planet_names')}
              active={planetName}
              handle={() => setPlanetName(!planetName)}
            />
            <Switch
              size='sm'
              label={$ui('line_show_color')}
              active={colorShown}
              handle={() => setColorShown(!colorShown)}
            />
          </div>


        </div>
        {/* <div className="x_edu_page_bg active" /> */}
        {/* <div className="x_edu_section">
        <h1 className="x_edu_content_h">Why?</h1>
        <div className="x_edu_content_desc x_edu_content_desc--v">
          <div className="w-richtext">
            <p>The Sun defines 70% of our Human Design</p>
          </div>
        </div>
      </div> */}
        {/* <div className="x_edu_section">
        <h1 className="x_edu_content_h">What are other profiles</h1>
        <div className="x_edu_content_desc x_edu_content_desc--v">
          <div className="w-richtext">
            <p>Human Design categorizes individuals into 4 major types: Generators ("Pure" and "Manifesting"), Projectors, Manifestors, and Reflectors. Understanding your type can provide valuable insights into how you interact with the world and make decisions that align with your authentic self.</p>
          </div>
        </div>
      </div> */}

            {/* <Variants
          mode='mars'
          design={design}
          options={[
          {
              title: 'label_trauma',
              varName: 'trauma',
              getLine: getMars(design, 'des'),
              // getColor: getMars(design,),
            // getTone: getDesignSunTone,
            transference: false,
            label: 'des',
          },
              ]} /> */}


          </div>

        </div>
      </div>
    </>

  )
})



export const PlanetPop = React.memo(({ planet, gate, design, children, largeSun }) => {
  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ = context.$;
  const $ui = context.$ui;
  const $planet = context.$planet;
  return (
    <>
      {children}




      <div className="x_planet_pop0">
        <div className="x_planet_pop_title">
          <div className="x_planet_pop_pl">
            <div className={`x_planet ${planet === 'Sun' && largeSun ? " x_planet--sun" : ''} ${planet.includes('Node') ? " x_planet--node" : ''}`}>
              {$planet(planet, 'glyph')}
            </div>
            <div>
              <div className="x_planet_title">
                {$planet(planet, 'title')}
              </div>
            </div>
          </div>
        </div>
        <div className="x_profile_planet_theme">
          {$planet(planet, 'description')}
        </div>
        <div className="x_planet_gates">

          <GateActivation gate={gate} design={design} />

        </div>
      </div>

    </>

  )
})



export const GateActivation = React.memo(({ gate, inLine = true, inColor = true }) => {
  const context = { ...useContext(LangContext) };
  const $ui = context.$ui;
  const $gate = context.$gate;
  return (
    <div className={`x_planet_gate0 x_planet_gate0--${$gate(gate['gate'], 'id')}`}>
      <div className="x_gate_title0">
        <div className="x_gate_title_hex">
          {$gate(gate['gate'], 'hexagram')}
        </div>
        <div className="x_gate_title_num">
          {$gate(gate['gate'], 'id')}{inLine && <>.{gate['line']}</>}{inColor && <>.{gate['color']}</>}
        </div>
        <div className="x_gate_info">
          <div className="x_gate_title_title">
            {$gate(gate['gate'], 'title')}

          </div>
          <div className="x_gate_desc">
            {$gate(gate['gate'], 'description')}
          </div>
        </div>
      </div>
      {/* <BodyFocused
        design={design}
        focus={getCenterByGate(gate['gate'])}
      /> */}
    </div>

  )
})
