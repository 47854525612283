import React, { useContext, useEffect, useState, useRef } from 'react';
// const cityTimezones = require('city-timezones');
import { timezones } from '../../static/timezones';
import { Select, Spin } from 'antd';
import { _getCity, handleCitySearch } from '../utilsDesign';
import { Preloader, Preloader0, PreloaderButton } from './kit';
import { LangContext } from '../../contexts/langContext';
import { uniqueId } from 'lodash';
import { ListCitiesPresets } from '../Lists/ListCity';
import { StateContext } from '../../contexts/stateContext';
import { Button } from './Button';
import { getGeoLocation, fetchNearestCities } from '../utilsGeo';
import { Icon } from './Icon';
import { message } from 'antd';

// const cities = require('all-the-cities');


const getTimezone = (tz, what) => {
  let t = timezones.filter(one => one['utc'].includes(tz))
  t = t.length ? t[0] : {}
  return t[what]
}




export function City({
  locateMe = false,
  autoFocus = false,
  placeholder,
  city = {},
  value = '',
  show = ['city', 'province', 'country', 'timezone', 'gmt'], //'province'
  inactive = false,
  setOffset = () => void (0),
  handleCityId = () => void (0),
  handleCityObj = () => void (0),
  handleEmpty = () => void (0),
  handleCityFocused,
  avatar = {},
  // className = 'ant-picker ant-picker-borderless'
  className = '',
  recents = false,
}) {

  const context = { ...useContext(LangContext), ...useContext(StateContext) };
  const $ui = context.$ui;


  placeholder = placeholder || $ui('avatar_city_placeholder');

  // const [city, setCity] = useState({})

  useEffect(() => {
    if (value) {
      _getCity(value, setCityObject)
    } else {
      setCityObject()
    }
  }, [value])

  useEffect(() => {
    if (city && cityObject && city.id && city.id !== cityObject.id) {
      setCityObject(city)
    }
  }, [city])

  useEffect(() => {
    if (!context.state.calcShown
      && city && cityObject && city.id && city.id !== cityObject.id) {
      setCityObject(city)
    }
  }, [city])

  const inputRef = useRef(null);



  const defaultCity = {
    "city": "Pavlodar",
    "city_ascii": "Pavlodar",
    "country": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "lat": "52.29999758",
    "lng": "76.95002112",
    "pop": "316254",
    "province": "Pavlodar",
    "timezone": "Asia/Almaty"
  };





  const [fetching, setFetching] = useState(false);


  const [cityObject, setCityObject] = useState(city.id ? city : null);
  const [cityVariants, setCityVariants] = useState([]);

  const [searchValue, setSearchValue] = useState('');


  // const [selected, setSelected] = useState('');
  const [suggest, setSuggest] = useState([]);
  const [suggestShown, setSuggestShown] = useState(false);

  const [selectedCity, setSelectedCity] = useState(0)


  const cytySearch = (val, callback) => {
    setFetching(true)
    setSuggest([])
    setSuggestShown(true)
    handleCitySearch(val, searchCallBack)
    setSearchValue(val)
    if (val) {
      setSuggestShown(true)
    }
  }

  const searchCallBack = (newCities) => {
    setSuggest(newCities)
    setFetching(false)
  }

  const handleSelect = (one) => {
    setSearchValue('')
    setCityObject(one)
    setSuggestShown(false)
    setOffset(one.offset)
    handleCityId(one.id)
    handleCityObj(one)
  }

  const handleClear = () => {
    handleCityId()
    setCityObject()
    handleEmpty()
    setSuggestShown(true)
  }

  const useFocus = (context, handleCityFocused) => {
    const htmlElRef = useRef(null);

    const setFocus = () => {
      if (context.state.calcShown) {
        if (htmlElRef.current) {
          console.log('htmlElRef.current = ', htmlElRef.current);
          htmlElRef.current.focus();
        }

        if (handleCityFocused && htmlElRef.current) {
          context.setState.setCitySearchActive(true);

          // Add blur event listener
          // htmlElRef.current.addEventListener('blur', () => {

          // });
        }
      }
    };

    return [htmlElRef, setFocus];
  };

  // Usage:
  const [cityInput, setInputFocus] = useFocus(context, handleCityFocused);



  // const cityInput = useRef();

  function findCityData(input) {
    // return input ? cityTimezones.lookupViaCity(input) : '';
  }

  function timeZonesToList(timezones) {
    let tz = []
    timezones.forEach(oneZ => (tz.push(...oneZ['utc'])))
    return tz;
  }


  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowUp') {
        event.preventDefault();
        // setSelectedCity((prevSelectedCity) => (prevSelectedCity > -1 ? prevSelectedCity - 1 : -1));
        setSelectedCity(selectedCity - 1);
        // console.log('selectedCity = ', selectedCity);
      } else if (event.key === 'ArrowDown') {
        event.preventDefault();
        // setSelectedCity((prevSelectedCity) => (prevSelectedCity < suggest.length - 1 ? prevSelectedCity + 1 : prevSelectedCity));
        setSelectedCity(selectedCity + 1);
        // console.log('selectedCity = ', selectedCity);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);



  useEffect(() => {

    // console.log('suggest = ', suggest);
  }, [suggest])


  useEffect(() => {
    if (cityObject) {
      context.setState.setCitySearchActive(false);
    }
  }, [cityObject])

  useEffect(() => {
    if (!cityObject && autoFocus) {
      setInputFocus()
    } else {
      // handleFocused(false)
    }
    // if (cityInput.current) {
    //   cityInput.current.focus()
    // }
  })


  useEffect(() => {
    if (recents && !suggestShown) {
      // setSuggestShown(true)
    }
  }, [searchValue, cityObject])


  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside the component
      if (!event.target.closest(".__city_selector")) {
        setSuggestShown(false);
      }
    };

    const handleMouseMove = (event) => {
      // Check if the mouse is outside the component
      if (!event.target.closest(".__city_selector")) {
        setSuggestShown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    // document.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.removeEventListener("click", handleClickOutside);
      // document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);



  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState(null);



  const handleLocationClick = async () => {
    setLoading(true);
    try {
      const position = await getGeoLocation();
      const { latitude, longitude } = position.coords;
      console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
      const data = await fetchNearestCities(latitude, longitude);
      if (data.length) {
        setCityVariants(data);
        setCityObject(data[0]);
        handleCityId(data[0].id)
        handleCityObj(data[0])
        message.success(`${$ui('geo_found_you')} ${data[0].city}, ${data[0].province}, ${data[0].country}!`);
      } else {

      }
    } catch (error) {
      console.error('Error getting location:', error.message);
      message.error(error.message);
    } finally {
      setLoading(false); // Stop loading after cityObject is set or if an error occurs
    }
  };





  return (
    <>
      <>

        <div className="x_input_city0 _f">

          {locateMe && !cityObject && <Button
            onClick={handleLocationClick}
            loading={loading}
            icon={'location_on'}
          >
            {/* {loading && } */}
          </Button>}

          {!inactive && !cityObject &&
            <input
            className={`x_input ${loading ? 'x_input--disabled' : ''} ${className} __city_selector `}
              autoFocus
              ref={cityInput}
              placeholder={placeholder}
            value={searchValue} onChange={(e) => cytySearch(e.target.value)}
            onClick={() => {
              !suggestShown ? setSuggestShown(true) : void (0)
              handleCityFocused && context.setState.setCitySearchActive(true)
            }}
          />

          }
          {/* {!cityObject && <PreloaderButton><Preloader size={16} /></PreloaderButton>} */}

          {cityObject &&
            <div
              className={`x_input ${className} __inputdone`}
            >
              <OneCity
                one={cityObject}
                show={show}
                handleClear={!inactive && handleClear}
                className={className}
              />
            </div>}


          {/* {!suggestShown && !searchValue && Boolean(recents) &&
          <div className={`x_input_drop0 ${suffix ? `x_input_drop0--${suffix}` : ''} active`}>
            <div className={`x_input_drop_ul ${className}`}>
              <ListCitiesPresets
                handleCityObj={handleSelect}
              />
            </div>
          </div>
        } */}



          {suggestShown && <div className={`x_input_drop0 ${className} active`}>


            <div className={`x_input_drop_ul ${className}`}>


              {recents && <ListCitiesPresets
                className={className}
                handleCityObj={handleSelect}
              />
              }



              {fetching ?
                <div style={{ width: '100%', height: '8em', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Preloader0 style={{ padding: '4em 0', position: 'relative' }}><Preloader width={80} height={80} /></Preloader0>
                </div>
                :
                null

              }



              {suggest.map(one => {
                return (
                  <div
                    className={`x_input_drop_li ${className}`}
                    key={`city_${one.city}_${one.country}_${one.id}`}
                  >
                    <OneCity
                      className={className}
                      one={one}
                      handleSelect={handleSelect} />
                  </div>
                )
              })}
            </div>

          </div>}


        </div>
      </>
    </>
  );
}






export function OneCity({
  icon = '',
  show = ['city', 'province', 'country', 'timezone', 'gmt'],
  one,
  handleSelect,
  handleClear,
  className = '',
  active
}) {

  const gmt = (g) => g >= 0 ? `+${g}` : g;

  return (


    <div className={`x_input_drop_suggest ${className}`}
      style={{ minHeight: 24 }}
      onClick={() => handleSelect ? handleSelect(one) : void (0)}
    >

      <div className={`x_input_drop_li_1 ${className}`}>

        {Boolean(icon) && <div className='t_icon t_icon--40'>{icon}</div>}

        {show.includes('city') && <div className="x_input_drop_city">{one ? one['city'] : 'City'}</div>}
        <div className={`x_input_drop_country ${className}`}>{show.includes('province') && <>{one ? one['province'] : ''}</>} {show.includes('country') && <>{one ? one['country'] : ''}</>}</div>
        {(show.includes('timezone') || show.includes('gmt') || show.includes('offset')) && <div className={`x_input_drop_right  ${className}`}>
          <div className="x_input_drop_country">
            {show.includes('timezone') && <>{(one && one.timezone) ? one.timezone.replace('_', ' ') : ''}</>} {show.includes('gmt') && <>{one && `GMT${gmt(one['offset'])}`}</>}
          </div>
        </div>}


      </div>

      {handleClear &&
        <div className={`x_input_right x_input_right--city ${className}`}>
          <div className={`x_input_action ${className}`}
            // style={{}}
            onClick={handleClear}
          ><div className="t_icon t_icon--40"><div>close</div></div></div></div>}


    </div>


  )
}




