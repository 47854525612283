import React, { Fragment, useEffect, useState, useContext, useRef } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { LangContext } from '../../contexts/langContext';
import { centerGates } from '../../static/centers_dictionary';
import { findGateInUser } from '../utilsDesign';
import { FriendsWith } from './FriendsWith';
import { Hr } from './Hr';
import { Icon } from './Icon';
import { Preview } from './Preview';
import { Bodygraph } from './Bodygraph';
import BodyFocused from './BodyFocused';
import { Videos } from './Videos';
import { Txt } from './Txt';

export function CenterInfo({ center, design, penta, size }) {

  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $center = context.$center;
  const $ui = context.$ui;
  const $auths = context.$auths;


  const activatedGates = (center, design, all) => {
    const centerG = centerGates[center];
    const gates = []
    centerG.forEach(oneGate => {
      const gs = findGateInUser(design.gates, oneGate);
      if (all) {
        if (gs.length) gates.push(gs)
      } else {
        if (gs.length) gates.push(gs[0])
      }
    })
    return gates.flat()
  }

  const defined = !penta ? design.active_chakras.includes(center) : null;

  return (
    <>
      <>

        <div className="x_info_section">
          <div className="x_info_w x_info_w--100">
            <BodyFocused design={design} focus={center} style={{
              width: '28em', height: '8em',
              marginTop: '-1em'
              // borderRadiusTopLeft: 10
            }} />
          </div>
        </div>

        <div className="x_info_section" style={{ marginTop: '0.2em' }}>
          <div className="x_info_w">

            <div className="x_edu_center_top">
              <div className="x_edu_title x_edu_title--center">
                {$center(center, 'title')}
              </div>
              <div className="x_edu_content_desc">
                {$center(center, 'description')}
              </div>
            </div>

            <div className="x_edu_active">
              <div className={defined ? "x_edu_badge" : "x_edu_badge x_edu_badge--open"}>
                {defined ? $ui('edu_center_activated') : $ui('edu_center_inactivated')}
              </div>
              <div className="_f _fgap04">
                {/* <b>{$center(center, 'percentage_defined')}%</b> {$ui('edu_percent_globally')} */}
              </div>
            </div>


            {!defined && <div className="x_info_description">
              <Txt>{$center(center, 'open')}</Txt>
            </div>}
            {defined && <div className="x_info_description">
              <Txt>{$center(center, 'defined')}</Txt>
            </div>}

          </div>

          {!activatedGates(center, design).length &&
            <div className="x_info_w">
              <div className='x_edu_openopen'><Icon>lightbulb</Icon>{$ui('edu_center_open')}</div>
              <div className="">
                {$center(center, 'open_fully')}
              </div>
            </div>
          }

          {center === design.auth &&
            <div className="x_info_w">
              <div className='x_edu_authority'><Icon>lightbulb</Icon>{$ui('basic_label_authority')}: <b>{$auths(center, 'title')}</b></div>
              <div className="">
                <div className="">
                  <div className="w-richtext">
                    <Txt>
                      {$auths(design['auth'], 'description')}
                    </Txt>
                  </div>
                </div>
              </div>
            </div>
          }




        </div>

      </>



      <div className="x_info_section">
        <div className="x_info_w" style={{ paddingRight: 0 }}>
          <Videos
            title={`${$center(center, 'title')}`}
            size='sm'
            relType='centers'
            relValue={center} />
        </div>
      </div>

      {context.me && <div className="x_info_section _fv _f100 _fr ">


        {size !== 'lg' && <div className="_fv _fgap2">
          <div className="x_info_w">
            <FriendsWith
              center={center}
              size='lg'
            globally={<>{$center(center, 'percentage_defined')}%</>}
              title={<>
            {/* <b>{$ui('friends_with')}</b>  */}
                <div className="x_edu_badge" style={{ fontSize: '0.7em' }}>
                  {design.active_chakras.includes(center) && $ui('edu_activated_also')} {$ui('edu_center_activated')}
                </div></>}
              filterCase={a => a.filter(one => one.avatar.design && one.avatar.design.active_chakras && one.avatar.design.active_chakras.includes(center))}
            />
          </div>

          <div className="x_info_w">
        <FriendsWith center={center}
          size='lg'
            globally={<>{100 - $center(center, 'percentage_defined')}%</>}
          title={<>
            {/* <b>{$ui('friends_with')}</b>  */}
            <div className="x_edu_badge x_edu_badge--open"
              style={{ fontSize: '0.7em' }}
            >{!design.active_chakras.includes(center) && $ui('edu_activated_also')} {$ui('edu_center_inactivated')}</div></>}
          filterCase={a => a.filter(one => one.avatar.design && one.avatar.design.active_chakras && !one.avatar.design.active_chakras.includes(center))}
        />
          </div>
      </div>}

      </div>}

    </>
  )
}