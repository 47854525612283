import React, { Fragment, useEffect, useRef, useState, useContext } from 'react'
import { Link, withRouter, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { getVariable, getPRR } from '../../static/variables_dictionary';
import { LangContext } from '../../contexts/langContext';
import { StateContext } from '../../contexts/stateContext';
import { SocialContext } from '../../contexts/socialContext';
import { getDesignSunTone, getDesignSunColor, getEnv, getDesignNodeTone, getPersSunTone, getPersSunColor, getPersNodeColor, getPersNodeTone } from '../utilsDesign';
import { BodyArrow } from './BodyArrows';



import { Videos } from './Videos';
import { IconBase, IconColor, IconTone } from './ColorToneBase';
import { TooltipH } from './TooltipH';
import { Txt } from './Txt';





export const Variants = React.memo(({ design, options, mode }) => {

  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ui = context.$ui;
  const $vars = context.$vars;

  const scrollDivRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (scrollDivRef.current) {
        if (window.innerWidth < 640) {
          console.log('scrollDivRef.current.scrollWidth = ', scrollDivRef.current.scrollWidth);
          scrollDivRef.current.scrollLeft = scrollDivRef.current.scrollWidth * .16 - 16;
        } else {
          scrollDivRef.current.scrollLeft = 0;
        }
      }
    };

    // Initial check
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>

      <div className="x_edu_content">



        <div className="x_phs0">
          {/* <div className={`x_phs_grad1 x_phs_grad1--2 x_phs_grad1--${design.type.toLowerCase()}`} /> */}
          {/* <div className={`x_phs_grad1 x_phs_grad1--${design.type.toLowerCase()}`} /> */}
          <div className="x_phs_scroll" ref={scrollDivRef}>



            {options.map((one) => {
              if (one.mode === 'phs') return <PHSone
                key={`${design.id}_${one.title}`}
                design={design}
                title={one.title}
                varName={one.varName}
                mode={one.mode}
                getColor={one.getColor}
                getTone={one.getTone}
                getBase={one.getBase}
                transference={one.transference}
                label={one.label}
              >
                {one.children}
              </PHSone>

              if (one.mode === 'mars') return <MarsOne
                key={`${design.id}_${one.title}`}
                design={design}
                title={one.title}
                varName={one.varName}
                mode={one.mode}
                getLine={one.getLine}
                transference={one.transference}
                label={one.label}
              >
                {one.children}
              </MarsOne>

              if (one.mode === 'moon') return <MoonOne
                key={`${design.id}_${one.title}`}
                design={design}
                title={one.title}
                varName={one.varName}
                mode={one.mode}
                getLine={one.getLine}
                transference={one.transference}
                label={one.label}
              >
                {one.children}
              </MoonOne>

            })}





          </div>
        </div>



      </div>

    </>
  )
})



const PHSone = ({
  design = {},
  title = '',
  varName = '',
  getColor = () => void (0),
  getTone = () => void (0),
  getBase = () => void (0),
  transference = true,
  label = 'prs',
  mode = '',
  children
}) => {
  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ = context.$;
  const $ui = context.$ui;
  const $profile = context.$profile;
  const $line = context.$line;
  const $vars = context.$vars;
  const $envs = context.$envs;


  const trans = {
    "1": 4,
    "2": 5,
    "3": 6,
    "4": 1,
    "5": 2,
    "6": 3
  }

  function shiftArray(arr, secondElement) {
    const index = arr.indexOf(secondElement);
    if (index === -1) {
      return arr; // If secondElement is not found, return the original array
    }
    const shiftedArray = [...arr.slice(index - 1), ...arr.slice(0, index - 1)];
    return shiftedArray;
  }

  const color = getColor(design);
  const tone = getTone(design);
  const base = getBase(design);

  return (<>
    <div className="x_phs_title0">
      <div className="x_phs_title">
        <div>{title}</div>

        <div className="x_phs_title_right">
          <div className={label === 'des' ? "x_phs_coltonbas x_phs_coltonbas--des" : "x_phs_coltonbas"}>

            <div className="x_phs_col">
              {children}
            </div>



            <IconColor title={$vars('label_color')} color={color} />


            <IconTone title={$vars('label_tone')} tone={tone} />


            <IconBase title={$vars('label_base')} base={base} />

          </div>
        </div>

      </div>




    </div>
    <div className="x_phs_line">
      <div className="x_phs_scroller">

        {shiftArray([1, 2, 3, 4, 5, 6], getColor(design)).map(oneColor => <div className={`x_phs_item1 ${getColor(design) === oneColor ? 'x_phs_item1--active' : ''}`} key={`${varName}_${oneColor}`}>

          {getColor(design) === oneColor && <div className="x_phs_color_active" />}

          <div className="_fv">
          <div className={`x_phs_h`}>
            <div className={`x_choose_menu_counter  ${getColor(design) === oneColor ? 'x_choose_menu_counter--active' : ''}`}>{oneColor}</div>
            {$vars(`${varName}_${oneColor}`, 'title')}
          </div>
          {Boolean($vars(`${varName}_${oneColor}`, 'description')) && <div className="x_phs_txt">
              <Txt>
            {$vars(`${varName}_${oneColor}`, 'description')}
              </Txt>
          </div>}


          <Videos
            title={`${$vars(`label_${varName}`, 'title')}: ${oneColor} ${$vars(`${varName}_${oneColor}`, 'title')}`}
            size='sm'
            relType='variables'
            relValue={`${varName}_${oneColor}`}
          />
          </div>

          {transference && <div className="x_phs_false0">
            <div>{$vars(`label_${varName}_transference`)}: </div>
            <div className="x_choose_menu_counter">{trans[oneColor]}</div>
            <div>
              {$vars(`${varName}_${trans[oneColor]}`, 'title')}
            </div>
          </div>}



        </div>)}


      </div>
      <div className="x_phs_scroller">

        {shiftArray([1, 2, 3, 4, 5, 6], getColor(design)).map(oneColor => {

          const activeTone = (tones = []) => {
            return getColor(design) === oneColor && [...tones].includes(getTone(design))
          }

          return (<div className="x_phs_item1" key={`${varName}_desc_${oneColor}`}>

            {getColor(design) === oneColor && <div className="x_phs_color_active x_phs_color_active--2" />}

            {mode === 'phs' && <div className="x_phs_tones_lr">
              <div className="x_phs_tones_1">
                <div className="x_phs_tone_nums">


                  {[1, 2, 3].map(oneTone => <div className={`x_phs_tone_n`} key={`${varName}_tone_${oneTone}`}>
                    <TooltipH title={$vars(`sense_${varName}_${oneTone}`)}>
                      <div className={`x_phs_tone_num ${getColor(design) === oneColor && getTone(design) == oneTone ? 'x_phs_tone_num--active' : ''}`}>{oneTone}</div>
                    </TooltipH>
                  </div>)}

                </div>

                {Boolean($vars(`${varName}_${oneColor}_right`, 'description')) &&
                  Boolean($vars(`${varName}_${oneColor}_right`, 'title')) &&
                  <div className={`x_phs_tone_t  ${activeTone([1, 2, 3]) ? 'x_phs_tone_t--active' : ''}`}>
                    {activeTone([1, 2, 3]) && <div className="x_phs_tone_active" />}
                    <div className="x_phs_tone_h">
                      {$vars(`${varName}_${oneColor}_left`, 'title')}
                    </div>
                    <div className="x_phs_tone_txt">
                      {$vars(`${varName}_${oneColor}_left`, 'description')}
                    </div>
                  </div>}



              </div>
              <div className="x_phs_tones_1">
                <div className="x_phs_tone_nums">

                  {[4, 5, 6].map(oneTone => <div className={`x_phs_tone_n`} key={`${varName}_tone_${oneTone}`}>
                    <TooltipH title={$vars(`sense_${varName}_${oneTone}`)}>
                      <div className={`x_phs_tone_num ${getColor(design) === oneColor && getTone(design) == oneTone ? 'x_phs_tone_num--active' : ''}`}>{oneTone}</div>
                    </TooltipH>
                  </div>)}

                </div>

                {Boolean($vars(`${varName}_${oneColor}_right`, 'description')) &&
                  Boolean($vars(`${varName}_${oneColor}_right`, 'title')) &&
                  <div className={`x_phs_tone_t  ${activeTone([4, 5, 6]) ? 'x_phs_tone_t--active' : ''}`}>

                    {activeTone([4, 5, 6]) && <div className="x_phs_tone_active" />}

                    <div className="x_phs_tone_h">
                      {$vars(`${varName}_${oneColor}_right`, 'title')}
                    </div>
                    <div className="x_phs_tone_txt">
                      {$vars(`${varName}_${oneColor}_right`, 'description')}
                    </div>
                  </div>}
              </div>
            </div>}
          </div>)
        }

        )}


      </div>
    </div>
  </>
  )
}



const MarsOne = ({
  design = {},
  title = '',
  varName = '',
  getLine = () => void (0),
  getColor = () => void (0),
  getTone = () => void (0),
  transference = true,
  label = 'prs',
  mode = '',
  children
}) => {
  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ = context.$;
  const $ui = context.$ui;
  const $profile = context.$profile;
  const $line = context.$line;
  const $vars = context.$vars;
  const $envs = context.$envs;


  console.log('getLine(design) = ', getLine(design));


  const trans = {
    "1": 4,
    "2": 5,
    "3": 6,
    "4": 1,
    "5": 2,
    "6": 4
  }

  function shiftArray(arr, secondElement) {
    const index = arr.indexOf(secondElement);
    if (index === -1) {
      return arr; // If secondElement is not found, return the original array
    }
    const shiftedArray = [...arr.slice(index - 1), ...arr.slice(0, index - 1)];
    return shiftedArray;
  }

  let line = getLine(design);

  return (<>
    <div className="x_phs_title0">
      <div className="x_phs_title">
        <div>{title}</div>

        <div className="x_phs_title_right">

        </div>

      </div>




    </div>
    <div className="x_phs_line">
      <div className="x_phs_scroller">

        {shiftArray([1, 2, 3, 4, 5, 6], getLine(design)).map(oneColor => <div className={`x_phs_item1 ${getLine(design) === oneColor ? 'x_phs_item1--active' : ''}`} key={`${varName}_${oneColor}`}>

          {getLine(design) === oneColor && <div className="x_phs_color_active"
            style={{
              inset: '-.8em 0 -2em'
            }}
          />}

          <div className={`x_phs_h`}>
            <div className={`x_choose_menu_counter  ${getLine(design) === oneColor ? 'x_choose_menu_counter--active' : ''}`}>{oneColor}</div>
            {$vars(`${varName}_${oneColor}`, 'title')}
          </div>
          {Boolean($vars(`${varName}_${oneColor}`, 'description')) && <div className="x_phs_txt">
            {$vars(`${varName}_${oneColor}`, 'description')}
          </div>}

          <Videos
            title={`${$vars(`label_${varName}`, 'title')}: ${oneColor} ${$vars(`${varName}_${oneColor}`, 'title')}`}
            size='sm'
            relType='variables'
            relValue={`${varName}_${oneColor}`}
          />

          {/* {transference && <div className="x_phs_false0">
            <div>{$vars(`label_${varName}_transference`)}: </div>
            <div className="x_choose_menu_counter">{trans[oneColor]}</div>
            <div>
              {$vars(`${varName}_${trans[oneColor]}`, 'title')}
            </div>
          </div>} */}



        </div>)}


      </div>
      <div className="x_phs_scroller">

        {shiftArray([1, 2, 3, 4, 5, 6], getLine(design)).map(oneColor => {

          const activeTone = (tones = []) => {
            return getLine(design) === oneColor && [...tones].includes(getLine(design))
          }

          return (<div className="x_phs_item1" key={`${varName}_desc_${oneColor}`}>

            {getLine(design) === oneColor && <div className="x_phs_color_active x_phs_color_active--2" />}


          </div>)
        }

        )}


      </div>
    </div>
  </>
  )
}


const MoonOne = ({
  design = {},
  title = '',
  varName = '',
  getLine = () => void (0),
  transference = true,
  mode = 'love',
  children
}) => {
  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ = context.$;
  const $ui = context.$ui;
  const $profile = context.$profile;
  const $line = context.$line;
  const $vars = context.$vars;
  const $envs = context.$envs;


  console.log('getLine(design) = ', getLine(design));


  const trans = {
    "1": 4,
    "2": 5,
    "3": 6,
    "4": 1,
    "5": 2,
    "6": 4
  }

  function shiftArray(arr, secondElement) {
    const index = arr.indexOf(secondElement);
    if (index === -1) {
      return arr; // If secondElement is not found, return the original array
    }
    const shiftedArray = [...arr.slice(index - 1), ...arr.slice(0, index - 1)];
    return shiftedArray;
  }

  let line = getLine(design);

  return (<>
    <div className="x_phs_title0">
      <div className="x_phs_title">
        <div>{title}</div>

        <div className="x_phs_title_right">

        </div>

      </div>




    </div>
    <div className="x_phs_line">
      <div className="x_phs_scroller">

        {shiftArray([1, 2, 3, 4, 5, 6], getLine(design)).map(oneColor => <div className={`x_phs_item1 ${getLine(design) === oneColor ? 'x_phs_item1--active' : ''}`} key={`${varName}_${oneColor}`}>

          {getLine(design) === oneColor && <div className="x_phs_color_active"
            style={{
              inset: '-.8em 0 -2em'
            }}
          />}

          <div className={`x_phs_h`}>
            <div className={`x_choose_menu_counter  ${getLine(design) === oneColor ? 'x_choose_menu_counter--active' : ''}`}>{oneColor}</div>
            {$vars(`${varName}_${oneColor}`, 'title')}
          </div>
          {Boolean($vars(`${varName}_${oneColor}`, 'description')) && <div className="x_phs_txt">
            {$vars(`${varName}_${oneColor}`, 'description')}
          </div>}


          <Videos
            title={`${$vars(`label_${varName}`, 'title')}: ${oneColor} ${$vars(`${varName}_${oneColor}`, 'title')}`}
            size='sm'
            relType='variables'
            relValue={`${varName}_${oneColor}`}
          />

          {/* {transference && <div className="x_phs_false0">
            <div>{$vars(`label_${varName}_transference`)}: </div>
            <div className="x_choose_menu_counter">{trans[oneColor]}</div>
            <div>
              {$vars(`${varName}_${trans[oneColor]}`, 'title')}
            </div>
          </div>} */}



        </div>)}


      </div>
      <div className="x_phs_scroller">

        {shiftArray([1, 2, 3, 4, 5, 6], getLine(design)).map(oneColor => {

          const activeTone = (tones = []) => {
            return getLine(design) === oneColor && [...tones].includes(getLine(design))
          }

          return (<div className="x_phs_item1" key={`${varName}_desc_${oneColor}`}>

            {getLine(design) === oneColor && <div className="x_phs_color_active x_phs_color_active--2" />}


          </div>)
        }

        )}


      </div>
    </div>
  </>
  )
}