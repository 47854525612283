import React, { Fragment, useEffect, useState, useContext } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { SocialContext } from '../../contexts/socialContext';
import { LangContext } from '../../contexts/langContext';

export function BodyHint({ center, design }) {

  const context = { ...useContext(LangContext) };
  const $center = context.$center;
  const $ui = context.$ui;

  const hints = {
    HD: { 'right': true, 'size': 'short', 'second': false, },
    AA: { 'right': false, 'size': 'short', 'second': false, },
    TT: { 'right': true, 'size': '', 'second': false, },
    GC: { 'right': false, 'size': 'long', 'second': false, },
    HT: { 'right': true, 'size': '', 'second': false, },
    SN: { 'right': false, 'size': 'short', 'second': false, },
    SP: { 'right': true, 'size': 'short', 'second': false, },
    SL: { 'right': false, 'size': 'short', 'second': true, },
    RT: { 'right': true, 'size': '', 'second': false, },
  }


  return (
    <>
      <div className={`x_body_beginner0 ${`x_body_beginner0--${center.toLowerCase()}`} ${hints[center].right ? 'x_body_beginner0--r' : ''}`}>
        <div className="x_body_beginner_info_bubble">
          <div><b>{$center(center, 'title')}</b>: {$center(center, 'description')}</div>
          <div className={design.active_chakras.includes(center) ? "x_edu_badge" : "x_edu_badge x_edu_badge--open"}
            style={{ fontSize: '0.8em' }}
          >
            {design.active_chakras.includes(center) ? $ui('edu_center_activated') : $ui('edu_center_inactivated')}
          </div>
        </div>
        <div className={`x_body_beginner_info_line x_body_beginner_info_line--${hints[center].size}`} />
        {hints[center].second && <div className="x_body_beginner_info_line_sl"><div className="x_body_beginner_info_line x_body_beginner_info_line--sl" /></div>}
        <div className={`x_body_beginner_c x_body_beginner_c--${center.toLowerCase()}`} />



      </div>
    </>
  )
}