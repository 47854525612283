import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';

export function PopInfo({
  id,
  children,
  handleClose = () => void (0),
  type = '',
  title,
  icon,
  addContent,
  addClassName
}) {



  useEffect(() => {
    if (id && addClassName === 'active' && isMobile) {
      document.body.classList.add('noscroll');
    }

    const handleResize = () => {
      setIsMobile(window.innerWidth < 479);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
      document.body.classList.remove('noscroll');
    };
  }, []);

  useEffect(() => {
    if (id && addClassName === 'active' && isMobile) {
      document.body.classList.add('noscroll');
    }

    const handleResize = () => {
      setIsMobile(window.innerWidth < 479);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
      document.body.classList.remove('noscroll');
    };
  }, [id]);





  const defaultPop = 300;
  const startOffset = window.innerHeight - defaultPop;
  const gap = 50;

  const [isMobile, setIsMobile] = useState(window.innerWidth < 479);
  const [startOffset0, setStartOffset0] = useState(1000);

  const [startY, setStartY] = useState(0);
  const [dragging, setDragging] = useState(false);
  const [offsetY, setOffsetY] = useState(0); // Initial transformY is 200px
  const [addClassNameInner, setAddClassNameInner] = useState(addClassName); // Initial transformY is 200px


  // const [startOffset0, setStartOffset0] = useState(window.innerHeight);

  useEffect(() => {
    if (!isMobile && addClassName !== 'active') {
      // setStartOffset0(1000);
      // setStartY(0);
      // setOffsetY(0);
    }
  }, [addClassNameInner]);


  useEffect(() => {
    setAddClassNameInner(addClassName)
  }, [addClassName]);





  useEffect(() => {
    if (!isMobile) {
      setOffsetY(0)
    } else {
      setStartOffset0(startOffset0)
      setTimeout(() => {
        // setOffsetY(startOffset0)
        setOffsetY(startOffset)
        // setTimeout(() => {
        //   setOffsetY(startOffset)
        // }, 3550);
      }, 50);
    }
  }, [isMobile])






  useEffect(() => {
    if (isMobile) {
      setOffsetY(startOffset0)
      setAddClassNameInner()
      setTimeout(() => {
        setOffsetY(startOffset)
        setAddClassNameInner(addClassName)
      }, 50);
    }
  }, [id])

  useEffect(() => {
    if (isMobile && offsetY < startOffset - gap && offsetY) {
      setOffsetY(0)
    }
  }, [offsetY])

  const draggableHeaderRef = useRef(null);
  const mainElementRef = useRef(null);

  const handleMouseDown = (e) => {
    const clientY = e.touches ? e.touches[0].clientY : e.clientY;
    setStartY(clientY);
    setDragging(true);
  };

  const handleMouseMove = (e) => {
    if (!dragging) return;
    const clientY = e.touches ? e.touches[0].clientY : e.clientY;
    const deltaY = startY - clientY;
    const newOffsetY = Math.min(startOffset0, Math.max(0, offsetY - deltaY));
    setOffsetY(newOffsetY);
    setStartY(clientY);
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  useEffect(() => {
    if (dragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
      window.addEventListener('touchmove', handleMouseMove);
      window.addEventListener('touchend', handleMouseUp);
    } else {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('touchmove', handleMouseMove);
      window.removeEventListener('touchend', handleMouseUp);
    }
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('touchmove', handleMouseMove);
      window.removeEventListener('touchend', handleMouseUp);
    };
  }, [dragging]);

  return (<>



    <div className={`x_info0 ${addClassName}`}
      onClick={(e) => !isMobile && e.stopPropagation()}
    >
      <div className="x_info_z inact active" onClick={handleClose} />

      <Header
        onClick={(e) => {
          e.stopPropagation();
          setOffsetY(prev => prev === startOffset ? 0 : startOffset);
        }}
        className='mobile_v_yes'
        ref={draggableHeaderRef}
        onMouseDown={handleMouseDown}
        onTouchStart={handleMouseDown}
        offsetY={offsetY}
      />



      <div className={`x_info ${addClassNameInner}`}
        onClick={(e) => e.stopPropagation()}
        ref={mainElementRef}
        style={{ transform: `translateY(${offsetY}px)` }}
      >

        <div className="x_info_mobileindicator" />

        <div
          className="x_info_inner active"
          style={{
            // maxHeight: offsetY,
            // overflow: 'hidden',
            transition: 'max-height 0.2s ease',
          }}
        >


          <div
            className={`m_b_h m_b_h--${type}`}
          >
            {/* <div className="m_b_h_icon">
              <div className="t_icon t_icon--20">
                {icon}
              </div>
            </div> */}
            {/* <div className="m_b_h_title">
              {title}
            </div> */}
            <div className="x_edu_title x_edu_title--center">
              {title}
            </div>
            <div className={`x_pop_right x_pop_right--${type}`}>
              <div className="x_action x_action--white mobile_v_yes"
                onClick={(e) => {
                  e.stopPropagation();
                  setOffsetY(prev => prev === startOffset ? 0 : startOffset);
                }}
              >
                {offsetY > 10 && <div className="t_icon t_icon--20">
                  keyboard_arrow_up
                </div>}
                {offsetY < 10 && <div className="t_icon t_icon--20">
                  keyboard_arrow_down
                </div>}
              </div>

              <div className="x_action x_action--white" onClick={handleClose}>
                <div className="t_icon t_icon--20">
                  close
                </div>
              </div>
            </div>
          </div>
          {children}
        </div>

      </div>
    </div>

  </>
  );
}

const Header = styled.div`
  /* border: 1px solid red; */
  left: 0;
  right: 5em;
  height: 20em;
  /* background: white; */
  z-index: 100;
  cursor: pointer;
  user-select: none;
  position: absolute;
  top: ${(props) => `${props.offsetY}px`};
`;

export default PopInfo;
