import React, { Fragment, useEffect, useState, useContext } from 'react'
import { Link, withRouter, useHistory, useLocation, useRouteMatch } from 'react-router-dom'

import { Svg } from './Svg';
import {
  getPersNodeTone,
  getPersSunTone,
  getDesignSunTone,
  getDesignNodeTone,
  getTone,
  getArrow
} from '../utilsDesign';
import { LangContext } from '../../contexts/langContext';
import { theme } from './Bodygraph';
import { TooltipH } from './TooltipH';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';

const arrowPR = `<svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.9837 24.0237C14.2051 24.8023 12.9435 24.8051 12.1615 24.0298L12.0362 23.9056C11.2494 23.1256 11.2466 21.8545 12.0301 21.0711L18.3952 14.7059H1.15234C0.600058 14.7059 0.152344 14.2582 0.152344 13.7059V11.3849C0.152344 10.8326 0.600059 10.3849 1.15234 10.3849H18.3952L12.0293 4.02945C11.246 3.2474 11.2468 1.97782 12.0312 1.19681L12.1614 1.06717C12.943 0.288998 14.2069 0.290364 14.9868 1.07022L25.0478 11.1312C25.8288 11.9123 25.8288 13.1786 25.0478 13.9596L14.9837 24.0237Z" fill="black"/>
</svg>`
const arrowPL = `<svg width="27" height="25" viewBox="0 0 27 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.6305 24.0237C12.4092 24.8023 13.6708 24.8051 14.4528 24.0298L14.578 23.9056C15.3649 23.1256 15.3676 21.8545 14.5842 21.0711L8.21902 14.7059H25.4619C26.0142 14.7059 26.4619 14.2582 26.4619 13.7059V11.3849C26.4619 10.8326 26.0142 10.3849 25.4619 10.3849H8.21902L14.5849 4.02945C15.3683 3.2474 15.3674 1.97782 14.583 1.19681L14.4528 1.06717C13.6713 0.288998 12.4073 0.290364 11.6275 1.07022L1.56647 11.1312C0.78542 11.9123 0.785419 13.1786 1.56647 13.9596L11.6305 24.0237Z" fill="black"/>
</svg>`
const arrowBR = `<svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.9837 24.0237C14.2051 24.8023 12.9435 24.8051 12.1615 24.0298L12.0362 23.9056C11.2494 23.1256 11.2466 21.8545 12.0301 21.0711L18.3952 14.7059H1.15234C0.600058 14.7059 0.152344 14.2582 0.152344 13.7059V11.3849C0.152344 10.8326 0.600059 10.3849 1.15234 10.3849H18.3952L12.0293 4.02945C11.246 3.2474 11.2468 1.97782 12.0312 1.19681L12.1614 1.06717C12.943 0.288998 14.2069 0.290364 14.9868 1.07022L25.0478 11.1312C25.8288 11.9123 25.8288 13.1786 25.0478 13.9596L14.9837 24.0237Z" fill="#F45656"/>
</svg>`
const arrowBL = `<svg width="27" height="25" viewBox="0 0 27 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.6305 24.0237C12.4092 24.8023 13.6708 24.8051 14.4528 24.0298L14.578 23.9056C15.3649 23.1256 15.3676 21.8545 14.5842 21.0711L8.21902 14.7059H25.4619C26.0142 14.7059 26.4619 14.2582 26.4619 13.7059V11.3849C26.4619 10.8326 26.0142 10.3849 25.4619 10.3849H8.21902L14.5849 4.02945C15.3683 3.2474 15.3674 1.97782 14.583 1.19681L14.4528 1.06717C13.6713 0.288998 12.4073 0.290364 11.6275 1.07022L1.56647 11.1312C0.78542 11.9123 0.785419 13.1786 1.56647 13.9596L11.6305 24.0237Z" fill="#F45656"/>
</svg>`

const arrowR = `<svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.9837 24.0237C14.2051 24.8023 12.9435 24.8051 12.1615 24.0298L12.0362 23.9056C11.2494 23.1256 11.2466 21.8545 12.0301 21.0711L18.3952 14.7059H1.15234C0.600058 14.7059 0.152344 14.2582 0.152344 13.7059V11.3849C0.152344 10.8326 0.600059 10.3849 1.15234 10.3849H18.3952L12.0293 4.02945C11.246 3.2474 11.2468 1.97782 12.0312 1.19681L12.1614 1.06717C12.943 0.288998 14.2069 0.290364 14.9868 1.07022L25.0478 11.1312C25.8288 11.9123 25.8288 13.1786 25.0478 13.9596L14.9837 24.0237Z" fill="currentColor"/>
</svg>`
const arrowL = `<svg width="27" height="25" viewBox="0 0 27 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.6305 24.0237C12.4092 24.8023 13.6708 24.8051 14.4528 24.0298L14.578 23.9056C15.3649 23.1256 15.3676 21.8545 14.5842 21.0711L8.21902 14.7059H25.4619C26.0142 14.7059 26.4619 14.2582 26.4619 13.7059V11.3849C26.4619 10.8326 26.0142 10.3849 25.4619 10.3849H8.21902L14.5849 4.02945C15.3683 3.2474 15.3674 1.97782 14.583 1.19681L14.4528 1.06717C13.6713 0.288998 12.4073 0.290364 11.6275 1.07022L1.56647 11.1312C0.78542 11.9123 0.785419 13.1786 1.56647 13.9596L11.6305 24.0237Z" fill="currentColor"/>
</svg>`





export const BodyArrows = React.memo(({
  hints,
  theme = {
    designColor: theme.designColor,
    personalityColor: theme.personalityColor,
  },
  design = {},
  variables = {
    left_up: "right",
    left_down: "left",
    right_up: "left",
    right_down: "left",
  },
  arrowsB1,
  arrowsB2,
  arrowsP1,
  arrowsP2,
  bodygraphWidth = 90,
  size = 'md',

}) => {

  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $vars = context.$vars;

  bodygraphWidth = bodygraphWidth !== 0 ? bodygraphWidth : 90;
  // console.log('bodygraphWidth = ', bodygraphWidth !== 90 && bodygraphWidth);

  const [width, setWidth] = useState(bodygraphWidth)

  const sizes = {
    'sm': 14,
    'md': 26,
    'lg': 44,
    'xlg': 54,
  }

  useEffect(() => {
    if (bodygraphWidth !== width) setWidth(bodygraphWidth)
  }, [bodygraphWidth])

  const calculateSize = (width) => {

    const originalParentWidth = 600;
    const originalSize = sizes[(width && width > 120) ? 'md' : 'xlg'];

    const scaleFactor = (width && width < originalParentWidth) ? width / originalParentWidth * 1.2 : 1;
    // const scaleFactor = 1;
    // console.log('scaleFactor = ', scaleFactor);
    const newSize = Math.floor(originalSize * scaleFactor);
    return newSize;
  };



  return (
    <>

      <div className={`x_bodygraph_arrows ${hints ? 'x_bodygraph_arrows--blur' : ''}`}>

        <div className="x_bodygraph_arrows_1"
          style={{ fontSize: calculateSize(width / 2) }}
        >

          {['diet', 'env'].map((variable) => {
            return (<BodyArrowNew
              key={variable}
              prs={false}
              arrow={getTone[variable](design, true)}
              width={calculateSize(width)}
              height={calculateSize(width)}
              tooltip={`${$vars(`${getArrow[variable]}_${getTone[variable](design, true)}`, 'title')} ${$vars(getArrow[variable])}`}
            />)
          })}

        </div>

        <div className="x_bodygraph_arrows_1"
          style={{ fontSize: calculateSize(width / 2) }}
        >

          {['motivation', 'perspective'].map((variable) => {
            return (<BodyArrowNew
              key={variable}
              prs={true}
              arrow={getTone[variable](design, true)}
              width={calculateSize(width)}
              height={calculateSize(width)}
              tooltip={`${$vars(`${getArrow[variable]}_${getTone[variable](design, true)}`, 'title')} ${$vars(getArrow[variable])}`}
            />)
          })}

        </div>



        {/* <div className="x_bodygraph_arrows_1"
          style={{ fontSize: calculateSize(width / 2) }}
        >
          {arrowsB1 && Boolean(design.design && design.design.length) && <div className="x_bodygraph_arrow">
            {variables['left_up'] === 'right' && <Svg
              color={theme.designColor}
              width={calculateSize(width)}
              height={calculateSize(width)}
            >
              {arrowBR}
            </Svg>}
            {variables['left_up'] === 'left' && <Svg
              color={theme.designColor}
              width={calculateSize(width)}
              height={calculateSize(width)}
            >
              {arrowBL}
            </Svg>}

          </div>}

          {arrowsB2 && Boolean(design.design && design.design.length) && <div className="x_bodygraph_arrow">

            {variables['left_down'] === 'right' && <Svg
              color={theme.designColor}
              width={calculateSize(width)}
              height={calculateSize(width)}
            >
              {arrowBR}
            </Svg>}

            {variables['left_down'] === 'left' && <Svg
              color={theme.designColor}
              width={calculateSize(width)}
              height={calculateSize(width)}
            >
              {arrowBL}
            </Svg>}

          </div>}
        </div> */}

        {/* <div className="x_bodygraph_arrows_1"
          style={{ fontSize: calculateSize(width / 2) }}
        >
          {arrowsP1 && Boolean(design.personality.length) && <div className="x_bodygraph_arrow"
            style={{ margin: `${calculateSize(width) / 4} 0` }}
          >
            {variables['right_up'] === 'right' && <Svg
              color={theme.personalityColor}
              width={calculateSize(width)}
              height={calculateSize(width)}
            >
              {arrowPR}
            </Svg>}
            {variables['right_up'] === 'left' && <Svg
              color={theme.personalityColor}
              width={calculateSize(width)}
              height={calculateSize(width)}
            >
              {arrowPL}
            </Svg>}
          </div>}
          {arrowsP2 && Boolean(design.personality.length) && <div className="x_bodygraph_arrow">
            {variables['right_down'] === 'right' && <Svg
              color={theme.personalityColor}
              width={calculateSize(width)}
              height={calculateSize(width)}
            >
              {arrowPR}
            </Svg>}
            {variables['right_down'] === 'left' && <Svg
              color={theme.personalityColor}
              width={calculateSize(width)}
              height={calculateSize(width)}
            >
              {arrowPL}
            </Svg>}
          </div>}
        </div> */}
      </div>

    </>
  )
})




export const BodyArrow = React.memo(({
  design,
  variables = {
    left_up: "right",
    left_down: "left",
    right_up: "left",
    right_down: "left",
  },
  arrowsB1,
  arrowsB2,
  arrowsP1,
  arrowsP2,
  prs = false,
  title,
  description,
  size = 'md',
  tooltip = true
}) => {


  const context = { ...useContext(LangContext) };
  const $vars = context.$vars;

  const sizes = {
    'xs': 18,
    'sm': 22,
    'md': 26,
    'lg': 44,
    'xlg': 64,
  }


  return (
    <>




      {arrowsB1 && <>

        <TooltipH
          title={`${$vars(`left_up_${getDesignSunTone(design, true)}`, 'title')} ${$vars(`label_diet`)}`}
        >

          {variables['left_up'] === 'right' && <Svg
            width={sizes[size]}
            height={sizes[size]}
          >
            {arrowR}
          </Svg>}
          {variables['left_up'] === 'left' && <Svg
            width={sizes[size]}
            height={sizes[size]}
          >
            {arrowL}
          </Svg>}

        </TooltipH>
      </>}

      {arrowsB2 && <>

        <TooltipH title={`${$vars(`left_down_${getDesignNodeTone(design, true)}`, 'title')} ${$vars(`label_env`)}`}>


        {variables['left_down'] === 'right' && <Svg
          width={sizes[size]}
          height={sizes[size]}
        >
          {arrowBR}
        </Svg>}

        {variables['left_down'] === 'left' && <Svg
          width={sizes[size]}
          height={sizes[size]}
        >
          {arrowBL}
        </Svg>}

        </TooltipH>

      </>}



      {arrowsP1 && <>

        <TooltipH title={`${$vars(`right_up_${getPersSunTone(design, true)}`, 'title')} ${$vars(`label_motivation`)}`}>

        {variables['right_up'] === 'right' && <Svg
          width={sizes[size]}
          height={sizes[size]}
        >
          {arrowPR}
        </Svg>}
        {variables['right_up'] === 'left' && <Svg
          width={sizes[size]}
          height={sizes[size]}
        >
          {arrowPL}
        </Svg>}

        </TooltipH>
      </>}

      {arrowsP2 && <>

        <TooltipH title={`${$vars(`right_down_${getPersNodeTone(design, true)}`, 'title')} ${$vars(`label_perspective`)}`}>

        {variables['right_down'] === 'right' && <Svg
          width={sizes[size]}
          height={sizes[size]}
        >
          {arrowPR}
        </Svg>}
        {variables['right_down'] === 'left' && <Svg
          width={sizes[size]}
          height={sizes[size]}
        >
          {arrowPL}
        </Svg>}

        </TooltipH>
      </>}



    </>
  )
})





export const BodyArrowNew = React.memo(({
  tooltip = '',
  arrow = 'left',
  size = 'md',
  prs = false,
  width,
  height,
}) => {


  const context = { ...useContext(LangContext) };
  const $vars = context.$vars;

  const sizes = {
    'xs': 18,
    'sm': 22,
    'md': 26,
    'lg': 44,
    'xlg': 64,
  }


  return (
    <>


      <TooltipH title={tooltip} overlayClassName='inact'>

        {arrow === 'right' && <Svg
          width={width ? width : sizes[size]}
          height={height ? height : sizes[size]}
          color={prs ? theme.personalityColor : theme.designColor}
        >
          {arrowBR}
        </Svg>}
        {arrow === 'left' && <Svg
          width={width ? width : sizes[size]}
          height={height ? height : sizes[size]}
          color={prs ? theme.personalityColor : theme.designColor}
        >
          {arrowBL}
        </Svg>}

      </TooltipH>



    </>
  )
})