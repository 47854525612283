import React, { Fragment, useEffect, useState, useContext } from 'react'
import { Link, useNavigate, useSearchParams, useParams, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { CenterInfo } from '../UiKit/CenterInfo';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { LangContext } from '../../contexts/langContext';
import { Channel, ChannelTypeInfo } from '../UiKit/Channel';
import {
  prepareChannels,
  findGateInUser,
  gatherUserChannels,
  handleGateClick
} from '../utilsDesign';
import { Txt } from '../UiKit/Txt';
import { GateAllActivations } from '../UiKit/GateAllActivations';
import { Videos } from '../UiKit/Videos';
import { GateInfo } from '../UiKit/GateInfo';
import { Hr } from '../UiKit/Hr';
import { Gate } from '../UiKit/Gate';
import { FriendsWith } from '../UiKit/FriendsWith';
import ChannelFocused from '../UiKit/ChannelFocused';


export const ChannelInfo = React.memo((props) => {
  const context = { ...useContext(LangContext), ...useContext(SocialContext) };
  const $ui = context.$ui;
  const $channel = context.$channel;
  const $center = context.$center;

  const {
    uniq = '',
    id,
    label = false,
    centers = [],
    circuit = '',
    type = [],
    description,
    gates = [],
    title = '',
    design = {},
    stream = '',
    activatedWith = {},
    className,
    owner,
    penta
  } = { ...props };

  const location = useLocation();
  const navigate = useNavigate();


  return (<>

    {/* <div className="x_info_section">
      <div className="x_info_w x_info_w--100">
        <ChannelFocused
          design={design}
          // focus={gates}
          highlightedGate={gates}
          style={{
          width: '28em', height: '8em',
          marginTop: '-1em'
          // borderRadiusTopLeft: 10
        }} />
      </div>
    </div> */}

    <div className="_fv _fgap1">
      <div className="x_info_section">
        <div className="x_info_w">

          <div className="x_edu_channel_title0">
            <div className="x_edu_channel_title">
              <div className="x_edu_channel_h1">
                {context.languageReady && <><span className="x_edu_channel_of _tnowrap">{$ui('channel_of')}</span>  {$channel(id, 'title')}</>}
                {!context.languageReady && id}
              </div>
            </div>
            <div className="x_edu_channel_desc">
              <Txt>{$channel(id, 'description')}</Txt>
            </div>
          </div>

        </div>
      </div>

      <div className="x_info_section">
        <div className="x_info_w _fv _fgap02 _o06">
          <ChannelTypeInfo
            {...props}
          />
        </div>
      </div>

      <div className="x_info_section">
        <div className="x_info_w">
          <Videos
            // style={{ width: '100%' }}
            title={`${gates[0]}–${gates[1]} ${$channel(id, 'title')}`}
            size='sm'
            relType='channels'
            relValue={id} />
        </div>
      </div>

      <div className="x_info_section">
        {design && design.gates && <div className='_fv'>
          <div className="x_info_w">
            <div className="x_info_subtitle">
              {$ui('edu_channel_activations')}
            </div>

            {Boolean(findGateInUser(design.gates, gates[0]).length) &&
              <div className='x_edu_chan x_info_gate0'
                onClick={(e) => {
                  e.stopPropagation();
                  handleGateClick(gates[0], location, navigate);
                }}
              >

                <div className="_fv _fgap06">
                  <GateInfo
                    design={design}
                    number={true}
                    title={true}
                    description={true}
                    gateId={gates[0]}
                  />
                  <GateAllActivations
                    number={false}
                    hexagram={true}
                    design={design}
                    gateId={gates[0]}
                  />
                </div>

              </div>}

            {Boolean(findGateInUser(design.gates, gates[1]).length) &&
              <div className='x_edu_chan x_info_gate0'
                onClick={(e) => {
                  e.stopPropagation();
                  handleGateClick(gates[1], location, navigate);
                }}
              >

                <div className="_fv _fgap06">
                  <GateInfo
                    design={design}
                    number={true}
                    title={true}
                    description={true}
                    gateId={gates[1]}
                  />
                  <GateAllActivations
                    number={false}
                    hexagram={true}
                    design={design}
                    gateId={gates[1]} />
                </div>

              </div>}

            {
              !Boolean(findGateInUser(design.gates, gates[0]).length) &&
              <div className='x_edu_chan x_info_gate0 x_info_gate0--0' style={{ paddingRight: 0 }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleGateClick(gates[0], location, navigate);
                }}
              >
                <div className="_fv _fl _f100">
                  <GateInfo
                    design={design}
                    number={true}
                    title={true}
                    description={true}
                    gateId={gates[0]}
                  />
                  <div className="x_edu_badge x_edu_badge--open">
                    {$ui('edu_gate_no_activations')}
                  </div>
                    {context.me && <FriendsWith
                    size=''
                    titleAfter={<b>{$ui('edu_gate_activated_in')}</b>}
                    filterCase={a => a.filter(one => design && findGateInUser(one.avatar.design.gates, gates[0]).length)}
                    />}
                </div>
              </div>}

            {
              !Boolean(findGateInUser(design.gates, gates[1]).length) &&
              <div className='x_edu_chan x_info_gate0 x_info_gate0--0' style={{ paddingRight: 0 }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleGateClick(gates[1], location, navigate);
                }}
              >
                <div className="_fv  _fl _f100">
                  <GateInfo
                    design={design}
                    number={true}
                    title={true}
                    description={true}
                    gateId={gates[1]}
                  />
                  <div className="x_edu_badge x_edu_badge--open">
                    {$ui('edu_gate_no_activations')}
                  </div>
                    {context.me && <FriendsWith
                    size=''
                    titleAfter={<b>{$ui('edu_gate_activated_in')}</b>}
                    filterCase={a => a.filter(one => design && findGateInUser(one.avatar.design.gates, gates[1]).length)}
                    />}
                </div>
              </div>}

          </div>
        </div>}
      </div>
    </div>
    {context.me && <div className="x_info_section _fv _f100 _fr ">
      <div className="x_info_w">
        <FriendsWith
          // titleAfter={<b><span className="x_edu_channel_of">{$ui('channel_of')}</span>  {$channel(id, 'title')}</b>}
          titleAfter={<b>{`${gates[0]}-${gates[1]}`}</b>}
          size='lg'
          // globally={<>{$center(center, 'percentage_defined')}%</>}
          filterCase={a => a.filter(one => {
            const ch = gatherUserChannels(one.avatar.design.gates).map(one => one.id)
            return ch.includes(id)
          })}
        />
      </div>
    </div>}
  </>)
})