import React, { Fragment, useEffect, useState, useContext, useRef } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { _getCity, drawProfile, drawType, localizeDate } from '../utilsDesign';
import { Avatar } from './Avatar';

import { CityDateTime } from './CityDateTime';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { convertGMTtoDateTime } from '../utilsDesign';
import { DropDown } from './DropDown';
import { PreloaderButton, Preloader } from './kit';
import { Icon } from './Icon';
import { LangContext } from '../../contexts/langContext';
import { AvatarName } from './AvatarName';


export function PentaPanel(props) {
  const {
    member = {},
  } = { ...props };
  const navigate = useNavigate();
  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) }
  const $ui = context.$ui;

  const $type = context.$type;
  const $profile = context.$profile;


  return (
    <>

      {member && member.avatar && <div className="x_calc_result_name_penta" style={{ cursor: 'pointer' }}
        onClick={() => navigate(`/u/${member.user ? member.user.token : member.avatar.token}`)}
      >
        <Avatar
          avatar={member.avatar}
          type=''
        />

        <div className="x_calc_result_title x_calc_result_title--penta ellipsis">

          <AvatarName avatar={member.avatar} className='ellipsis' />

          {/* {member.avatar.name ? member.avatar.name : context.getDefaultTitle(member.avatar.design)} */}
          </div>

      </div>}



    </>
  )
}