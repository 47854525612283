import React, { Fragment, useEffect, useState, useContext } from 'react'
import { GateInfo } from '../UiKit/GateInfo';
import { GateAllActivations } from '../UiKit/GateAllActivations';
import { Videos } from '../UiKit/Videos';
import { FriendsWith } from '../UiKit/FriendsWith';
import { findGateInUser } from '../utilsDesign';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { LangContext } from '../../contexts/langContext';
import { gates_dictionary } from '../../static/gates_dictionary';
import BodyFocused from '../UiKit/BodyFocused';

export function StudyGate({
  design,
  gateId
}) {

  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $center = context.$center;
  const $ui = context.$ui;
  const $gate = context.$gate;

  return (
    <>

      <div className="x_info_section">
        <div className="x_info_w x_info_w--100">
          <BodyFocused
            design={design}
            focus={gates_dictionary[gateId].center}
            highlighted={gates_dictionary[gateId].center}
            highlightedGates={[gateId]}
            style={{
              width: '28em', height: '8em',
              marginTop: '-1em'
              // borderRadiusTopLeft: 10
            }} />
        </div>
      </div>


      <div className="_fv _fgap1 _f100">
        <div className="x_info_section">
          <div className="x_info_w">

            {/* <Gate
                      key={`gate_${gates[0]}`}
                      label={false}
                      design={design}
                      gateNumber={gates[0]}
                    // expert={expert}
                    /> */}

            <GateInfo
              design={design}
              number={true}
              title={true}
              hexagram={true}
              description={true}
              gateId={gateId}
            />

            <GateAllActivations
              planets={true}
              number={false}
              hexagram={false}
              design={design}
              gateId={parseInt(gateId)}
            />
            {!Boolean(findGateInUser(design.gates, gateId).length) && <div className="x_edu_badge x_edu_badge--open">
              {$ui('edu_gate_no_activations')}
            </div>}

            <Videos
              title={`${$gate(gateId, 'id')}. ${$gate(gateId, 'title')}`}
              size='sm'
              relType='gates'
              relValue={gateId.toString()}
            />

          </div>
        </div>
        {context.me && <div className="x_info_section _fv _f100 _fr ">
          <div className="x_info_w">
            <FriendsWith
              size=''
              titleAfter={<b>{$ui('edu_gate_activated_in')}</b>}
              filterCase={a => a.filter(one => design && findGateInUser(one.avatar.design.gates, gateId).length)}
            />
          </div>
        </div>}

      </div>


    </>


  )
}
