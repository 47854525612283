import React, { Fragment, useEffect, useState, useContext, useRef } from 'react';
import { Link, withRouter } from 'react-router-dom'
import styled, { css } from 'styled-components';
import { LangContext } from '../../contexts/langContext';
import { localizeDate, convertGMTtoDateTime } from '../utilsDesign';

export function Date({ children, date, time, utc, localize = true, hideYear, noWrap = true }) {

  const context = { ...useContext(LangContext) }

  const dateString = localizeDate(
    convertGMTtoDateTime(children, null, 'date'),
    context.lang,
    hideYear
  );

  const timeSring =
    convertGMTtoDateTime(children, null, 'time');

  const utcString =
    convertGMTtoDateTime(children, null, 'utc');


  return (
    <>
      {`${date ? dateString : ''}${(date && time) ? ', ' : ''}${time ? timeSring : ''} ${Boolean(utc) ? utcString : ''}`}
    </>
  )
}