import React, { Fragment, useEffect, useState, useContext, useRef } from 'react'
import { Link, withRouter, useHistory, useLocation, useRouteMatch } from 'react-router-dom'


import { Preview } from '../UiKit/Preview';
import { Background } from '../UiKit/Background';
import { drawProfile, drawType, drawAuthority } from '../utilsDesign';
import { Switch } from '../UiKit/Switch';
import { AvatarDob } from '../UiKit/AvatarPanel';
import { StateContext } from '../../contexts/stateContext';
import { SocialContext } from '../../contexts/socialContext';
import { LangContext } from '../../contexts/langContext';
import { Txt } from '../UiKit/Txt';
import { Button } from '../UiKit/Button';

export const Disclaimer = React.memo((props) => {



  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ui = context.$ui;

  return (
    <>


      <div className="x_edu_content">
      <div className="x_edu_disclaimer0">







        <div className="x_edu_content_h x_edu_content_h--2">
          {
            <>
              {$ui('disclaimer_title')}

            </>}
        </div>
          {Boolean($ui('disclaimer_info', 'rich')) &&
          <Txt>
            {$ui('disclaimer_info', 'rich')}
          </Txt>
          }
        {context.me && <div className="_f _fc">
          <Button ghost onClick={() => context.setState.setFaqShown(true)}>{$ui('disclaimer_info')}</Button>
        </div>}











      </div>
      </div>




    </>
  )
})


