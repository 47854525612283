import React, { Fragment, useEffect, useState, useContext, useRef } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { StateContext } from '../../contexts/stateContext';

export function VersionToggler(props) {
  const context = { ...useContext(StateContext) };



  const version = '2024-mar-09--1'; //🔴

  // console.log(`🔴🔴🔴🔴🔴 VERSION: ${version}`)


  return (
    <>
      <div
        className='x_version0'
      //   style={{
      //     position: 'fixed',
      //     bottom: 0,
      //     right: 0,
      //     minWidth: 40,
      //     height: 40,
      //     zIndex: 10000,
      //     display: 'flex',
      //     alignItems: 'center',
      //     justifyContent: 'center',
        // // background: '#f45656'
      // }}
        onClick={() => context.setState.setVersion(prev => prev === '.' ? 'β' : '')}
      >
        {context.state.version}
      </div >
    </>
  )
}





export const WithVersion = ({ children }) => {

  const context = { ...useContext(StateContext) };


  if (context.state.version !== '' && React.isValidElement(children)) {
    return React.cloneElement(children);
  }

};
