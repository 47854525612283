import React, { Fragment, useEffect, useState, useContext } from 'react'
import { Link, withRouter } from 'react-router-dom'
import styled, { css } from 'styled-components';
import { MessageContext, MessageProvider } from '../../contexts/messageContext';
import Chats from './Chats';
import { SocialContext } from '../../contexts/socialContext';

export function WithChats({ children }) {

  return (
    <>


        <WithChatInner />

        {children}

    </>
  )
}


const WithChatInner = ({ }) => {
  const context = { ...useContext(SocialContext) };
  const messageContext = { ...useContext(MessageContext) }
  return (
    <>
      {Boolean(context.me) && Boolean(messageContext.currentChat) && <Chats />}
    </>
  )
}