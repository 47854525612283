import React, { Component, useState, useEffect, useRef } from 'react';
import { defaultNarrative, getNarrative } from '../components/Edu/narratives';
import {
  _getUser,
  drawProfile,
  drawType,
  getLatestUpdateDate,
  _updateMe,
  parseDesignInPersonas,
  _getPublicUser
} from '../components/utilsDesign';
import { setLocal, getLocal } from '../components/utilsSocial';
import ui_local from '../static/ui_local.json';
import types_local from '../static/types_local.json';
import profiles_local from '../static/profiles_local.json';

var Airtable = require('airtable');

const LangContext = React.createContext();

function LangProvider({ children }) {

  const AIRTABLE_URL = process.env.REACT_APP_AIRTABLE_URL;
  const AIRTABLE_APIKEY = process.env.REACT_APP_AIRTABLE_APIKEY;


  var base = new Airtable({ apiKey: AIRTABLE_APIKEY }).base(AIRTABLE_URL);




  const [language, setLanguage1] = useState(getLocal('language') ? getLocal('language') : 'en')
  const [amPm, setAmPm] = useState(getLocal('ampm') ? getLocal('ampm') : 'ampm')

  const setLanguage = (l) => {
    setLanguage1(l);
    setLocal('language', l)
    setAmPm(l === 'en' ? 'ampm' : '24')
    _updateMe({ locale: l })
  }

  const [ui, setUi] = useState({ ...ui_local });
  // const [ui, setUi] = useState(getLocal('lang_ui') ? getLocal('lang_ui') : null);
  // const [ui, setUi] = useState(null);
  // const [ui, setUi] = useState({});

  const [types, setTypes] = useState({ ...types_local })
  const [profiles, setProfiles] = useState({ ...profiles_local })
  const [lines, setLines] = useState({})
  const [variables, setVariables] = useState({})
  const [environments, setEnvironments] = useState({})
  const [centers, setCenters] = useState({})
  const [authorities, setAuthorities] = useState({})
  const [planets, setPlanets] = useState({})
  const [gates, setGates] = useState({})
  const [channels, setChannels] = useState({})
  const [celebs, setCelebs] = useState({})
  const [videos, setVideos] = useState([])
  const [crosses, setCrosses] = useState([])



  // const [narratives, setNarratives] = useState(
  //   getLocal('narratives')
  //     ? getLocal('narratives')
  //     : { 'default': defaultNarrative }
  // )
  const [narratives, setNarratives] = useState(
    { 'default': defaultNarrative }
  )

  const [narrativeCurrent, setNarrativeCurrent] = useState('simple')

  //TODO: update local narrative if Airtable changed
  // const [narrativePages, setNarrativePages] = useState(
  //   getLocal('narrativePages')
  //     ? getLocal('narrativePages')
  //     : {}
  // )
  const [narrativePages, setNarrativePages] = useState(
    {}
  )


  useEffect(() => {
    if (ui && Object.keys(ui).length) {
      setLocal('lang_ui', ui)
    }
  }, [ui])


  const getNarrative = (id) => {

    if (narratives[id]) { return narratives[id] }
    else { return {} }
  }



  // useEffect(() => {
  //   const fetchAllRecords = async () => {
  //     try {
  //       const records = await base('ui').select().all();
  //       const allRecords = {};

  //       records.forEach(record => {
  //         const id = record.get('id');
  //         const data = {
  //           en: record.get('en'),
  //           ru: record.get('ru'),
  //           'rich-en': record.get('rich-en'),
  //           'rich-ru': record.get('rich-ru'),
  //           updated: record.get('updated'),
  //         };
  //         allRecords[id] = data;
  //       });

  //       setUi(allRecords);
  //     } catch (error) {
  //       console.error('Error fetching records:', error);
  //     }
  //   };

  //   if (Object.keys(ui).length === 0) {
  //     fetchAllRecords();
  //   }
  // }, [ui]);

  // const checkNarrativesChanged =




  useEffect(() => {
    if (!Object.keys(narrativePages).length) {
      base('narrative_pages').select({
        maxRecords: 100,
      }).eachPage(function page(records, fetchNextPage) {
        const page = {}
        records
          .forEach(onePage => {
            page[onePage.id] = ({
              'id': onePage.get('id'),
              'active': onePage.get('active'),
              'utility': onePage.get('utility'),
              'title-en': onePage.get('title-en') ? onePage.get('title-en') : '',
              'title-ru': onePage.get('title-ru') ? onePage.get('title-ru') : '',
              'pageTitle-en': onePage.get('pageTitle-en') ? onePage.get('pageTitle-en') : '',
              'pageTitle-ru': onePage.get('pageTitle-ru') ? onePage.get('pageTitle-ru') : '',
              'pageDescription-en': onePage.get('pageDescription-en') ? onePage.get('pageDescription-en') : '',
              'pageDescription-ru': onePage.get('pageDescription-ru') ? onePage.get('pageDescription-ru') : '',
              'bodygraph': onePage.get('bodygraph') ? onePage.get('bodygraph') : false,
              'updated': onePage.get('updated'),
            })
          })
        setNarrativePages(page)
        setLocal('narrativePages', page)
      }, function done(err) {
        if (err) { console.error(err); return; }
      });
    }
  }, [narrativePages])



  useEffect(() => {
    if (Object.keys(narratives).length === 1 && Object.keys(narrativePages).length) {
      base('narratives').select({
        maxRecords: 100,
        sort: [{ field: "order", direction: "desc" }],
      }).eachPage(function page(records, fetchNextPage) {

        const getPage = (pageId) => {
          const page = narrativePages[pageId];
          return page
        }

        let seq = JSON.parse(JSON.stringify(narratives));
        records
          .filter(one => one.get('active'))
          .forEach(record => {
            seq[record.get('id')] = ({
              'id': record.get('id'),
              'beta': record.get('beta'),
              'user_choose': record.get('user_choose'),
              'title-en': record.get('title-en'),
              'title-ru': record.get('title-ru'),
              'trigger-en': record.get('trigger-en'),
              'trigger-ru': record.get('trigger-ru'),
              'updated': record.get('updated'),
            })
            seq[record.get('id')]['pages'] = ([
              ...record.get('pages')
                .map(pageId => {
                  let pages = getPage(pageId);
                  return pages
                }),
            ])
            setNarratives(seq)
            setLocal('narratives', seq)
            // if (!narratives[record.get('id')]) setNarratives(prev => {
            //   let pr = JSON.parse(JSON.stringify(prev))
            //   pr[seq.id] = seq;
            //   return pr;
            // })
          })
      }, function done(err) {
        if (err) { console.error(err); return; }
      });
    }
  }, [narratives, narrativePages])






  useEffect(() => {
    setLocal('language', language)
  }, [ui])

  useEffect(() => {
    // console.log('narratives12312312312312313123213231232 = ', narratives);
  }, [narratives])






  useEffect(() => {
    if (!Object.keys(types).length || types['P'].local) {
      base('types').select({
        maxRecords: 100,
      }).eachPage(function page(records, fetchNextPage) {
        const types = {}
        records.forEach(onePage => {
          types[onePage.get('id')] = ({
            'id': onePage.get('id'),
            'title-en': onePage.get('title-en') ? onePage.get('title-en') : '',
            'title-ru': onePage.get('title-ru') ? onePage.get('title-ru') : '',
            'description-en': onePage.get('description-en') ? onePage.get('description-en') : '',
            'description-ru': onePage.get('description-ru') ? onePage.get('description-ru') : '',
            'description_long-en': onePage.get('description_long-en') ? onePage.get('description_long-en') : '',
            'description_long-ru': onePage.get('description_long-ru') ? onePage.get('description_long-ru') : '',
            'title_full-en': onePage.get('title_full-en') ? onePage.get('title_full-en') : '',
            'title_full-ru': onePage.get('title_full-ru') ? onePage.get('title_full-ru') : '',
            'title_plural-en': onePage.get('title_plural-en') ? onePage.get('title_plural-en') : '',
            'title_plural-ru': onePage.get('title_plural-ru') ? onePage.get('title_plural-ru') : '',
            'title_compact-en': onePage.get('title_compact-en') ? onePage.get('title_compact-en') : '',
            'title_compact-ru': onePage.get('title_compact-ru') ? onePage.get('title_compact-ru') : '',
            'title_compact_plural-en': onePage.get('title_compact_plural-en') ? onePage.get('title_compact_plural-en') : '',
            'title_compact_plural-ru': onePage.get('title_compact_plural-ru') ? onePage.get('title_compact_plural-ru') : '',
            'percentage': onePage.get('percentage') ? onePage.get('percentage') : '',
            'color_bg': onePage.get('color_bg') ? onePage.get('color_bg') : '',
            'color_border': onePage.get('color_border') ? onePage.get('color_border') : '',
            'tags-en': onePage.get('tags-en') ? onePage.get('tags-en') : '',
            'tags-ru': onePage.get('tags-ru') ? onePage.get('tags-ru') : '',
          })
        })
        setTypes(types)
      }, function done(err) {
        if (err) { console.error(err); return; }
      });
    }
  }, [])



  useEffect(() => {
    if (!Object.keys(profiles).length || profiles['13'].local) {
      base('profiles').select({
        maxRecords: 100,
      }).eachPage(function page(records, fetchNextPage) {
        const profiles = {}
        records.forEach(onePage => {
          profiles[onePage.get('id')] = ({
            'id': onePage.get('id'),
            'profile': onePage.get('profile') ? onePage.get('profile') : '',
            'title-en': onePage.get('title-en') ? onePage.get('title-en') : '',
            'title-ru': onePage.get('title-ru') ? onePage.get('title-ru') : '',
            'description-en': onePage.get('description-en') ? onePage.get('description-en') : '',
            'description-ru': onePage.get('description-ru') ? onePage.get('description-ru') : '',
            'percent': onePage.get('percent') ? onePage.get('percent') : '',
            'tags-en': onePage.get('tags-en') ? onePage.get('tags-en') : '',
            'tags-ru': onePage.get('tags-ru') ? onePage.get('tags-ru') : '',
          })
        })
        setProfiles(profiles)
      }, function done(err) {
        if (err) { console.error(err); return; }
      });
    }
  }, [])



  useEffect(() => {
    if (!Object.keys(planets).length) {
      base('planets').select({
        maxRecords: 100,
      }).eachPage(function page(records, fetchNextPage) {
        const planets = {}
        records.forEach(onePage => {
          planets[onePage.get('id')] = ({
            'id': onePage.get('id'),
            'glyph': onePage.get('glyph') ? onePage.get('glyph') : '',
            'short-en': onePage.get('short-en') ? onePage.get('short-en') : '',
            'short-ru': onePage.get('short-ru') ? onePage.get('short-ru') : '',
            'title-en': onePage.get('title-en') ? onePage.get('title-en') : '',
            'title-ru': onePage.get('title-ru') ? onePage.get('title-ru') : '',
            'description-en': onePage.get('description-en') ? onePage.get('description-en') : '',
            'description-ru': onePage.get('description-ru') ? onePage.get('description-ru') : '',
            'tags-en': onePage.get('tags-en') ? onePage.get('tags-en') : '',
            'tags-ru': onePage.get('tags-ru') ? onePage.get('tags-ru') : '',
          })
        })
        setPlanets(planets)
      }, function done(err) {
        if (err) { console.error(err); return; }
      });
    }
  }, [planets])


  useEffect(() => {
    if (!Object.keys(lines).length) {
      base('lines').select({
        maxRecords: 100,
      }).eachPage(function page(records, fetchNextPage) {
        const lin = {}
        records.forEach(onePage => {
          lin[onePage.get('id')] = ({
            'id': onePage.get('id'),
            'title-en': onePage.get('title-en') ? onePage.get('title-en') : '',
            'title-ru': onePage.get('title-ru') ? onePage.get('title-ru') : '',
            'description-en': onePage.get('description-en') ? onePage.get('description-en') : '',
            'description-ru': onePage.get('description-ru') ? onePage.get('description-ru') : '',
            'percent': onePage.get('percent') ? onePage.get('percent') : '',
          })
        })
        setLines(lin)
      }, function done(err) {
        if (err) { console.error(err); return; }
      });
    }
  }, [lines])



  useEffect(() => {
    if (!Object.keys(gates).length) {
      base('gates').select({
        maxRecords: 100,
      }).eachPage(function page(records, fetchNextPage) {
        const lin = {}
        records.forEach(onePage => {
          lin[onePage.get('id')] = ({
            'id': onePage.get('id'),
            'title-en': onePage.get('title-en') ? onePage.get('title-en') : '',
            'title-ru': onePage.get('title-ru') ? onePage.get('title-ru') : '',
            'hexagram': onePage.get('hexagram') ? onePage.get('hexagram') : '',
            'description-en': onePage.get('description-en') ? onePage.get('description-en') : '',
            'description-ru': onePage.get('description-ru') ? onePage.get('description-ru') : '',
            'type': onePage.get('types') ? onePage.get('types')[0] : '',
            'circuit': onePage.get('circuits') ? onePage.get('circuits')[0] : '',
          })
        })
        setGates(lin)
      }, function done(err) {
        if (err) { console.error(err); return; }
      });
    }
  }, [gates])


  useEffect(() => {
    if (!Object.keys(channels).length) {
      base('channels').select({
        maxRecords: 100,
      }).eachPage(function page(records, fetchNextPage) {
        const lin = {}
        records.forEach(onePage => {
          lin[onePage.get('id')] = ({
            'id': onePage.get('id'),
            'title-en': onePage.get('title-en') ? onePage.get('title-en') : '',
            'title-ru': onePage.get('title-ru') ? onePage.get('title-ru') : '',
            'description-en': onePage.get('description-en') ? onePage.get('description-en') : '',
            'description-ru': onePage.get('description-ru') ? onePage.get('description-ru') : '',
            type: onePage.get('types') ? onePage.get('types') : [],
            circuit: onePage.get('circuit') ? onePage.get('circuit') : [],
            stream: onePage.get('stream') ? onePage.get('stream') : [],
            gates: onePage.get('gates') ? [parseInt(onePage.get('gates')[0]), parseInt(onePage.get('gates')[1])] : [],
            centers: onePage.get('center1') && onePage.get('center2') ? [...onePage.get('center1'), ...onePage.get('center2')] : [],
          })
        })

          //   linArray.push({
          //     id: onePage.get('id'),
          //     type: onePage.get('types') ? onePage.get('types') : [],
          //     circuit: onePage.get('circuit') ? onePage.get('circuit') : [],
          //     stream: onePage.get('stream') ? onePage.get('stream') : [],
          //     gates: onePage.get('gates') ? [parseInt(onePage.get('gates')[0]), parseInt(onePage.get('gates')[1])] : [],
          //     centers: onePage.get('center1') && onePage.get('center2') ? [...onePage.get('center1'), ...onePage.get('center2')] : [],
          //   })
          // })
          // console.log(JSON.stringify(linArray));

        setChannels(lin)
      }, function done(err) {
        if (err) { console.error(err); return; }
      });
    }
  }, [channels])




  useEffect(() => {
    const fetchAllRecords = async () => {
      try {
        const records = await base('variables').select().all();
        const allRecords = {};

        records.forEach(record => {
          const id = record.get('id');
          const data = {
            'id': record.get('id'),
            'transference': record.get('transference'),
            'title-en': record.get('title-en') ? record.get('title-en') : '',
            'title-ru': record.get('title-ru') ? record.get('title-ru') : '',
            'description-en': record.get('description-en') ? record.get('description-en') : '',
            'description-ru': record.get('description-ru') ? record.get('description-ru') : '',
          };
          allRecords[id] = data;
        });

        setVariables(allRecords);
      } catch (error) {
        console.error('Error fetching records:', error);
      }
    };

    if (Object.keys(variables).length === 0) {
      fetchAllRecords();
    }
  }, []);


  useEffect(() => {
    const fetchAllRecords = async () => {
      try {
        const records = await base('crosses').select().all();
        const allRecords = {};

        records.forEach(record => {
          const id = `${record.get('angle')}_${record.get('id')}`;
          const data = {
            'id': record.get('id'),
            'title-en': record.get('title-en') ? record.get('title-en') : '',
            'title-ru': record.get('title-ru') ? record.get('title-ru') : '',
            'angle': record.get('angle') ? record.get('angle') : '',
            'description-en': record.get('description-en') ? record.get('description-en') : '',
            'description-ru': record.get('description-ru') ? record.get('description-ru') : '',
          };
          allRecords[id] = data;
        });
        setCrosses(allRecords);
      } catch (error) {
        console.error('Error fetching records:', error);
      }
    };

    if (Object.keys(crosses).length === 0) {
      fetchAllRecords();
    }
  }, []);





  useEffect(() => {
    if (!Object.keys(environments).length) {
      base('environments').select({
        maxRecords: 100,
      }).eachPage(function page(records, fetchNextPage) {
        const data = {}
        records.forEach(one => {
          data[one.get('id')] = ({
            'id': one.get('id'),
            'title-en': one.get('title-en') ? one.get('title-en') : '',
            'title-ru': one.get('title-ru') ? one.get('title-ru') : '',
            'description-en': one.get('description-en') ? one.get('description-en') : '',
            'description-ru': one.get('description-ru') ? one.get('description-ru') : '',
            'resonating': one.get('resonating') ? one.get('resonating') : '',
          })
        })
        setEnvironments(data)
      }, function done(err) {
        if (err) { console.error(err); return; }
      });
    }
  }, [environments])


  useEffect(() => {
    if (!Object.keys(authorities).length) {
      base('authorities').select({
        maxRecords: 100,
      }).eachPage(function page(records, fetchNextPage) {
        const data = {}
        records.forEach(one => {
          data[one.get('id')] = ({
            'id': one.get('id'),
            'title-en': one.get('title-en') ? one.get('title-en') : '',
            'title-ru': one.get('title-ru') ? one.get('title-ru') : '',
            'description-en': one.get('description-en') ? one.get('description-en') : '',
            'description-ru': one.get('description-ru') ? one.get('description-ru') : '',
            'tags-en': one.get('tags-en') ? one.get('tags-en') : '',
            'tags-ru': one.get('tags-ru') ? one.get('tags-ru') : '',
          })
        })
        setAuthorities(data)
      }, function done(err) {
        if (err) { console.error(err); return; }
      });
    }
  }, [authorities])

  useEffect(() => {
    if (!Object.keys(centers).length) {
      base('centers').select({
        maxRecords: 100,
      }).eachPage(function page(records, fetchNextPage) {
        const data = {}
        records.forEach(one => {
          data[one.get('id')] = ({
            'id': one.get('id'),
            'title-en': one.get('title-en') ? one.get('title-en') : '',
            'title-ru': one.get('title-ru') ? one.get('title-ru') : '',
            'description-en': one.get('description-en') ? one.get('description-en') : '',
            'description-ru': one.get('description-ru') ? one.get('description-ru') : '',
            'percentage_defined': one.get('percentage_defined') ? one.get('percentage_defined') : '',
            'description-long-en': one.get('description-long-en') ? one.get('description-long-en') : '',
            'description-long-ru': one.get('description-long-ru') ? one.get('description-long-ru') : '',
            'open-en': one.get('open-en') ? one.get('open-en') : '',
            'open-ru': one.get('open-ru') ? one.get('open-ru') : '',
            'open_fully-en': one.get('open_fully-en') ? one.get('open_fully-en') : '',
            'open_fully-ru': one.get('open_fully-ru') ? one.get('open_fully-ru') : '',
            'defined-en': one.get('defined-en') ? one.get('defined-en') : '',
            'defined-ru': one.get('defined-ru') ? one.get('defined-ru') : '',
            'is_authority-en': one.get('is_authority-en') ? one.get('is_authority-en') : '',
            'is_authority-ru': one.get('is_authority-ru') ? one.get('is_authority-ru') : '',
          })
        })
        setCenters(data)
      }, function done(err) {
        if (err) { console.error(err); return; }
      });
    }
  }, [centers])




  const celebsTable = process.env.REACT_APP_AIRTABLE_CELEBS;
  useEffect(() => {
    if (!Object.keys(celebs).length) {
      base(celebsTable).select({
        maxRecords: 100,
      }).eachPage(function page(records, fetchNextPage) {
        const results = {};
        records
          .filter(one => one.get('active'))
          .filter(one => one.get('hd_id'))
          .forEach(one => {
            results[one.get('hd_id')] = ({
              'id': one.get('id'),
              'hd_id': one.get('hd_id') ? one.get('hd_id') : '',
            })
          })
        setCelebs(results)
      }, function done(err) {
        if (err) {
          console.log(err);
          // console.error(err);
          setCelebs({})
          return;
        }
      });
    }
  }, [])



  useEffect(() => {
    if (!videos.length) {
      const fetchAllRecords = async () => {
        try {
          const records = await base('videos').select().all();
          const results = records
            .filter(one => one.get('active'))
            .map(one => ({
              '_id': one.id,
              'id': one.get('id'),
              'youtubeId': one.get('youtubeId') ? one.get('youtubeId') : '',
              'startTime': one.get('start_time') ? one.get('start_time') : 0,
              'endTime': one.get('end_time') ? one.get('end_time') : null,
              'locale': one.get('locale'),
              'comment-en': one.get('comment-en'),
              'comment-ru': one.get('comment-ru'),
              'start': one.get('start') ? one.get('start') : 0,
              'rel-types': one.get('rel-types'),
              'rel-profiles': one.get('rel-profiles'),
              'rel-channels': one.get('rel-channels'),
              'rel-gates': one.get('rel-gates'),
              'rel-auths': one.get('rel-auths'),
              'rel-variables': one.get('rel-variables'),
              'rel-centers': one.get('rel-centers'),
            }))

          setVideos(results)
        } catch (error) {
          console.error('Error fetching records:', error);
        }
      };

      if (Object.keys(variables).length === 0) {
        fetchAllRecords();
      }
    }
  }, []);






  const [celebsData, setCelebsData] = useState([]);

  useEffect(() => {
    if (Object.keys(celebs).length > 0 && celebsData.length === 0) {
      // Use a Set to keep track of userIds that have been fetched successfully
      const fetchedUserIds = new Set();

      const fetchUser = async (userId) => {
        try {
          // Check if userId has already been fetched successfully
          if (fetchedUserIds.has(userId)) {
            return null; // Return null to skip fetching again
          }

          const userData = await new Promise((resolve) => {
            _getPublicUser(userId, (userData) => {
              resolve(userData);
            }, (error) => {
              // Handle error if needed
              // console.error('Error fetching user:', error);
              console.log('Error fetching user:', error);
              resolve(null); // Resolve with null if there's an error
            });
          });

          // Add userId to fetchedUserIds if userData is not null
          if (userData !== null) {
            fetchedUserIds.add(userId);
          }

          return userData;
        } catch (error) {
          console.error('Error fetching user:', error);
          return null; // Return null if there's an error
        }
      };

      const fetchDataForCelebs = async () => {
        try {
          const promises = Object.keys(celebs).map(oneId => fetchUser(oneId));
          const userDataArray = await Promise.all(promises);
          setCelebsData(parseDesignInPersonas(userDataArray.filter(userData => userData !== null))); // Filter out null values
        } catch (error) {
          // console.error('Error fetching celebs:', error);
          console.log('Error fetching celebs:', error);
        }
      };

      fetchDataForCelebs();
    }
  }, [celebs]);




  const $ui = (str, field, quantity = 1) => {
    if (ui && Object.keys(ui).length) {

      const lang = quantity > 1 && ui[str][`${language}+`] ? `${language}+` : language;
      if (!field) { return ui[str] ? ui[str][lang] : str }
      else { return ui[str][`${field}-${lang}`] ? ui[str][`${field}-${lang}`] : str }
    } else {
      return str
    }
  };

  const $ = (str, obj) => obj[`${str}-${language}`]

  const $type = (id, str) => types[id] ? types[id][`${str}-${language}`] ? types[id][`${str}-${language}`] : types[id][`${str}`] : id;
  const $profile = (id, str) => {
    let a = profiles[id] ? profiles[id][`${str}-${language}`] ? profiles[id][`${str}-${language}`] : profiles[id][`${str}`] : id
    return a
  }
  // const $line = (id, str) => lines[id] ? lines[id][`${str}-${language}`] ? lines[id][`${str}-${language}`] : lines[id][`${str}`] : <span className='x_txt_loading'>{id}</span>;
  const $line = (id, str) => lines[id] ? lines[id][`${str}-${language}`] ? lines[id][`${str}-${language}`] : lines[id][`${str}`] : id;
  const $vars = (id, str = 'title') => variables[id] ? variables[id][`${str}-${language}`] ? variables[id][`${str}-${language}`] : variables[id][`${str}`] : str !== 'title' ? '' : id;
  const $envs = (id, str) => environments[id] ? environments[id][`${str}-${language}`] ? environments[id][`${str}-${language}`] : environments[id][str] : str !== 'title' ? '' : id;
  const $center = (id, str) => centers[id] ? centers[id][`${str}-${language}`] ? centers[id][`${str}-${language}`] : centers[id][str] : str !== 'title' ? '' : id;
  const $auths = (id, str) => authorities[id] ? authorities[id][`${str}-${language}`] ? authorities[id][`${str}-${language}`] : authorities[id][str] : str !== 'title' ? '' : id;
  const $planet = (id, str) => planets[id] ? planets[id][`${str}-${language}`] ? planets[id][`${str}-${language}`] : planets[id][str] : str !== 'title' ? '' : id;
  const $channel = (id, str) => channels[id] ? channels[id][`${str}-${language}`] ? channels[id][`${str}-${language}`] : channels[id][str] : str !== 'title' ? '' : id;
  const $gate = (id, str) => gates[id] ? gates[id][`${str}-${language}`] ? gates[id][`${str}-${language}`] : gates[id][str] : str === 'hexagram' ? '' : id;
  const $narr = (id, str) => {
    return narratives[id] ? narratives[id][`${str}-${language}`] ? narratives[id][`${str}-${language}`] : narratives[id][str] : id
  };

  const $cross = (id, str) => crosses[id] ? crosses[id][`${str}-${language}`] ? crosses[id][`${str}-${language}`] : crosses[id][str] : id;

  const defaultTitles = {
    en: {
      short: 'Unnamed'
    },
    ru: {
      short: 'Безымянный'
    }
  }

  const getDefaultTitle = (design, show = 'unnamed type') => {
    let ret = 'Unnamed'
    let typ = design ? design['type'] : ''
    let prof = design ? design['profile'] : []
    // if (show = 'type' && typ) ret = `${defaultTitles[language]['short']} ${drawType(typ)}`
    if (show === 'unnamed type' && typ) ret = `${defaultTitles[language]['short']} ${drawType(typ, 'short')}${drawProfile(prof)}`
    if (show === 'type' && typ) ret = `${drawType(typ, 'short')} ${drawProfile(prof)}`
    return ret;
  }



  const getVideoList = (rel, relValue, locale = language) => {

    const varLabel = relValue.split('_')[0];
    const relLabelVideo = `label_${varLabel}`

    const getLabelVideo = videos
      .filter(one => one.locale.includes(locale))
      .map(one => ({
        ...one,
        comment: one[`comment-${language}`]
      }))
      .filter(one => {
        return one[`rel-${rel}`] ? one[`rel-${rel}`].includes(relLabelVideo) : false
      });

    const videosLocale =
      videos
        .filter(one => one.locale.includes(locale))
        .map(one => ({
          ...one,
          comment: one[`comment-${language}`]
        }))
        .filter(one => one[`rel-${rel}`] ? one[`rel-${rel}`].includes(relValue) : false)
    return [...getLabelVideo, ...videosLocale]
  }

  const getVideoById = (_id) => {
    let vid = videos.filter(one => one._id === _id)[0];
    if (vid) {
      vid['comment'] = vid[`comment-${language}`];
    } else {
      vid = {}
    }
    return vid;
  }

  const languages = ['en', 'ru']

  const [languageReady, setLanguageReady] = useState()

  useEffect(() => {
    if (
      Object.keys(ui).length &&
      Object.keys(types).length &&
      Object.keys(profiles).length &&
      Object.keys(lines).length &&
      Object.keys(gates).length &&
      Object.keys(variables).length &&
      Object.keys(environments).length &&
      Object.keys(authorities).length &&
      Object.keys(channels).length &&
      Object.keys(planets).length
    ) {
      setLanguageReady(true)
    }
  }, [
    ui,
    types,
    profiles,
    lines,
    gates,
    variables,
    environments,
    authorities,
    channels,
    planets,
  ])


  return (
    <LangContext.Provider
      value={{
        languageReady,
        languages,
        language,
        amPm,
        narratives,
        narrativeCurrent,
        celebs,
        videos,
        celebsData,
        lang: {
          ui,
          types,
          profiles,
          lines,
          gates,
          variables,
          environments,
          authorities,
          channels,
          planets
        },
        setLanguage,
        $ui,
        $,
        $type,
        $profile,
        $line,
        $vars,
        $envs,
        $narr,
        $auths,
        $planet,
        $channel,
        $center,
        $gate,
        $cross,
        setNarrativeCurrent,
        getDefaultTitle,
        getNarrative,
        getVideoList,
        getVideoById
      }}
    >
      {
        narratives
        && children}
    </LangContext.Provider>
  )

}

const LangConsumer = LangContext.Consumer;

export { LangContext, LangProvider, LangConsumer };