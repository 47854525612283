import React, { Fragment, useEffect, useState, useContext, useRef } from 'react';
import { Link, useNavigate, useSearchParams, useParams, useLocation } from 'react-router-dom';
import { SocialContext } from '../contexts/socialContext';
import { StateContext } from '../contexts/stateContext';
import { LangContext } from '../contexts/langContext';

export function WithURLparams(props) {
  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) }

  let urlParams = new URLSearchParams(window.location.search);
  const [searchParams] = useSearchParams();
  const queryString = searchParams.toString().replace('%3A', ':').replace('%2F', '/');

  const location = useLocation();

  const lang = urlParams.get('ln') && context.languages.includes(urlParams.get('ln')) ? urlParams.get('ln') : '';


  const removeLanguageParam = () => {
    const hash = location.hash;
    const params = new URLSearchParams(hash.slice(1)); // Remove the leading '#'
    params.delete('ln');
    const newHash = params.toString();
    window.history.replaceState(null, '', `${location.pathname}${location.search}${newHash ? `#${newHash}` : ''}`);
  };

  useEffect(() => {
    const hash = location.hash;
    const params = new URLSearchParams(hash.slice(1)); // Remove the leading '#'
    const languageParam = params.get('ln');
    if (languageParam !== context.language && context.languages.includes(languageParam)) {
      context.setLanguage(languageParam)
    }
    removeLanguageParam()
  }, [location]);


  const addProps = {}

  return (
    <>
      {React.cloneElement(props.children, { ...addProps })}
    </>
  )
}