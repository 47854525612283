import React from 'react'
import styled, { css } from 'styled-components';
import { getCenter } from '../../static/centers_dictionary';
import { Preview } from './Preview';
import { Bodygraph } from './Bodygraph';
import { Background } from './Background';

export default function BodyFocused({
  style = {},
  design,
  focus = 'HD',
  title = false,
  rounded = true,
  size = 'md',
  highlighted,
  numbers = true,
  width = '12em',
  height = '12em',
  highlightedGates,
}) {


  const sizes = {
    md: {
      width: '12em',
      height: '12em',
      bodyWidth: 600,
    },
    sm: {
      width: '6em',
      height: '6em',
      bodyWidth: 300,
    },
  }

  const focusPosition = {
    "sm": {
      "HD": {
        x: '0%',
        y: '20.5%',
      },
      "AA": {
        x: '0%',
        y: '14.5%',
      },
      "TT": {
        x: '0%',
        y: '6.5%',
      },
      "GC": {
        x: '0%',
        y: '-2%',
      },
      "HT": {
        x: '-17%',
        y: '-6.2%',
      },
      "SL": {
        x: '0%',
        y: '-13%',
      },
      "SN": {
        x: '29.5%',
        y: '-12.5%',
      },
      "SP": {
        x: '-28%',
        y: '-12.5%',
      },
      "RT": {
        x: '0%',
        y: '-20.6%',
      },
    },
    "md": {
      "HD": {
        x: '0%',
        y: '39%',
      },
      "AA": {
        x: '0%',
        y: '29%',
      },
      "TT": {
        x: '0%',
        y: '12%',
      },
      "GC": {
        x: '0%',
        y: '-4%',
      },
      "HT": {
        x: '-17%',
        y: '-12%',
      },
      "SL": {
        x: '0%',
        y: '-25%',
      },
      "SN": {
        x: '29%',
        y: '-24%',
      },
      "SP": {
        x: '-28%',
        y: '-24%',
      },
      "RT": {
        x: '0%',
        y: '-40%',
      },
    }
  }

  return (
    <>
      <CSSbody1
        width={!style.width ? sizes[size]['width'] : style.width ? style.width : width}
        height={!style.height ? sizes[size]['height'] : style.height ? style.height : width}
        style={{ ...style }}
      >

        {title && <CSStitle>{getCenter([focus], 'title')}</CSStitle>}

        <CSSbodyInner
          {...focusPosition[size][focus]}
        >
          <Preview
            numbers={true}
            simplified={false}
            highlighted={[focus]}
            focusedCenter={[highlighted] ? focus : false}
            highlightedGates={highlightedGates}
            centers={[focus]}
            width={sizes[size]['bodyWidth']}
            design={design}
          // aura={false}
          />
        </CSSbodyInner>
        <Background style={{ zIndex: -1 }} aura={false} design={design} />
      </CSSbody1>
    </>
  )
}


const CSStitle = styled.div`
  position: absolute;
  z-index: 1;
  font-weight: 600;
`
const CSSbody1 = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: ${props => props.width};
  height: ${props => props.height};
  /* border-radius: 1em; */
  /* border: 1px solid black; */
  /* margin: 1em; */
`
const CSSbodyInner = styled.div`
  transform: translate(${props => props.x}, ${props => props.y});
`