import React, { useState, useContext, useEffect } from "react";
import styled, { css } from "styled-components";
import { Svg } from './Svg';




export const preloader = `<svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
    <path fill="currentColor" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
      <animateTransform
         attributeName="transform"
         attributeType="XML"
         type="rotate"
         dur="1s"
         from="0 50 50"
         to="360 50 50"
         repeatCount="indefinite" />
  </path>
</svg>`

export const Flex = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ center }) =>
    center &&
    css`
      justify-content: center;
      align-items: center;
    `}

  ${({ left }) =>
    left &&
    css`
      justify-content: flex-start;
    `}
  ${({ right }) =>
    right &&
    css`
      justify-content: flex-end;
    `}
  ${({ top }) =>
    top &&
    css`
      align-items: flex-start;
    `}
  ${({ vertical }) =>
    vertical &&
    css`
      flex-direction: column;
    `}

  @media (max-width: 991px) {
    /* display: block; */
  }
`;

export const Button = styled.div`
  transition: color 150ms ease-in-out, border 150ms ease-in-out,
    background 150ms ease-in-out;

  white-space: nowrap;
  text-decoration: none;
  & * {
    text-decoration: none !important;
  }
  display: inline-flex;
  align-items: center;
  /* background: ${({ flat }) =>
    typeof flat !== "undefined"
      ? "transparent"
      : "var(--main-accent-color)"}; */

  font-weight: 700;
  color: var(--main-accent-text-color);

  ${({ flat }) =>
    flat &&
    css`
      color: var(--main-accent-color);
      background: transparent;
    `}


  padding: 1.2em 2em 1.25em 2em;
  font-size: ${({ sm }) => (sm ? "0.7em" : "1em")};
  line-height: 1em;
  border-radius: 0.45em;
  text-transform: uppercase;
  cursor: pointer;
  border: 1px solid var(--main-accent-color);
  &:hover {
    border: 1px solid var(--main-accent-hover-color);
    background: var(--main-accent-hover-color);
    ${({ flat }) =>
    flat &&
    css`
        color: var(--main-accent-hover-color);
        background: transparent;
      `}
    ${({ ghost }) =>
    ghost &&
    css`
        color: var(--main-accent-hover-color);
        border: 1px solid var(--main-accent-hover-color);
        background: transparent;
      `}
    ${({ disabled }) =>
    disabled &&
    css`
        background: #ccc;
        border-color: #ccc;
        cursor: wait;
      `}
  }

  background: var(--main-accent-color);
  ${({ flat }) =>
    flat &&
    css`
      background: transparent;
      padding: 1em 0em;
      border: 1px solid transparent;
      @media (max-width: 991px) {
        border: 1px solid var(--main-accent-hover-color);
      }
    `}
  ${({ ghost }) =>
    ghost &&
    css`
      color: var(--main-accent-color);
      background: transparent;
      /* padding: 1em 1em; */
    `}
  ${({ sm }) =>
    sm &&
    css`
      padding: 1em 1em;
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      background: #ccc;
      border-color: #ccc;
      cursor: wait;
    `}
  ${({ black }) =>
    black &&
    css`
      color: black;
    `}
  ${({ round }) =>
    round &&
    css`
      display: flex;
      justify-content: center;
      border-radius: 1000px;
      width: 44px;
      height: 44px;
      padding: 0;
    `}
  ${({ left }) =>
    left &&
    css`
      margin-right: 1em;
    `}
    ${({ secondary }) =>
    secondary &&
    css`
      color: var(--main-text-lighter-color);
      background: var(--main-bg-lighter-color);
      border-color: var(--main-bg-lighter-color);
      &:hover {
        color: var(--main-text-color);
        background: var(--main-bg-lighter-color);
        border-color: var(--main-bg-lighter-color);
      }
    `}
`;

export const CSSmainLayout = styled.div`
  padding: 0em 1em 0 3em;
  display: grid;
  grid-template-columns: 200px 1fr 280px;
  grid-column-gap: 2em;
  grid-row-gap: 1em;

  @media (max-width: 1280px) {
    padding: 0em 2em;
  }

  @media (max-width: 991px) {
    display: block;
    padding: 0em 1em;
  }

  @media (max-width: 767px) {
  }

  @media (max-width: 479px) {
  }
  ${({ middle }) =>
    middle &&
    css`
      align-items: center;
    `}
`;

export const Line = styled.div`
  margin-bottom: 0.8em;
  ${({ xs }) =>
    typeof xs !== "undefined" &&
    css`
      margin-bottom: 0em;
    `}
  ${({ sm }) =>
    typeof sm !== "undefined" &&
    css`
      margin-bottom: 0.4em;
    `}
  ${({ lg }) =>
    typeof lg !== "undefined" &&
    css`
      margin-bottom: 1.2em;
    `}
  ${({ xlg }) =>
    typeof xlg !== "undefined" &&
    css`
      margin-bottom: 2em;
    `}
`;

export const Text = styled.div`
  line-height: 1.4em;
  /* margin-bottom: 0.8em; */
  ${({ xs }) =>
    typeof xs !== "undefined" &&
    css`
      font-size: 0.7em;
    `}
  ${({ sm }) =>
    typeof sm !== "undefined" &&
    css`
      font-size: 0.9em;
    `}
  ${({ lg }) =>
    typeof lg !== "undefined" &&
    css`
      font-size: 1.2em;
    `}
  ${({ xlg }) =>
    typeof xlg !== "undefined" &&
    css`
      font-size: 2em;
    `}
  ${({ xxlg }) =>
    typeof xxlg !== "undefined" &&
    css`
      font-size: 3em;
    `}
  ${({ opacity }) =>
    typeof opacity !== "undefined" &&
    css`
      opacity: ${opacity};
    `}
  ${({ bold }) =>
    typeof bold !== "undefined" &&
    css`
      font-weight: 600;
    `}
  ${({ color }) =>
    typeof color !== "undefined" &&
    css`
      color: ${color};
    `}
  ${({ center }) =>
    typeof center !== "undefined" &&
    css`
      text-align: center;
    `}
`;

/* export const Hr = styled.hr`
  background: transparent;
  height: 0px;
  border: none;
  border-bottom: 1px solid var(--main-border-color);
`; */

export const MIcon = styled.span`
  max-width: 2em;
  /* overflow: hidden; */
  margin-top: -2px;
  text-transform: lowercase;
  font-size: ${({ size }) => (size ? `${size}px` : "32px")};
  margin-right: ${({ left }) => (left ? "4px" : "0px")};
  font-family: "Materialicons";
  ${({ color }) =>
    typeof color !== "undefined" &&
    css`
      color: ${color};
    `}
  ${({ left }) =>
    typeof left !== "undefined" &&
    css`
      margin-right: 0.4em;
    `}
  ${({ right }) =>
    typeof right !== "undefined" &&
    css`
      margin-left: 0.4em;
    `}
`;

export const MobHide = styled.div`
  width: 100%;
  display: block;
  @media (max-width: 991px) {
    display: none;
  }
`;

export const MobShow = styled.div`
  width: 100%;
  display: none;
  @media (max-width: 991px) {
    display: block;
  }
`;
export const MobHideSpan = styled.span`
  width: 100%;
  display: block;
  @media (max-width: 991px) {
    display: none;
  }
`;

export const MobShowSpan = styled.span`
  width: 100%;
  display: none;
  @media (max-width: 991px) {
    display: block;
  }
`;

export const PopClose = ({ handle }) => {
  const CSSclose = styled.div`
    position: absolute;
    left: auto;
    right: -1em;
    top: auto;
    bottom: auto;
    z-index: 1;
    display: flex;
    width: 4em;
    height: 4em;
    justify-content: center;
    align-items: center;
    @media (max-width: 991px) {
      right: 0em;
    }
  `;

  return (
    <CSSclose>
      <Button flat black round>
        <MIcon onClick={handle}>close</MIcon>
      </Button>
    </CSSclose>
  );
};



export const CSSpreloader = styled.div`
  &:after {
    position: absolute;
    left: 0;
    top: 0;
    botton: 0;
    right: 0;
    z-index: 1;
    background-color: green;
    content: ${(<Svg hight={24}>{preloader}</Svg>)};
  }
`;

export const Preloader = ({ width = 44, height = 44, style = {} }) => {
  return (
    <Svg
      style={{
        position: "relative",
        zIndex: 1,
        ...style
      }}
      width={width}
      height={height}
      forceHeight={height}
    >
      {preloader}
    </Svg>
  );
};

export const Preloader0 = styled.div`
  filter: ${({ invert }) => (invert ? "invert(1)" : "invert(0)")};
  height: ${({ height }) => (height ? height : "100dvh")};
  display: flex;
  flex-direction: column;
  z-index: 100;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: ${({ height }) => (Boolean(height) ? 'relative' : 'absolute')};;
`;



export const PreloaderMain = styled.div`

font-size: 1.4em;
  height: ${({ height }) => (height ? height : "100dvh")};
  display: flex;
  flex-direction: column;
  z-index: 100;
  background-color: white;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: ${({ height }) => (Boolean(height) ? 'relative' : 'absolute')};

`;

export const PreloaderButton = styled.div`
  height: ${({ height }) => (height ? height : "100%")};
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 100;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: absolute;
  cursor: wait;
`;
